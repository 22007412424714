import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class GenerateQrAuthTokenRequest {

  @jsonMember public password!: string;

  public constructor(password: string) {
    this.password = password;
  }

}
