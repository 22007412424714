import { Component } from '@angular/core';
import { SidebarMenuProvider } from '@core/layouts/dashboard-layout/services/sidebar-menu.provider';
import { Observable } from 'rxjs';
import { SidebarMenuItem } from '@core/layouts/dashboard-layout/models/sidebar-menu-item.interface';

@Component({
  selector: 'app-dashboard-layout-sidebar',
  templateUrl: './dashboard-layout-sidebar.component.html',
  styleUrls: ['./dashboard-layout-sidebar.component.scss'],
  providers: [
    SidebarMenuProvider,
  ],
})
export class DashboardLayoutSidebarComponent {
  public menu$: Observable<SidebarMenuItem[]> = this.menuProvider.menu$;

  public constructor(
    private readonly menuProvider: SidebarMenuProvider,
  ) {
  }

}
