import { Injectable } from '@angular/core';
import { ConversationFilter } from '@api/conversations-api/models/filters/conversation.filter';
import { AttributeFilterModel } from '@api/models/collection/requests/attribute-filter.model';
import { ConversationType } from '@api/conversations-api/models/enums/conversation-type.enum';
import { ResourceCollectionRequestBuilder } from '@api/helpers/resource-collection-request.builder';
import { SortModel } from '@api/models/collection/requests/sort.model';
import { SortOrder } from '@api/models/collection/enum/sort-order.enum';
import { ConversationFilterField } from '@api/conversations-api/models/filters/conversation-filter-field.enum';

@Injectable({
  providedIn: 'root',
})
export class ConversationsCollectionRequestBuilder extends ResourceCollectionRequestBuilder<ConversationFilter> {

  public addFilterPrivateOnly(): this {
    const filter = new AttributeFilterModel();
    filter.value = ConversationType.PRIVATE;

    return this.addFilter('type', filter);
  }

  public addFilterGroupOnly(): this {
    const filter = new AttributeFilterModel();
    filter.value = ConversationType.GROUP;

    return this.addFilter('type', filter);
  }

  public addFilterNameContain(name: string): this {
    const filter = new AttributeFilterModel();
    filter.value = name;

    return this.addFilter('name', filter);
  }

  public addFilterGlobal(query: string): this {
    const filter = new AttributeFilterModel();
    filter.operator = 'contain';
    filter.value = query;

    return this.addFilter('global', filter);
  }

  public addOrderByLastMessageDateDesc(): this {
    const sort = new SortModel();

    sort.order = SortOrder.DESC;
    sort.orderBy = ConversationFilterField.LAST_MESSAGE_CREATED_AT;

    this.addSort(sort);

    return this;
  }

  protected getFilterInstance(): ConversationFilter {
    return new ConversationFilter();
  }
}
