import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';

@jsonObject
export class ConfirmResetPasswordRequest {

  @jsonMember(String) public token!: Uuid;
  @jsonMember public password!: string;
  @jsonMember public passwordRepeat!: string;

  public constructor(token: Uuid, password: string, passwordRepeat: string) {
    this.token = token;
    this.password = password;
    this.passwordRepeat = passwordRepeat;
  }
}
