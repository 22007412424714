import { apiParams } from '@environments/const/environment.const';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { CollectionRequest } from '@api/models/collection/requests/collection.request';
import { AttributeFilter } from '@api/common/attributes/models/filters/attribute.filter';
import { Observable } from 'rxjs';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';
import { AttributeResponse } from '@api/common/attributes/models/responses/attribute.response';
import { apiDirectoriesCommon } from '@environments/environment';
import { Uuid } from '@api/types/uuid.type';
import { CreateAttributeRequest } from '@api/common/attributes/models/requests/create-attribute.request';
import { IdResponse } from '@api/interfaces/id.response';
import { UpdateAttributeRequest } from '@api/common/attributes/models/requests/update-attribute.request';
import { AttributesOfDocumentsApi } from '@api/documents-api/services/interfaces/attributes-of-documents.api';

const { ID } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class AttributesOfDocumentsService implements AttributesOfDocumentsApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(request: CollectionRequest<AttributeFilter>): Observable<CollectionResponse<AttributeResponse>> {
    const { ATTRIBUTES: { LIST } } = apiDirectoriesCommon;
    const requestQueryString: string = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, AttributeFilter);
    const url: string = `${this.grabEndpointUrl(LIST)}?${requestQueryString}`;

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToCollection(AttributeResponse),
      );
  }

  public details(id: Uuid): Observable<AttributeResponse> {
    const { ATTRIBUTES: { DETAILS } } = apiDirectoriesCommon;
    const url: string = this
      .grabEndpointUrl(DETAILS)
      .replace(ID, id.toString());

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToModel(AttributeResponse),
      );
  }

  public create(request: CreateAttributeRequest): Observable<IdResponse> {
    const { ATTRIBUTES: { CREATE } } = apiDirectoriesCommon;
    const url: string = this.grabEndpointUrl(CREATE);
    const payload: Object = this.apiPayloadProcessor.toObject(request, CreateAttributeRequest);

    return this.http
      .post(url, payload)
      .pipe(
        this.apiPayloadProcessor.mapToModel(IdResponse),
      );
  }

  public update(id: Uuid, request: UpdateAttributeRequest): Observable<Object> {
    const { ATTRIBUTES: { UPDATE } } = apiDirectoriesCommon;
    const url: string = this
      .grabEndpointUrl(UPDATE)
      .replace(ID, id.toString());
    const payload: Object = this.apiPayloadProcessor.toObject(request, UpdateAttributeRequest);

    return this.http.put(url, payload);
  }

  public delete(id: Uuid): Observable<Object> {
    const { ATTRIBUTES: { DELETE } } = apiDirectoriesCommon;
    const url: string = this
      .grabEndpointUrl(DELETE)
      .replace(ID, id.toString());

    return this.http.delete(url);
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiDirectoriesCommon.API_HOST_URL, endpoint].join('');
  }

}
