import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { ProcedureStatus } from '@api/procedures-api/models/enums/procedure-status';

@jsonObject
export class ProcedureSubmitResponse {
  @jsonMember(String) public readonly procedureId!: Uuid;
  @jsonMember(String) public readonly procedureStatus!: ProcedureStatus;
  @jsonMember public readonly message!: string;
}
