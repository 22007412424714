import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VIDEO_PROVIDER_API } from '@api/video-provider-api/services/tokens/video-provider-api.token';
import { VideoProviderApiService } from '@api/video-provider-api/services/video-provider-api.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    {
      provide: VIDEO_PROVIDER_API,
      useClass: VideoProviderApiService,
    },
  ],
})
export class VideoProviderApiModule {
}
