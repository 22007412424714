import { Component, Input, OnChanges } from '@angular/core';
import { ContactResponse } from '@api/contacts-api/models/responses/contact.response';

@Component({
  selector: 'app-contact-avatar-group',
  templateUrl: './contact-avatar-group.component.html',
  styleUrls: ['./contact-avatar-group.component.scss'],
})
export class ContactAvatarGroupComponent implements OnChanges {

  @Input() public avatarsPerGroup: number = 2;
  @Input() public desiredParticipants: ContactResponse[] = [];

  public otherParticipantsLabel!: string;

  public ngOnChanges(): void {
    this.configureParameters();
  }

  private get otherParticipantsCount(): number {
    return this.desiredParticipants.length - this.avatarsPerGroup;
  }

  private prepareOtherParticipantsLabel(): string {
    return `${this.otherParticipantsCount}+`;
  }

  private configureParameters(): void {
    this.otherParticipantsLabel = this.prepareOtherParticipantsLabel();
  }

}
