import { apiParams } from '@environments/const/environment.const';

const {
  ID,
  TYPE,
  TASK_ID,
  PROCEDURE_ID,
  STAGE_ID,
  FIELD_ID,
  VALIDATOR_ID,
  VERIFICATION_ID,
  PROPERTY_ID,
  CATEGORY,
} = apiParams;

export const _commonEnvironment = {

  // Modul CMUiA - API Podstawowe
  apiCMUiACommon: {
    API_HOST_URL: 'https://cmuia.arsolutions.loc/api',
    AUTHENTICATION: {
      SIGN_IN: '/auth/login',
      REFRESH: '/auth/refresh',
      PASSWORD_RESET_REQUEST: '/auth/reset-password/request',
      PASSWORD_RESET: '/auth/reset-password',
      PASSWORD_COMPLEXITY: '/auth/password-complexity',
      GENERATE_QR_AUTH_TOKEN: '/auth/token/generate',
    },
    ACTION_TOKENS: {
      DETAILS: `/action-tokens/${ID}`,
      CONFIRM: '/action-tokens/{id}/confirm',
    },
    ME: {
      PROFILE: '/me',
      CHANGE_PASSWORD: '/me/change-password',
      DELETE_AVATAR: '/me/avatar',
      UPLOAD_AVATAR: '/me/avatar',
    },
  },

  // Modul CMUiA - API Administracyjne
  apiCMUiAAdmin: {
    API_HOST_URL: 'https://cmuia.arsolutions.loc/api/admin',
    USERS: {
      LIST: '/users',
      DETAILS: `/users/${ID}`,
      CREATE: '/users',
      UPDATE: `/users/${ID}`,
      ACTIVATE: '/users/{id}/activate',
      DEACTIVATE: '/users/{id}/deactivate',
    },
    PERMISSION: {
      LIST: '/permissions',
    },
    PERMISSION_GROUPS: {
      LIST: '/permission-groups',
      DETAILS: `/permission-groups/${ID}`,
      UPDATE: `/permission-groups/${ID}`,
      DELETE: `/permission-groups/${ID}`,
    },
  },

  // Modul slownikow - API Podstawowe
  apiDictionariesCommon: {
    API_HOST_URL: 'https://dictionaries.arsolutions.loc/api',
    DICTIONARIES: {
      LIST: '/dictionaries',
      DETAILS: '/dictionaries/{type}',
    },
    DICTIONARY_ITEMS: {
      LIST: '/dictionaries/{type}/items',
      DETAILS: `/dictionaries/{type}/items/${ID}`,
      CREATE: '/dictionaries/{type}/items',
      UPDATE: `/dictionaries/{type}/items/${ID}`,
      DELETE: `/dictionaries/{type}/items/${ID}`,
    },
  },

  // Modul konwersacji - API Podstawowe
  apiConversationsCommon: {
    API_HOST_URL: 'https://conversations.arsolutions.loc/api',
    CONTACTS: {
      LIST: '/contacts',
      DETAILS: `/contacts/${ID}`,
      PERMISSION_GROUPS: {
        LIST: '/contacts/permission-groups',
      },
    },
    CHAT: {
      TOKEN: '/chat/token',
      CONVERSATIONS: {
        CREATE: '/chat/conversations',
        DELETE: `/chat/conversations/${ID}`,
        LIST: '/chat/conversations',
        DETAILS: `/chat/conversations/${ID}`,
        LEAVE: `/chat/conversations/${ID}/leave`,
        UNREAD_LIST: '/chat/conversations/unread',
        MARK_ALL_READ: '/chat/conversations/mark-all-read',
        FORWARD_MESSAGE: `/chat/messages/${ID}/forward`,
        DELETE_MESSAGE: `/chat/messages/${ID}`,
        ADD_PARTICIPANTS: `/chat/conversations/${ID}/participants`,
        DELETE_PARTICIPANTS: `/chat/conversations/${ID}/participants`,
        PARTICIPANTS_LIST: `/chat/conversations/${ID}/participants`,
      },
      FILES: {
        DOWNLOAD: `/chat/files/${ID}/download`,
      },
    },
    VIDEO: {
      TOKEN: '/video/token',
      ROOM: {
        CALL: '/video/room/call',
        DETAILS: `/video/room/${ID}`,
        CALL_DETAILS: `/video/room/call/${ID}`,
        ACCEPT_CALL: `/video/room/call/${ID}/accept`,
        REJECT_CALL: `/video/room/call/${ID}/reject`,
        CANCEL_CALL: `/video/room/call/${ID}/cancel`,
        CANCEL_MULTIPLE_CALLS: `/video/room/call/cancel`,
        START_RECORDING: `/video/room/${ID}/recordings/start`,
        END_RECORDING: `/video/room/${ID}/recordings/end`,
        INVITE_PARTICIPANTS: `/video/room/${ID}/call`,
        UPLOAD_SCREENSHOT: `/video/room/${ID}/screenshot`,
      },
    },
    NOTIFICATIONS: {
      UNREAD_LIST: '/notifications',
      MARK_ALL_READ: '/notifications/mark-all-read',
      MARK_SINGLE_AS_READ: `/notifications/${ID}/mark-as-read`,
    },
    LIVE_DATA: {
      DISCOVER: '/live-data/discover',
    },
  },

  apiDirectoriesCommon: {
    API_HOST_URL: 'https://documents.arsolutions.loc/api',
    FILE_MANAGER: {
      PROPERTIES: {
        LIST: `file-manager/properties/${TYPE}`,
      },
      DIRECTORIES: {
        ROOT: 'file-manager/directories/root',
        TREE: 'file-manager/directories/tree',
        DETAILS: `file-manager/directories/${ID}`,
        CREATE: 'file-manager/directories',
        UPDATE: `file-manager/directories/${ID}`,
      },
      FILES: {
        DETAILS: `file-manager/files/${ID}`,
        CREATE: `file-manager/files`,
        UPDATE: `file-manager/files/${ID}`,
      },
      DOWNLOAD: 'file-manager/download',
      DETAILS: `file-manager/${ID}`,
      LIST: 'file-manager',
      DELETE: 'file-manager/delete',
      MOVE: 'file-manager/move',
      COPY: 'file-manager/copy',
    },
    REPOSITORY: {
      DETAILS: `repository/${ID}`,
      UPLOAD: `repository/upload`,
      PREVIEW: `repository/${ID}/preview`,
    },
    ATTRIBUTES: {
      LIST: '/attributes',
      DETAILS: `/attributes/${ID}`,
      CREATE: '/attributes',
      UPDATE: `/attributes/${ID}`,
      DELETE: `/attributes/${ID}`,
    },
  },

  apiProceduresManagement: {
    API_HOST_URL: 'https://procedures.arsolutions.loc/api/management',
    PROCEDURES: {
      LIST: '/procedures',
      STATS: '/procedures/stats',
      DETAILS: `/procedures/${PROCEDURE_ID}`,
      UPDATE: `/procedures/${PROCEDURE_ID}`,
      DELETE: `/procedures/${PROCEDURE_ID}`,
      ARCHIVE: `/procedures/${PROCEDURE_ID}/archive`,
      SUBMIT: `/procedures/${PROCEDURE_ID}/submit`,
      VERSION: `/procedures/${PROCEDURE_ID}/version`,
      STAGES: {
        LIST: `/procedures/${PROCEDURE_ID}/stages`,
        UPDATE: `/procedures/${PROCEDURE_ID}/stages/${STAGE_ID}`,
        DELETE: `/procedures/${PROCEDURE_ID}/stages/${STAGE_ID}`,
        DETAILS: `/procedures/${PROCEDURE_ID}/stages/${STAGE_ID}`,
        CREATE: `/procedures/${PROCEDURE_ID}/stages`,
        REORDER: `/procedures/${PROCEDURE_ID}/stages/${STAGE_ID}/reorder`,
      },
      STAGES_EXECUTION_ORDER: {
        LIST: `/stages/${STAGE_ID}/orders`,
        CREATE: `/stages/${STAGE_ID}/orders`,
        UPDATE: `/stages/${STAGE_ID}/orders/${ID}`,
        DELETE: `/stages/${STAGE_ID}/orders/${ID}`,
        DETAILS: `/stages/${STAGE_ID}/orders/${ID}`,
      },
      FIELDS: {
        LIST: `/stages/${STAGE_ID}/fields`,
        UPDATE: `/stages/${STAGE_ID}/fields/${FIELD_ID}`,
        DELETE: `/stages/${STAGE_ID}/fields/${FIELD_ID}`,
        DETAILS: `/stages/${STAGE_ID}/fields/${FIELD_ID}`,
        CREATE: `/stages/${STAGE_ID}/fields`,
        PROPERTIES: {
          LIST: `/fields/${FIELD_ID}/attributes`,
          UPDATE: `/fields/${FIELD_ID}/attributes/${PROPERTY_ID}`,
          DELETE: `/fields/${FIELD_ID}/attributes/${PROPERTY_ID}`,
          CREATE: `/fields/${FIELD_ID}/attributes`,
        },
        VALIDATORS: {
          LIST: `/fields/${FIELD_ID}/validators`,
          UPDATE: `/fields/${FIELD_ID}/validators/${VALIDATOR_ID}`,
          DELETE: `/fields/${FIELD_ID}/validators/${VALIDATOR_ID}`,
          CREATE: `/fields/${FIELD_ID}/validators`,
        },
      },
      ATTRIBUTES: {
        LIST: '/attributes',
        DETAILS: `/attributes/${ID}`,
        CREATE: '/attributes',
        UPDATE: `/attributes/${ID}`,
        DELETE: `/attributes/${ID}`,
      },
      VERIFICATIONS: {
        LIST: `/procedures/${PROCEDURE_ID}/verifications`,
        ACCEPT: `/procedures/${PROCEDURE_ID}/verifications/${VERIFICATION_ID}/accept`,
        REJECT: `/procedures/${PROCEDURE_ID}/verifications/${VERIFICATION_ID}/reject`,
      },
      EXPRESSIONS: {
        LIST: '/expressions/operators',
        VALIDITY: '/expressions/lint',
      },
    },
    DICTIONARIES: {
      LIST: `/dictionaries/${TYPE}/items`,
    },
    TASKS: {
      LIST: '/tasks',
      CREATE: '/tasks',
      UPDATE: `/tasks/${TASK_ID}`,
      DETAILS: `/tasks/${TASK_ID}`,
      DELETE: `/tasks/${TASK_ID}`,
      CANCEL: `/tasks/${TASK_ID}/cancel`,
      CANCEL_MULTIPLE: '/tasks/cancel',
      ARCHIVE: `/tasks/${TASK_ID}/archive`,
      STATS: `/tasks/stats`,
      PROCEDURES: '/tasks/procedures',
      ACTIVITIES: {
        LIST: `/tasks/${TASK_ID}/actions`,
        ACCEPT: `/tasks/${TASK_ID}/verifications/${ID}/accept`,
        REJECT: `/tasks/${TASK_ID}/verifications/${ID}/reject`,
      },
      STAGES: {
        LIST: `/tasks/${TASK_ID}/stages`,
        DETAILS: `/tasks/${TASK_ID}/stages/${STAGE_ID}`,
      },
    },
  },
  apiProceduresCommon: {
    API_HOST_URL: 'https://procedures.arsolutions.loc/api',
    TASKS: {
      LIST: '/tasks',
      DETAILS: `/tasks/${TASK_ID}`,
      STATS: '/tasks/stats',
      ACTIVITIES: {
        LIST: `/tasks/${TASK_ID}/actions`,
      },
      STAGES: {
        LIST: `/tasks/${TASK_ID}/stages`,
        DETAILS: `/tasks/${TASK_ID}/stages/${STAGE_ID}`,
      },
    },
    DICTIONARIES: {
      LIST: `/dictionaries/${TYPE}/items`,
    },
    EXPERTS: {
      LIST: '/experts',
    },
    FIELD_WORKERS: {
      LIST: '/employees',
    },
    NOTIFICATIONS: {
      UNREAD_LIST: '/notifications',
      MARK_ALL_READ: '/notifications/mark-all-as-read',
      MARK_SINGLE_AS_READ: `/notifications/${ID}/mark-as-read`,
    },
  },

  // Modul Nofitier - API Podstawowe
  apiNotifierCommon: {
    API_HOST_URL: 'https://notifier.arsolutions.loc/api',
    PUSH: {
      REGISTER_DEVICE: '/push/register-device',
      UNREGISTER_DEVICE: '/push/unregister-device',
    },
  },

  apiReportsCommon: {
    API_HOST_URL: 'https://reports.arsolutions.loc/api',
    DEV_EXPRESS_API_GATE: '/devexpress/dashboard',
    DASHBOARDS: {
      LIST: '/dashboards',
      DETAILS: `/dashboards/${ID}`,
      DELETE: `/dashboards/${ID}`,
      GET_DEFAULT: `/dashboards/default`,
      SET_DEFAULT: `/dashboards/default`,
      DASHBOARD_PERMISSIONS: `/dashboards/${ID}/permissions`,
      SET_DASHBOARD_PERMISSIONS: `/dashboards/${ID}/permissions`,
      PERMISSIONS_LIST: '/permissions',
    },
  },

  apiSettingsCommon: {
    API_HOST_URL: 'https://settings.arsolutions.loc/api',
    LIST: `/settings/${CATEGORY}`,
    UPDATE: `/settings/${CATEGORY}`,
  },

  firebase: {
    apiKey: 'AIzaSyAsodJjbbcs2Alqt1OdBmB8K5Scy9qpcUY',
    authDomain: 'arsolutions-9716f.firebaseapp.com',
    projectId: 'arsolutions-9716f',
    storageBucket: 'arsolutions-9716f.appspot.com',
    messagingSenderId: '384011919297',
    appId: '1:384011919297:web:6bb79f0ce58ac17799d940',
    measurementId: 'G-31VBNVSHYP',
  },
};
