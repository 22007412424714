import { ProcedureExpressionsApi } from '@api/procedures-api/services/interfaces/procedure-expressions.api';
import { apiProceduresManagement } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { Observable } from 'rxjs';
import { ProcedureExpressionValidityRequest } from '@api/procedures-api/models/requests/procedure-expression-validity.request';
import { Injectable } from '@angular/core';
import { ProcedureExpressionOperator } from '@api/procedures-api/models/enums/procedure-expression-operator';

@Injectable({
  providedIn: 'root',
})
export class ProcedureExpressionsApiService implements ProcedureExpressionsApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(): Observable<Array<ProcedureExpressionOperator>> {
    const { EXPRESSIONS: { LIST } } = apiProceduresManagement.PROCEDURES;
    const url = this.grabEndpointUrl(LIST);

    return this
      .http
      .get<Array<ProcedureExpressionOperator>>(url);
  }

  public validity(request: ProcedureExpressionValidityRequest): Observable<void> {
    const { EXPRESSIONS: { VALIDITY } } = apiProceduresManagement.PROCEDURES;
    const url = this.grabEndpointUrl(VALIDITY);
    const body = this.apiPayloadProcessor.toObject(request, ProcedureExpressionValidityRequest);

    return this
      .http
      .post<void>(url, body);
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiProceduresManagement.API_HOST_URL, endpoint].join('');
  }

}
