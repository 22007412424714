import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class TaskContextOperationsResponse {

  @jsonMember public readonly canUpdate!: boolean;
  @jsonMember public readonly canDelete!: boolean;
  @jsonMember public readonly canArchive!: boolean;
  @jsonMember public readonly canCancel!: boolean;
  @jsonMember public readonly canView!: boolean;

}
