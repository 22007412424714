import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Permission } from '../models/permission.enum';
import { PermissionsList } from '../models/permissions-list.model';
import { ShowIfHasPermissionDirective } from './show-if-has-permission.directive';
import { PermissionsService } from '@core/permissions/services/permissions.service';

@Directive({
  selector: '[appHideIfHasPermission]',
})
export class HideIfHasPermissionDirective extends ShowIfHasPermissionDirective {

  @Input('appHideIfHasPermission')
  public set permission(permission: Permission | Permission[] | PermissionsList) {
    this.permissionToCheck = permission;
    this.evaluateVisibility();
  }

  public constructor(permissionsService: PermissionsService, templateRef: TemplateRef<Object>, viewContainer: ViewContainerRef) {
    super(permissionsService, templateRef, viewContainer);
  }

  protected render(): void {
    if (!this.hasPermission) {
      this.addView();
    } else {
      this.removeView();
    }
  }
}
