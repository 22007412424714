import { jsonArrayMember, jsonObject } from 'typedjson';
import { FilePropertyRequest } from '@api/documents-api/models/requests/file-property.request';
import { AttributeRequest } from '@api/common/attributes/models/requests/attribute.request';

@jsonObject
export class UpdateDocumentRequest {

  @jsonArrayMember(FilePropertyRequest) public readonly properties!: FilePropertyRequest[];
  @jsonArrayMember(AttributeRequest) public readonly attributes?: AttributeRequest[];

  public constructor(
    properties: FilePropertyRequest[],
    attributes?: AttributeRequest[],
  ) {
    this.properties = properties;
    this.attributes = attributes;
  }

}
