import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { UserStatus } from '@api/cmuia-api/models/enums/user-status';

@jsonObject
export class UpdateUserRequest {

  @jsonMember(String) public id!: Uuid;
  @jsonMember(String) public status?: UserStatus;
  @jsonArrayMember(String) public permissionGroupsIds!: Uuid[];
  @jsonMember public email?: string;
  @jsonMember public firstName?: string;
  @jsonMember public lastName?: string;
  @jsonMember public jobName?: string;
  @jsonMember public department?: string;

  public constructor(
    id: Uuid,
    status?: UserStatus,
    permissionGroupsIds?: Uuid[],
    email?: string,
    firstName?: string,
    lastName?: string,
    jobName?: string,
    department?: string,
  ) {
    this.id = id;
    this.permissionGroupsIds = permissionGroupsIds ?? [];
    this.status = status;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.jobName = jobName;
    this.department = department;
  }
}
