<p-avatar
  shape="circle"
  [label]="label"
  [image]="avatarUrl"
  [icon]="avatarIcon ? (avatarIcon | iconCssClass) : undefined"
  [size]="size"
  [class.md]="mediumSize"
  styleClass="contact-avatar">

  <p-badge
    *ngIf="badgeSeverity"
    [severity]="badgeSeverity"
    styleClass="p-badge-{{badgeSeverity}}">
  </p-badge>
</p-avatar>
