import { Injectable } from '@angular/core';
import { ProcedureVerificationsApi } from '@api/procedures-api/services/interfaces/procedure-verifications.api';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { Uuid } from '@app/api/types/uuid.type';
import { Observable } from 'rxjs';
import { CollectionRequest } from '@api/models/collection/requests/collection.request';
import { ProcedureVerificationFilter } from '@api/procedures-api/models/filters/procedure-verification.filter';
import { ProcedureVerificationResponse } from '@api/procedures-api/models/responses/procedure-verification.response';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';
import { apiProceduresManagement } from '@environments/environment';
import { apiParams } from '@environments/const/environment.const';

const { PROCEDURE_ID, VERIFICATION_ID } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class ProcedureVerificationsApiService implements ProcedureVerificationsApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(procedureId: Uuid, request?: CollectionRequest<ProcedureVerificationFilter>): Observable<CollectionResponse<ProcedureVerificationResponse>> {
    let url: string = this.grabEndpointUrl(apiProceduresManagement.PROCEDURES.VERIFICATIONS.LIST)
      .replace(PROCEDURE_ID, procedureId.toString());

    if (request) {
      url = url.concat('?', this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, ProcedureVerificationFilter));
    }

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToCollection(ProcedureVerificationResponse),
      );
  }

  public accept(procedureId: Uuid, verificationId: Uuid): Observable<void> {
    const url: string = this.grabEndpointUrl(apiProceduresManagement.PROCEDURES.VERIFICATIONS.ACCEPT)
      .replace(PROCEDURE_ID, procedureId.toString())
      .replace(VERIFICATION_ID, verificationId.toString());

    return this.http.post<void>(url, null);
  }

  public reject(procedureId: Uuid, verificationId: Uuid, comment?: string): Observable<void> {
    const url: string = this.grabEndpointUrl(apiProceduresManagement.PROCEDURES.VERIFICATIONS.REJECT)
      .replace(PROCEDURE_ID, procedureId.toString())
      .replace(VERIFICATION_ID, verificationId.toString());

    const payload = {
      comment,
    };

    return this.http.post<void>(url, payload);
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiProceduresManagement.API_HOST_URL, endpoint].join('');
  }
}
