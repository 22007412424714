import { Injectable } from '@angular/core';
import { TaskActivitiesManagementApi } from '@api/procedures-api/services/interfaces/task-activities-management.api';
import { apiProceduresManagement } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { apiParams } from '@environments/const/environment.const';
import { Uuid } from '@app/api/types/uuid.type';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';
import { Observable } from 'rxjs';
import { CollectionRequest } from '@api/models/collection/requests/collection.request';
import { TaskActivitiesFilter } from '@api/procedures-api/models/filters/task-activities.filter';
import { TaskActivityVerificationRejectRequest } from '../models/requests/task-activity-verification-reject.request';
import { TaskActivityVerificationAcceptRequest } from '@api/procedures-api/models/requests/task-activity-verification-accept.request';
import { TaskActivityResponse } from '../models/responses/task-activity.response';

const { TASK_ID, ID } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class TaskActivitiesManagementApiService implements TaskActivitiesManagementApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(request: CollectionRequest<TaskActivitiesFilter>, id: Uuid): Observable<CollectionResponse<TaskActivityResponse>> {
    const { ACTIVITIES: { LIST } } = apiProceduresManagement.TASKS;
    const requestQueryString: string = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, TaskActivitiesFilter);
    const url: string = `${this.grabEndpointUrl(LIST)
      .replace(TASK_ID, id.toString())}?${requestQueryString}`;

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToCollection(TaskActivityResponse),
      );
  }

  public accept(taskId: Uuid, verificationId: Uuid, request?: TaskActivityVerificationAcceptRequest): Observable<void> {
    const { ACTIVITIES: { ACCEPT } } = apiProceduresManagement.TASKS;
    const payload: Object = this.apiPayloadProcessor.toObject(request, TaskActivityVerificationAcceptRequest);
    const url: string = this.grabEndpointUrl(ACCEPT)
      .replace(TASK_ID, taskId.toString())
      .replace(ID, verificationId.toString());

    return this
      .http
      .post<void>(url, payload);
  }

  public reject(taskId: Uuid, verificationId: Uuid, request?: TaskActivityVerificationRejectRequest): Observable<void> {
    const { ACTIVITIES: { REJECT } } = apiProceduresManagement.TASKS;
    const payload: Object = this.apiPayloadProcessor.toObject(request, TaskActivityVerificationRejectRequest);
    const url: string = this.grabEndpointUrl(REJECT)
      .replace(TASK_ID, taskId.toString())
      .replace(ID, verificationId.toString());

    return this
      .http
      .post<void>(url, payload);
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiProceduresManagement.API_HOST_URL, endpoint].join('');
  }

}
