import { jsonArrayMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';

@jsonObject
export class DeleteParticipantsRequest {

  @jsonArrayMember(String) public readonly participantsIds!: Uuid[];

  public constructor(
    participantsIds: Uuid[],
  ) {
    this.participantsIds = participantsIds;
  }
}
