<p-confirmDialog
  #confirmDialog
  styleClass="alert-dialog {{ alertMessage?.severity | alertDialogSeverityCssClass }}"
  [style]="{maxWidth: '60vw'}"
  [rejectVisible]="false"
  [acceptIcon]="null"
  [key]="key">

  <ng-template pTemplate="header">
    <span></span>

    <div>
      <div
        *ngIf="alertMessage && alertMessage.severityIcon"
        class="alert-dialog__severity-icon">
        <i [class]="alertMessage.severityIcon | iconCssClass"></i>
      </div>
      <span
        *ngIf="confirmDialog.option('header')"
        class="p-dialog-title alert-dialog">
        {{ confirmDialog.option('header') }}
      </span>
    </div>

    <div class="p-dialog-header-icons">
      <button
        *ngIf="confirmDialog.closable"
        type="button"
        [ngClass]="{'p-dialog-header-icon p-dialog-header-close p-link':true}"
        (click)="confirmDialog.close($event)"
        (keydown.enter)="confirmDialog.close($event)">
        <span class="pi pi-times"></span>
      </button>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      [icon]="confirmDialog.option('acceptIcon')"
      [label]="confirmDialog.acceptButtonLabel"
      (click)="confirmDialog.accept()"
      [ngClass]="'alert-dialog-accept'"
      [class]="confirmDialog.option('acceptButtonStyleClass')"
      [attr.aria-label]="confirmDialog.acceptAriaLabel">
    </button>
  </ng-template>

</p-confirmDialog>
