import { Injectable, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastMessage } from '@common/components/toast/interface/toast-message.interface';
import { ToastMessageBuilder } from '@common/components/toast/services/toast-message.builder';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable()
export class ToastMessageService implements OnDestroy {

  private messagesQueue: ToastMessage[] = [];
  private destroy$ = new Subject<void>();

  public constructor(
    private readonly messageService: MessageService,
    private readonly toastMessageBuilder: ToastMessageBuilder,
    private readonly router: Router,
  ) {
    this.showQueuedMessagesOnNavigationEnd();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public show(message: ToastMessage): void {
    this.messageService.add(message);
  }

  public messageBuilder(): ToastMessageBuilder {
    return this.toastMessageBuilder;
  }

  public enqueue(message: ToastMessage): void {
    this.messagesQueue.push(message);
  }

  private showQueuedMessagesOnNavigationEnd(): void {
    this.router
      .events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        // Niestety musze uzyc setTimeout bo inaczej toast nie chce sie pokazac :-(
        setTimeout(() => {
          this.showQueuedMessages();
        });
      });
  }

  private showQueuedMessages(): void {
    let message;
    while (message = this.messagesQueue.pop()) {
      this.show(message);
    }
  }

}
