import { Inject, Injectable } from '@angular/core';
import { CHAT_API } from '@api/conversations-api/services/tokens/chat-api.token';
import { ChatApi } from '@api/conversations-api/services/interfaces/chat.api';
import { backoff } from '@common/utils/backoff.operator';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { CONVERSATIONS_PROVIDER_API } from '@api/conversations-provider-api/services/tokens/conversations-provider-api.token';
import { ConversationsProviderApi } from '@api/conversations-provider-api/services/interfaces/conversations-provider.api';

@Injectable({
  providedIn: 'root',
})
export class TokenRetrieverService {

  private retrievingCancel$ = new Subject<void>();
  private receiveRetrying$ = new BehaviorSubject<boolean>(false);

  public receiveRetrying = this.receiveRetrying$.asObservable();

  public constructor(@Inject(CHAT_API) private chatApi: ChatApi,
                     @Inject(CONVERSATIONS_PROVIDER_API) private conversationsProviderApi: ConversationsProviderApi) {
  }

  public retrieveChatToken(): void {
    this.chatApi.getToken().pipe(
      backoff(20, 1000, () => this.onReceiveRetrying()),
      takeUntil(this.retrievingCancel$),
    ).subscribe(
      ({ token }) => this.onChatTokenRetrievalSuccess(token),
      this.onChatTokenRetrievalError,
    );
  }

  private connectChatClient(token: string): void {
    this.conversationsProviderApi.connectClient(token).pipe(
      backoff(20, 1000, () => this.onReceiveRetrying()),
      takeUntil(this.retrievingCancel$),
    ).subscribe(
      () => this.onChatConnectSuccess(),
      this.onChatConnectError,
    );
  }

  public cancelRetrieving(): void {
    this.retrievingCancel$.next();
  }

  private onChatConnectError(error: HttpErrorResponse): void {
    console.error('Połączenie z klientem czatu zamknięte permanentnie. Nie nastąpi więcej prób wznowienia połączenia.', error);
  }

  private onChatTokenRetrievalError(error: HttpErrorResponse): void {
    console.error('Przerwanie ponownych żądań pozyskania tokenu czatu. Nie nastąpi więcej prób pozyskania tokenu.', error);
  }

  private onChatConnectSuccess(): void {
    this.refreshTokenOnExpire();
    this.onReceived();
  }

  private onChatTokenRetrievalSuccess(token: string): void {
    this.connectChatClient(token);
    this.onReceived();
  }

  private refreshTokenOnExpire(): void {
    this.conversationsProviderApi
      .tokenAboutToExpire()
      .pipe(
        switchMap(() => this.chatApi.getToken()),
        switchMap(response => this.conversationsProviderApi.updateToken(response.token)),
        takeUntil(this.conversationsProviderApi.clientShutdown),
      ).subscribe();
  }

  private onReceived(): void {
    this.receiveRetrying$.next(false);
  }

  private onReceiveRetrying(): void {
    this.receiveRetrying$.next(true);
  }

}
