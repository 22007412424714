import { LogicalOperator } from './logical-operator.enum';
import { Permission } from './permission.enum';

export class PermissionsList {

  public permissions: Permission[];
  public operator: LogicalOperator;

  public constructor(permissions: Permission[], operator?: LogicalOperator) {
    this.permissions = permissions;
    this.operator = operator ? operator : LogicalOperator.AND;
  }

}
