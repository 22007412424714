import { jsonArrayMember, jsonObject } from 'typedjson';

@jsonObject
export class AttributeConfigRequest {

  @jsonArrayMember(String) public readonly availableValues?: string[];

  public constructor(availableValues?: string[]) {
    this.availableValues = availableValues;
  }

}
