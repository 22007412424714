import { jsonArrayMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';

@jsonObject
export class InviteParticipantsRequest {
  @jsonArrayMember(String) public participantsIds!: Uuid[];

  public constructor(participantsIds: Uuid[]) {
    this.participantsIds = participantsIds;
  }
}
