import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {

  public constructor(private messageService: MessageService,
                     private cd: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    /**
     * ChangeDetector jest wywolywany w tym miejscu, poniewaz tylko wtedy dzialaja tosty wyswietlane programowo
     * przez inne komponenty w metodzie ngOnInit, ngAfterViewInit.
     */
    this.cd.detectChanges();
  }

}
