<p-avatarGroup>
  <ng-container *ngFor="let participant of desiredParticipants; let i=index">
    <ng-container *ngIf="i < avatarsPerGroup">
      <app-contact-avatar
        size="xlarge"
        [contact]="participant">
      </app-contact-avatar>
    </ng-container>
  </ng-container>
  <app-contact-avatar
    class="other-participants"
    size="xlarge"
    [label]="otherParticipantsLabel">
  </app-contact-avatar>
</p-avatarGroup>
