import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { CONVERSATIONS_LIVE_DATA_API } from '@api/conversations-api/services/tokens/conversations-live-data-api.token';
import { ConversationsLiveDataApi } from '@api/conversations-api/services/interfaces/conversations-live-data.api';

@Injectable({
  providedIn: 'root',
})
export class ConversationsLiveDataConnectionInitializedGuard implements CanActivate {

  public constructor(@Inject(CONVERSATIONS_LIVE_DATA_API) private conversationsLiveDataService: ConversationsLiveDataApi) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.openLiveDataConnection();

    return true;
  }

  private openLiveDataConnection(): void {
    this.conversationsLiveDataService.shutDownConnection();
    this.conversationsLiveDataService.openConnection();
  }
}
