import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class TasksStatsResponse {

  @jsonMember public readonly createdCount!: number;
  @jsonMember public readonly expiredCount!: number;
  @jsonMember public readonly finishedCount!: number;
  @jsonMember public readonly alertsCount!: number;

}
