import { Injectable } from '@angular/core';
import { AssetsMusicProvider } from '@core/assets/providers/assets-music.provider';
import { AssetsMusic } from '@core/assets/enums/assets-music.enum';

@Injectable({ providedIn: 'root' })
export class MusicHandler {
  public constructor(private assetsMusicProvider: AssetsMusicProvider) {
  }

  public play(music: AssetsMusic, loop?: boolean): HTMLAudioElement {
    const musicPath = this.assetsMusicProvider.getUrl(music);
    const audio = new Audio(musicPath);
    audio.loop = !!loop;
    void audio.play();

    return audio;
  }
}
