import { ContactsApi } from '@api/contacts-api/services/interfaces/contacts.api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactResponse } from '@api/contacts-api/models/responses/contact.response';
import { Uuid } from '@api/types/uuid.type';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';
import { CollectionRequest } from '@api/models/collection/requests/collection.request';
import { ContactFilter } from '@api/contacts-api/models/filters/contact.filter';
import { apiConversationsCommon } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { ContactsCollectionRequestBuilder } from '@api/contacts-api/services/contacts-collection-request.builder';

@Injectable({
  providedIn: 'root',
})
export class ContactsApiService implements ContactsApi {

  public constructor(
    private http: HttpClient,
    private apiPayloadProcessor: ApiPayloadProcessorService,
    private collectionRequestBuilder: ContactsCollectionRequestBuilder,
  ) {
  }

  public list(request: CollectionRequest<ContactFilter>): Observable<CollectionResponse<ContactResponse>> {
    const requestQueryString = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, ContactFilter);
    const url = this.grabEndpointUrl(apiConversationsCommon.CONTACTS.LIST) + '?' + requestQueryString;

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToCollection(ContactResponse),
    );
  }

  public details(id: Uuid): Observable<ContactResponse> {
    const url = this.grabEndpointUrl(apiConversationsCommon.CONTACTS.DETAILS).replace('{id}', id.toString());

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToModel(ContactResponse),
    );
  }

  public getCollectionRequestBuilder(): ContactsCollectionRequestBuilder {
    return this.collectionRequestBuilder;
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiConversationsCommon.API_HOST_URL, endpoint].join('');
  }
}
