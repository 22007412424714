export enum AttributeFilterOperator {
  CONTAIN = 'contain',
  EQUAL = 'equal',
  NOT_EQUAL = 'notEqual',
  BETWEEN = 'between',
  IS_NULL = 'isNull',
  IS_NOT_NULL = 'isNotNull',
  LT = 'lt',
  LTE = 'lte',
  GT = 'gt',
  GTE = 'gte',
  IN = 'in',
  NOT_IN = 'notIn',
}
