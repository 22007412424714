import { apiParams } from '@environments/const/environment.const';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { apiProceduresManagement } from '@environments/environment';
import { ProcedureFieldValidatorsApi } from '@api/procedures-api/services/interfaces/procedure-field-validators.api';
import { ProcedureFieldValidatorCreateRequest } from '@api/procedures-api/models/requests/procedure-field-validator-create.request';
import { Observable } from 'rxjs';
import { Uuid } from '@api/types/uuid.type';
import { ProcedureFieldValidatorResponse } from '@api/procedures-api/models/responses/procedure-field-validator.response';
import { ProcedureFieldValidatorUpdateRequest } from '@api/procedures-api/models/requests/procedure-field-validator-update.request';
import { IdResponse } from '@api/interfaces/id.response';

const { VALIDATOR_ID, FIELD_ID } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class ProcedureFieldValidatorsApiService implements ProcedureFieldValidatorsApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public add(fieldId: Uuid, request: ProcedureFieldValidatorCreateRequest): Observable<IdResponse> {
    const { PROCEDURES: { FIELDS: { VALIDATORS: { CREATE } } } } = apiProceduresManagement;
    const body: Object = this.apiPayloadProcessor.toObject(request, ProcedureFieldValidatorCreateRequest);
    const url: string = this
      .grabEndpointUrl(CREATE)
      .replace(FIELD_ID, fieldId.toString());

    return this
      .http
      .post(url, body)
      .pipe(this.apiPayloadProcessor.mapToModel(IdResponse));
  }

  public delete(fieldId: Uuid, validatorId: Uuid): Observable<Object> {
    const { PROCEDURES: { FIELDS: { VALIDATORS: { DELETE } } } } = apiProceduresManagement;
    const url: string = this
      .grabEndpointUrl(DELETE)
      .replace(FIELD_ID, fieldId.toString())
      .replace(VALIDATOR_ID, validatorId.toString());

    return this
      .http
      .delete(url);
  }

  public list(fieldId: Uuid): Observable<ProcedureFieldValidatorResponse[]> {
    const { PROCEDURES: { FIELDS: { VALIDATORS: { LIST } } } } = apiProceduresManagement;
    const url: string = this
      .grabEndpointUrl(LIST)
      .replace(FIELD_ID, fieldId.toString());

    return this
      .http
      .get(url)
      .pipe(this.apiPayloadProcessor.mapToModelArray(ProcedureFieldValidatorResponse));
  }

  public update(fieldId: Uuid, validatorId: Uuid, request: ProcedureFieldValidatorUpdateRequest): Observable<Object> {
    const { PROCEDURES: { FIELDS: { VALIDATORS: { UPDATE } } } } = apiProceduresManagement;
    const body: Object = this.apiPayloadProcessor.toObject(request, ProcedureFieldValidatorUpdateRequest);
    const url: string = this
      .grabEndpointUrl(UPDATE)
      .replace(FIELD_ID, fieldId.toString())
      .replace(VALIDATOR_ID, validatorId.toString());

    return this
      .http
      .put(url, body);
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiProceduresManagement.API_HOST_URL, endpoint].join('');
  }

}
