import { Injectable } from '@angular/core';
import { Serializable } from 'typedjson';
import { JsonSerializer } from '@core/serializer/json.serializer';
import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';
import { CollectionMetadata } from '@api/models/collection/responses/collection-metadata';
import { CollectionRequest } from '@api/models/collection/requests/collection.request';
import { IStringifyOptions, stringify } from 'qs';
import { ResourceFilterModel } from '@api/models/collection/requests/resource-filter.model';
import { SortModel } from '@api/models/collection/requests/sort.model';
import { SortOrder } from '@api/models/collection/enum/sort-order.enum';

@Injectable({
  providedIn: 'root',
})
export class ApiPayloadProcessorService {

  public toObject<T>(object: T, type: Serializable<T>): Object {
    const defaultObjectString: string = '{}';

    return JSON.parse(JsonSerializer.stringify(object, type) || defaultObjectString);
  }

  public toModel<T>(object: Object, type: Serializable<T>): T {
    return JsonSerializer.parse(object, type);
  }

  public toArray<T>(object: Object, type: Serializable<T>): T[] {
    return JsonSerializer.parseArray(object, type);
  }

  public mapToModel<S, T>(type: Serializable<T>): OperatorFunction<S, T> {
    return map((value: S) => this.toModel(value, type));
  }

  public mapToModelArray<S, T>(type: Serializable<T>): OperatorFunction<S, T[]> {
    return map((value: S) => this.toArray(value, type));
  }

  public mapToCollection<Item>(type: Serializable<Item>): OperatorFunction<Object, CollectionResponse<Item>> {
    return map((response) => {
      const responseCollection = response as CollectionResponse<Object>;

      return CollectionResponse.from(
        this.toArray(responseCollection.collection, type),
        this.toModel(responseCollection.metadata, CollectionMetadata),
      );
    });
  }

  public mapToCollectionRequestQueryString(request: CollectionRequest<ResourceFilterModel>, filterType: Serializable<ResourceFilterModel>): string {
    const { filter, page, limit, sorts, pagination } = request;

    return this.mapObjectToQueryString({
      filter: this.toObject(filter, filterType),
      limit: limit,
      page: page,
      order: sorts
        ? this.toOrderObject(sorts)
        : null,
      pagination: pagination ?? undefined,
    });
  }

  public mapModelToQueryString<T>(model: T, type: Serializable<T>): string {
    const plainObject: Object = this.toObject(model, type);

    return this.mapObjectToQueryString(plainObject);
  }

  public mapObjectToQueryString(object: Object, options: IStringifyOptions = { skipNulls: true }): string {
    return stringify(object, options);
  }

  private toOrderObject(sorts: SortModel[]): { [key: string]: SortOrder } {
    return sorts.reduce((object: {}, sort: SortModel) => {
      return {
        ...object,
        ...{ [sort.orderBy.toString()]: sort.order },
      };
    }, {});
  }
}
