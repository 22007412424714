import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoutePathPipe } from './route-path.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    RoutePathPipe,
  ],
  exports: [
    RoutePathPipe,
  ],
})
export class RoutingPipesModule {
}
