import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { LayoutSwitcherService } from '../services/layout-switcher.service';
import { MenuItem } from 'primeng/api';
import { LayoutName } from '../enums/layout-name.enum';
import { Observable } from 'rxjs';
import { PageTitleProvider } from '../components/page-title/providers/page-title.provider';
import { BreadcrumbsProvider } from '../components/breadcrumbs/providers/breadcrumbs.provider';
import { DialogService } from 'primeng/dynamicdialog';
import { IncomingCallsReceiverService } from '@common/components/audio-video-calling/services/incoming-calls-receiver.service';
import { TokenRetrieverService } from '@common/services/token-retriever.service';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
  providers: [
    DialogService,
    IncomingCallsReceiverService,
  ],
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {

  private readonly SIDEBAR_ACTIVE_CSS_CLASS = 'grid-container--sidenav-expanded';

  @ViewChild('gridContainer', { static: false }) public gridContainer!: ElementRef;

  public pageTitle$: Observable<string> = this.pageTitleProvider.title$;
  public breadcrumbs$: Observable<MenuItem[]> = this.breadcrumbsProvider.breadcrumbs$;

  public constructor(
    private readonly layoutService: LayoutSwitcherService,
    private readonly pageTitleProvider: PageTitleProvider,
    private readonly breadcrumbsProvider: BreadcrumbsProvider,
    private readonly renderer: Renderer2,
    private readonly incomingCallReceiver: IncomingCallsReceiverService,
    private readonly tokenRetrieverService: TokenRetrieverService,
  ) {

    this.layoutService.setLayout(LayoutName.DASHBOARD);
  }

  public ngOnInit(): void {
    this.incomingCallReceiver.subscribeForIncomingCalls();
  }

  public ngOnDestroy(): void {
    this.layoutService.unsetLayout();
    this.tokenRetrieverService.cancelRetrieving();
  }

  public toggleMenu(): void {
    const htmlElem: HTMLElement = this.gridContainer.nativeElement;
    const isActive = htmlElem.classList.contains(this.SIDEBAR_ACTIVE_CSS_CLASS);

    if (isActive) {
      this.renderer.removeClass(htmlElem, this.SIDEBAR_ACTIVE_CSS_CLASS);
    } else {
      this.renderer.addClass(htmlElem, this.SIDEBAR_ACTIVE_CSS_CLASS);
    }
  }
}
