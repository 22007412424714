import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingIndicatorService {
  private readonly visibilitySource$: BehaviorSubject<boolean>;

  public constructor() {
    this.visibilitySource$ = new BehaviorSubject<boolean>(false);
  }

  public get visibility$(): Observable<boolean> {
    return this.visibilitySource$.asObservable();
  }

  public show(): void {
    this.visibilitySource$.next(true);
  }

  public hide(): void {
    this.visibilitySource$.next(false);
  }
}
