export enum DictionaryType {

  APPLICATION_STATUSES = 'APPLICATION_STATUSES',
  APPLICATION_PRIORITIES = 'APPLICATION_PRIORITIES',
  PROCESS_CATEGORIES = 'PROCESS_CATEGORIES',
  PROCEDURE_STATUSES = 'PROCEDURE_STATUSES',
  PROCEDURE_CATEGORIES = 'PROCEDURE_CATEGORIES',
  TASK_STATUSES = 'TASK_STATUSES',
  TASK_PRIORITIES = 'TASK_PRIORITIES',

}
