import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AlertTypeKey } from '@common/components/alert/enums/alert-type-key.enum';
import { AlertMessage } from '@common/components/alert/interface/alert-message.interface';
import { ConfirmDialog } from 'primeng/confirmdialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent implements OnInit {

  public readonly key = AlertTypeKey.DIALOG;

  @ViewChild('confirmDialog', { static: false }) public confirmDialogCmp?: ConfirmDialog;

  public constructor(private cd: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    /**
     * ChangeDetector jest wywolywany w tym miejscu, poniewaz tylko wtedy dzialaja tosty wyswietlane programowo
     * przez inne komponenty w metodzie ngOnInit, ngAfterViewInit.
     */
    this.cd.detectChanges();
  }

  public get alertMessage(): AlertMessage | undefined {
    if (!this.confirmDialogCmp) {
      return undefined;
    }
    return this.confirmDialogCmp.confirmation;
  }
}
