import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { DictionaryItemValueResponse } from '@api/dictionaries-api/models/responses/dictionary-item-value.repsonse';
import { ProcedureUserDignityResponse } from '@api/procedures-api/models/responses/procedure-user-dignity.response';
import { TaskContextOperationsResponse } from '@api/procedures-api/models/responses/task-context-operations.response';
import { TaskRelatedProcedureResponse } from '@api/procedures-api/models/responses/task-related-procedure.response';

@jsonObject
export class TaskResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly name!: string;
  @jsonMember public readonly number!: string;
  @jsonMember public readonly procedure!: TaskRelatedProcedureResponse;
  @jsonMember public readonly priority!: DictionaryItemValueResponse;
  @jsonMember public readonly expert!: ProcedureUserDignityResponse;
  @jsonMember public readonly author!: ProcedureUserDignityResponse;
  @jsonMember public readonly status!: DictionaryItemValueResponse;
  @jsonMember public readonly createdAt!: Date;
  @jsonMember public readonly contextOperations!: TaskContextOperationsResponse;
  @jsonMember public readonly executor!: ProcedureUserDignityResponse;
  @jsonMember public readonly expiredAt!: Date;
  @jsonMember public readonly isTest!: boolean;
  @jsonMember public readonly isArchived!: boolean;
  @jsonMember public readonly progress!: number;
  @jsonMember public readonly additionalExpert?: ProcedureUserDignityResponse;
  @jsonMember public readonly description?: string;

}
