import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { ConversationType } from '@api/conversations-api/models/enums/conversation-type.enum';

@jsonObject()
export class ConversationInfoResponse {
  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly twilioSid!: string;
  @jsonMember public readonly name!: string;
  @jsonMember(String) public readonly type!: ConversationType;
}
