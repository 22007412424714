import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { BREADCRUMB } from '@core/routing/routing-data.const';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsProvider {
  private readonly breadcrumbsSource$: BehaviorSubject<MenuItem[]>;

  public constructor(
    private readonly translateService: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {
    this.breadcrumbsSource$ = new BehaviorSubject<MenuItem[]>([]);
  }

  public get breadcrumbs$(): Observable<MenuItem[]> {
    return merge(
      this.breadcrumbsFromLanguageChange(),
      this.breadcrumbsFromManual(),
      this.breadcrumbsFromActivatedRoute(),
      this.breadcrumbsFromRouterNavigationEnd(),
    );
  }

  public rebuild(): void {
    this.breadcrumbsSource$.next(this.createBreadcrumbs(this.activatedRoute));
  }

  private breadcrumbsFromManual(): Observable<MenuItem[]> {
    return this.breadcrumbsSource$.asObservable();
  }

  private breadcrumbsFromLanguageChange(): Observable<MenuItem[]> {
    return this
      .translateService
      .onLangChange
      .pipe(
        map(() => this.createBreadcrumbs(this.activatedRoute)),
      );
  }

  private breadcrumbsFromActivatedRoute(): Observable<MenuItem[]> {
    return this
      .activatedRoute
      .data
      .pipe(
        map(() => this.createBreadcrumbs(this.activatedRoute)),
      );
  }

  private breadcrumbsFromRouterNavigationEnd(): Observable<MenuItem[]> {
    return this.router
      .events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.createBreadcrumbs(this.activatedRoute)));
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: MenuItem[] = [],
  ): MenuItem[] {
    const { children } = route;

    if (children.length === 0) {
      return breadcrumbs;
    }

    children.forEach((child: ActivatedRoute) => {
      const routeURL: string = child
        .snapshot
        .url
        .map((segment) => segment.path).join('/');

      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label: string = child.snapshot.data[BREADCRUMB] as string;

      if (label) {
        const translatedLabel: string = this.translateService.instant(label);

        breadcrumbs.push({ label: translatedLabel, routerLink: url });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    });

    return breadcrumbs;
  }
}
