import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { UserStatus } from '@api/cmuia-api/models/enums/user-status';
import { UserPermissionGroupResponse } from '@api/cmuia-api/models/responses/user-permission-group.response';
import { UserContextOperationsResponse } from '@api/cmuia-api/models/responses/user-context-operations.response';

@jsonObject
export class UserResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly email!: string;
  @jsonMember(String) public readonly status!: UserStatus;
  @jsonArrayMember(UserPermissionGroupResponse) public readonly permissionGroups!: UserPermissionGroupResponse[];
  @jsonMember public readonly firstName?: string;
  @jsonMember public readonly lastName?: string;
  @jsonMember public readonly jobName?: string;
  @jsonMember public readonly department?: string;
  @jsonMember public readonly avatar?: string;
  @jsonMember public readonly lastLoginAt?: Date;
  @jsonMember public readonly contextOperations!: UserContextOperationsResponse;
  @jsonMember public readonly createdAt!: Date;

}
