import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { NotificationType } from '@api/procedures-api/models/enums/notification-type';
import { NotificationContextResponse } from '@api/procedures-api/models/responses/notification-context.response';

@jsonObject
export class NotificationResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember(String) public readonly userId!: Uuid;
  @jsonMember(String) public readonly type!: NotificationType;
  @jsonMember public readonly isRead!: boolean;
  @jsonMember public readonly title!: string;
  @jsonMember public readonly body!: string;
  @jsonMember public readonly context!: NotificationContextResponse;
  @jsonMember public readonly createdAt!: Date;
  @jsonMember public readonly readAt?: Date;

  public isProcedureNotification(): boolean {
    return !!this.context.procedureId;
  }

  public isTaskNotification(): boolean {
    return !!this.context.taskId;
  }

}
