import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class PasswordComplexityResponse {
  @jsonMember public readonly minLength!: number;
  @jsonMember public readonly maxLength!: number;
  @jsonMember public readonly minCapitalLetters!: number;
  @jsonMember public readonly minLowercaseLetters!: number;
  @jsonMember public readonly minSpecialChars!: number;
  @jsonMember public readonly minDigits!: number;
}
