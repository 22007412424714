<div class="user-info mr-3"
     (click)="toggleUserMenu($event)"
     pRipple>

  <div class="d-inline-block user-info__avatar align-middle">
    <p-avatar [image]="avatarUrl"
              [icon]="avatarIcon ? (avatarIcon | iconCssClass) : undefined"
              shape="circle"></p-avatar>
  </div>

  <div class="d-none d-md-inline-block align-middle ml-2 mr-3">
    <div *ngIf="dignity" class="user-info__name">{{ dignity }}</div>
    <div *ngIf="jobName" class="user-info__role p-text-secondary">{{ jobName }}</div>
  </div>
</div>

<p-menu [popup]="true"
        [model]="items$ | async"
        [appendTo]="'body'"
        [style]="{marginTop: '5px'}"></p-menu>
