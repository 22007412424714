import { jsonArrayMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';

@jsonObject()
export class DeleteCollectionEntriesRequest {

  @jsonArrayMember(String) public readonly entries!: Uuid[];

  public constructor(
    entries: Uuid[],
  ) {
    this.entries = entries;
  }

}
