import { Component, OnDestroy } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ContactResponse } from '@api/contacts-api/models/responses/contact.response';
import { Icon } from '@core/assets/enums/icon.enum';
import { CallMode } from '@common/components/audio-video-call-dialog/enums/call-mode';
import { CallDialogData } from '@common/components/audio-video-call-dialog/interface/call-dialog-data';
import { AssetsMusic } from '@core/assets/enums/assets-music.enum';
import { MusicHandler } from '@app/core/assets/services/music-handler';

@Component({
  selector: 'app-audio-video-call-dialog',
  templateUrl: './audio-video-call-dialog.component.html',
  styleUrls: ['./audio-video-call-dialog.component.scss'],
})
export class AudioVideoCallDialogComponent implements OnDestroy {

  public readonly caller: ContactResponse;
  public readonly privateCallWithContact: ContactResponse;
  public readonly desiredParticipants: ContactResponse[];
  public readonly ICON = Icon;
  public readonly CALL_MODE = CallMode;
  public readonly METING_SUB_TITLE = 'SECTION.LABEL.GROUP_CALL';

  public callMode!: CallMode;
  public callInformation!: string;
  public meetingName?: string;
  public isGroupMeeting: boolean;

  private readonly acceptCallCallback?: () => void;
  private readonly declineCallCallback: () => void;

  private incomingCallAudioRef?: HTMLAudioElement;
  private outgoingCallAudioRef?: HTMLAudioElement;

  public constructor(
    private readonly config: DynamicDialogConfig,
    private readonly musicHandler: MusicHandler,
    private readonly dialogRef: DynamicDialogRef,
  ) {
    const data = this.config.data as CallDialogData;

    this.callMode = data.callMode;
    this.meetingName = data.meetingName;
    this.desiredParticipants = data.desiredParticipants;
    this.caller = data.caller;
    this.isGroupMeeting = this.desiredParticipants.length > 2;
    if (this.callMode === CallMode.INCOMING) {
      this.privateCallWithContact = this.caller;
    } else {
      const [concat] = this.desiredParticipants.filter((contact) => contact.id !== this.caller.id);
      this.privateCallWithContact = concat;
    }

    this.acceptCallCallback = data.acceptCallCallback;
    this.declineCallCallback = data.declineCallCallback;
    this.callInformation = this.getCallInformation(data.callMode, data.video);

    if (this.callMode === CallMode.INCOMING) {
      this.playIncomingCallMusic();
    } else {
      this.playOutgoingCallMusic();
    }
  }

  public onAcceptCallClick(): void {
    if (this.acceptCallCallback) {
      this.acceptCallCallback();
    }

    this.dialogRef.close();
  }

  public onDeclineCallClick(): void {
    if (this.declineCallCallback) {
      this.declineCallCallback();
    }

    this.dialogRef.close();
  }

  public ngOnDestroy(): void {
    this.incomingCallAudioRef?.pause();
    this.outgoingCallAudioRef?.pause();
  }

  private playIncomingCallMusic(): void {
    this.incomingCallAudioRef = this.musicHandler.play(AssetsMusic.INCOMING_CALL_MUSIC, true);
  }

  private playOutgoingCallMusic(): void {
    this.outgoingCallAudioRef = this.musicHandler.play(AssetsMusic.OUTGOING_CALL_MUSIC, true);
  }

  private getCallInformation(callMode: CallMode, video: boolean): string {
    if (callMode === CallMode.INCOMING && video) {
      return 'DIALOG.AUDIO_VIDEO_CALL.LABEL.INCOMING_VIDEO_CALL';

    } else if (callMode === CallMode.INCOMING && !video) {
      return 'DIALOG.AUDIO_VIDEO_CALL.LABEL.INCOMING_AUDIO_CALL';

    } else if (callMode === CallMode.OUTGOING && video) {
      return 'DIALOG.AUDIO_VIDEO_CALL.LABEL.ESTABLISHING_AN_VIDEO_CALL';
    }

    return 'DIALOG.AUDIO_VIDEO_CALL.LABEL.ESTABLISHING_AN_AUDIO_CALL';
  }

}
