import { MessageTypeEnum } from '@api/conversations-api/models/enums/message-type.enum';
import { Uuid } from '@api/types/uuid.type';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class MessageResponse {
  @jsonMember public readonly body!: string;
  @jsonMember public readonly createdAt!: Date;
  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly twilioSid!: string;
  @jsonMember(String) public readonly type!: MessageTypeEnum;
}
