import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';

@jsonObject
export class MoveDocumentsRequest {

  @jsonMember(String) public readonly destinationDirectoryId!: Uuid;
  @jsonArrayMember(String) public readonly entries!: Uuid[];

  public constructor(
    destinationDirectoryId: Uuid,
    entries: Uuid[],
  ) {
    this.destinationDirectoryId = destinationDirectoryId;
    this.entries = entries;
  }

}
