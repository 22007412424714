import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { ProcedureFieldType } from '@api/procedures-api/models/enums/procedure-field-type';

@jsonObject
export class ProcedureFieldResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly label!: string;
  @jsonMember public readonly code!: string;
  @jsonMember public readonly type!: ProcedureFieldType;
  @jsonMember public readonly createdAt!: Date;

}
