import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { FilePropertyRequest } from '@api/documents-api/models/requests/file-property.request';
import { AttributeRequest } from '@api/common/attributes/models/requests/attribute.request';

@jsonObject
export class CreateDocumentRequest {

  @jsonMember(String) public readonly parentId!: Uuid;
  @jsonArrayMember(FilePropertyRequest) public readonly properties!: FilePropertyRequest[];
  @jsonArrayMember(AttributeRequest) public readonly attributes?: AttributeRequest[];

  public constructor(
    parentId: Uuid,
    properties: FilePropertyRequest[],
    attributes?: AttributeRequest[],
  ) {
    this.parentId = parentId;
    this.properties = properties;
    this.attributes = attributes;
  }

}
