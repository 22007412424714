import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPayloadProcessorService } from '@app/api/helpers/api-payload-processor.service';
import { CollectionRequest } from '@app/api/models/collection/requests/collection.request';
import { CollectionResponse } from '@app/api/models/collection/responses/collection.response';
import { apiCMUiAAdmin } from '@environments/environment';
import { Observable } from 'rxjs';
import { UserPermissionFilter } from '../models/filters/user-permission.filter';
import { UserPermissionResponse } from '../models/responses/user-permission.response';
import { AdminUserPermissionsApi } from './interfaces/admin-user-permissions.api';

@Injectable({
  providedIn: 'root',
})
export class AdminUserPermissionsApiService implements AdminUserPermissionsApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(request: CollectionRequest<UserPermissionFilter>): Observable<CollectionResponse<UserPermissionResponse>> {
    const { PERMISSION: { LIST } } = apiCMUiAAdmin;
    const requestQueryString: string = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, UserPermissionFilter);
    const url: string = `${this.grabEndpointUrl(LIST)}?${requestQueryString}`;

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToCollection(UserPermissionResponse),
      );
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiCMUiAAdmin.API_HOST_URL, endpoint].join('');
  }

}
