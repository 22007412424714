import { Component } from '@angular/core';

@Component({
  selector: 'app-cover-layout-footer',
  templateUrl: './cover-layout-footer.component.html',
  styleUrls: ['./cover-layout-footer.component.scss'],
})
export class CoverLayoutFooterComponent {

  public constructor() {
  }

}
