import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderNotificationsComponent } from './header-notifications.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { HeaderNotificationsItemComponent } from './header-notifications-item/header-notifications-item.component';
import { BadgeModule } from 'primeng/badge';
import { AssetsModule } from '@core/assets/assets.module';
import { TimeagoModule } from 'ngx-timeago';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayPanelPositionCorrectorModule } from '@common/directives/overlay-panel/overlay-panel-position-corrector.module';

const NG_PRIME_MODULES = [
  OverlayPanelModule,
  ButtonModule,
  RippleModule,
  BadgeModule,
];

const INTERNAL_MODULES = [
  AssetsModule,
  OverlayPanelPositionCorrectorModule,
];

const EXTERNAL_MODULES = [
  TimeagoModule,
  TranslateModule,
];

@NgModule({
  imports: [
    CommonModule,
    INTERNAL_MODULES,
    EXTERNAL_MODULES,
    NG_PRIME_MODULES,
  ],
  declarations: [
    HeaderNotificationsComponent,
    HeaderNotificationsItemComponent,
  ],
  exports: [
    HeaderNotificationsComponent,
  ],
})
export class HeaderNotificationsModule {
}
