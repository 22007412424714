import { Component, Inject, ViewChild } from '@angular/core';
import { Icon } from '@core/assets/enums/icon.enum';
import { NotificationItemViewModel } from '@core/layouts/dashboard-layout/components/dashboard-layout-header-notifications/header-notifications/models/notification-item.view-model';
import { Observable } from 'rxjs';
import { NavigateService } from '@core/routing/services/navigate.service';
import { RouteName } from '@core/routing/route-name.enum';
import { ID, PROCEDURE_ID, TASK_ID } from '@app/core/routing/routing-param.const';
import { ConversationsNotificationsProvider } from '@core/layouts/dashboard-layout/components/dashboard-layout-header-notifications/services/conversations-notifications.provider';
import { GeneralNotificationsProvider } from '@core/layouts/dashboard-layout/components/dashboard-layout-header-notifications/services/general-notifications.provider';
import { NotificationResponse } from '@api/conversations-api/models/responses/notification.response';
import { NotificationResponse as ProceduresNotificationResponse } from '@api/procedures-api/models/responses/notification.response';
import { CONVERSATIONS_NOTIFICATIONS_API } from '@api/conversations-api/services/tokens/notifications-api.token';
import { ConversationsNotificationsApi } from '@api/conversations-api/services/interfaces/conversations-notifications.api';
import { Uuid } from '@api/types/uuid.type';
import { Permission } from '@core/permissions/models/permission.enum';
import { HeaderNotificationsComponent } from '@core/layouts/dashboard-layout/components/dashboard-layout-header-notifications/header-notifications/header-notifications.component';
import { PROCEDURES_NOTIFICATIONS_API } from '@api/procedures-api/services/tokens/notifications-api.token';
import { ProceduresNotificationsApi } from '@api/procedures-api/services/interfaces/procedures-notifications.api';
import { ApiResponseMessageHandlerService } from '@common/handlers/services/api-response-message-handler.service';

@Component({
  selector: 'app-dashboard-layout-header-notifications',
  templateUrl: './dashboard-layout-header-notifications.component.html',
  styleUrls: ['./dashboard-layout-header-notifications.component.scss'],
  providers: [
    GeneralNotificationsProvider,
    ConversationsNotificationsProvider,
  ],
})
export class DashboardLayoutHeaderNotificationsComponent {

  @ViewChild('chatNotificationsCmp') public chatNotificationsCmp?: HeaderNotificationsComponent;

  public readonly PERMISSION = Permission;
  public readonly ICON = Icon;
  public readonly OPEN_CHAT_LABEL = 'HEADER.OPEN_CHAT';

  public generalNotifications$: Observable<NotificationItemViewModel[]>;
  public conversationsNotifications$: Observable<NotificationItemViewModel[]>;

  public constructor(
    @Inject(CONVERSATIONS_NOTIFICATIONS_API) private readonly conversationsNotificationsApi: ConversationsNotificationsApi,
    @Inject(PROCEDURES_NOTIFICATIONS_API) private readonly proceduresNotificationsApi: ProceduresNotificationsApi,
    private readonly generalNotificationsProvider: GeneralNotificationsProvider,
    private readonly conversationsNotificationsProvider: ConversationsNotificationsProvider,
    private readonly navigateService: NavigateService,
    private readonly apiResponseMessageHandlerService: ApiResponseMessageHandlerService,
  ) {
    this.generalNotifications$ = this.generalNotificationsProvider.getSource();
    this.conversationsNotifications$ = this.conversationsNotificationsProvider.getSource();
  }

  public onConversationsNotificationClicked(notification: NotificationItemViewModel): void {
    const dataModel = notification.data as NotificationResponse;

    this.markSingleConversationNotificationAsRead(dataModel);

    if (dataModel.isUnreadConversationNotification() && dataModel.metadata.conversation) {
      const conversation = dataModel.metadata.conversation;
      this.goToChatConversation(conversation.id);

    } else if (dataModel.isParticipantAddedToConversationNotification() && dataModel.metadata.conversation) {
      const conversation = dataModel.metadata.conversation;
      this.goToChatConversation(conversation.id);

    } else if (dataModel.isMissedIncomingCallNotification() && dataModel.metadata.caller) {
      const caller = dataModel.metadata.caller;
      this.goToContactDetails(caller.id);

    } else if (dataModel.isRoomRecordingReadyNotification()) {
      this.goToDocuments();
    }
  }

  public markConversationsNotificationsAsRead(): void {
    this.conversationsNotificationsApi
      .markAllRead()
      .subscribe(() => this.onConversationsNotificationsMarkedAsRead());
  }

  private onConversationsNotificationsMarkedAsRead(): void {
    this
      .apiResponseMessageHandlerService
      .handleSuccess(builder =>
        builder
          .initInfoMessage(
            'MESSAGE.CONVERSATION_NOTIFICATION_MARKED_AS_READY.TITLE.SUCCESS',
            'MESSAGE.CONVERSATION_NOTIFICATION_MARKED_AS_READY.BODY.SUCCESS',
          )
          .build(),
      );
  }

  public openChatClicked(): void {
    this.goToChat();
    this.chatNotificationsCmp?.hide();
  }

  private goToChat(): void {
    this.navigateService.navigate(RouteName.CHAT);
  }

  private goToChatConversation(conversationId: Uuid): void {
    this.navigateService.navigate(RouteName.CHAT_CONVERSATION, {
      [ID]: conversationId,
    });
  }

  private goToContactDetails(contactId: Uuid): void {
    this.navigateService.navigate(RouteName.PANEL_CONTACT_DETAILS, {
      [ID]: contactId,
    });
  }

  private goToDocuments(): void {
    this.navigateService.navigate(RouteName.PANEL_DOCUMENTS);
  }

  private markSingleConversationNotificationAsRead(notificationModel: NotificationResponse): void {
    this.conversationsNotificationsApi
      .markSingleAsRead(notificationModel.id)
      .subscribe(() => this.onSingleNotificationMarkedAsRead());
  }

  public onProceduresNotificationClicked(notification: NotificationItemViewModel): void {
    const dataModel = notification.data as ProceduresNotificationResponse;

    this.markSingleProcedureNotificationAsRead(dataModel);

    if (dataModel.isProcedureNotification()) {
      this.goToProcedureDetails(dataModel.context.procedureId as Uuid);

    } else if (dataModel.isTaskNotification()) {
      this.goToTaskDetails(dataModel.context.taskId as Uuid);
    }
  }

  public markProceduresNotificationsAsRead(): void {
    this.proceduresNotificationsApi
      .markAllRead()
      .subscribe(() => {
        this.onProceduresNotificationsMarkedAsRead();
        this.generalNotificationsProvider.refresh();
      });
  }

  private markSingleProcedureNotificationAsRead(dataModel: ProceduresNotificationResponse): void {
    this.proceduresNotificationsApi
      .markSingleAsRead(dataModel.id)
      .subscribe(() => {
        this.onSingleNotificationMarkedAsRead();
        this.generalNotificationsProvider.refresh();
      });
  }

  private goToProcedureDetails(procedureId: Uuid): void {
    this.navigateService.navigate(RouteName.PANEL_PROCEDURES_DETAILS, {
      [PROCEDURE_ID]: procedureId,
    });
  }

  private goToTaskDetails(taskId: Uuid): void {
    this.navigateService.navigate(RouteName.PANEL_TASKS_DETAILS, {
      [TASK_ID]: taskId,
    });
  }

  private onProceduresNotificationsMarkedAsRead(): void {
    this
      .apiResponseMessageHandlerService
      .handleSuccess(builder =>
        builder
          .initInfoMessage(
            'MESSAGE.PROCEDURE_NOTIFICATION_MARKED_AS_READY.TITLE.SUCCESS',
            'MESSAGE.PROCEDURE_NOTIFICATION_MARKED_AS_READY.BODY.SUCCESS',
          )
          .build(),
      );
  }

  private onSingleNotificationMarkedAsRead(): void {
    this
      .apiResponseMessageHandlerService
      .handleSuccess(builder =>
        builder
          .initInfoMessage(
            'MESSAGE.SINGLE_NOTIFICATION_MARKED_AS_READY.TITLE.SUCCESS',
            'MESSAGE.SINGLE_NOTIFICATION_MARKED_AS_READY.BODY.SUCCESS',
          )
          .build(),
      );
  }

}
