import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class CollectionEntryContextOperationsResponse {

  @jsonMember public readonly canBeCopied!: boolean;
  @jsonMember public readonly canBeDeleted!: boolean;
  @jsonMember public readonly canBeDownload!: boolean;
  @jsonMember public readonly canBeMoved!: boolean;
  @jsonMember public readonly canBePinned!: boolean;
  @jsonMember public readonly canBeUnpinned!: boolean;
  @jsonMember public readonly canBeUpdated!: boolean;
  @jsonMember public readonly canCreateInside!: boolean;

}
