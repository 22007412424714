import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { ContactResponse } from '@api/contacts-api/models/responses/contact.response';
import { CallSettingsResponse } from '@api/conversations-api/models/responses/call-settings.response';
import { VideoRoomContextOperationsResponse } from '@api/conversations-api/models/responses/video-room-context-operations.response';

@jsonObject
export class VideoRoomResponse {
  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly twilioSid!: string;
  @jsonMember public readonly twilioUniqueName!: string;
  @jsonMember public readonly name?: string;
  @jsonMember public readonly creator?: ContactResponse;
  @jsonMember public readonly initialSettings!: CallSettingsResponse;
  @jsonMember public readonly createdAt!: Date;
  @jsonMember public readonly contextOperations!: VideoRoomContextOperationsResponse;
  @jsonMember(String) public readonly conversationId!: Uuid;
}
