import { jsonMember, jsonObject } from 'typedjson';
import { ProcedureFieldType } from '@api/procedures-api/models/enums/procedure-field-type';

@jsonObject
export class ProcedureFieldCreateRequest {

  @jsonMember public code!: string;
  @jsonMember public label!: string;
  @jsonMember(String) public type!: ProcedureFieldType;

}
