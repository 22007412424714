import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { LayoutsModule } from '@core/layouts/layouts.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule } from '@api/api.module';
import { StorageModule } from '@core/storage/storage.module';
import { AuthModule } from '@core/auth/auth.module';
import { PermissionsModule } from '@core/permissions/permissions.module';
import { AppRoutingModule } from '@core/routing/app-routing.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule } from 'ngx-timeago';
import { TimeagoTranslationProvider } from '@core/translations/providers/timeago-translation.provider';
import { FcmModule } from '@core/fcm/fcm.module';
import { ApplicationTranslationProvider } from '@core/translations/providers/application-translation.provider';
import { Language } from '@core/translations/enums/language.enum';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { RouteReuseStrategy } from '@angular/router';
import { RouteCustomReuseStrategyProvider } from './core/routing/provider/route-custom-reuse-strategy.provider';

function initializerFactory(translate: TranslateService) {
  return () => translate
    .use(Language.POLISH)
    .pipe(
      catchError((error) => {
        console.error(error);

        return of({});
      }),
    );
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutsModule,
    ApiModule,
    AuthModule,
    PermissionsModule,
    StorageModule,
    TranslateModule.forRoot({
      defaultLanguage: Language.POLISH,
      loader: {
        provide: TranslateLoader,
        useClass: ApplicationTranslationProvider,
      },
    }),
    TimeagoModule.forRoot({
      intl: {
        provide: TimeagoIntl,
        useClass: TimeagoTranslationProvider,
      },
      formatter: {
        provide: TimeagoFormatter,
        useClass: TimeagoCustomFormatter,
      },
    }),
    FcmModule,
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteCustomReuseStrategyProvider,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
