import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { CollectionRequest } from '@api/models/collection/requests/collection.request';
import { Observable } from 'rxjs';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';
import { ProcedureUserDignityResponse } from '@api/procedures-api/models/responses/procedure-user-dignity.response';
import { apiProceduresCommon } from '@environments/environment';
import { ProcedureFilter } from '@api/procedures-api/models/filters/procedure.filter';
import { ProceduresFieldWorkersApi } from '@api/procedures-api/services/interfaces/procedures-field-workers.api';
import { ProcedureFieldWorkerFilter } from '@api/procedures-api/models/filters/procedure-field-worker.filter';
import { ProcedureFieldWorkerResponse } from '@api/procedures-api/models/responses/procedure-field-workers.response';

@Injectable({
  providedIn: 'root',
})
export class ProceduresFieldWorkersApiService implements ProceduresFieldWorkersApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(request: CollectionRequest<ProcedureFieldWorkerFilter>): Observable<CollectionResponse<ProcedureFieldWorkerResponse>> {
    const { LIST } = apiProceduresCommon.FIELD_WORKERS;
    const requestQueryString: string = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, ProcedureFilter);
    const url: string = `${this.grabEndpointUrl(LIST)}?${requestQueryString}`;

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToCollection(ProcedureUserDignityResponse),
      );
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiProceduresCommon.API_HOST_URL, endpoint].join('');
  }

}
