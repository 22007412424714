import { Injectable } from '@angular/core';
import { ToastMessageService } from '@common/components/toast/services/toast-message.service';
import { ApiResponseMessageBuilderService } from '@common/handlers/builders/api-response-message-builder.service';
import { ToastMessage } from '@common/components/toast/interface/toast-message.interface';
import { ToastMessageSeverity } from '@common/components/toast/enums/toast-message-severity.enum';

@Injectable({
  providedIn: 'root',
})
export class ApiResponseMessageHandlerService {

  public constructor(
    private readonly toastMessageService: ToastMessageService,
    private readonly builder: ApiResponseMessageBuilderService,
  ) {
  }

  public handleSuccess(factory: (builder: ApiResponseMessageBuilderService) => ToastMessage): void {
    this.toastMessageService.show(factory(this.builder.setSeverity(ToastMessageSeverity.SUCCESS)));
  }

  public handleError(factory: (builder: ApiResponseMessageBuilderService) => ToastMessage): void {
    this.toastMessageService.show(factory(this.builder.setSeverity(ToastMessageSeverity.ERROR)));
  }

}
