import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconCssClassPipe } from './pipes/icon-css-class.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    IconCssClassPipe,
  ],
  exports: [
    IconCssClassPipe,
  ],
})
export class AssetsModule {
}
