import { ResourceCollectionRequestBuilder } from '@api/helpers/resource-collection-request.builder';
import { NotificationFilter } from '@api/procedures-api/models/filters/notification.filter';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsCollectionRequestBuilder extends ResourceCollectionRequestBuilder<NotificationFilter> {

  protected getFilterInstance(): NotificationFilter {
    return new NotificationFilter();
  }

}
