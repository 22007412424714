import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { VIDEO_API } from '@api/conversations-api/services/tokens/video-api.token';
import { VideoApi } from '@api/conversations-api/services/interfaces/video.api';
import { CONVERSATIONS_LIVE_DATA_API } from '@api/conversations-api/services/tokens/conversations-live-data-api.token';
import { ConversationsLiveDataApi } from '@api/conversations-api/services/interfaces/conversations-live-data.api';
import { filter, take, takeUntil } from 'rxjs/operators';
import { IncomingCallResponse } from '@api/conversations-api/models/responses/incoming-call.response';
import { CallDialogData } from '@common/components/audio-video-call-dialog/interface/call-dialog-data';
import { CallMode } from '@common/components/audio-video-call-dialog/enums/call-mode';
import { AudioVideoCallDialogComponent } from '@common/components/audio-video-call-dialog/audio-video-call-dialog.component';
import { VideoRoomResponse } from '@api/conversations-api/models/responses/video-room.response';
import { NavigateService } from '@core/routing/services/navigate.service';
import { RouteName } from '@core/routing/route-name.enum';
import { ID } from '@core/routing/routing-param.const';
import { CALL_SETTINGS } from '@core/routing/routing-data.const';
import { MusicHandler } from '@app/core/assets/services/music-handler';
import { AssetsMusic } from '@core/assets/enums/assets-music.enum';

@Injectable()
export class IncomingCallsReceiverService implements OnDestroy {

  private destroyed$ = new Subject<void>();
  private dialogRef?: DynamicDialogRef;

  public constructor(
    @Inject(VIDEO_API) private videoCallsApiService: VideoApi,
    @Inject(CONVERSATIONS_LIVE_DATA_API) private conversationsLiveDataService: ConversationsLiveDataApi,
    private dialog: DialogService,
    private navigateService: NavigateService,
    private musicHandler: MusicHandler,
  ) {
  }

  public subscribeForIncomingCalls(): void {
    this.conversationsLiveDataService
      .incomingCall()
      .pipe(
        filter((incomingCall) => incomingCall.isRingingStatus()),
        takeUntil(this.destroyed$),
      ).subscribe((incomingCall) => this.onIncomingCall(incomingCall));
  }

  private onIncomingCall(incomingCall: IncomingCallResponse): void {
    this.openIncomingCallDialog(incomingCall);
    this.subscribeForCallCanceledEvent(incomingCall);
    this.subscribeForCallAcceptedEvent(incomingCall);
    this.subscribeForCallRejectedEvent(incomingCall);
    this.subscribeForCallMissedEvent(incomingCall);
  }

  private subscribeForCallCanceledEvent(incomingCall: IncomingCallResponse): void {
    this.conversationsLiveDataService
      .incomingCall()
      .pipe(
        filter((call) => call.id === incomingCall.id && call.isCanceledStatus()),
        take(1),
        takeUntil(this.destroyed$),
      ).subscribe(() => this.onIncomingCallCanceledEvent());
  }

  private onIncomingCallCanceledEvent(): void {
    this.closeDialog();
    this.musicHandler.play(AssetsMusic.UNTAKEN_CALL_MUSIC);
  }

  private subscribeForCallAcceptedEvent(incomingCall: IncomingCallResponse): void {
    this.conversationsLiveDataService
      .incomingCall()
      .pipe(
        filter((call) => call.id === incomingCall.id && call.isAcceptedStatus()),
        take(1),
        takeUntil(this.destroyed$),
      ).subscribe((call) => this.onIncomingCallAcceptedEvent(call));
  }

  private onIncomingCallAcceptedEvent(incomingCall: IncomingCallResponse): void {
    // TODO: Docelowa obsluga
    console.warn('Zaakceptowano polaczenie', incomingCall);
    this.closeDialog();
  }

  private subscribeForCallRejectedEvent(incomingCall: IncomingCallResponse): void {
    this.conversationsLiveDataService
      .incomingCall()
      .pipe(
        filter((call) => call.id === incomingCall.id && call.isRejectedStatus()),
        take(1),
        takeUntil(this.destroyed$),
      ).subscribe(() => this.onIncomingCallRejectedEvent());
  }

  private onIncomingCallRejectedEvent(): void {
    this.closeDialog();
    this.musicHandler.play(AssetsMusic.UNTAKEN_CALL_MUSIC);
  }

  private subscribeForCallMissedEvent(incomingCall: IncomingCallResponse): void {
    this.conversationsLiveDataService
      .incomingCall()
      .pipe(
        filter((call) => call.id === incomingCall.id && call.isMissedStatus()),
        take(1),
        takeUntil(this.destroyed$),
      ).subscribe(() => this.onIncomingCallMissedEvent());
  }

  private onIncomingCallMissedEvent(): void {
    this.closeDialog();
    this.musicHandler.play(AssetsMusic.UNTAKEN_CALL_MUSIC);
  }

  private openIncomingCallDialog(incomingCall: IncomingCallResponse): void {
    const pendingRoom = incomingCall.pendingRoom;

    const callDialogData: CallDialogData = {
      meetingName: pendingRoom.name,
      caller: incomingCall.caller,
      desiredParticipants: pendingRoom.desiredParticipants,
      callMode: CallMode.INCOMING,
      video: incomingCall.settings.videoEnabled,
      acceptCallCallback: () => {
        this.onAcceptIncomingCall(incomingCall);
      },
      declineCallCallback: () => {
        this.onDeclineIncomingCall(incomingCall);
      },
    };

    this.dialogRef = this.dialog.open(AudioVideoCallDialogComponent, {
      closable: false,
      data: callDialogData,
      width: '40vw',
    });
  }

  private onAcceptIncomingCall(incomingCall: IncomingCallResponse): void {
    this.videoCallsApiService
      .acceptIncomingCall(incomingCall.id)
      .subscribe(
        (room) => this.onIncomingCallAccepted(room),
        () => this.onIncomingCallAcceptError(),
      );
  }

  private onIncomingCallAccepted(room: VideoRoomResponse): void {
    this.closeDialog();
    this.navigateService.navigate(RouteName.VIDEO_CALL_ROOM,
      { [ID]: room.id },
      { state: { [CALL_SETTINGS]: room.initialSettings } },
    );
  }

  private onIncomingCallAcceptError(): void {
    // TODO: Obsluzyc ewentualny blad
    console.warn('blad podczas akceptacji polaczenia');
    this.closeDialog();
  }

  private onDeclineIncomingCall(incomingCall: IncomingCallResponse): void {
    this.videoCallsApiService
      .rejectIncomingCall(incomingCall.id)
      .subscribe(
        () => this.onIncomingCallRejected(incomingCall),
        () => this.onIncomingCallRejectError(),
      );
  }

  private onIncomingCallRejected(incomingCall: IncomingCallResponse): void {
    // TODO: Docelowa obsluga
    console.warn('odrzucono polaczenie przychodzace', incomingCall);
    this.closeDialog();
  }

  private onIncomingCallRejectError(): void {
    // TODO: Obsluzyc ewentualny blad
    console.warn('blad podczas odrzucania polaczenia');
    this.closeDialog();
  }

  private closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
