import { AnyT, jsonMember, jsonObject } from 'typedjson';
import { FileSystemObjectPropertyName } from '@api/documents-api/models/enums/file-system-object-property-name.enum';

@jsonObject
export class CollectionEntryDetailsPropertyResponse<ValueType = string> {

  @jsonMember({ name: 'id' }) public readonly name!: FileSystemObjectPropertyName;
  @jsonMember(AnyT) public readonly value!: ValueType;

}
