<div class="header-notifications">

  <app-header-notifications [notifications]="generalNotifications$ | async"
                            [triggerButtonIcon]="ICON.HEADER_NOTIFICATION_BELL"
                            (notificationClicked)="onProceduresNotificationClicked($event)"
                            (markAllReadClicked)="markProceduresNotificationsAsRead()"
                            triggerButtonStyleCss="mr-3"></app-header-notifications>

  <ng-container *appShowIfHasPermission="PERMISSION.HAS_CHAT_AND_VIDEO_CONVERSATIONS_ACCESS">
    <app-header-notifications #chatNotificationsCmp
                              [notifications]="conversationsNotifications$ | async"
                              [triggerButtonIcon]="ICON.HEADER_NOTIFICATION_CHAT"
                              [additionalButtonsTemplate]="chatAdditionalButtons"
                              (notificationClicked)="onConversationsNotificationClicked($event)"
                              (markAllReadClicked)="markConversationsNotificationsAsRead()"></app-header-notifications>

    <ng-template #chatAdditionalButtons>
      <div class="p-fluid">
        <button pButton
                pRipple
                class="mt-1"
                [label]="OPEN_CHAT_LABEL | translate"
                (click)="openChatClicked()"></button>
      </div>
    </ng-template>
  </ng-container>

</div>
