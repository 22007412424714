import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OnlyLoggedUserGuard } from '@core/auth/guards/only-logged-user.guard';
import { CoverLayoutComponent } from '@core/layouts/cover-layout/cover-layout.component';
import { DashboardLayoutComponent } from '@core/layouts/dashboard-layout/dashboard-layout.component';
import { ResolvePermissionsGuard } from '@core/permissions/guards/resolve-permissions.guard';
import { BREADCRUMB, ROUTE_PERMISSIONS } from '@core/routing/routing-data.const';
import { DASHBOARD, MANAGE, NOT_FOUND, PANEL, PROFILE, UI_GUIDE, WILDCARD } from '@core/routing/routing-path.const';
import { ConversationsLiveDataConnectionInitializedGuard } from '@core/chat/guards/conversations-live-data-connection-initialized.guard';
import { RegisterFcmGuard } from '@core/fcm/guards/register-fcm.guard';
import { AllowIfHasPermissionGuard } from '@core/permissions/guards/allow-if-has-permission.guard';
import { RouteName } from '@core/routing/route-name.enum';
import { ChatTokenRetrieverGuard } from '@modules/panel/contacts/guards/chat-token-retriever-guard.service';

const routes: Routes = [
  {
    path: UI_GUIDE,
    loadChildren: () => import('../../modules/ui-guide/ui-guide.module').then((m) => m.UiGuideModule),
  },
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [
      OnlyLoggedUserGuard,
      ResolvePermissionsGuard,
      ConversationsLiveDataConnectionInitializedGuard,
      RegisterFcmGuard,
      ChatTokenRetrieverGuard,
    ],
    children: [
      {
        path: DASHBOARD,
        data: {
          [BREADCRUMB]: null,
          [ROUTE_PERMISSIONS]: RouteName.DASHBOARD,
        },
        canActivate: [
          AllowIfHasPermissionGuard,
        ],
        loadChildren: () => import('../../modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: PROFILE,
        data: {
          [ROUTE_PERMISSIONS]: RouteName.MY_PROFILE,
        },
        loadChildren: () => import('../../modules/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: PANEL,
        loadChildren: () => import('../../modules/panel/panel.module').then((m) => m.PanelModule),
      },
      {
        path: MANAGE,
        data: {
          [BREADCRUMB]: 'BREADCRUMB.MANAGEMENT',
        },
        loadChildren: () => import('../../modules/manage/manage.module').then((m) => m.ManageModule),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: DASHBOARD,
      },
    ],
  },
  {
    path: '',
    component: CoverLayoutComponent,
    canActivate: [
      ResolvePermissionsGuard,
    ],
    loadChildren: () => import('../../modules/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: WILDCARD,
    redirectTo: `/${NOT_FOUND}`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'disabled', enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
