import { ProceduresLiveDataApi } from '@api/procedures-api/services/interfaces/procedures-live-data.api';
import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PushPayloadData } from '@api/procedures-api/models/interfaces/push-payload-data.interface';
import { NotificationCategory } from '@api/procedures-api/models/enums/notification-category';

@Injectable({
  providedIn: 'root',
})
export class ProceduresLiveDataApiService implements ProceduresLiveDataApi {

  private notificationsUpdated$ = new Subject<void>();

  public constructor(private zone: NgZone) {
    navigator.serviceWorker.addEventListener('message', (messageEvent: MessageEvent) => {
      this.handlePushMessage(messageEvent.data);
    });
  }

  public notificationsUpdated(): Observable<void> {
    return this.notificationsUpdated$.asObservable();
  }

  private handlePushMessage(payload: unknown): void {
    const push = payload as { data?: PushPayloadData };

    if (push.data?.ar_category === NotificationCategory.UNREAD_NOTIFICATIONS_CHANGED) {
      this.onNotificationsUpdatedMessage();
    }
  }

  private onNotificationsUpdatedMessage(): void {
    this.zone.run(() => {
      this.notificationsUpdated$.next();
    });
  }
}
