import { Injectable } from '@angular/core';
import { Event, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LazyModuleLoadingService {

  public constructor(private router: Router) {
  }

  public get loading$(): Observable<boolean> {
    return this
      .router
      .events
      .pipe(
        filter((event: Event) => this.isRouteConfigLoadEvent(event)),
        map((event: Event) => this.isRouteConfigLoadStart(event)),
      );
  }

  private isRouteConfigLoadEvent(event: Event): boolean {
    return this.isRouteConfigLoadStart(event) || this.isRouteConfigLoadEnd(event);
  }

  private isRouteConfigLoadStart(event: Event): boolean {
    return event instanceof RouteConfigLoadStart;
  }

  private isRouteConfigLoadEnd(event: Event): boolean {
    return event instanceof RouteConfigLoadEnd;
  }

}
