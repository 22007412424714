import { AnyT, jsonMember, jsonObject } from 'typedjson';
import { FileSystemObjectPropertyName } from '@api/documents-api/models/enums/file-system-object-property-name.enum';

@jsonObject
export class FilePropertyRequest<ValueType = unknown> {

  @jsonMember(String, { name: 'id' }) public readonly name!: FileSystemObjectPropertyName | string;
  @jsonMember(AnyT) public readonly value!: ValueType;

  public constructor(
    name: string,
    value: ValueType,
  ) {
    this.name = name;
    this.value = value;
  }

}
