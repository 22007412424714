import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { ProcedureFieldResponse } from '@api/procedures-api/models/responses/procedure-field.response';
import { ProcedureFieldValidatorResponse } from '@api/procedures-api/models/responses/procedure-field-validator.response';
import { ProcedureFieldPropertyResponse } from '@api/procedures-api/models/responses/procedure-field-property.response';
import { ProcedureStageFieldValuesType } from '@api/procedures-api/models/types/procedure-stage-field-values.type';

@jsonObject
export class TaskStageFieldResponse {

  @jsonMember public readonly field!: ProcedureFieldResponse;
  @jsonArrayMember(ProcedureFieldPropertyResponse) public readonly attributes!: ProcedureFieldPropertyResponse[];
  @jsonArrayMember(ProcedureFieldValidatorResponse) public readonly validators!: ProcedureFieldValidatorResponse[];
  @jsonArrayMember(AnyT) public values!: ProcedureStageFieldValuesType;

}
