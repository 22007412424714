import { AttributeFilterModel } from '@app/api/models/collection/requests/attribute-filter.model';
import { ResourceFilterModel } from '@app/api/models/collection/requests/resource-filter.model';
import { jsonArrayMember, jsonObject } from 'typedjson';

@jsonObject
export class DictionaryFilter extends ResourceFilterModel {

  @jsonArrayMember(AttributeFilterModel) public name?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public description?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public type?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public module?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public isAddable?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public isEditable?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public isRemovable?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public updatedAt?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel, { name: 'query' }) public global?: AttributeFilterModel[];

}
