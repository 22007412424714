import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ADMIN_DICTIONARIES_API } from '@api/dictionaries-api/services/tokens/admin-dictionaries-api.token';
import { AdminDictionariesApiService } from '@api/dictionaries-api/services/admin-dictionaries-api.service';
import { ADMIN_DICTIONARY_ITEMS_API } from '@api/dictionaries-api/services/tokens/admin-dictionary-items-api.token';
import { AdminDictionaryItemsApiService } from '@api/dictionaries-api/services/admin-dictionary-items-api.service';
import { DICTIONARIES_API } from '@api/dictionaries-api/services/tokens/dictionaries-api.token';
import { DictionariesApiService } from '@api/dictionaries-api/services/dictionaries-api.service';
import { DictionaryItemsApiService } from '@api/dictionaries-api/services/dictionary-items-api.service';
import { DICTIONARY_ITEMS_API } from '@api/dictionaries-api/services/tokens/dictionary-items-api.token';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
  providers: [
    {
      provide: DICTIONARIES_API,
      useClass: DictionariesApiService,
    },
    {
      provide: ADMIN_DICTIONARIES_API,
      useClass: AdminDictionariesApiService,
    },
    {
      provide: DICTIONARY_ITEMS_API,
      useClass: DictionaryItemsApiService,
    },
    {
      provide: ADMIN_DICTIONARY_ITEMS_API,
      useClass: AdminDictionaryItemsApiService,
    },
  ],
})
export class DictionariesApiModule {
}
