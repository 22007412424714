import { Injectable } from '@angular/core';
import { RouteProvider } from '../provider/route.provider';
import { NavigationExtras, Params, Router, UrlTree } from '@angular/router';
import { RouteName } from '../route-name.enum';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigateService {

  public constructor(
    private routeProvider: RouteProvider,
    private router: Router,
  ) {
  }

  public navigate(name: RouteName, params: Params = {}, extras?: NavigationExtras): void {
    void this
      .router
      .navigate(this.routeProvider.getPath(name, params), extras);
  }

  public navigate$(name: RouteName, params: Params = {}, extras?: NavigationExtras): Observable<boolean> {
    return from(
      this
        .router
        .navigate(this.routeProvider.getPath(name, params), extras),
    );
  }

  public navigateByUrl(name: RouteName, params: Params = {}, extras?: NavigationExtras): void {
    void this
      .router
      .navigateByUrl(this.convertToUrlTree(name, params), extras);
  }

  public navigateByUrl$(name: RouteName, params: Params = {}, extras?: NavigationExtras): Observable<boolean> {
    return from(
      this
        .router
        .navigateByUrl(this.convertToUrlTree(name, params), extras),
    );
  }

  public convertToUrlTree(name: RouteName, params: Params = {}): UrlTree {
    return this
      .router
      .createUrlTree(this.routeProvider.getPath(name, params));
  }

}
