export const ROOT = '/';
export const UI_GUIDE = 'ui-guide';
export const ADD = 'add';
export const PANEL = 'panel';
export const MANAGE = 'manage';
export const SIGN_IN = 'sign-in';
export const SIGN_OUT = 'sign-out';
export const DASHBOARD = 'dashboard';
export const PROFILE = 'profile';
export const TASKS = 'tasks';
export const PROCEDURES = 'procedures';
export const CONTACTS = 'contacts';
export const DOCUMENTS = 'documents';
export const CAMERA = 'camera';
export const ALERTS = 'alerts';
export const USERS = 'users';
export const PERMISSIONS = 'permissions';
export const DICTIONARIES = 'dictionaries';
export const ATTRIBUTES = 'attributes';
export const SETTINGS = 'settings';
export const CONFIRM = 'confirm';
export const RESET_PASSWORD = 'reset-password';
export const EMAIL_CHANGE = 'email-change';
export const CREATE = 'create';
export const FILE = 'file';
export const FIELDS = 'fields';
export const STAGES = 'stages';
export const DIRECTORY = 'directory';
export const UPDATE = 'update';
export const CREATOR = 'creator';
export const DETAILS = 'details';
export const CHAT = 'chat';
export const VIDEO = 'video';
export const CALL = 'call';
export const ACCEPT = 'accept';
export const REJECT = 'reject';
export const DESIGNER = 'designer';
export const NOT_FOUND = '404';
export const WILDCARD = '**';
