import { Pipe, PipeTransform } from '@angular/core';
import { RouteName } from '@core/routing/route-name.enum';
import { RouteProvider } from '@core/routing/provider/route.provider';

@Pipe({
  name: 'routePath',
})
export class RoutePathPipe implements PipeTransform {

  public constructor(private routeProvider: RouteProvider) {
  }

  public transform(route: RouteName): string[] {
    return this.routeProvider.getPath(route);
  }

}
