import { AttributeFilterModel } from '@api/models/collection/requests/attribute-filter.model';
import { ResourceFilterModel } from '@api/models/collection/requests/resource-filter.model';
import { jsonArrayMember, jsonObject } from 'typedjson';

@jsonObject
export class AttributeFilter extends ResourceFilterModel {

  @jsonArrayMember(AttributeFilterModel) public name?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public type?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public isRequired?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public createdAt?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel, { name: 'name' }) public global?: AttributeFilterModel[];

}
