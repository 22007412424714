import { CollectionRequest } from '@api/models/collection/requests/collection.request';
import { SortModel } from '@api/models/collection/requests/sort.model';
import { AttributeFilterModel } from '@api/models/collection/requests/attribute-filter.model';
import { ResourceFilterModel } from '@api/models/collection/requests/resource-filter.model';

export abstract class ResourceCollectionRequestBuilder<FilterModel extends ResourceFilterModel> {
  protected request!: CollectionRequest<FilterModel>;

  protected abstract getFilterInstance(): FilterModel;

  public constructor() {
    this.reset();
  }

  private reset(): void {
    this.request = new CollectionRequest<FilterModel>();
    this.request.filter = this.getFilterInstance();
  }

  public build(): CollectionRequest<FilterModel> {
    const request = this.request;
    this.reset();

    return request;
  }

  public fromRequest(request: CollectionRequest<FilterModel>): this {
    this.request = request;

    return this;
  }

  public applyFilter(filter: FilterModel): this {
    this.request.filter = filter;

    return this;
  }

  public applySorts(sorts: SortModel[]): this {
    this.request.sorts = sorts;

    return this;
  }

  public addSort(sort: SortModel): this {
    if (!this.request.sorts) {
      this.request.sorts = [];
    }

    this.request.sorts = [...this.request.sorts, sort];

    return this;
  }

  public addDefaultSort(sort: SortModel): this {
    const defaultSort: SortModel | undefined = this.request?.sorts?.find((item: SortModel) => item.orderBy === sort.orderBy);

    if (defaultSort) {
      return this;
    }

    if (this.request.sorts && this.request.sorts.length) {
      return this;
    }

    return this.addSort(sort);
  }

  public setPage(page: number): this {
    this.request.page = page;

    return this;
  }

  public setLimit(limit: number): this {
    this.request.limit = limit;

    return this;
  }

  public disablePagination(): this {
    this.request.disablePagination();

    return this;
  }

  public enablePagination(limit?: number): this {
    this.request.enablePagination(limit);

    return this;
  }

  protected addFilter(fieldName: string, filter: AttributeFilterModel): this {
    if (!this.request.filter) {
      this.request.filter = this.getFilterInstance();
    }
    if (!this.request.filter[fieldName]) {
      // @ts-ignore
      this.request.filter[fieldName] = [];
    }
    // @ts-ignore
    this.request.filter[fieldName].push(filter);

    return this;
  }

  protected addCustomFilter(fieldName: string, filter: Object): this {
    if (!this.request.filter) {
      this.request.filter = this.getFilterInstance();
    }

    // @ts-ignore
    this.request.filter[fieldName] = filter;

    return this;
  }

}

