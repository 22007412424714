import { jsonArrayMember, jsonObject } from 'typedjson';
import { SettingPropertyRequest } from './setting-property.request';

@jsonObject
export class UpdateSettingsRequest {

  @jsonArrayMember(SettingPropertyRequest) public readonly settings!: SettingPropertyRequest[];

  public constructor(
    settings: SettingPropertyRequest[],
  ) {
    this.settings = settings;
  }

}
