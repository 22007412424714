export enum RouteName {

  ROOT = 'ROOT',
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  RESET_PASSWORD_INFO = 'RESET_PASSWORD_INFO',
  UI_GUIDE = 'UI_GUIDE',
  DASHBOARD = 'DASHBOARD',
  DASHBOARD_CREATE = 'DASHBOARD_CREATE',
  DASHBOARD_UPDATE = 'DASHBOARD_UPDATE',
  DASHBOARDS_MANAGEMENT = 'DASHBOARDS_MANAGEMENT',
  MY_PROFILE = 'MY_PROFILE',
  PANEL_TASKS = 'PANEL_TASKS',
  PANEL_TASKS_ALERTS = 'PANEL_TASKS_ALERTS',
  PANEL_TASKS_DETAILS = 'PANEL_TASKS_DETAILS',
  PANEL_PROCEDURES = 'PANEL_PROCEDURES',
  PANEL_PROCEDURE_ALERTS = 'PANEL_PROCEDURE_ALERTS',
  PANEL_PROCEDURES_DETAILS = 'PANEL_PROCEDURES_DETAILS',
  PANEL_PROCEDURE_CREATOR = 'PANEL_PROCEDURE_CREATOR',
  PANEL_PROCEDURE_CREATOR_STAGES = 'PANEL_PROCEDURE_CREATOR_STAGES',
  PANEL_PROCEDURE_CREATOR_STAGE_DEFINITION = 'PANEL_PROCEDURE_CREATOR_STAGE_DEFINITION',
  PANEL_PROCEDURE_CREATOR_STAGE_FIELD_ADD = 'PANEL_PROCEDURE_CREATOR_STAGE_FIELD_ADD',
  PANEL_PROCEDURE_CREATOR_STAGE_FIELD_UPDATE = 'PANEL_PROCEDURE_CREATOR_STAGE_FIELD_UPDATE',
  PANEL_CONTACTS = 'PANEL_CONTACTS',
  PANEL_CONTACT_DETAILS = 'PANEL_CONTACTS_DETAILS',
  PANEL_DOCUMENTS = 'PANEL_DOCUMENTS',
  PANEL_DOCUMENT_DIRECTORY_DETAILS = 'PANEL_DOCUMENT_DIRECTORY_DETAILS',
  PANEL_DOCUMENT_FILE_DETAILS = 'PANEL_DOCUMENT_FILE_DETAILS',
  PANEL_DOCUMENT_FILE_UPDATE = 'PANEL_DOCUMENT_FILE_UPDATE',
  PANEL_DOCUMENT_CREATE = 'PANEL_DOCUMENT_CREATE',
  PANEL_CAMERA = 'PANEL_CAMERA',
  MANAGE_USERS_OR_PERMISSION_GROUPS = 'MANAGE_USERS_OR_PERMISSION_GROUPS',
  MANAGE_USERS = 'MANAGE_USERS',
  MANAGE_USERS_PERMISSIONS = 'MANAGE_USERS_PERMISSIONS',
  MANAGE_USERS_PERMISSIONS_CREATE = 'MANAGE_USERS_PERMISSIONS_CREATE',
  MANAGE_USERS_PERMISSIONS_UPDATE = 'MANAGE_USERS_PERMISSIONS_UPDATE',
  CREATE_USER = 'CREATE_USER',
  UPDATE_USER = 'UPDATE_USER',
  MANAGE_DICTIONARIES = 'MANAGE_DICTIONARIES',
  MANAGE_DICTIONARY_ITEMS = 'MANAGE_DICTIONARY_ITEMS',
  MANAGE_ATTRIBUTES = 'MANAGE_ATTRIBUTES',
  MANAGE_ATTRIBUTES_PROCEDURES = 'MANAGE_ATTRIBUTES_PROCEDURES',
  MANAGE_ATTRIBUTES_DOCUMENTS = 'MANAGE_ATTRIBUTES_DOCUMENTS',
  MANAGE_SETTINGS = 'MANAGE_SETTINGS',
  MANAGE_SETTINGS_PROCEDURES = 'MANAGE_SETTINGS_PROCEDURES',
  MANAGE_SETTINGS_DOCUMENTS = 'MANAGE_SETTINGS_DOCUMENTS',
  CHAT = 'CHAT',
  CHAT_CONVERSATION = 'CHAT_CONVERSATION',
  CHAT_CONVERSATION_CREATE = 'CHAT_CONVERSATION_CREATE',
  VIDEO_CALL_ROOM = 'VIDEO_CALL_ROOM',
  INCOMING_CALL_ACCEPT = 'INCOMING_CALL_ACCEPT',
  INCOMING_CALL_REJECT = 'INCOMING_CALL_REJECT',
  NOT_FOUND = 'NOT_FOUND',

}
