import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutHeaderLangPickerComponent } from './layout-header-lang-picker.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
  ],
  declarations: [
    LayoutHeaderLangPickerComponent,
  ],
  exports: [
    LayoutHeaderLangPickerComponent,
  ],
})
export class LayoutHeaderLangPickerModule {
}
