import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { DictionaryItemValueResponse } from '@api/dictionaries-api/models/responses/dictionary-item-value.repsonse';

@jsonObject
export class TaskRelatedProcedureResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly name!: string;
  @jsonMember public readonly category!: DictionaryItemValueResponse;

}
