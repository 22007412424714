export const apiParams = {
  ID: '{id}',
  TYPE: '{type}',
  CATEGORY: '{category}',
  PROCEDURE_ID: '{procedureId}',
  TASK_ID: '{taskId}',
  PERMISSION_GROUP_ID: '{permissionGroupId}',
  STAGE_ID: '{stageId}',
  FIELD_ID: '{fieldId}',
  VALIDATOR_ID: '{validatorId}',
  VERIFICATION_ID: '{verificationId}',
  PROPERTY_ID: '{propertyId}',
};
