import { FileSystemObjectType } from '@api/documents-api/models/enums/file-system-object-type.enum';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { CollectionEntryDetailsPropertyResponse } from '@api/documents-api/models/responses/collection-entry-details-property.response';
import { CollectionEntryDetailsAttributeResponse } from '@api/documents-api/models/responses/collection-entry-details-attribute.response';
import { FileSystemObjectPropertyName } from '@api/documents-api/models/enums/file-system-object-property-name.enum';
import { CollectionEntryContextOperationsResponse } from '@api/documents-api/models/responses/collection-entry-context-operations.response';

@jsonObject
export class CollectionEntryDetailsResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember(String) public readonly parentId!: Uuid;
  @jsonMember(String) public readonly type!: FileSystemObjectType;
  @jsonMember public readonly contextOperations!: CollectionEntryContextOperationsResponse;
  @jsonArrayMember(CollectionEntryDetailsResponse) public readonly children?: CollectionEntryDetailsResponse[];
  @jsonArrayMember(CollectionEntryDetailsPropertyResponse) public readonly properties?: CollectionEntryDetailsPropertyResponse[];
  @jsonArrayMember(CollectionEntryDetailsAttributeResponse) public readonly attributes?: CollectionEntryDetailsAttributeResponse[];

  public get isDirectory(): boolean {
    return this.type === FileSystemObjectType.DIRECTORY;
  }

  public get isFile(): boolean {
    return this.type === FileSystemObjectType.FILE;
  }

  public get fileProperty(): CollectionEntryDetailsPropertyResponse | undefined {
    return this.properties?.find(({ name }) => name === FileSystemObjectPropertyName.FILE);
  }

}
