import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CHAT_API } from '@api/conversations-api/services/tokens/chat-api.token';
import { ChatApiService } from '@api/conversations-api/services/chat-api.service';
import { CONVERSATIONS_LIVE_DATA_API } from '@api/conversations-api/services/tokens/conversations-live-data-api.token';
import { ConversationsLiveDataApiService } from '@api/conversations-api/services/conversations-live-data-api.service';
import { VIDEO_API } from '@api/conversations-api/services/tokens/video-api.token';
import { VideoApiService } from '@api/conversations-api/services/video-api.service';
import { CONVERSATIONS_NOTIFICATIONS_API } from '@api/conversations-api/services/tokens/notifications-api.token';
import { ConversationsNotificationsApiService } from '@api/conversations-api/services/conversations-notifications-api.service';
import { CONVERSATIONS_PERMISSION_GROUPS_API } from '@api/conversations-api/services/tokens/conversations-permission-groups-api.token';
import { ConversationsPermissionGroupsApiService } from '@api/conversations-api/services/conversations-permission-groups-api.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  declarations: [],
  providers: [
    {
      provide: CHAT_API,
      useClass: ChatApiService,
    },
    {
      provide: VIDEO_API,
      useClass: VideoApiService,
    },
    {
      provide: CONVERSATIONS_LIVE_DATA_API,
      useClass: ConversationsLiveDataApiService,
    },
    {
      provide: VIDEO_API,
      useClass: VideoApiService,
    },
    {
      provide: CONVERSATIONS_NOTIFICATIONS_API,
      useClass: ConversationsNotificationsApiService,
    },
    {
      provide: CONVERSATIONS_PERMISSION_GROUPS_API,
      useClass: ConversationsPermissionGroupsApiService,
    },
  ],
})
export class ConversationsApiModule {
}
