<div class="notifications-list__item d-flex align-items-center"
     pRipple
     (click)="clicked.next()">
  <div *ngIf="model.icon" class="col notifications-list__item_icon flex-grow-0 flex-shrink-0">
    <i class="{{ model.icon | iconCssClass }}"></i>
  </div>

  <div class="notifications-list__item-content flex-grow-1">
    <div class="d-flex justify-content-between">
      <div class="notifications-list__item_title flex-grow-1">{{ model.title }}</div>
      <div class="notifications-list__item_time text-right ml-1">{{ model.time | timeago:'live' }}</div>
    </div>

    <div class="notifications-list__item_description mt-1">{{ model.description }}</div>
  </div>
</div>
