import { DictionariesApi } from '@api/dictionaries-api/services/interfaces/dictionaries.api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { apiDictionariesCommon } from '@environments/environment';
import { Observable } from 'rxjs';
import { DictionaryResponse } from '@api/dictionaries-api/models/responses/dictionary.response';
import { DictionaryType } from '@api/dictionaries-api/models/enums/dictionary-type';

@Injectable({
  providedIn: 'root',
})
export class DictionariesApiService implements DictionariesApi {

  public constructor(
    private http: HttpClient,
    private apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(): Observable<DictionaryResponse[]> {
    const url = this.grabEndpointUrl(apiDictionariesCommon.DICTIONARIES.LIST);

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToModelArray(DictionaryResponse),
    );
  }

  public details(type: DictionaryType): Observable<DictionaryResponse> {
    const url = this.grabEndpointUrl(apiDictionariesCommon.DICTIONARIES.DETAILS).replace('{type}', type);

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToModel(DictionaryResponse),
    );
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiDictionariesCommon.API_HOST_URL, endpoint].join('');
  }
}
