import { IL10nsStrings, TimeagoIntl } from 'ngx-timeago';
import { Injectable, OnDestroy } from '@angular/core';
import { strings as polishString } from 'ngx-timeago/language-strings/pl';
import { strings as englishString } from 'ngx-timeago/language-strings/en';
import { TranslateService } from '@ngx-translate/core';
import { startWith, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Language } from '@core/translations/enums/language.enum';

@Injectable({
  providedIn: 'root',
})
export class TimeagoTranslationProvider extends TimeagoIntl implements OnDestroy {

  private readonly destroy$: Subject<void> = new Subject<void>();

  public constructor(
    private readonly translateService: TranslateService,
  ) {
    super();

    this.listenForLanguageChange();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  private listenForLanguageChange(): void {
    this
      .translateService
      .onLangChange
      .pipe(
        map(({ lang }) => lang),
        startWith(this.lang),
        takeUntil(this.destroy$),
      )
      .subscribe({ next: (lang: string) => this.localeInitializer(lang) });
  }

  private get lang(): string {
    return this.translateService.currentLang;
  }

  private localeInitializer(lang: string): void {
    switch (lang) {
      case Language.POLISH: {
        this.setLanguageStrings(polishString);
        break;
      }

      case Language.ENGLISH: {
        this.setLanguageStrings(englishString);
        break;
      }

      default: {
        this.setLanguageStrings(polishString);
        break;
      }
    }
  }

  private setLanguageStrings(strings: IL10nsStrings): void {
    this.strings = strings;
    this.changes.next();
  }

}
