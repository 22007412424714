import { jsonMember, jsonObject } from 'typedjson';
import { AttributeFilterValueNormalizer } from '@api/models/collection/requests/attribute-filter-value-normalizer';

@jsonObject
export class AttributeFilterModel {

  @jsonMember public operator?: string;
  @jsonMember({
    serializer: AttributeFilterValueNormalizer.normalize,
  })
  public value?: string | string[];

}
