import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { FilePropertyRequest } from '@api/documents-api/models/requests/file-property.request';

@jsonObject
export class CreateDirectoryRequest {

  @jsonMember(String) public readonly parentId!: Uuid;
  @jsonArrayMember(FilePropertyRequest) public readonly properties!: FilePropertyRequest[];

  public constructor(
    parentId: Uuid,
    properties: FilePropertyRequest[],
  ) {
    this.parentId = parentId;
    this.properties = properties;
  }

}
