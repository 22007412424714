import { Uuid } from '@app/api/types/uuid.type';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class ProcedureAttributeResponse {

  @jsonMember(String) public readonly attributeId!: Uuid;
  @jsonMember public readonly value!: string;

}
