import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { ProcedureFieldPropertyType } from '@api/procedures-api/models/enums/procedure-field-property-type.enum';
import { ProcedureFieldPropertyValueRequest } from '@api/procedures-api/models/requests/procedure-field-property-value.request';

@jsonObject
export class ProcedureFieldPropertyCreateRequest {

  @jsonMember public key!: ProcedureFieldPropertyType;
  @jsonArrayMember(ProcedureFieldPropertyValueRequest, { name: 'value' }) public values!: ProcedureFieldPropertyValueRequest[];

  public constructor(
    key: ProcedureFieldPropertyType,
    values: ProcedureFieldPropertyValueRequest[],
  ) {
    this.key = key;
    this.values = values;
  }

}
