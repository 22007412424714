import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { FileSystemObjectPropertyType } from '@api/documents-api/models/enums/file-system-object-property-type.enum';
import { CollectionPropertyOptionResponse } from '@api/documents-api/models/responses/collection-property-option.response';
import { FileSystemObjectPropertyName } from '@api/documents-api/models/enums/file-system-object-property-name.enum';

@jsonObject
export class CollectionPropertyResponse {

  @jsonMember(String) public readonly id!: FileSystemObjectPropertyName;
  @jsonMember public readonly name!: string;
  @jsonMember public readonly isRequired!: boolean;
  @jsonMember(String) public readonly type!: FileSystemObjectPropertyType;
  @jsonMember public readonly createdAt!: Date;
  @jsonMember public readonly updatedAt!: Date;
  @jsonArrayMember(CollectionPropertyOptionResponse) public readonly options?: CollectionPropertyOptionResponse[];

}
