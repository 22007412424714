import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';

@jsonObject
export class AttributeRequest {

  @jsonMember(String) public readonly attributeId!: Uuid;
  @jsonMember public readonly value!: string;

  public constructor(attributeId: Uuid, value: string) {
    this.attributeId = attributeId;
    this.value = value;
  }

}
