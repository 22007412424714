import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class ProcedureFieldValidatorDateRelativeParamResponse {

  @jsonMember public readonly pastCount?: number;
  @jsonMember public readonly futureCount?: number;
  @jsonMember public readonly allowToday?: boolean;

}
