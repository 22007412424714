import { Injectable } from '@angular/core';
import { ResourceCollectionRequestBuilder } from '@api/helpers/resource-collection-request.builder';
import { NotificationFilter } from '@api/conversations-api/models/filters/notification.filter';

@Injectable({
  providedIn: 'root',
})
export class NotificationsCollectionRequestBuilder extends ResourceCollectionRequestBuilder<NotificationFilter> {
  protected getFilterInstance(): NotificationFilter {
    return new NotificationFilter();
  }
}
