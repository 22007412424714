import { Directive, HostListener } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Directive({
  selector: '[appOverlayPanelPositionCorrectorDirective]',
})
export class OverlayPanelPositionCorrectorDirective {

  public constructor(
    private readonly overlayPanel: OverlayPanel,
  ) {
  }

  @HostListener('window:scroll', ['$event'])
  private onScroll(): void {
    if (this.overlayPanel.overlayVisible) {
      this.overlayPanel.align();
    }
  };

}
