import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactAvatarComponent } from './contact-avatar.component';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { AssetsModule } from '@core/assets/assets.module';


@NgModule({
  imports: [
    CommonModule,

    // Moduly wlasne
    AssetsModule,

    // PrimeNg
    AvatarModule,
    BadgeModule,
  ],
  declarations: [
    ContactAvatarComponent,
  ],
  exports: [
    ContactAvatarComponent,
  ],
})
export class ContactAvatarModule {
}
