export enum AssetsImage {

  LOGO_INVERSE = 'logo_inverse.png',
  LOGO_SQUARE = 'logo_square.png',
  LOGO_HORIZONTAL = 'logo_horizontal.png',
  TILES = 'grid.svg', // lub 'three-v.svg',
  TILES_MORE_INFO = 'three-h.svg',
  ICON_DOC = 'doc.svg',
  ICON_PDF = 'pdf.svg',
  ICON_XLS = 'xls.svg',
  ICON_DIRECTORY = 'directory.svg',
  UNRECOGNIZED_FILE = 'unrecognized_file.svg',
  ACIDIC_LIQUID = 'acidic_liquid.svg',
  ALARMS = 'alarms.svg',
  BIOLOGICAL_HAZARD = 'biological_hazard.svg',
  DANGER_OF_FLAME = 'danger_of_flame.svg',
  EXPLOSIVE_MATERIAL = 'explosive_material.svg',
  FLAMMABLE_MATERIAL = 'flammable_material.svg',
  GLOVES = 'gloves.svg',
  HALT = 'halt.svg',
  HIGH_VOLTAGE = 'high_voltage.svg',
  MEASUREMENT = 'measurement.svg',
  PRESSURIZED_CYLINDER = 'pressurized_cylinder.svg',
  RADIATION = 'radiation.svg',
  WARNING = 'warning.svg',
  WIRE_AND_PLUG = 'wire_and_plug.svg',
  WRENCH_AND_SCREW_DRIVER = 'wrench_and_screw_driver.svg',

}
