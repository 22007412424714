import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { ContactAvailability } from '@api/contacts-api/models/enums/contact-availability.enum';
import { ConversationInfoResponse } from '@api/conversations-api/models/responses/conversation-info.response';
import { ContactContextOperationsResponse } from '@api/contacts-api/models/responses/contact-context-operations.response';

@jsonObject
export class ContactResponse {
  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember(String) public readonly userId!: Uuid;
  @jsonMember(String) public readonly availabilityStatus: ContactAvailability = ContactAvailability.OFFLINE;
  @jsonMember public readonly fullName!: string;
  @jsonMember public readonly avatar?: string;
  @jsonMember public readonly jobName?: string;
  @jsonMember public readonly department?: string;
  @jsonMember public readonly lastContactAt?: Date;
  @jsonArrayMember(String) public readonly roles?: string[];
  @jsonMember public readonly privateConversation?: ConversationInfoResponse;
  @jsonMember public readonly contextOperations!: ContactContextOperationsResponse;
}
