import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { CollectionEntryResponse } from '@api/documents-api/models/responses/collection-entry.response';

@jsonObject
export class TreeCollectionEntryResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember(String) public readonly parentId!: Uuid;
  @jsonMember public readonly name!: string;
  @jsonArrayMember(CollectionEntryResponse) public readonly children?: CollectionEntryResponse[];

}
