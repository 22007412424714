import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class SignInRequest {

  @jsonMember public username!: string;
  @jsonMember public password!: string;

  public constructor(username: string, password: string) {
    this.username = username;
    this.password = password;
  }
}
