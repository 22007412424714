import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class ProcedureContextOperationsResponse {

  @jsonMember public readonly canView!: boolean;
  @jsonMember public readonly canUpdate!: boolean;
  @jsonMember public readonly canDelete!: boolean;
  @jsonMember public readonly canArchive!: boolean;
  @jsonMember public readonly canCreateVersion!: boolean;

}
