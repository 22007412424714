import { DictionaryItemsApi } from '@api/dictionaries-api/services/interfaces/dictionary-items.api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { apiDictionariesCommon } from '@environments/environment';
import { DictionaryType } from '@api/dictionaries-api/models/enums/dictionary-type';
import { Observable } from 'rxjs';
import { DictionaryItemResponse } from '@api/dictionaries-api/models/responses/dictionary-item.response';
import { Uuid } from '@api/types/uuid.type';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';

@Injectable({
  providedIn: 'root',
})
export class DictionaryItemsApiService implements DictionaryItemsApi {

  public constructor(
    private http: HttpClient,
    private apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(type: DictionaryType): Observable<CollectionResponse<DictionaryItemResponse>> {
    const url = this.grabEndpointUrl(apiDictionariesCommon.DICTIONARY_ITEMS.LIST)
      .replace('{type}', type);

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToCollection(DictionaryItemResponse),
    );
  }

  public details(type: DictionaryType, id: Uuid): Observable<DictionaryItemResponse> {
    const url = this.grabEndpointUrl(apiDictionariesCommon.DICTIONARY_ITEMS.DETAILS)
      .replace('{type}', type)
      .replace('{id}', id.toString());

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToModel(DictionaryItemResponse),
    );
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiDictionariesCommon.API_HOST_URL, endpoint].join('');
  }

}
