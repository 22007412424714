/**
 * Lista dostępnych uprawnień, ról, cech użytkowników
 */
export enum Permission {
  IS_LOGGED = 'IS_LOGGED',
  IS_NOT_LOGGED = 'IS_NOT_LOGGED',
  IS_ADMIN = 'IS_ADMIN',
  IS_NOT_ADMIN = 'IS_NOT_ADMIN',
  IS_MANAGER = 'IS_MANAGER',
  IS_NOT_MANAGER = 'IS_NOT_MANAGER',
  IS_EXPERT = 'IS_EXPERT',
  IS_NOT_EXPERT = 'IS_NOT_EXPERT',
  IS_EMPLOYEE = 'IS_EMPLOYEE',
  IS_NOT_EMPLOYEE = 'IS_NOT_EMPLOYEE',

  HAS_CONTACTS_ACCESS = 'HAS_CONTACTS_ACCESS',
  HAS_CHAT_AND_VIDEO_CONVERSATIONS_ACCESS = 'HAS_CHAT_AND_VIDEO_CONVERSATIONS_ACCESS',
  HAS_USERS_ACCESS = 'HAS_USERS_ACCESS',
  HAS_PERMISSION_GROUPS_ACCESS = 'HAS_PERMISSION_GROUPS_ACCESS',
  HAS_DICTIONARIES_ACCESS = 'HAS_DICTIONARIES_ACCESS',
  HAS_ATTRIBUTES_ACCESS = 'HAS_ATTRIBUTES_ACCESS',
  HAS_DASHBOARD_ACCESS = 'HAS_DASHBOARD_ACCESS',
  HAS_PROCEDURES_ACCESS = 'HAS_PROCEDURES_ACCESS',
  HAS_TASKS_ACCESS = 'HAS_TASKS_ACCESS',
  HAS_DOCUMENTS_ACCESS = 'HAS_DOCUMENTS_ACCESS',
  HAS_ALL_TASKS_ACCESS = 'HAS_ALL_TASKS_ACCESS',
  HAS_MY_TASKS_ACCESS = 'HAS_MY_TASKS_ACCESS',
  HAS_SETTINGS_ACCESS = 'HAS_SETTINGS_ACCESS',

  CAN_CREATE_USERS = 'CAN_CREATE_USERS',
  CAN_CREATE_PERMISSION_GROUPS = 'CAN_CREATE_PERMISSION_GROUPS',
  CAN_CREATE_PROCEDURES = 'CAN_CREATE_PROCEDURES',
  CAN_CREATE_ATTRIBUTES = 'CAN_CREATE_ATTRIBUTES',
  CAN_CREATE_TASKS = 'CAN_CREATE_TASKS',
  CAN_UPDATE_OWN_PROFILE_DATA = 'CAN_UPDATE_OWN_PROFILE_DATA',
  CAN_CHANGE_OWN_PASSWORD = 'CAN_CHANGE_OWN_PASSWORD',
  CAN_AUTH_BY_QR_CODE = 'CAN_AUTH_BY_QR_CODE',
  CAN_CREATE_DASHBOARDS = 'CAN_CREATE_DASHBOARDS',
}
