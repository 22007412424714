import { Uuid } from '@app/api/types/uuid.type';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { AttributeRequest } from '@api/common/attributes/models/requests/attribute.request';

@jsonObject
export class ProcedureCreateRequest {

  @jsonMember public readonly name?: string;
  @jsonMember(String) public readonly categoryId?: Uuid;
  @jsonMember(String) public readonly expertId?: Uuid;
  @jsonMember(String) public readonly verifierId?: Uuid;
  @jsonMember public readonly description?: string;
  @jsonArrayMember(AttributeRequest) public readonly attributes?: AttributeRequest[];

  public constructor(
    name?: string,
    description?: string,
    categoryId?: Uuid,
    expertId?: Uuid,
    verifierId?: Uuid,
    attributes?: AttributeRequest[],
  ) {
    this.name = name;
    this.categoryId = categoryId;
    this.expertId = expertId;
    this.verifierId = verifierId;
    this.description = description;
    this.attributes = attributes;
  }

}
