import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SETTINGS_API } from './services/tokens/settings-api.token';
import { SettingsApiService } from './services/settings-api.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    {
      provide: SETTINGS_API,
      useClass: SettingsApiService,
    },
  ],
})
export class SettingsApiModule {
}
