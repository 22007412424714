import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { NotificationType } from '@api/conversations-api/models/enums/notification-type.enum';
import { NotificationMetadataResponse } from '@api/conversations-api/models/responses/notification-metadata.response';

@jsonObject
export class NotificationResponse {
  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember(String) public readonly type!: NotificationType;
  @jsonMember public readonly isRead!: boolean;
  @jsonMember public readonly title!: string;
  @jsonMember public readonly message!: string;
  @jsonMember public readonly createdAt!: Date;
  @jsonMember public readonly bumpedAt!: Date;
  @jsonMember public readonly bumpCount!: number;
  @jsonMember public readonly readAt?: Date;
  @jsonMember public readonly metadata!: NotificationMetadataResponse;

  public isUnreadConversationNotification(): boolean {
    return this.type === NotificationType.UNREAD_CONVERSATION;
  }

  public isParticipantAddedToConversationNotification(): boolean {
    return this.type === NotificationType.PARTICIPANT_ADDED_TO_CONVERSATION;
  }

  public isMissedIncomingCallNotification(): boolean {
    return this.type === NotificationType.MISSED_INCOMING_CALL;
  }

  public isRoomRecordingReadyNotification(): boolean {
    return this.type === NotificationType.ROOM_RECORDING_READY;
  }
}
