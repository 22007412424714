import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, map } from 'rxjs/operators';
import { RouteProvider } from '../../routing/provider/route.provider';
import { RouteName } from '../../routing/route-name.enum';

@Injectable({
  providedIn: 'root',
})
export class OnlyLoggedUserGuard implements CanActivate, CanActivateChild, CanLoad {


  public constructor(private authService: AuthService,
                     private routeProvider: RouteProvider,
  ) {
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.ensureUserIsAuthenticated();
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return of(true);
  }

  public canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.ensureUserIsAuthenticated();
  }

  private ensureUserIsAuthenticated(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authService.me().pipe(
      map(() => true),
      catchError(() => {
        return of(this.signOutPageUrlTree());
      }),
    );
  }

  private signOutPageUrlTree(): UrlTree {
    return this.routeProvider.getUrlTree(RouteName.SIGN_OUT);
  }
}
