import { Injectable } from '@angular/core';
import { ResourceCollectionRequestBuilder } from '@api/helpers/resource-collection-request.builder';
import { UserFilter } from '@api/cmuia-api/models/filters/user.filter';
import { AttributeFilterModel } from '@api/models/collection/requests/attribute-filter.model';
import { PermissionGroupType } from '@api/cmuia-api/models/enums/permission-group-type';
import { AttributeFilterOperator } from '@api/models/collection/enum/attribute-filter-operator.enum';

@Injectable({
  providedIn: 'root',
})
export class UsersCollectionRequestBuilder extends ResourceCollectionRequestBuilder<UserFilter> {

  public addFilterByPermissionGroupType(types: PermissionGroupType[]): this {
    const filter = new AttributeFilterModel();
    filter.operator = AttributeFilterOperator.IN;
    filter.value = types;

    return this.addFilter('permissionGroupsType', filter);
  }

  protected getFilterInstance(): UserFilter {
    return new UserFilter();
  }

}
