import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class TaskActivityVerificationAcceptRequest {

  @jsonMember public comment?: string;

  public constructor(
    comment?: string,
  ) {
    this.comment = comment;
  }

}
