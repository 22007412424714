import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { ConversationType } from '@api/conversations-api/models/enums/conversation-type.enum';
import { Uuid } from '@api/types/uuid.type';
import { stringValueSerializer } from '@core/serializer/string-value.serializer';

@jsonObject
export class CreateConversationRequest {
  @jsonMember({ serializer: stringValueSerializer() }) public name?: string;
  @jsonMember public type?: ConversationType;
  @jsonArrayMember(String) public participantsIds!: Uuid[];

  public constructor(participantsIds: Uuid[], type?: ConversationType, name?: string) {
    this.participantsIds = participantsIds;
    this.name = name;
    this.type = type;
  }
}
