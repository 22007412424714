import { Injectable } from '@angular/core';
import { AlertMessage } from '@common/components/alert/interface/alert-message.interface';
import { IconCssClassProvider } from '@core/assets/providers/icon-css-class.provider';
import { Icon } from '@core/assets/enums/icon.enum';
import { AlertSeverity } from '@common/components/alert/enums/alert-severity.enum';

@Injectable({
  providedIn: 'root',
})
export class AlertMessageBuilder {

  private message!: AlertMessage;


  public constructor(private iconCssClassProvider: IconCssClassProvider) {
    this.reset();
  }

  private reset(): void {
    this.message = {};
  }

  public build(): AlertMessage {
    const message = this.message;

    this.reset();

    return message;
  }

  private setSeverity(severity: AlertSeverity): this {
    this.message.severity = severity;

    return this;
  }

  public setSeverityIcon(icon: Icon): this {
    this.message.severityIcon = icon;

    return this;
  }

  public removeSeverityIcon(): this {
    this.message.severityIcon = undefined;

    return this;
  }

  public setSeverityInfo(): this {
    this.setSeverity(AlertSeverity.INFO);
    this.setSeverityIcon(Icon.ALERT_INFO);

    return this;
  }

  public setSeveritySuccess(): this {
    this.setSeverity(AlertSeverity.SUCCESS);
    this.setSeverityIcon(Icon.ALERT_SUCCESS);

    return this;
  }

  public setSeverityWarning(): this {
    this.setSeverity(AlertSeverity.WARN);
    this.setSeverityIcon(Icon.ALERT_WARNING);

    return this;
  }

  public setSeverityDanger(): this {
    this.setSeverity(AlertSeverity.DANGER);
    this.setSeverityIcon(Icon.ALERT_DANGER);

    return this;
  }

  public setSeverityError(): this {
    this.setSeverity(AlertSeverity.ERROR);
    this.setSeverityIcon(Icon.ALERT_ERROR);

    return this;
  }

  public setTitle(title: string): this {
    this.message.header = title;

    return this;
  }

  public setBody(body: string): this {
    this.message.message = body;

    return this;
  }

  public setAcceptLabel(label: string): this {
    this.message.acceptLabel = label;

    return this;
  }

  public setAcceptIcon(icon: Icon): this {
    this.message.acceptIcon = this.iconCssClassProvider.getCssClass(icon);

    return this;
  }

  public setAcceptCallback(callback: Function): this {
    this.message.accept = callback;

    return this;
  }

  public setCloseCallback(callback: Function): this {
    this.message.reject = callback;

    return this;
  }

  public setAcceptButtonStyleClass(cssClass: string): this {
    this.message.acceptButtonStyleClass = cssClass;

    return this;
  }

  public setIcon(icon: Icon): this {
    this.message.icon = this.iconCssClassProvider.getCssClass(icon);

    return this;
  }

  public from(confirm: AlertMessage): this {
    Object.assign(this.message, confirm);

    return this;
  }
}
