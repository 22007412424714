import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CHAT_API } from '@api/conversations-api/services/tokens/chat-api.token';
import { ChatApi } from '@api/conversations-api/services/interfaces/chat.api';
import { Icon } from '@core/assets/enums/icon.enum';
import { OverlayPanel } from 'primeng/overlaypanel';
import { CONVERSATIONS_LIVE_DATA_API } from '@api/conversations-api/services/tokens/conversations-live-data-api.token';
import { ConversationsLiveDataApi } from '@api/conversations-api/services/interfaces/conversations-live-data.api';
import { NotificationItemViewModel } from '@core/layouts/dashboard-layout/components/dashboard-layout-header-notifications/header-notifications/models/notification-item.view-model';

@Component({
  selector: 'app-header-notifications',
  templateUrl: './header-notifications.component.html',
  styleUrls: ['./header-notifications.component.scss'],
})
export class HeaderNotificationsComponent {

  @Input() public notifications: NotificationItemViewModel[] = [];
  @Input() public triggerButtonIcon!: Icon;
  @Input() public triggerButtonStyleCss?: string;
  @Input() public emptyText = 'HEADER.NOTIFICATIONS.NO_NOTIFICATIONS';
  @Input() public markAllReadButtonText = 'HEADER.NOTIFICATIONS.MARK_AS_READ';
  @Input() public additionalButtonsTemplate?: TemplateRef<Object>;
  @Output() public markAllReadClicked = new EventEmitter<void>();
  @Output() public notificationClicked = new EventEmitter<NotificationItemViewModel>();

  @ViewChild(OverlayPanel) public overlayPanel!: OverlayPanel;

  public constructor(
    @Inject(CHAT_API) private readonly chatApiService: ChatApi,
    @Inject(CONVERSATIONS_LIVE_DATA_API) private readonly conversationsLiveDataApi: ConversationsLiveDataApi,
    private readonly cd: ChangeDetectorRef,
    private readonly zone: NgZone,
  ) {
  }

  public togglePanel($event: MouseEvent): void {
    this.overlayPanel.toggle($event);
    this.cd.detectChanges();
  }

  public hide(): void {
    if (this.overlayPanel.overlayVisible) {
      this.overlayPanel.hide();
      this.cd.detectChanges();
    }
  }

  public onMarkAllReadClicked(): void {
    this.hide();
    this.markAllReadClicked.next();
  }

  public onNotificationClicked(notification: NotificationItemViewModel): void {
    this.hide();
    this.zone.run(() => {
      this.notificationClicked.next(notification);
    });
  }

}
