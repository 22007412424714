import { Injectable } from '@angular/core';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenProvider {

  public constructor(private tokenStorage: TokenStorageService) {
  }

  public get token(): string | null {
    return this.tokenStorage.getToken();
  }

  public get refreshToken(): string | null {
    return this.tokenStorage.getRefreshToken();
  }

  public hasToken(): boolean {
    return !!this.token;
  }

  public hasRefreshToken(): boolean {
    return !!this.refreshToken;
  }

}
