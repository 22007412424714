import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { Language } from '@core/translations/enums/language.enum';
import { LanguageLabelsProviderService } from '@app/features/language/providers/language-labels-provider.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LanguageSelectOptionsProviderService {

  public constructor(
    private readonly labelsProvider: LanguageLabelsProviderService,
  ) {
  }

  public options$(): Observable<SelectItem<Language>[]> {
    return combineLatest(
      Object
        .values(Language)
        .map((language: Language) => combineLatest({
            label: this.labelsProvider.shortLabel(language),
            title: this.labelsProvider.extendedLabel(language),
            value: of(language),
          }),
        ),
    )
      .pipe(
        map((languages: Array<{ label: string, title: string, value: Language }>) =>
          languages.map(({ label, title, value }) => ({ label, title, value })),
        ),
      );
  }

}
