import { AnyT, jsonMember, jsonObject } from 'typedjson';
import { FileSystemObjectPropertyOption } from '@api/documents-api/models/enums/file-system-object-property-option-key.enum';
import { JsonSerializer } from '@core/serializer/json.serializer';

@jsonObject
export class FileSystemObjectPropertyOptionListItemResponse {

  @jsonMember public readonly key!: string;
  @jsonMember public readonly value!: string;

}

@jsonObject
export class CollectionPropertyOptionResponse<ValueType = string> {

  @jsonMember public readonly key!: FileSystemObjectPropertyOption;
  @jsonMember(AnyT, { name: 'value' }) private readonly _value!: ValueType;

  public get value(): ValueType | FileSystemObjectPropertyOptionListItemResponse[] {
    switch (this.key) {
      case FileSystemObjectPropertyOption.MAX_LENGTH:
      case FileSystemObjectPropertyOption.MAX_FILE_SIZE:
      case FileSystemObjectPropertyOption.FORBIDDEN_CHARACTERS:
      case FileSystemObjectPropertyOption.LIST_MULTIPLE_SELECTION: {
        return this._value;
      }

      case FileSystemObjectPropertyOption.LIST_OPTIONS: {
        return JsonSerializer.parseArray(this._value, FileSystemObjectPropertyOptionListItemResponse);
      }
    }
  }

}
