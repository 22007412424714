<div #gridContainer
     appDropDashboardHeaderShadow
     [dropShadowOffsetY]="header.clientHeight"
     class="grid-container">
  <header #header class="header">
    <app-dashboard-layout-header
      (toggleMenu)="toggleMenu()">
    </app-dashboard-layout-header>
  </header>
  <aside class="sidenav">
    <div class="sidenav__inner">
      <app-dashboard-layout-sidebar></app-dashboard-layout-sidebar>
    </div>
  </aside>
  <main class="main">
    <div class="container-fluid">
      <app-page-title
        *ngIf="pageTitle$ | async as title"
        [title]="title">
      </app-page-title>

      <ng-container *ngIf="breadcrumbs$ | async as breadcrumbs">
        <div *ngIf="breadcrumbs.length > 0">
          <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
        </div>
      </ng-container>

      <ng-container *ngIf="{ title: pageTitle$ | async, breadcrumbs: breadcrumbs$ | async } as params">
        <div *ngIf="params.title || (params.breadcrumbs && params.breadcrumbs.length > 0)"
             class="main--v-spacer"></div>
      </ng-container>

      <router-outlet></router-outlet>
    </div>
  </main>
</div>

<app-toast></app-toast>
<app-confirmation-dialog></app-confirmation-dialog>
<app-confirmation-popup></app-confirmation-popup>
<app-alert-dialog></app-alert-dialog>
