import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class UpdateUserProfileRequest {

  @jsonMember public email!: string;
  @jsonMember public firstName?: string;
  @jsonMember public lastName?: string;
  @jsonMember public jobName?: string;
  @jsonMember public department?: string;

  public constructor(email: string, firstName?: string, lastName?: string, jobName?: string, department?: string) {
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.jobName = jobName;
    this.department = department;
  }
}
