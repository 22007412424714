import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardLayoutComponent } from './dashboard-layout.component';
import { RouterModule } from '@angular/router';
import { DashboardLayoutHeaderModule } from './components/dashboard-layout-header/dashboard-layout-header.module';
import { DashboardLayoutSidebarModule } from './components/dashboard-layout-sidebar/dashboard-layout-sidebar.module';
import { DropDashboardHeaderShadowDirective } from './directives/drop-dashboard-header-shadow.directive';
import { PageTitleModule } from '../components/page-title/page-title.module';
import { BreadcrumbsModule } from '../components/breadcrumbs/breadcrumbs.module';
import { ToastModule } from '@common/components/toast/toast.module';
import { ConfirmationModule } from '@common/components/confirmation/confirmation.module';
import { AlertModule } from '@common/components/alert/alert.module';
import { AudioVideoCallDialogModule } from '@common/components/audio-video-call-dialog/audio-video-call-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DashboardLayoutHeaderModule,
    DashboardLayoutSidebarModule,
    PageTitleModule,
    BreadcrumbsModule,
    ToastModule,
    ConfirmationModule,
    AlertModule,
    AudioVideoCallDialogModule,
  ],
  declarations: [
    DashboardLayoutComponent,
    DropDashboardHeaderShadowDirective,
  ],
})
export class DashboardLayoutModule {
}
