import { Injectable } from '@angular/core';
import { ToastMessage } from '@common/components/toast/interface/toast-message.interface';
import { ToastMessageSeverity } from '@common/components/toast/enums/toast-message-severity.enum';
import { Icon } from '@core/assets/enums/icon.enum';
import { IconCssClassProvider } from '@core/assets/providers/icon-css-class.provider';

@Injectable({
  providedIn: 'root',
})
export class ToastMessageBuilder {

  private message!: ToastMessage;

  public constructor(private iconCssClassProvider: IconCssClassProvider) {
    this.reset();
  }

  private reset(): void {
    this.message = {};
    this.setSeverity(ToastMessageSeverity.INFO);
  }

  public build(): ToastMessage {
    const message = this.message;

    this.reset();

    return message;
  }

  public setSeverity(severity: ToastMessageSeverity): this {
    this.message.severity = severity;

    return this;
  }

  public setInfoSeverity(): this {
    return this.setSeverity(ToastMessageSeverity.INFO);
  }

  public setSuccessSeverity(): this {
    return this.setSeverity(ToastMessageSeverity.SUCCESS);
  }

  public setWarnSeverity(): this {
    return this.setSeverity(ToastMessageSeverity.WARN);
  }

  public setErrorSeverity(): this {
    return this.setSeverity(ToastMessageSeverity.ERROR);
  }

  public setTitle(title: string): this {
    this.message.summary = title;

    return this;
  }

  public setBody(body: string): this {
    this.message.detail = body;

    return this;
  }

  public setIcon(icon: Icon): this {
    this.message.icon = this.iconCssClassProvider.getCssClass(icon);

    return this;
  }

  public setSeverityIcon(severity: ToastMessageSeverity): this {
    const icons: { [key in ToastMessageSeverity]: Icon } = {
      [ToastMessageSeverity.SUCCESS]: Icon.MESSAGE_SUCCESS,
      [ToastMessageSeverity.INFO]: Icon.MESSAGE_INFO,
      [ToastMessageSeverity.WARN]: Icon.MESSAGE_WARN,
      [ToastMessageSeverity.ERROR]: Icon.MESSAGE_ERROR,
    };

    this.setIcon(icons[severity]);

    return this;
  }

  public removeIcon(): this {
    this.message.icon = undefined;

    return this;
  }

  public setSticky(sticky: boolean): this {
    this.message.sticky = sticky;

    return this;
  }

  public setCloseable(closeable: boolean): this {
    this.message.closable = closeable;

    return this;
  }

  public setLife(lifeMillis: number): this {
    this.message.life = lifeMillis;

    return this;
  }

  public setData(data: Object): this {
    this.message.data = data;

    return this;
  }

  public setId(id: string): this {
    this.message.id = id;

    return this;
  }

  public setKey(key: string): this {
    this.message.key = key;

    return this;
  }
}
