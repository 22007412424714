import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { DashboardContextOperationsResponse } from '@api/dashboards-api/models/responses/dashboard-context-operations.response';
import { DashboardPermissionResponse } from '@api/dashboards-api/models/responses/dashboard-permission.response';

@jsonObject
export class DashboardResponse {
  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember(String) public readonly creatorId!: Uuid;
  @jsonMember public readonly devExpressDashboardId!: string;
  @jsonMember public readonly name!: string;
  @jsonMember public readonly createdAt!: Date;
  @jsonArrayMember(DashboardPermissionResponse) public readonly permissions!: DashboardPermissionResponse[];
  @jsonMember public readonly contextOperations!: DashboardContextOperationsResponse;
}
