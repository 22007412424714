import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class RefreshTokenRequest {

  @jsonMember public refreshToken!: string;

  public constructor(refreshToken: string) {
    this.refreshToken = refreshToken;
  }
}
