import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { TreeExpandedNodeQueryParams } from '@api/documents-api/models/params/tree-expanded-node.query-params';

@jsonObject
export class TreeQueryParams {

  @jsonMember(String) public readonly parent?: Uuid;
  @jsonArrayMember(TreeExpandedNodeQueryParams) public readonly expanded?: TreeExpandedNodeQueryParams[];

  public constructor(
    parent?: Uuid,
    expanded?: TreeExpandedNodeQueryParams[],
  ) {
    this.parent = parent;
    this.expanded = expanded;
  }

}
