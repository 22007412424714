import { Uuid } from '@app/api/types/uuid.type';
import { jsonMember, jsonObject } from 'typedjson';
import { PermissionGroupType } from '@api/cmuia-api/models/enums/permission-group-type';
import { PermissionGroupContextOperationsResponse } from '@api/cmuia-api/models/responses/permission-group-context-operations.response';

@jsonObject
export class UserPermissionGroupResponse {
  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly name!: string;
  @jsonMember public readonly code!: string;
  @jsonMember(String) public readonly type!: PermissionGroupType;
  @jsonMember public readonly description!: string;
  @jsonMember public readonly color?: string;
  @jsonMember public readonly contextOperations!: PermissionGroupContextOperationsResponse;
}
