import { Component } from '@angular/core';
import { ConfirmationTypeKey } from '@common/components/confirmation/enums/confirmation-type-key.enum';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss'],
})
export class ConfirmationPopupComponent {

  public readonly key = ConfirmationTypeKey.POPUP;

  public constructor() {
  }

}
