import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageTitleProvider } from '@core/layouts/components/page-title/providers/page-title.provider';
import { BrowserTabPageTitleService } from '@core/layouts/services/browser-tab-page-title.service';
import { Subject } from 'rxjs';
import { PrimeNGConfig, Translation } from 'primeng/api';
import { PrimengTranslationProvider } from '@core/translations/providers/primeng-translation.provider';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  private readonly DEFAULT_PAGE_TITLE = 'ARSolutions';

  private $_destroyed = new Subject<void>();

  public constructor(
    private readonly primengConfig: PrimeNGConfig,
    private readonly primengTranslationProvider: PrimengTranslationProvider,
    private readonly titleProvider: PageTitleProvider,
    private readonly browserTabPageTitleService: BrowserTabPageTitleService) {
  }

  public ngOnInit(): void {
    this.configurePrimeNg();
    this.listenToPageTitleChange();
  }

  private listenToPageTitleChange(): void {
    this
      .titleProvider
      .title$
      .subscribe((pageTitle: string) => {
        this.setBrowserTabPageTitle(pageTitle);
      });
  }

  private setBrowserTabPageTitle(pageTitle: string): void {
    this.browserTabPageTitleService.setTitle(pageTitle || this.DEFAULT_PAGE_TITLE);
  }

  private configurePrimeNg(): void {
    this.primengConfig.ripple = true;
    this.setPrimeNgTranslations();
  }

  private setPrimeNgTranslations(): void {
    this.primengTranslationProvider.getCurrentTranslation().subscribe((translation: Translation) => {
      this.primengConfig.setTranslation(translation);
    });
  }

  public ngOnDestroy(): void {
    this.$_destroyed.next();
  }
}
