<div class="grid-container">
  <header class="d-flex justify-content-end p-3 header">
    <app-cover-layout-header></app-cover-layout-header>
  </header>
  <main class="main">
    <div class="main__inner">
      <div class="container-fluid">
        <section class="header-logo">
          <a [routerLink]="linkUrl" title="AR Solutions">
            <img class="header-logo__image img-fluid" src="{{ logoUrl }}" alt="AR Solutions"/>
          </a>
        </section>

        <router-outlet></router-outlet>
      </div>
    </div>
  </main>

  <div class="bottom-fill"></div>
</div>

<app-toast></app-toast>
