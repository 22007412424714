import { jsonArrayMember, jsonObject } from 'typedjson';
import { AttributeFilterModel } from '@api/models/collection/requests/attribute-filter.model';
import { ResourceFilterModel } from '@api/models/collection/requests/resource-filter.model';

@jsonObject
export class ConversationFilter extends ResourceFilterModel {
  @jsonArrayMember(AttributeFilterModel) public id?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public name?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public type?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public lastMessageCreatedAt?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel, { name: 'query' }) public global?: AttributeFilterModel[];
}
