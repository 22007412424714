import { ConversationResponse } from '@api/conversations-api/models/responses/conversation.response';
import { ContactResponse } from '@api/contacts-api/models/responses/contact.response';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class UnreadConversationResponse {
  @jsonMember public readonly conversation!: ConversationResponse;
  @jsonMember public readonly participant!: ContactResponse;
  @jsonMember public readonly lastReadMessageIndex?: number;
  @jsonMember public readonly lastReadAt?: Date;
}
