import { Component } from '@angular/core';
import { ConfirmationTypeKey } from '@common/components/confirmation/enums/confirmation-type-key.enum';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {

  public readonly key = ConfirmationTypeKey.DIALOG;

  public constructor() {
  }

}
