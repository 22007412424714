import { Uuid } from '@app/api/types/uuid.type';
import { jsonMember, jsonObject } from 'typedjson';
import { ProcedureStageExecutionOrderNextStageResponse } from '@api/procedures-api/models/responses/procedure-stage-execution-order-next-stage.response';

@jsonObject
export class ProcedureStageExecutionOrderResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly expression!: string;
  @jsonMember(ProcedureStageExecutionOrderNextStageResponse) public readonly nextProcedureStage!: ProcedureStageExecutionOrderNextStageResponse;

}
