import { Injectable } from '@angular/core';
import { LocalStorageService } from '../../storage/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {

  private readonly TOKEN_KEY = 'TOKEN';
  private readonly REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';

  public constructor(private storage: LocalStorageService) {
  }

  public storeToken(token: string): void {
    this.storage.setItem(this.TOKEN_KEY, token);
  }

  public storeRefreshToken(refreshToken: string): void {
    this.storage.setItem(this.REFRESH_TOKEN_KEY, refreshToken);
  }

  public clearToken(): void {
    this.storage.removeItem(this.TOKEN_KEY);
  }

  public clearRefreshToken(): void {
    this.storage.removeItem(this.REFRESH_TOKEN_KEY);
  }

  public getToken(): string | null {
    return this.storage.getItem(this.TOKEN_KEY);
  }

  public getRefreshToken(): string | null {
    return this.storage.getItem(this.REFRESH_TOKEN_KEY);
  }

}
