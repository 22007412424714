import { jsonArrayMember, jsonObject } from 'typedjson';
import { ResourceFilterModel } from '@api/models/collection/requests/resource-filter.model';
import { AttributeFilterModel } from '@api/models/collection/requests/attribute-filter.model';

@jsonObject
export class TaskActivitiesFilter extends ResourceFilterModel {

  @jsonArrayMember(AttributeFilterModel) public createdAt?: AttributeFilterModel[];

}
