import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class AppLoggedUserGlobalPermitsResponse {
  @jsonMember public readonly hasContactsAccess!: boolean;
  @jsonMember public readonly hasChatAndVideoConversationsAccess!: boolean;
  @jsonMember public readonly hasUsersAccess!: boolean;
  @jsonMember public readonly hasPermissionGroupsAccess!: boolean;
  @jsonMember public readonly hasDictionariesAccess!: boolean;
  @jsonMember public readonly hasAttributesAccess!: boolean;
  @jsonMember public readonly hasDashboardAccess!: boolean;
  @jsonMember public readonly hasProceduresAccess!: boolean;
  @jsonMember public readonly hasTasksAccess!: boolean;
  @jsonMember public readonly hasAllTasksAccess!: boolean;
  @jsonMember public readonly hasMyTasksAccess!: boolean;
  @jsonMember public readonly hasDocumentsAccess!: boolean;
  @jsonMember public readonly hasSettingsAccess!: boolean;
  @jsonMember public readonly canCreateUsers!: boolean;
  @jsonMember public readonly canCreatePermissionGroups!: boolean;
  @jsonMember public readonly canCreateProcedures!: boolean;
  @jsonMember public readonly canCreateAttributes!: boolean;
  @jsonMember public readonly canCreateTasks!: boolean;
  @jsonMember public readonly canCreateDashboards!: boolean;
}
