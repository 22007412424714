import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';

@jsonObject
export class ProcedureStageUpdateRequest {

  @jsonMember public name!: string;
  @jsonMember public description!: string;
  @jsonMember public instructionContent!: string;
  @jsonMember public isConfirmationRequired!: boolean;
  @jsonMember public isOptional!: boolean;
  @jsonArrayMember(String) public repositoryFiles?: Uuid[];

  public static fromPartial(partial: Partial<ProcedureStageUpdateRequest>): ProcedureStageUpdateRequest {
    return Object.assign(new ProcedureStageUpdateRequest(), partial);
  }
}
