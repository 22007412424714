import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AssetsModule } from '@core/assets/assets.module';
import { AlertDialogSeverityCssClassPipe } from './pipes/alert-dialog-severity-css-class.pipe';
import { ConfirmationService } from 'primeng/api';
import { AlertMessageService } from '@common/components/alert/service/alert-message.service';


@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    ButtonModule,
    ConfirmDialogModule,
    AssetsModule,
  ],
  declarations: [
    AlertDialogComponent,
    AlertDialogSeverityCssClassPipe,
  ],
  exports: [
    AlertDialogComponent,
  ],
  providers: [
    ConfirmationService,
    AlertMessageService,
  ],
})
export class AlertModule {
}
