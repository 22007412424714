import { Serializable, TypedJSON } from 'typedjson';
import { JsonParseException } from './json-parse.exception';
import { JsonArrayParseException } from './json-array-parse.exception';

export class JsonSerializer {

  public static parse<T>(object: Object, type: Serializable<T>): T {
    const parsedObject: T | undefined = TypedJSON.parse(object, type);

    if (!parsedObject) {
      throw new JsonParseException();
    }

    return parsedObject;
  }

  public static parseArray<T>(object: Object, type: Serializable<T>): T[] {
    const parsedArray: T[] | undefined = TypedJSON.parseAsArray(object, type);

    if (!parsedArray) {
      throw new JsonArrayParseException();
    }

    return parsedArray;
  }

  public static stringify<T>(object: T, type: Serializable<T>): string {
    return TypedJSON.stringify(object, type);
  }

  public static stringifyAsArray<T>(object: T[], type: Serializable<T>): string {
    return TypedJSON.stringifyAsArray(object, type);
  }

}
