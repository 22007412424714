import { BaseCallResponse } from '@api/conversations-api/models/responses/base-call.response';
import { jsonMember, jsonObject } from 'typedjson';
import { ContactResponse } from '@api/contacts-api/models/responses/contact.response';
import { CallContextOperationsResponse } from '@api/conversations-api/models/responses/call-context-operations.response';
import { CallPendingVideoRoomResponse } from '@api/conversations-api/models/responses/call-pending-video-room.response';

@jsonObject
export class CallDetailsResponse extends BaseCallResponse {
  @jsonMember public readonly caller!: ContactResponse;
  @jsonMember public readonly callee!: ContactResponse;
  @jsonMember public readonly contextOperations!: CallContextOperationsResponse;
  @jsonMember public readonly pendingRoom!: CallPendingVideoRoomResponse;
}
