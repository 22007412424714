<ul class="sidenav__list">

  <li *ngFor="let item of (menu$ | async)"
      class="sidenav__list-item"
      routerLinkActive="sidenav__list-item--active">
    <a [routerLink]="item.route | routePath" pRipple>
      <i class="sidenav__list-item__icon {{ item.icon | iconCssClass }}"></i>
      <span class="sidenav__list-item__label">{{ item.label }}</span>
    </a>
  </li>

</ul>
