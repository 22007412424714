import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationItemViewModel } from '@core/layouts/dashboard-layout/components/dashboard-layout-header-notifications/header-notifications/models/notification-item.view-model';

@Component({
  selector: 'app-header-notifications-item',
  templateUrl: './header-notifications-item.component.html',
  styleUrls: ['./header-notifications-item.component.scss'],
})
export class HeaderNotificationsItemComponent {

  @Input() public model!: NotificationItemViewModel;

  @Output() public clicked = new EventEmitter<void>();

}
