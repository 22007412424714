import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConversationsProviderApiService } from '@api/conversations-provider-api/services/conversations-provider-api.service';
import { CONVERSATIONS_PROVIDER_API } from '@api/conversations-provider-api/services/tokens/conversations-provider-api.token';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    {
      provide: CONVERSATIONS_PROVIDER_API,
      useClass: ConversationsProviderApiService,
    },
  ],
})
export class ConversationsProviderApiModule {
}
