import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { RepositoryFileDetailsResponse } from '@api/documents-api/models/responses/repository-file-details.response';

@jsonObject
export class ProcedureStageResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly name!: string;
  @jsonMember public readonly description?: string;
  @jsonMember public readonly position!: number;
  @jsonMember public readonly instructionContent?: string;
  @jsonMember public readonly isConfirmationRequired!: boolean;
  @jsonMember public readonly isOptional!: boolean;
  @jsonMember public readonly createdAt!: Date;
  @jsonArrayMember(RepositoryFileDetailsResponse) public readonly repositoryFiles?: RepositoryFileDetailsResponse[];

}
