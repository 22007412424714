import { SortOrder } from '@api/models/collection/enum/sort-order.enum';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class SortModel {

  @jsonMember(String) public order!: SortOrder;
  @jsonMember public orderBy!: string;

  public static fromParams(order: SortOrder, orderBy: string): SortModel {
    return Object.assign(new SortModel(), { order, orderBy });
  }

}
