import { truthyOrFalsy } from '@common/helpers/truthy-or-falsy.helper';
import { StringValueSerializerOptions } from '@core/serializer/interfaces/string-value-serializer-options.interface';

export const stringValueSerializer = (options?: StringValueSerializerOptions): (value?: string) => string | undefined => {
  const serializerOptions: StringValueSerializerOptions = { allowEmptyString: false, trim: true, ...options };
  const { allowEmptyString, trim } = serializerOptions;
  const excluded: unknown[] = [];

  if (allowEmptyString) {
    excluded.push('');
  }

  return (value?: string) => {
    const valueToSerialize = trim
      ? value?.trim()
      : value;

    return truthyOrFalsy(valueToSerialize, excluded)
      ? valueToSerialize
      : undefined;
  };
};
