import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardLayoutHeaderComponent } from './dashboard-layout-header.component';
import { RouterModule } from '@angular/router';
import { DashboardLayoutHeaderNotificationsModule } from '../dashboard-layout-header-notifications/dashboard-layout-header-notifications.module';
import { DashboardLayoutHeaderUserInfoModule } from '../dashboard-layout-header-user-info/dashboard-layout-header-user-info.module';
import { LayoutHeaderLangPickerModule } from '../../../components/layout-header-lang-picker/layout-header-lang-picker.module';
import { AssetsModule } from '@core/assets/assets.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    // Moduly wlasne
    AssetsModule,
    DashboardLayoutHeaderNotificationsModule,
    DashboardLayoutHeaderUserInfoModule,
    LayoutHeaderLangPickerModule,
  ],
  declarations: [DashboardLayoutHeaderComponent],
  exports: [DashboardLayoutHeaderComponent],
})
export class DashboardLayoutHeaderModule {
}
