import { jsonMember, jsonObject } from 'typedjson';
import { ContactResponse } from '@api/contacts-api/models/responses/contact.response';
import { VideoRoomResponse } from '@api/conversations-api/models/responses/video-room.response';
import { BaseCallResponse } from '@api/conversations-api/models/responses/base-call.response';

@jsonObject
export class OutgoingCallResponse extends BaseCallResponse {
  @jsonMember public readonly callee!: ContactResponse;
  @jsonMember public readonly room!: VideoRoomResponse;
}
