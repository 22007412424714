import { AuthApi } from './interfaces/auth.api';
import { Observable } from 'rxjs';
import { RefreshResponse } from '../models/responses/refresh.response';
import { SignInRequest } from '../models/requests/sign-in.request';
import { SignInResponse } from '../models/responses/sign-in.response';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiCMUiACommon } from '@environments/environment';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { RefreshTokenRequest } from '../models/requests/refresh-token.request';
import { AskForResetPasswordRequest } from '@api/cmuia-api/models/requests/ask-for-reset-password.request';
import { ConfirmResetPasswordRequest } from '@api/cmuia-api/models/requests/confirm-reset-password.request';
import { GenerateQrAuthTokenRequest } from '@api/cmuia-api/models/requests/generate-qr-auth-token.request';
import { QrAuthTokenResponse } from '@api/cmuia-api/models/responses/qr-auth-token.response';
import { PasswordComplexityResponse } from '@api/cmuia-api/models/responses/password-complexity.response';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService implements AuthApi {

  public constructor(
    private http: HttpClient,
    private apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public signIn(request: SignInRequest): Observable<SignInResponse> {
    const url = this.grabEndpointUrl(apiCMUiACommon.AUTHENTICATION.SIGN_IN);
    const payload = this.apiPayloadProcessor.toObject(request, SignInRequest);

    return this.http.post(url, payload).pipe(
      this.apiPayloadProcessor.mapToModel(SignInResponse),
    );
  }

  public refresh(request: RefreshTokenRequest): Observable<RefreshResponse> {
    const url = this.grabEndpointUrl(apiCMUiACommon.AUTHENTICATION.REFRESH);
    const payload = this.apiPayloadProcessor.toObject(request, RefreshTokenRequest);

    return this.http.post(url, payload).pipe(
      this.apiPayloadProcessor.mapToModel(RefreshResponse),
    );
  }

  public askForPasswordReset(request: AskForResetPasswordRequest): Observable<Object> {
    const url = this.grabEndpointUrl(apiCMUiACommon.AUTHENTICATION.PASSWORD_RESET_REQUEST);
    const payload = this.apiPayloadProcessor.toObject(request, AskForResetPasswordRequest);

    return this.http.post(url, payload);
  }

  public confirmPasswordReset(request: ConfirmResetPasswordRequest): Observable<Object> {
    const url = this.grabEndpointUrl(apiCMUiACommon.AUTHENTICATION.PASSWORD_RESET);
    const payload = this.apiPayloadProcessor.toObject(request, ConfirmResetPasswordRequest);

    return this.http.post(url, payload);
  }

  public generateQrAuthToken(request: GenerateQrAuthTokenRequest): Observable<QrAuthTokenResponse> {
    const url = this.grabEndpointUrl(apiCMUiACommon.AUTHENTICATION.GENERATE_QR_AUTH_TOKEN);
    const payload = this.apiPayloadProcessor.toObject(request, GenerateQrAuthTokenRequest);

    return this.http.post(url, payload).pipe(
      this.apiPayloadProcessor.mapToModel(QrAuthTokenResponse),
    );
  }

  public passwordComplexity(): Observable<PasswordComplexityResponse> {
    const url = this.grabEndpointUrl(apiCMUiACommon.AUTHENTICATION.PASSWORD_COMPLEXITY);

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToModel(PasswordComplexityResponse),
    );
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiCMUiACommon.API_HOST_URL, endpoint].join('');
  }
}
