import { ProceduresNotificationsApi } from '@api/procedures-api/services/interfaces/procedures-notifications.api';
import { Injectable } from '@angular/core';
import { NotificationsCollectionRequestBuilder } from '@api/procedures-api/builders/notifications-collection-request.builder';
import { Observable } from 'rxjs';
import { Uuid } from '@api/types/uuid.type';
import { CollectionRequest } from '@api/models/collection/requests/collection.request';
import { NotificationFilter } from '@api/procedures-api/models/filters/notification.filter';
import { HttpClient, HttpContext } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { apiProceduresCommon } from '@environments/environment';
import { LOADING_INDICATOR_ENABLED } from '@core/layouts/components/loading-indicator/interceptors/loading-interceptor-context-params';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';
import { NotificationResponse } from '@api/procedures-api/models/responses/notification.response';

@Injectable({
  providedIn: 'root',
})
export class ProceduresNotificationsApiService implements ProceduresNotificationsApi {

  public constructor(
    private http: HttpClient,
    private apiPayloadProcessor: ApiPayloadProcessorService,
    private notificationsCollectionRequestBuilder: NotificationsCollectionRequestBuilder,
  ) {
  }

  public unreadNotificationsList(request: CollectionRequest<NotificationFilter>): Observable<CollectionResponse<NotificationResponse>> {
    const requestQueryString = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, NotificationFilter);
    const url = this.grabEndpointUrl(apiProceduresCommon.NOTIFICATIONS.UNREAD_LIST) + '?' + requestQueryString;

    return this.http
      .get(url, {
        context: new HttpContext().set(LOADING_INDICATOR_ENABLED, false),
      })
      .pipe(
        this.apiPayloadProcessor.mapToCollection(NotificationResponse),
      );
  }

  public markAllRead(): Observable<void> {
    const url = this.grabEndpointUrl(apiProceduresCommon.NOTIFICATIONS.MARK_ALL_READ);

    return this.http.post<void>(url, {});
  }

  public markSingleAsRead(id: Uuid): Observable<void> {
    const url = this.grabEndpointUrl(apiProceduresCommon.NOTIFICATIONS.MARK_SINGLE_AS_READ).replace('{id}', id.toString());

    return this.http.post<void>(url, {});
  }

  public getCollectionRequestBuilder(): NotificationsCollectionRequestBuilder {
    return this.notificationsCollectionRequestBuilder;
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiProceduresCommon.API_HOST_URL, endpoint].join('');
  }

}
