import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { CollectionRequest } from '@api/models/collection/requests/collection.request';
import { UserPermissionGroupFilter } from '@api/cmuia-api/models/filters/user-permission-group.filter';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';
import { UserPermissionGroupResponse } from '@api/cmuia-api/models/responses/user-permission-group.response';
import { ConversationsPermissionGroupsApi } from '@api/conversations-api/services/interfaces/conversations-permission-groups.api';
import { apiConversationsCommon } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConversationsPermissionGroupsApiService implements ConversationsPermissionGroupsApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(request: CollectionRequest<UserPermissionGroupFilter>): Observable<CollectionResponse<UserPermissionGroupResponse>> {
    const { PERMISSION_GROUPS: { LIST } } = apiConversationsCommon.CONTACTS;
    const requestQueryString: string = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, UserPermissionGroupFilter);
    const url: string = `${this.grabEndpointUrl(LIST)}?${requestQueryString}`;

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToCollection(UserPermissionGroupResponse),
      );
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiConversationsCommon.API_HOST_URL, endpoint].join('');
  }

}
