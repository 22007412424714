import { Injectable } from '@angular/core';
import { SidebarMenuItem } from '@core/layouts/dashboard-layout/models/sidebar-menu-item.interface';
import { RouteName } from '@core/routing/route-name.enum';
import { Icon } from '@core/assets/enums/icon.enum';
import { RoutePermissionsProvider } from '@core/routing/provider/route-permissions.provider';
import { PermissionsService } from '@core/permissions/services/permissions.service';
import { Observable, switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Injectable()
export class SidebarMenuProvider {

  private readonly MENU_ITEMS_LABELS_KEYS = {
    DASHBOARD: 'SIDEBAR.MENU.DASHBOARD',
    TASKS: 'SIDEBAR.MENU.TASKS',
    PROCEDURES: 'SIDEBAR.MENU.PROCEDURES',
    CONTACTS: 'SIDEBAR.MENU.CONTACTS',
    DOCUMENTS: 'SIDEBAR.MENU.DOCUMENTS',
    USERS: 'SIDEBAR.MENU.USERS',
    DICTIONARIES: 'SIDEBAR.MENU.DICTIONARIES',
    ATTRIBUTES: 'SIDEBAR.MENU.ATTRIBUTES',
    SETTINGS: 'SIDEBAR.MENU.SETTINGS',
  };

  public menu$: Observable<SidebarMenuItem[]> =
    this
      .permissionsService
      .permissionsReady$
      .pipe(
        switchMap(() => this.getAccessibleMenuItems()),
      );

  public constructor(
    private readonly translateService: TranslateService,
    private readonly routePermissionsProvider: RoutePermissionsProvider,
    private readonly permissionsService: PermissionsService) {
  }

  private getAccessibleMenuItems(): Observable<SidebarMenuItem[]> {
    return this
      .menuStructure()
      .pipe(
        map((menu: SidebarMenuItem[]) =>
          menu.filter((item: SidebarMenuItem) => {
            if (!item.permissions) {
              return true;
            }

            return this
              .permissionsService
              .checkPermissions(item.permissions);
          }),
        ));
  }

  private menuStructure(): Observable<SidebarMenuItem[]> {
    return this
      .translateService
      .stream(Object.values(this.MENU_ITEMS_LABELS_KEYS))
      .pipe(
        map((labels) => [
            {
              route: RouteName.DASHBOARD,
              label: labels[this.MENU_ITEMS_LABELS_KEYS.DASHBOARD],
              icon: Icon.MENU_DASHBOARD,
              permissions: this.routePermissionsProvider.getPermissions(RouteName.DASHBOARD),
            },
            {
              route: RouteName.PANEL_TASKS,
              label: labels[this.MENU_ITEMS_LABELS_KEYS.TASKS],
              icon: Icon.MENU_TASKS,
              permissions: this.routePermissionsProvider.getPermissions(RouteName.PANEL_TASKS),
            },
            {
              route: RouteName.PANEL_PROCEDURES,
              label: labels[this.MENU_ITEMS_LABELS_KEYS.PROCEDURES],
              icon: Icon.MENU_PROCEDURES,
              permissions: this.routePermissionsProvider.getPermissions(RouteName.PANEL_PROCEDURES),
            },
            {
              route: RouteName.PANEL_CONTACTS,
              label: labels[this.MENU_ITEMS_LABELS_KEYS.CONTACTS],
              icon: Icon.MENU_CONTACTS,
              permissions: this.routePermissionsProvider.getPermissions(RouteName.PANEL_CONTACTS),
            },
            {
              route: RouteName.PANEL_DOCUMENTS,
              label: labels[this.MENU_ITEMS_LABELS_KEYS.DOCUMENTS],
              icon: Icon.MENU_DOCUMENTS,
              permissions: this.routePermissionsProvider.getPermissions(RouteName.PANEL_DOCUMENTS),
            },
            {
              route: RouteName.MANAGE_USERS,
              label: labels[this.MENU_ITEMS_LABELS_KEYS.USERS],
              icon: Icon.MENU_USERS,
              permissions: this.routePermissionsProvider.getPermissions(RouteName.MANAGE_USERS_OR_PERMISSION_GROUPS),
            },
            {
              route: RouteName.MANAGE_DICTIONARIES,
              label: labels[this.MENU_ITEMS_LABELS_KEYS.DICTIONARIES],
              icon: Icon.MENU_DICTIONARIES,
              permissions: this.routePermissionsProvider.getPermissions(RouteName.MANAGE_DICTIONARIES),
            },
            {
              route: RouteName.MANAGE_ATTRIBUTES,
              label: labels[this.MENU_ITEMS_LABELS_KEYS.ATTRIBUTES],
              icon: Icon.MENU_ATTRIBUTES,
              permissions: this.routePermissionsProvider.getPermissions(RouteName.MANAGE_ATTRIBUTES),
            },
            {
              route: RouteName.MANAGE_SETTINGS,
              label: labels[this.MENU_ITEMS_LABELS_KEYS.SETTINGS],
              icon: Icon.MENU_CONFIGURATION_PARAMETERS,
              permissions: this.routePermissionsProvider.getPermissions(RouteName.MANAGE_SETTINGS),
            },
          ],
        ),
      );
  }

}
