import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { ResourceFilterModel } from '@api/models/collection/requests/resource-filter.model';
import { AttributeFilterModel } from '@api/models/collection/requests/attribute-filter.model';
import { CollectionEntryFilterField } from '@api/documents-api/models/filters/collection-entry-filter-field.enum';

@jsonObject
export class CollectionEntryFilter extends ResourceFilterModel {

  @jsonArrayMember(AttributeFilterModel) public readonly [CollectionEntryFilterField.ID]?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public readonly [CollectionEntryFilterField.PARENT]?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public readonly [CollectionEntryFilterField.TYPE]?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public readonly [CollectionEntryFilterField.NAME]?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public readonly [CollectionEntryFilterField.CREATED_AT]?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public readonly [CollectionEntryFilterField.SIZE]?: AttributeFilterModel[];
  @jsonMember public readonly [CollectionEntryFilterField.ATTRIBUTE]?: { [key: string]: AttributeFilterModel };

}
