import { Injectable } from '@angular/core';
import { ConfirmationMessage } from '@common/components/confirmation/interface/confirmation-message.interface';
import { ConfirmEventType } from 'primeng/api';
import { Icon } from '@core/assets/enums/icon.enum';
import { IconCssClassProvider } from '@core/assets/providers/icon-css-class.provider';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationMessageBuilder {

  private message!: ConfirmationMessage;

  public constructor(private iconCssClassProvider: IconCssClassProvider) {
    this.reset();
  }

  private reset(): void {
    this.message = {};
  }

  public build(): ConfirmationMessage {
    const message = this.message;

    this.reset();

    return message;
  }

  public setTitle(title: string): this {
    this.message.header = title;

    return this;
  }

  public setBody(body: string): this {
    this.message.message = body;

    return this;
  }

  public setAcceptCallback(callback: Function): this {
    this.message.accept = callback;

    return this;
  }

  public setRejectCallback(callback: (type: ConfirmEventType) => void): this {
    this.message.reject = callback;

    return this;
  }

  public setIcon(icon: Icon): this {
    this.message.icon = this.iconCssClassProvider.getCssClass(icon);

    return this;
  }

  public setKey(key: string): this {
    this.message.key = key;

    return this;
  }

  public setTarget(target: EventTarget): this {
    this.message.target = target;

    return this;
  }

  public setAcceptLabel(label: string): this {
    this.message.acceptLabel = label;

    return this;
  }

  public setRejectLabel(label: string): this {
    this.message.rejectLabel = label;

    return this;
  }

  public setAcceptIcon(icon: Icon): this {
    this.message.acceptIcon = this.iconCssClassProvider.getCssClass(icon);

    return this;
  }

  public setRejectIcon(icon: Icon): this {
    this.message.rejectIcon = this.iconCssClassProvider.getCssClass(icon);

    return this;
  }

  public setAcceptVisible(visible: boolean): this {
    this.message.acceptVisible = visible;

    return this;
  }

  public setRejectVisible(visible: boolean): this {
    this.message.rejectVisible = visible;

    return this;
  }

  public setBlockScroll(block: boolean): this {
    this.message.blockScroll = block;

    return this;
  }

  public setCloseOnEscape(closeOnEscape: boolean): this {
    this.message.closeOnEscape = closeOnEscape;

    return this;
  }

  public setDismissMask(mask: boolean): this {
    this.message.dismissableMask = mask;

    return this;
  }

  public setDefaultFocus(focus: 'accept' | 'reject' | 'close' | 'none'): this {
    this.message.defaultFocus = focus;

    return this;
  }

  public setAcceptButtonStyleClass(cssClass: string): this {
    this.message.acceptButtonStyleClass = cssClass;

    return this;
  }

  public setRejectButtonStyleClass(cssClass: string): this {
    this.message.rejectButtonStyleClass = cssClass;

    return this;
  }

  public from(confirm: ConfirmationMessage): this {
    Object.assign(this.message, confirm);

    return this;
  }
}

