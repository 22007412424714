import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { CollectionRequest } from '@api/models/collection/requests/collection.request';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';
import { Observable } from 'rxjs';
import { TaskFilter } from '../models/filters/task.filter';
import { TaskDetailsResponse } from '../models/responses/task-details.response';
import { apiProceduresCommon } from '@environments/environment';
import { Uuid } from '@api/types/uuid.type';
import { apiParams } from '@environments/const/environment.const';
import { TaskResponse } from '../models/responses/task-response';
import { TasksApi } from '@api/procedures-api/services/interfaces/tasks.api';
import { TasksStatsResponse } from '@api/procedures-api/models/responses/tasks-stats.response';

const { TASK_ID } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class TasksApiService implements TasksApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(request: CollectionRequest<TaskFilter>): Observable<CollectionResponse<TaskResponse>> {
    const { LIST } = apiProceduresCommon.TASKS;
    const requestQueryString: string = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, TaskFilter);
    const url: string = `${this.grabEndpointUrl(LIST)}?${requestQueryString}`;

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToCollection(TaskResponse),
      );
  }

  public details(id: Uuid): Observable<TaskDetailsResponse> {
    const { DETAILS } = apiProceduresCommon.TASKS;
    const url: string = this
      .grabEndpointUrl(DETAILS)
      .replace(TASK_ID, id.toString());

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToModel(TaskDetailsResponse),
      );
  }

  public stats(filter?: TaskFilter): Observable<TasksStatsResponse> {
    const { STATS } = apiProceduresCommon.TASKS;
    const requestQueryString: string = this.apiPayloadProcessor.mapObjectToQueryString({
      filter: this.apiPayloadProcessor.toObject(filter, TaskFilter),
    });
    const url: string = `${this.grabEndpointUrl(STATS)}?${requestQueryString}`;

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToModel(TasksStatsResponse),
      );
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiProceduresCommon.API_HOST_URL, endpoint].join('');
  }

}
