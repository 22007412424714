import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class AttributeContextOperationsResponse {

  @jsonMember public readonly canView!: boolean;
  @jsonMember public readonly canUpdate!: boolean;
  @jsonMember public readonly canDelete!: boolean;

}

