import { Injectable } from '@angular/core';
import { DocumentsManagerApi } from './interfaces/documents-manager.api';
import { Observable } from 'rxjs';
import { Uuid } from '@api/types/uuid.type';
import { CollectionEntryResponse } from '@api/documents-api/models/responses/collection-entry.response';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { apiDirectoriesCommon } from '@environments/environment';
import { apiParams } from '@environments/const/environment.const';
import { CreateDirectoryRequest } from '@api/documents-api/models/requests/create-directory.request';
import { IdResponse } from '@api/interfaces/id.response';
import { CollectionPropertyResponse } from '@api/documents-api/models/responses/collection-property.response';
import { CreateDocumentRequest } from '../models/requests/create-document.request';
import { CollectionEntryDetailsResponse } from '@api/documents-api/models/responses/collection-entry-details.response';
import { FileSystemObjectType } from '@api/documents-api/models/enums/file-system-object-type.enum';
import { UpdateDirectoryRequest } from '@api/documents-api/models/requests/update-directory.request';
import { UpdateDocumentRequest } from '@api/documents-api/models/requests/update-document.request';
import { TreeQueryParams } from '@api/documents-api/models/params/tree.query-params';
import { Serializable } from 'typedjson';
import { DeleteCollectionEntriesRequest } from '@api/documents-api/models/requests/delete-collection-entries.request';
import { CopyDocumentsRequest } from '@api/documents-api/models/requests/copy-documents.request';
import { MoveDocumentsRequest } from '@api/documents-api/models/requests/move-documents.request';
import { DocumentFilesDownloadQueryParams } from '@api/documents-api/models/params/document-files-download-query.params';
import { CollectionEntriesDownloadResponse } from '@api/documents-api/models/responses/collection-entries-download.response';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';
import { CollectionRequest } from '@api/models/collection/requests/collection.request';
import { CollectionEntryFilter } from '@api/documents-api/models/filters/collection-entry.filter';
import { CollectionEntryOperationSummaryResponse } from '@api/documents-api/models/responses/collection-entry-operation-summary.response';
import { TreeCollectionEntryResponse } from '../models/responses/tree-collection-entry.response';

const { ID, TYPE } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class DocumentsManagerApiService implements DocumentsManagerApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public rootDetails(): Observable<CollectionEntryDetailsResponse> {
    const { API_HOST_URL, FILE_MANAGER: { DIRECTORIES: { ROOT } } } = apiDirectoriesCommon;
    const url: string = `${API_HOST_URL}/${ROOT}`;

    return this
      .http
      .get(url)
      .pipe(this.apiPayloadProcessor.mapToModel(CollectionEntryDetailsResponse));
  }

  public tree(queryParams?: TreeQueryParams): Observable<CollectionResponse<TreeCollectionEntryResponse>> {
    const { API_HOST_URL, FILE_MANAGER: { DIRECTORIES: { TREE } } } = apiDirectoriesCommon;
    const baseUrl: string = `${API_HOST_URL}/${TREE}`;
    const url: string = this.urlWithQueryPrams(baseUrl, { queryParams, queryType: TreeQueryParams });

    return this
      .http
      .get(url)
      .pipe(this.apiPayloadProcessor.mapToCollection(TreeCollectionEntryResponse));
  }

  public objects(request: CollectionRequest<CollectionEntryFilter>): Observable<CollectionResponse<CollectionEntryResponse>> {
    const { API_HOST_URL, FILE_MANAGER: { LIST } } = apiDirectoriesCommon;
    const baseUrl = `${API_HOST_URL}/${LIST}`;
    const query = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, CollectionEntryFilter);
    const url: string = `${baseUrl}?${query}`;

    return this
      .http
      .get(url)
      .pipe(this.apiPayloadProcessor.mapToCollection(CollectionEntryResponse));
  }

  public deleteObjects(request: DeleteCollectionEntriesRequest): Observable<CollectionEntryOperationSummaryResponse> {
    const { API_HOST_URL, FILE_MANAGER: { DELETE } } = apiDirectoriesCommon;
    const payload: Object = this.apiPayloadProcessor.toObject(request, DeleteCollectionEntriesRequest);
    const url: string = `${API_HOST_URL}/${DELETE}`;

    return this
      .http
      .put<CollectionEntryOperationSummaryResponse>(url, payload);
  }

  public fileDetails(id: Uuid): Observable<CollectionEntryDetailsResponse> {
    const { API_HOST_URL, FILE_MANAGER: { FILES: { DETAILS } } } = apiDirectoriesCommon;
    const url: string = `${API_HOST_URL}/${DETAILS}`.replace(ID, id.toString());

    return this
      .http
      .get(url)
      .pipe(this.apiPayloadProcessor.mapToModel(CollectionEntryDetailsResponse));
  }

  public directoryDetails(id: Uuid): Observable<CollectionEntryDetailsResponse> {
    const { API_HOST_URL, FILE_MANAGER: { DIRECTORIES: { DETAILS } } } = apiDirectoriesCommon;
    const url: string = `${API_HOST_URL}/${DETAILS}`.replace(ID, id.toString());

    return this
      .http
      .get(url)
      .pipe(this.apiPayloadProcessor.mapToModel(CollectionEntryDetailsResponse));
  }

  public createFile(request: CreateDocumentRequest): Observable<IdResponse> {
    const { API_HOST_URL, FILE_MANAGER: { FILES: { CREATE } } } = apiDirectoriesCommon;
    const url: string = `${API_HOST_URL}/${CREATE}`;
    const body = this.apiPayloadProcessor.toObject(request, CreateDocumentRequest);

    return this
      .http
      .post(url, body)
      .pipe(this.apiPayloadProcessor.mapToModel(IdResponse));
  }

  public updateFile(id: Uuid, request: UpdateDocumentRequest): Observable<Object> {
    const { API_HOST_URL, FILE_MANAGER: { FILES: { UPDATE } } } = apiDirectoriesCommon;
    const url: string = `${API_HOST_URL}/${UPDATE}`.replace(ID, id.toString());
    const body = this.apiPayloadProcessor.toObject(request, UpdateDocumentRequest);

    return this
      .http
      .put(url, body);
  }

  public createDirectory(request: CreateDirectoryRequest): Observable<IdResponse> {
    const { API_HOST_URL, FILE_MANAGER: { DIRECTORIES: { CREATE } } } = apiDirectoriesCommon;
    const url: string = `${API_HOST_URL}/${CREATE}`;
    const body = this.apiPayloadProcessor.toObject(request, CreateDirectoryRequest);

    return this
      .http
      .post(url, body)
      .pipe(this.apiPayloadProcessor.mapToModel(IdResponse));
  }

  public updateDirectory(id: Uuid, request: UpdateDirectoryRequest): Observable<Object> {
    const { API_HOST_URL, FILE_MANAGER: { DIRECTORIES: { UPDATE } } } = apiDirectoriesCommon;
    const url: string = `${API_HOST_URL}/${UPDATE}`.replace(ID, id.toString());
    const body = this.apiPayloadProcessor.toObject(request, UpdateDirectoryRequest);

    return this
      .http
      .put(url, body);
  }

  public properties(type: FileSystemObjectType): Observable<CollectionPropertyResponse[]> {
    const { API_HOST_URL, FILE_MANAGER: { PROPERTIES: { LIST } } } = apiDirectoriesCommon;
    const url: string = `${API_HOST_URL}/${LIST}`
      .replace(TYPE, type);

    return this
      .http
      .get(url)
      .pipe(this.apiPayloadProcessor.mapToModelArray(CollectionPropertyResponse));
  }

  public copy(request: CopyDocumentsRequest): Observable<CollectionEntryOperationSummaryResponse> {
    const { API_HOST_URL, FILE_MANAGER: { COPY } } = apiDirectoriesCommon;
    const url: string = `${API_HOST_URL}/${COPY}`;
    const body = this.apiPayloadProcessor.toObject(request, CopyDocumentsRequest);

    return this
      .http
      .post<CollectionEntryOperationSummaryResponse>(url, body);
  };

  public move(request: MoveDocumentsRequest): Observable<CollectionEntryOperationSummaryResponse> {
    const { API_HOST_URL, FILE_MANAGER: { MOVE } } = apiDirectoriesCommon;
    const url: string = `${API_HOST_URL}/${MOVE}`;
    const body = this.apiPayloadProcessor.toObject(request, MoveDocumentsRequest);

    return this
      .http
      .put<CollectionEntryOperationSummaryResponse>(url, body);
  };

  public download(queryParams: DocumentFilesDownloadQueryParams): Observable<CollectionEntriesDownloadResponse> {
    const { API_HOST_URL, FILE_MANAGER: { DOWNLOAD } } = apiDirectoriesCommon;
    const baseUrl = `${API_HOST_URL}/${DOWNLOAD}`;
    const url = this.urlWithQueryPrams(baseUrl, {
      queryParams,
      queryType: DocumentFilesDownloadQueryParams,
    });

    return this
      .http
      .get(url)
      .pipe(this.apiPayloadProcessor.mapToModel(CollectionEntriesDownloadResponse));
  }

  private urlWithQueryPrams<T>(baseUrl: string, query?: { queryParams: T, queryType: Serializable<T> }): string {
    if (!query) {
      return baseUrl;
    }

    const { queryParams, queryType } = query;

    return `${baseUrl}?${this.apiPayloadProcessor.mapModelToQueryString(queryParams, queryType)}`;
  }

}
