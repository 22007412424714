import { Inject, Injectable } from '@angular/core';
import { StorageService } from './interfaces/storage.service';
import { LOCAL_STORAGE } from './tokens/local-storage.token';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService implements StorageService {

  public constructor(@Inject(LOCAL_STORAGE) private storage: Storage) {
  }

  public get length(): number {
    return this.storage.length;
  }

  public clear(): void {
    this.storage.clear();
  }

  public getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  public key(index: number): string | null {
    return this.storage.key(index);
  }

  public removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  public setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }
}
