export enum Icon {

  MENU_DASHBOARD = 'MENU_DASHBOARD',
  MENU_TASKS = 'MENU_TASKS',
  MENU_PROCEDURES = 'MENU_PROCEDURES',
  MENU_CONTACTS = 'MENU_CONTACTS',
  MENU_DOCUMENTS = 'MENU_DOCUMENTS',
  MENU_CAMERA = 'MENU_CAMERA',
  MENU_USERS = 'MENU_USERS',
  MENU_DICTIONARIES = 'MENU_DICTIONARIES',
  MENU_ATTRIBUTES = 'MENU_ATTRIBUTES',
  MENU_CONFIGURATION_PARAMETERS = 'MENU_CONFIGURATION_PARAMETERS',

  HEADER_NOTIFICATION_BELL = 'HEADER_NOTIFICATION_BELL',
  HEADER_NOTIFICATION_CHAT = 'HEADER_NOTIFICATION_CHAT',

  HAMBURGER = 'HAMBURGER',
  SETTINGS = 'SETTINGS',
  SEARCH = 'SEARCH',
  PENCIL = 'PENCIL',
  EYE = 'EYE',
  MORE = 'MORE',
  TRASH = 'TRASH',
  REFRESH_DOUBLE = 'REFRESH_DOUBLE',
  CLOSE = 'CLOSE',
  CHECK_MARK = 'CHECK_MARK',
  USER = 'USER',
  USERS = 'USERS',
  PHONE = 'PHONE',
  MICROPHONE = 'MICROPHONE',
  MICROPHONE_MUTE = 'MICROPHONE_MUTE',
  COMMENT = 'COMMENT',
  VIDEO = 'VIDEO',
  COPY = 'COPY',
  COPY_ALT = 'COPY_ALT',
  CHECK_MARK_CIRCLE = 'CHECK_MARK_CIRCLE',
  USER_CIRCLE = 'USER_CIRCLE',
  CLOSE_CIRCLE = 'CLOSE_CIRCLE',
  CLEAR_FILTER = 'CLEAR_FILTER',
  AT = 'AT',
  SMILE = 'SMILE',
  PAPERCLIP = 'PAPERCLIP',
  TILTED_PAPERCLIP = 'TILTED_PAPERCLIP',
  SHARE = 'SHARE',
  DRAG = 'DRAG',
  FLAG = 'FLAG',

  MESSAGE_SUCCESS = 'MESSAGE_SUCCESS',
  MESSAGE_INFO = 'MESSAGE_INFO',
  MESSAGE_WARN = 'MESSAGE_WARN',
  MESSAGE_ERROR = 'MESSAGE_ERROR',

  ARROW_LEFT = 'ARROW_LEFT',
  ARROW_RIGHT = 'ARROW_RIGHT',
  ARROW_TO_TOP = 'ARROW_TO_TOP',
  ARROW_COLLAPSE_LEFT = 'ARROW_COLLAPSE_LEFT',

  ALERT_INFO = 'ALERT_INFO',
  ALERT_SUCCESS = 'ALERT_SUCCESS',
  ALERT_WARNING = 'ALERT_WARNING',
  ALERT_DANGER = 'ALERT_DANGER',
  ALERT_ERROR = 'ALERT_ERROR',

  COLLAPSE_ALT = 'COLLAPSE_ALT',
  EXPAND_ALT = 'EXPAND_ALT',
  EXPAND_ALT_2 = 'EXPAND_ALT_2',

  FILLED_VIDEO = 'FILLED_VIDEO',
  FILLED_PHONE = 'FILLED_PHONE',
  FILLED_CHAT = 'FILLED_CHAT',
  FILLED_CLOSE = 'FILLED_CLOSE',
  FILLED_PHOTO_CAMERA = 'FILLED_PHOTO_CAMERA',
  FILLED_ADD_USER = 'FILLED_ADD_USER',
  FILLED_COG = 'FILLED_COG',
  FILLED_FOLDER = 'FILLED_FOLDER',
  FILLED_GRID = 'FILLED_GRID',
  FILLED_LIST = 'FILLED_LIST',
  FILLED_PENCIL = 'FILLED_PENCIL',
  FILLED_ARROW_TOP_RIGHT = 'FILLED_ARROW_TOP_RIGHT',
  FILLED_SAVE = 'FILLED_SAVE',
  FILLED_RECORD = 'FILLED_RECORD',
  FILLED_SHARED_RIGHT = 'FILLED_SHARED_RIGHT',
  FILLED_ARROW_UNDO = 'FILLED_ARROW_UNDO',
  FILLED_MICROPHONE_2 = 'FILLED_MICROPHONE_2',
  FILLED_MICROPHONE_2_DISABLED = 'FILLED_MICROPHONE_2_DISABLED',
  FILLED_VIDEO_DISABLED = 'FILLED_VIDEO_DISABLED',
  FILLED_PAINT_BUCKET = 'FILLED_PAINT_BUCKET',
  FILLED_PENCIL_EDIT_SMALL = 'FILLED_PENCIL_EDIT_SMALL',
  FILLED_PENCIL_EDIT_MEDIUM = 'FILLED_PENCIL_EDIT_MEDIUM',
  FILLED_PENCIL_EDIT_LARGE = 'FILLED_PENCIL_EDIT_LARGE',
  FILLED_QUESTION_MARK = 'FILLED_QUESTION_MARK',

  FOLDER = 'FOLDER',
  FOLDER_MINUS = 'FOLDER_MINUS',
  FOLDER_PLUS = 'FOLDER_PLUS',

  CLOUD_DOWNLOAD = 'CLOUD_DOWNLOAD',

  PLUS = 'PLUS',
  MINUS = 'MINUS',

  CHAT = 'CHAT',
  DOWNLOAD = 'DOWNLOAD',
  TEXT = 'TEXT',
  ERASER = 'ERASER',
  INTERVIEW = 'INTERVIEW',
  PAUSE = 'PAUSE',
  PLAY = 'PLAY',

  NOTE = 'NOTE',
  NOTIFICATION_BELL = 'NOTIFICATION_BELL',
  DOCUMENT_IN_ACCEPT = 'DOCUMENT_IN_ACCEPT',
  DOCUMENT_IN_PROGRESS = 'DOCUMENT_IN_PROGRESS',

  LINE_BASIC = 'LINE_BASIC',
  LINE_ARROW = 'LINE_ARROW',
  LINE_WIDTH_SMALL = 'LINE_WIDTH_SMALL',
  LINE_WIDTH_MEDIUM = 'LINE_WIDTH_MEDIUM',
  LINE_WIDTH_LARGE = 'LINE_WIDTH_LARGE',

  FONT_SIZE_2 = 'FONT_SIZE_2',
  FONT_SIZE_SMALL = 'FONT_SIZE_SMALL',
  FONT_SIZE_MEDIUM = 'FONT_SIZE_MEDIUM',
  FONT_SIZE_LARGE = 'FONT_SIZE_LARGE',

  WARNING = 'WARNING',
  SEND = 'SEND',

  CHEVRON_LEFT = 'CHEVRON_LEFT',
  CHEVRON_RIGHT = 'CHEVRON_RIGHT',
  CHEVRON_DOWN = 'CHEVRON_DOWN',

  ZOOM_IN = 'ZOOM_IN',
  ZOOM_OUT = 'ZOOM_OUT',
  DOWNLOAD_2 = 'DOWNLOAD_2',

  ROTATE_LEFT = 'ROTATE_LEFT',
  ROTATE_RIGHT = 'ROTATE_RIGHT',

}
