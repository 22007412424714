import { Component, EventEmitter, Output } from '@angular/core';
import { AssetsImageProvider } from '@core/assets/providers/assets-image.provider';
import { AssetsImage } from '@core/assets/enums/assets-image.enum';
import { RouteProvider } from '@core/routing/provider/route.provider';
import { RouteName } from '@core/routing/route-name.enum';
import { UserContextService } from '@core/auth/services/user-context.service';
import { Observable } from 'rxjs';
import { UserContextModel } from '@core/auth/models/user-context.model';
import { Icon } from '@core/assets/enums/icon.enum';

@Component({
  selector: 'app-dashboard-layout-header',
  templateUrl: './dashboard-layout-header.component.html',
  styleUrls: ['./dashboard-layout-header.component.scss'],
})
export class DashboardLayoutHeaderComponent {

  public readonly ICON = Icon;

  @Output() public toggleMenu = new EventEmitter();

  public userContext$: Observable<UserContextModel | null> = this.userContextService.userContext$;

  public homeUrl = this.routeProvider.getPath(RouteName.DASHBOARD);
  public brandLogoSrc: string = this.assetsProvider.getUrl(AssetsImage.LOGO_HORIZONTAL);
  public brandSmallLogoSrc: string = this.assetsProvider.getUrl(AssetsImage.LOGO_SQUARE);

  public constructor(private userContextService: UserContextService,
                     private assetsProvider: AssetsImageProvider,
                     private routeProvider: RouteProvider) {
  }

}
