import { Injectable } from '@angular/core';
import { AssetsMusic } from '@core/assets/enums/assets-music.enum';

@Injectable({
  providedIn: 'root',
})
export class AssetsMusicProvider {

  private readonly ASSETS_ROOT_PATH = '/assets/music/';

  public getUrl(name: AssetsMusic): string {
    return [this.ASSETS_ROOT_PATH, name].join('');
  }

}
