import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPayloadProcessorService } from '@app/api/helpers/api-payload-processor.service';
import { IdResponse } from '@app/api/interfaces/id.response';
import { CollectionRequest } from '@app/api/models/collection/requests/collection.request';
import { CollectionResponse } from '@app/api/models/collection/responses/collection.response';
import { Uuid } from '@app/api/types/uuid.type';
import { apiProceduresManagement } from '@environments/environment';
import { Observable } from 'rxjs';
import { ProcedureFilter } from '../models/filters/procedure.filter';
import { ProcedureCreateRequest } from '../models/requests/procedure-create.request';
import { ProcedureResponse } from '../models/responses/procedure.response';
import { ProceduresApi } from './interfaces/procedures.api';
import { apiParams } from '@environments/const/environment.const';
import { ProcedureUpdateRequest } from '../models/requests/procedure-update.request';
import { ProcedureSubmitResponse } from '@api/procedures-api/models/responses/procedure-submit.response';
import { ProceduresStatsResponse } from '@api/procedures-api/models/responses/procedures-stats.response';

const { PROCEDURE_ID } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class ProceduresApiService implements ProceduresApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(request: CollectionRequest<ProcedureFilter>): Observable<CollectionResponse<ProcedureResponse>> {
    const { PROCEDURES: { LIST } } = apiProceduresManagement;
    const requestQueryString: string = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, ProcedureFilter);
    const url: string = `${this.grabEndpointUrl(LIST)}?${requestQueryString}`;

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToCollection(ProcedureResponse),
      );
  }

  public stats(filter?: ProcedureFilter): Observable<ProceduresStatsResponse> {
    const { PROCEDURES: { STATS } } = apiProceduresManagement;
    const requestQueryString: string = this.apiPayloadProcessor.mapObjectToQueryString({
      filter: this.apiPayloadProcessor.toObject(filter, ProcedureFilter),
    });
    const url: string = `${this.grabEndpointUrl(STATS)}?${requestQueryString}`;

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToModel(ProceduresStatsResponse),
      );
  }

  public details(id: Uuid): Observable<ProcedureResponse> {
    const { PROCEDURES: { DETAILS } } = apiProceduresManagement;
    const url: string = this
      .grabEndpointUrl(DETAILS)
      .replace(PROCEDURE_ID, id.toString());

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToModel(ProcedureResponse),
      );
  }

  public create(request: ProcedureCreateRequest): Observable<IdResponse> {
    const { PROCEDURES: { LIST } } = apiProceduresManagement;
    const url: string = this.grabEndpointUrl(LIST);
    const payload: Object = this.apiPayloadProcessor.toObject(request, ProcedureCreateRequest);

    return this.http
      .post(url, payload)
      .pipe(
        this.apiPayloadProcessor.mapToModel(IdResponse),
      );
  }

  public update(id: Uuid, request: ProcedureUpdateRequest): Observable<Object> {
    const { PROCEDURES: { UPDATE } } = apiProceduresManagement;
    const url: string = this
      .grabEndpointUrl(UPDATE)
      .replace(PROCEDURE_ID, id.toString());
    const payload: Object = this.apiPayloadProcessor.toObject(request, ProcedureUpdateRequest);

    return this.http.put(url, payload);
  }

  public submit(id: Uuid): Observable<ProcedureSubmitResponse> {
    const { PROCEDURES: { SUBMIT } } = apiProceduresManagement;
    const url: string = this
      .grabEndpointUrl(SUBMIT)
      .replace(PROCEDURE_ID, id.toString());

    return this.http
      .post(url, {})
      .pipe(
        this.apiPayloadProcessor.mapToModel(ProcedureSubmitResponse),
      );
  }

  public delete(id: Uuid): Observable<Object> {
    const { PROCEDURES: { DELETE } } = apiProceduresManagement;
    const url: string = this
      .grabEndpointUrl(DELETE)
      .replace(PROCEDURE_ID, id.toString());

    return this.http.delete(url);
  }

  public archive(id: Uuid): Observable<void> {
    const { PROCEDURES: { ARCHIVE } } = apiProceduresManagement;
    const url: string = this
      .grabEndpointUrl(ARCHIVE)
      .replace(PROCEDURE_ID, id.toString());

    return this.http.post<void>(url, {});
  }

  public version(id: Uuid): Observable<void> {
    const { PROCEDURES: { VERSION } } = apiProceduresManagement;

    const url: string = this
      .grabEndpointUrl(VERSION)
      .replace(PROCEDURE_ID, id.toString());

    return this.http.post<void>(url, {});
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiProceduresManagement.API_HOST_URL, endpoint].join('');
  }

}
