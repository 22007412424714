<p-toast [baseZIndex]="5000">

  <ng-template let-message pTemplate="message">
    <span *ngIf="message.icon"
          [class]="'p-toast-message-icon ' + message.icon"></span>
    <div class="p-toast-message-text"
         [ngClass]="{'p-toast-message-text--no-icon': !(!!message.icon)}">
      <div class="p-toast-summary">{{message.summary | translate}}</div>
      <div class="p-toast-detail">{{message.detail | translate}}</div>
    </div>
  </ng-template>

</p-toast>
