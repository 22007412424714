export const primengTranslations = {
  startsWith: 'PRIMENG.STARTS_WITH',
  contains: 'PRIMENG.CONTAINS',
  notContains: 'PRIMENG.NOT_CONTAINS',
  endsWith: 'PRIMENG.ENDS_WITH',
  equals: 'PRIMENG.EQUALS',
  notEquals: 'PRIMENG.NOT_EQUALS',
  noFilter: 'PRIMENG.NO_FILTER',
  lt: 'PRIMENG.LT',
  lte: 'PRIMENG.LTE',
  gt: 'PRIMENG.GT',
  gte: 'PRIMENG.GTE',
  is: 'PRIMENG.IS',
  isNot: 'PRIMENG.IS_NOT',
  before: 'PRIMENG.BEFORE',
  after: 'PRIMENG.AFTER',
  dateIs: 'PRIMENG.DATE_IS',
  dateIsNot: 'PRIMENG.DATE_IS_NOT',
  dateBefore: 'PRIMENG.DATE_IS_BEFORE',
  dateAfter: 'PRIMENG.DATE_IS_AFTER',
  clear: 'PRIMENG.CLEAR',
  apply: 'PRIMENG.APPLY',
  matchAll: 'PRIMENG.MATCH_ALL',
  matchAny: 'PRIMENG.MATCH_ANY',
  addRule: 'PRIMENG.ADD_RULE',
  removeRule: 'PRIMENG.REMOVE_RULE',
  accept: 'PRIMENG.YES',
  reject: 'PRIMENG.NO',
  choose: 'PRIMENG.CHOOSE',
  upload: 'PRIMENG.UPLOAD',
  cancel: 'PRIMENG.CANCEL',
  dayNames: [
    'PRIMENG.DAY_NAMES.SUNDAY',
    'PRIMENG.DAY_NAMES.MONDAY',
    'PRIMENG.DAY_NAMES.TUESDAY',
    'PRIMENG.DAY_NAMES.WEDNESDAY',
    'PRIMENG.DAY_NAMES.THURSDAY',
    'PRIMENG.DAY_NAMES.FRIDAY',
    'PRIMENG.DAY_NAMES.SATURDAY',
  ],
  dayNamesShort: [
    'PRIMENG.DAY_NAMES_SHORT.SUN',
    'PRIMENG.DAY_NAMES_SHORT.MON',
    'PRIMENG.DAY_NAMES_SHORT.TUE',
    'PRIMENG.DAY_NAMES_SHORT.WED',
    'PRIMENG.DAY_NAMES_SHORT.THU',
    'PRIMENG.DAY_NAMES_SHORT.FRI',
    'PRIMENG.DAY_NAMES_SHORT.SAT',
  ],
  dayNamesMin: [
    'PRIMENG.DAY_NAMES_MIN.SU',
    'PRIMENG.DAY_NAMES_MIN.MO',
    'PRIMENG.DAY_NAMES_MIN.TU',
    'PRIMENG.DAY_NAMES_MIN.WE',
    'PRIMENG.DAY_NAMES_MIN.TH',
    'PRIMENG.DAY_NAMES_MIN.FR',
    'PRIMENG.DAY_NAMES_MIN.SA',
  ],
  monthNames: [
    'PRIMENG.MONTH_NAMES.JANUARY',
    'PRIMENG.MONTH_NAMES.FEBRUARY',
    'PRIMENG.MONTH_NAMES.MARCH',
    'PRIMENG.MONTH_NAMES.APRIL',
    'PRIMENG.MONTH_NAMES.MAY',
    'PRIMENG.MONTH_NAMES.JUNE',
    'PRIMENG.MONTH_NAMES.JULY',
    'PRIMENG.MONTH_NAMES.AUGUST',
    'PRIMENG.MONTH_NAMES.SEPTEMBER',
    'PRIMENG.MONTH_NAMES.OCTOBER',
    'PRIMENG.MONTH_NAMES.NOVEMBER',
    'PRIMENG.MONTH_NAMES.DECEMBER',
  ],
  monthNamesShort: [
    'PRIMENG.MONTH_NAMES_SHORT.JAN',
    'PRIMENG.MONTH_NAMES_SHORT.FEB',
    'PRIMENG.MONTH_NAMES_SHORT.MAR',
    'PRIMENG.MONTH_NAMES_SHORT.APR',
    'PRIMENG.MONTH_NAMES_SHORT.MAY',
    'PRIMENG.MONTH_NAMES_SHORT.JUN',
    'PRIMENG.MONTH_NAMES_SHORT.JUL',
    'PRIMENG.MONTH_NAMES_SHORT.AUG',
    'PRIMENG.MONTH_NAMES_SHORT.SEP',
    'PRIMENG.MONTH_NAMES_SHORT.OCT',
    'PRIMENG.MONTH_NAMES_SHORT.NOV',
    'PRIMENG.MONTH_NAMES_SHORT.DEC',
  ],
  today: 'PRIMENG.TODAY',
  weekHeader: 'PRIMENG.WK',
  weak: 'PRIMENG.WEAK',
  medium: 'PRIMENG.MEDIUM',
  strong: 'PRIMENG.STRONG',
  passwordPrompt: 'PRIMENG.PASSWORD_PROMPT',
  emptyMessage: 'PRIMENG.EMPTY_MESSAGE',
  emptyFilterMessage: 'PRIMENG.EMPTY_FILTER_MESSAGE',
};
