import { jsonMember, jsonObject } from 'typedjson';
import { ProcedureFieldType } from '@api/procedures-api/models/enums/procedure-field-type';
import { ProcedureFieldConfigUpdateRequest } from '@api/procedures-api/models/requests/procedure-field-config-update.request';

@jsonObject
export class ProcedureFieldUpdateRequest {

  @jsonMember public label?: string;
  @jsonMember public code?: string;
  @jsonMember public type?: ProcedureFieldType;
  @jsonMember public config?: ProcedureFieldConfigUpdateRequest;

  public static fromPartial(partial: Partial<ProcedureFieldUpdateRequest>): ProcedureFieldUpdateRequest {
    return Object.assign(new ProcedureFieldUpdateRequest(), partial);
  }
}
