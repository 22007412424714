import { DictionaryItemValueResponse } from '@app/api/dictionaries-api/models/responses/dictionary-item-value.repsonse';
import { Uuid } from '@app/api/types/uuid.type';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { ProcedureAttributeResponse } from './procedure-attribute.response';
import { ProcedureContextOperationsResponse } from './procedure-context-operations.response';
import { ProcedureUserDignityResponse } from '@api/procedures-api/models/responses/procedure-user-dignity.response';

@jsonObject
export class ProcedureResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly number!: string;
  @jsonMember public readonly category!: DictionaryItemValueResponse;
  @jsonMember public readonly name!: string;
  @jsonMember public readonly author!: ProcedureUserDignityResponse;
  @jsonMember public readonly description!: string;
  @jsonMember public readonly status!: DictionaryItemValueResponse;
  @jsonMember public readonly createdAt!: Date;
  @jsonMember public readonly contextOperations!: ProcedureContextOperationsResponse;
  @jsonMember public readonly version!: number;
  @jsonMember public readonly activeTaskCount!: number;
  @jsonMember public readonly finishedTaskCount!: number;
  @jsonMember public readonly alertTaskCount!: number;
  @jsonMember public readonly expert?: ProcedureUserDignityResponse;
  @jsonMember public readonly verifier?: ProcedureUserDignityResponse;
  @jsonArrayMember(ProcedureAttributeResponse) public readonly attributes?: ProcedureAttributeResponse[];

  public hasVerifierAssigned(): boolean {
    return !!this.verifier;
  }

  public get authorFullName(): string {
    return this.author.fullName;
  }

  public get expertFullName(): string | undefined {
    return this.expert?.fullName;
  }

  public get verifierFullName(): string | undefined {
    return this.verifier?.fullName;
  }

  public get isFromTheVersion(): boolean {
    return this.version > 1;
  }

}
