import { jsonMember, jsonObject } from 'typedjson';
import { DictionaryType } from '@api/dictionaries-api/models/enums/dictionary-type';
import { DictionaryModule } from '@api/dictionaries-api/models/enums/dictionary-module';
import { DictionaryContextOperationsResponse } from '@api/dictionaries-api/models/responses/dictionary-context-operations.response';

@jsonObject
export class DictionaryResponse {
  @jsonMember public readonly name!: string;
  @jsonMember public readonly description!: string;
  @jsonMember(String) public readonly type!: DictionaryType;
  @jsonMember(String) public readonly module!: DictionaryModule;
  @jsonMember public readonly isAddable!: boolean;
  @jsonMember public readonly isEditable!: boolean;
  @jsonMember public readonly isRemovable!: boolean;
  @jsonMember public readonly updatedAt!: Date;
  @jsonMember public readonly contextOperations!: DictionaryContextOperationsResponse;
}
