import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowIfHasPermissionDirective } from '@core/permissions/directives/show-if-has-permission.directive';
import { HideIfHasPermissionDirective } from '@core/permissions/directives/hide-if-has-permission.directive';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ShowIfHasPermissionDirective,
    HideIfHasPermissionDirective,
  ],
  exports: [
    ShowIfHasPermissionDirective,
    HideIfHasPermissionDirective,
  ],
})
export class PermissionsModule {
}
