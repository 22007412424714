import { ResourceFilterModel } from '@api/models/collection/requests/resource-filter.model';
import { jsonArrayMember, jsonObject } from 'typedjson';
import { AttributeFilterModel } from '@api/models/collection/requests/attribute-filter.model';

@jsonObject
export class TaskFilter extends ResourceFilterModel {

  @jsonArrayMember(AttributeFilterModel) public number?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public name?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public procedureName?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public procedureCategoryId?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public priorityId?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public expertDignity?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public authorDignity?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public executorDignity?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public additionalExpertDignity?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public expiredAt?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public status?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public createdAt?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public isTest?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public isArchived?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public progress?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public canCancel?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public procedureId?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel, { name: 'query' }) public global?: AttributeFilterModel[];

}
