import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactAvatarGroupComponent } from './contact-avatar-group.component';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { AvatarModule } from 'primeng/avatar';
import { ContactAvatarModule } from '@modules/panel/contacts/components/contact-avatar/contact-avatar.module';

const INTERNAL_MODULES = [
  ContactAvatarModule,
];

const PRIMENG_MODULES = [
  AvatarGroupModule,
  AvatarModule,
];

@NgModule({
  declarations: [
    ContactAvatarGroupComponent,
  ],
  imports: [
    CommonModule,
    PRIMENG_MODULES,
    INTERNAL_MODULES,
  ],
  exports: [
    ContactAvatarGroupComponent,
  ],
})
export class ContactAvatarGroupModule {
}
