import { Injectable } from '@angular/core';
import { Language } from '@core/translations/enums/language.enum';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageLabelsProviderService {

  public constructor(
    private readonly translateService: TranslateService,
  ) {
  }

  private readonly extended: Record<Language, Observable<string>> = {
    [Language.ENGLISH]: this.translateService.stream('LANGUAGE.EXTENDED.ENGLISH'),
    [Language.POLISH]: this.translateService.stream('LANGUAGE.EXTENDED.POLISH'),
  };

  private readonly short: Record<Language, Observable<string>> = {
    [Language.ENGLISH]: this.translateService.stream('LANGUAGE.SHORT.ENGLISH'),
    [Language.POLISH]: this.translateService.stream('LANGUAGE.SHORT.POLISH'),
  };

  public extendedLabel(language: Language): Observable<string> {
    return this.extended[language];
  }

  public shortLabel(language: Language): Observable<string> {
    return this.short[language];
  }
}
