import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { apiDictionariesCommon } from '@environments/environment';
import { AdminDictionaryItemsApi } from '@api/dictionaries-api/services/interfaces/admin-dictionary-items.api';
import { Observable } from 'rxjs';
import { DictionaryItemResponse } from '@api/dictionaries-api/models/responses/dictionary-item.response';
import { DictionaryType } from '@api/dictionaries-api/models/enums/dictionary-type';
import { Uuid } from '@api/types/uuid.type';
import { CreateDictionaryItemRequest } from '@api/dictionaries-api/models/requests/create-dictionary-item.request';
import { IdResponse } from '@api/interfaces/id.response';
import { UpdateDictionaryItemRequest } from '@api/dictionaries-api/models/requests/update-dictionary-item.request';
import { CollectionResponse } from '@app/api/models/collection/responses/collection.response';
import { CollectionRequest } from '@app/api/models/collection/requests/collection.request';
import { DictionaryItemFilter } from '../models/filters/dictionary-item.filter';
import { apiParams } from '@environments/const/environment.const';

const { ID, TYPE } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class AdminDictionaryItemsApiService implements AdminDictionaryItemsApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(type: DictionaryType, request: CollectionRequest<DictionaryItemFilter>): Observable<CollectionResponse<DictionaryItemResponse>> {
    const { DICTIONARY_ITEMS: { LIST } } = apiDictionariesCommon;
    const requestQueryString: string = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, DictionaryItemFilter);
    const url: string = `${this.grabEndpointUrl(LIST)}?${requestQueryString}`
      .replace(TYPE, type);

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToCollection(DictionaryItemResponse),
      );
  }

  public details(type: DictionaryType, id: Uuid): Observable<DictionaryItemResponse> {
    const url: string = this
      .grabEndpointUrl(apiDictionariesCommon.DICTIONARY_ITEMS.DETAILS)
      .replace(TYPE, type)
      .replace(ID, id.toString());

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToModel(DictionaryItemResponse),
    );
  }

  public create(type: DictionaryType, request: CreateDictionaryItemRequest): Observable<IdResponse> {
    const url: string = this
      .grabEndpointUrl(apiDictionariesCommon.DICTIONARY_ITEMS.CREATE)
      .replace(TYPE, type);

    const payload: Object = this.apiPayloadProcessor.toObject(request, CreateDictionaryItemRequest);

    return this.http.post(url, payload).pipe(
      this.apiPayloadProcessor.mapToModel(IdResponse),
    );
  }

  public update(type: DictionaryType, id: Uuid, request: UpdateDictionaryItemRequest): Observable<Object> {
    const url: string = this
      .grabEndpointUrl(apiDictionariesCommon.DICTIONARY_ITEMS.UPDATE)
      .replace(TYPE, type)
      .replace(ID, id.toString());

    const payload: Object = this.apiPayloadProcessor.toObject(request, UpdateDictionaryItemRequest);

    return this.http.put(url, payload);
  }

  public delete(type: DictionaryType, id: Uuid): Observable<Object> {
    const url: string = this
      .grabEndpointUrl(apiDictionariesCommon.DICTIONARY_ITEMS.DELETE)
      .replace(TYPE, type)
      .replace(ID, id.toString());

    return this.http.delete(url);
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiDictionariesCommon.API_HOST_URL, endpoint].join('');
  }

}
