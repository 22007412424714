import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Permission } from '../models/permission.enum';
import { PermissionsList } from '../models/permissions-list.model';
import { RouteName } from '@core/routing/route-name.enum';
import { PermissionsService } from '@core/permissions/services/permissions.service';
import { NavigateService } from '@core/routing/services/navigate.service';
import { PERMISSIONS, ROUTE_NO_PERMISSION_REDIRECT_PATH, ROUTE_PERMISSIONS } from '@core/routing/routing-data.const';
import { RoutePermissionsProvider } from '@core/routing/provider/route-permissions.provider';
import { LoggedUserLandingPageRouteProvider } from '@core/routing/provider/logged-user-landing-page-route.provider';

@Injectable({
  providedIn: 'root',
})
export class AllowIfHasPermissionGuard implements CanActivate {

  protected permissions?: Permission | Permission[] | PermissionsList;
  protected routePermissions?: RouteName;
  protected noPermissionRedirectPath?: RouteName;

  public constructor(private permissionsService: PermissionsService,
                     private routePermissionsProvider: RoutePermissionsProvider,
                     private landingRouteProvider: LoggedUserLandingPageRouteProvider,
                     private navigateService: NavigateService) {
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    this.readRouteConfiguration(route);
    this.checkGuardConfiguration();

    return this.permissionsService.permissionsReady$.pipe(
      map(() => {
        const hasPermission = this.hasPermission();

        if (!hasPermission) {
          this.onNoPermission(route);
        }

        return hasPermission;
      }),
    );
  }

  private hasPermission(): boolean {
    if (this.permissions) {
      return this.permissionsService.checkPermissions(this.permissions);
    }

    if (this.routePermissions) {
      const routePermissions = this.routePermissionsProvider.getPermissions(this.routePermissions);
      return this.permissionsService.checkPermissions(routePermissions);
    }

    return true;
  }

  protected onNoPermission(route: ActivatedRouteSnapshot): void {
    this.logDenyAccess(route);

    const redirectPath = this.noPermissionRedirectPath ?? this.landingRouteProvider.resolveLandingPageRoute();
    if (redirectPath) {
      this.navigateService.navigate(redirectPath);
    }
  }

  protected readRouteConfiguration(route: ActivatedRouteSnapshot): void {
    this.permissions = route.data[PERMISSIONS];
    this.routePermissions = route.data[ROUTE_PERMISSIONS];
    this.noPermissionRedirectPath = route.data[ROUTE_NO_PERMISSION_REDIRECT_PATH];
  }

  protected checkGuardConfiguration(): void {
    if (!this.permissions && !this.routePermissions) {
      const permissionsExample = 'data: {' + PERMISSIONS + ': [' + Permission.IS_ADMIN + ']}';
      const msg = 'W konfiguracji route użyto Guarda AllowIfHasPermissionGuard lecz nie wskazano wymaganych uprawnień. ' +
        'Do route należy przekazać pod atrybutem "data" listę uprawnień, np. ' + permissionsExample;

      throw new Error(msg);
    }
  }

  private logDenyAccess(route: ActivatedRouteSnapshot): void {
    const routePath = route.pathFromRoot.map((activetedRouteSnapshot: ActivatedRouteSnapshot) => {
      return activetedRouteSnapshot.url.join('/');
    }).join('/');

    const msg = `AllowIfHasPermissionGuard: Blokada nawigacji do ścieżki "${routePath}" - brak wymaganych uprawnień.`;
    console.warn(msg);
  }
}
