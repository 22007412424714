import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { merge, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TITLE } from '@core/routing/routing-data.const';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class PageTitleProvider {

  public constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly translateService: TranslateService,
  ) {
  }

  public get title$(): Observable<string> {
    return merge(
      this.titleFromLanguageChange(),
      this.titleFromActivatedRoute(),
      this.titleFromRouterNavigationEnd(),
    );
  }

  private titleFromActivatedRoute(): Observable<string> {
    return this
      .activatedRoute
      .data
      .pipe(
        map(() => this.getTitle(this.activatedRoute)),
      );
  }

  private titleFromLanguageChange(): Observable<string> {
    return this
      .translateService
      .onLangChange
      .pipe(
        map(() => this.getTitle(this.activatedRoute)),
      );
  }

  private titleFromRouterNavigationEnd(): Observable<string> {
    return this.router
      .events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.getTitle(this.activatedRoute)),
      );
  }

  private getTitle(activatedRoute: ActivatedRoute): string {
    let element: ActivatedRouteSnapshot | null = activatedRoute.snapshot;
    let title = '';

    while (element) {
      element = element.firstChild;

      const currentTitle = element?.data[TITLE];

      if (currentTitle) {
        title = currentTitle;
      }
    }

    if (title) {
      return this.translateService.instant(title);
    }

    return title;
  }

}
