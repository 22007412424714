import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { DictionaryItemValueResponse } from '@api/dictionaries-api/models/responses/dictionary-item-value.repsonse';
import { ProcedureStageResponse } from '@api/procedures-api/models/responses/procedure-stage.response';
import { RepositoryFileDetailsResponse } from '@api/documents-api/models/responses/repository-file-details.response';
import { TaskRealizationStatus } from '@api/procedures-api/models/enums/task-realization-status';
import { TaskActivityContextOperationsResponse } from './task-activity-context-operations.response';

@jsonObject
export class TaskActivityResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember(String) public readonly verificationId!: Uuid;
  @jsonMember public readonly stage!: ProcedureStageResponse;
  @jsonMember public readonly action!: DictionaryItemValueResponse;
  @jsonMember public readonly createdAt!: Date;
  @jsonMember public readonly comment!: string;
  @jsonMember public readonly contextOperations?: TaskActivityContextOperationsResponse;
  @jsonArrayMember(RepositoryFileDetailsResponse) public readonly repositoryFiles!: RepositoryFileDetailsResponse[];

  public isFactualError(): boolean {
    return this.action.value === TaskRealizationStatus.REPORTED_FACTUAL_ERROR;
  }

}
