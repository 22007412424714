import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalStorageService } from './services/local-storage.service';
import { STORAGE } from './services/tokens/storage.token';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
  providers: [
    {
      provide: STORAGE,
      useClass: LocalStorageService,
    },
  ],
})
export class StorageModule {
}
