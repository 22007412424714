import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { Role } from '@core/auth/enums/role.enum';
import { AppLoggedUserGlobalPermitsResponse } from '@api/cmuia-api/models/responses/app-logged-user-global-permits.response';
import { AppLoggedUserOwnContextOperationsResponse } from '@api/cmuia-api/models/responses/app-logged-user-own-context-operations.response';

@jsonObject
export class AppLoggedUserResponse {
  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly email!: string;
  @jsonMember public readonly firstName?: string;
  @jsonMember public readonly lastName?: string;
  @jsonMember public readonly jobName?: string;
  @jsonMember public readonly department?: string;
  @jsonMember public readonly avatar?: string;
  @jsonMember public readonly contextOperations!: AppLoggedUserOwnContextOperationsResponse;
  @jsonMember public readonly globalPermits!: AppLoggedUserGlobalPermitsResponse;
  @jsonArrayMember(String) public readonly roles!: Role[];
}
