import { Component } from '@angular/core';
import { LanguageSelectOptionsProviderService } from '@app/features/language/providers/language-select-options-provider.service';
import { Observable } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { Language } from '@core/translations/enums/language.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-layout-header-lang-picker',
  templateUrl: './layout-header-lang-picker.component.html',
  styleUrls: ['./layout-header-lang-picker.component.scss'],
})
export class LayoutHeaderLangPickerComponent {

  public readonly languages$: Observable<SelectItem<Language>[]>;

  public currentLang!: Language;

  public constructor(
    private readonly translateService: TranslateService,
    private readonly languageSelectOptionsProviderService: LanguageSelectOptionsProviderService,
  ) {
    this.languages$ = this.getLanguagesSelectItems();
    this.currentLang = this.getCurrentLanguage();
  }

  public handleLanguageChange(event: { value: Language }): void {
    const { value } = event;

    this.changeCurrentLanguage(value);
  }

  private getLanguagesSelectItems(): Observable<SelectItem<Language>[]> {
    return this.languageSelectOptionsProviderService.options$();
  }

  private getCurrentLanguage(): Language {
    return this.translateService.currentLang as Language;
  }

  private changeCurrentLanguage(language: Language): void {
    this
      .translateService
      .use(language)
      .subscribe();
  }

}
