import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { SortModel } from '@api/models/collection/requests/sort.model';
import { CollectionParams } from '@api/models/collection/enum/collection-params.enum';
import { ResourceFilterModel } from '@api/models/collection/requests/resource-filter.model';

@jsonObject
export class CollectionRequest<FilterType extends ResourceFilterModel> {
  @jsonMember public limit? = CollectionParams.DEFAULT_PAGE_SIZE;
  @jsonMember public page = CollectionParams.FIRST_PAGE;
  @jsonMember public filter?: FilterType;
  @jsonArrayMember(SortModel) public sorts?: SortModel[];
  @jsonMember public pagination?: boolean;

  public disablePagination(): void {
    this.pagination = false;
    this.limit = undefined;
    this.page = CollectionParams.FIRST_PAGE;
  }

  public enablePagination(limit?: number): this {
    this.pagination = true;
    this.limit = limit ?? CollectionParams.DEFAULT_PAGE_SIZE;

    return this;
  }
}
