import { Component, OnDestroy } from '@angular/core';
import { LayoutSwitcherService } from '../services/layout-switcher.service';
import { LayoutName } from '../enums/layout-name.enum';
import { AssetsImageProvider } from '../../assets/providers/assets-image.provider';
import { AssetsImage } from '../../assets/enums/assets-image.enum';
import { RouteProvider } from '../../routing/provider/route.provider';
import { RouteName } from '../../routing/route-name.enum';

/**
 * Layout zainicjalizoany na podstawie szablonu: https://getbootstrap.com/docs/4.0/examples/cover/
 */

@Component({
  selector: 'app-cover-layout',
  templateUrl: './cover-layout.component.html',
  styleUrls: ['./cover-layout.component.scss'],
})
export class CoverLayoutComponent implements OnDestroy {

  public linkUrl: string[];
  public logoUrl: string;

  public constructor(private layoutService: LayoutSwitcherService,
                     private routeProvider: RouteProvider,
                     private assetsImageProvider: AssetsImageProvider) {

    this.layoutService.setLayout(LayoutName.COVER);
    this.linkUrl = routeProvider.getPath(RouteName.ROOT);
    this.logoUrl = assetsImageProvider.getUrl(AssetsImage.LOGO_INVERSE);
  }

  public ngOnDestroy(): void {
    this.layoutService.unsetLayout();
  }

}
