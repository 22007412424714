import { Uuid } from '@api/types/uuid.type';
import { jsonMember, jsonObject } from 'typedjson';
import { momentSerializer } from '@app/core/http/helpers/date-serializer.helper';

@jsonObject
export class CreateTaskRequest {

  @jsonMember public readonly name?: string;
  @jsonMember(String) public readonly procedureId?: Uuid;
  @jsonMember(String) public readonly priorityId?: Uuid;
  @jsonMember(String) public readonly expertId?: Uuid;
  @jsonMember(String) public readonly additionalExpertId?: Uuid;
  @jsonMember(String) public readonly executorId?: Uuid;
  @jsonMember({
    serializer: momentSerializer(),
  })
  public readonly expiredAt?: Date;
  @jsonMember public readonly description?: string;
  @jsonMember public readonly isTest?: boolean;

  public constructor(
    name?: string,
    procedureId?: Uuid,
    priorityId?: Uuid,
    expertId?: Uuid,
    additionalExpertId?: Uuid,
    executorId?: Uuid,
    expiredAt?: Date,
    description?: string,
    isTest?: boolean,
  ) {
    this.name = name;
    this.procedureId = procedureId;
    this.priorityId = priorityId;
    this.expertId = expertId;
    this.additionalExpertId = additionalExpertId;
    this.executorId = executorId;
    this.expiredAt = expiredAt;
    this.description = description;
    this.isTest = isTest;
  }

}
