import { Uuid } from '@api/types/uuid.type';
import { jsonMember, jsonObject } from 'typedjson';
import { AttributeType } from '../enums/attribute-type.enum';
import { AttributeConfigResponse } from './attribute-config.response';
import { AttributeContextOperationsResponse } from '@api/common/attributes/models/responses/attribute-context-operations.response';

@jsonObject
export class AttributeResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly name!: string;
  @jsonMember public readonly type!: AttributeType;
  @jsonMember public readonly isRequired!: boolean;
  @jsonMember public readonly createdAt!: Date;
  @jsonMember public readonly contextOperations!: AttributeContextOperationsResponse;
  @jsonMember({ name: 'payload' }) public readonly config?: AttributeConfigResponse;

  public get availableValues(): string[] | undefined {
    return this.config?.availableValues;
  }

}
