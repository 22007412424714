import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class ConversationContextOperationsResponse {
  @jsonMember public readonly canView!: boolean;
  @jsonMember public readonly canUpdate!: boolean;
  @jsonMember public readonly canDelete!: boolean;
  @jsonMember public readonly canLeave!: boolean;
  @jsonMember public readonly canAddParticipants!: boolean;
  @jsonMember public readonly canRemoveParticipant!: boolean;
}
