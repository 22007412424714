import { Uuid } from '@api/types/uuid.type';

export class UserModel {
  public readonly id!: Uuid;
  public readonly email!: string;
  public readonly firstName?: string;
  public readonly lastName?: string;
  public readonly jobName?: string;
  public readonly department?: string;
  public readonly avatar?: string;

  public get fullName(): string {
    const parts = [];
    if (this.firstName) {
      parts.push(this.firstName);
    }
    if (this.lastName) {
      parts.push(this.lastName);
    }

    if (parts.length === 0) {
      parts.push(this.email);
    }

    return parts.join(' ');
  }

  public constructor(id: Uuid, email: string, firstName?: string, lastName?: string, jobName?: string, department?: string, avatar?: string) {
    this.id = id;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.jobName = jobName;
    this.department = department;
    this.avatar = avatar;
  }
}
