import { Injectable } from '@angular/core';
import { AlertMessage } from '@common/components/alert/interface/alert-message.interface';
import { ConfirmationService } from 'primeng/api';
import { AlertTypeKey } from '@common/components/alert/enums/alert-type-key.enum';
import { AlertMessageBuilder } from '@common/components/alert/service/alert-message.builder';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AlertMessageService {

  private readonly DEFAULT_ACCEPT_LABEL = 'LABEL.ALERT_ACCEPT';

  public constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly alertMessageBuilder: AlertMessageBuilder,
    private readonly translateService: TranslateService,
  ) {
  }

  public showAlertDialog(message: AlertMessage): void {
    if (!message.key) {
      message.key = AlertTypeKey.DIALOG;
    }

    this.confirmationService.confirm(this.translateMessage(message));
  }

  public messageBuilder(): AlertMessageBuilder {
    return this.alertMessageBuilder;
  }

  private translateMessage(message: AlertMessage): AlertMessage {
    const { message: body, header, acceptLabel: accept } = message;
    const acceptLabel: string = accept ?? this.DEFAULT_ACCEPT_LABEL;

    return {
      ...message,
      header: this.translate(header),
      message: this.translate(body),
      acceptLabel: this.translate(acceptLabel),
    };
  }

  private translate(value?: string): string | undefined {
    return value && this.translateService.instant(value);
  }

}
