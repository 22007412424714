import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from '@core/permissions/services/permissions.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ResolvePermissionsGuard implements CanActivate {

  public constructor(private permissionsService: PermissionsService) {
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.permissionsService.permissionsReady$.pipe(
      map(() => true),
    );
  }

}
