import { Uuid } from '@api/types/uuid.type';
import { jsonMember, jsonObject } from 'typedjson';
import { DictionaryItemOptionsRequest } from '@api/dictionaries-api/models/requests/dictionary-item-options.request';

@jsonObject
export class CreateDictionaryItemRequest {

  @jsonMember(String) public parentId?: Uuid;
  @jsonMember public name!: string;
  @jsonMember public description?: string;
  @jsonMember public value!: string;
  @jsonMember public colour?: string;
  @jsonMember public isActive: boolean = true;
  @jsonMember public options?: DictionaryItemOptionsRequest;

  public constructor(
    name: string,
    value: string,
    description?: string,
    colour?: string,
    parentId?: Uuid,
  ) {
    this.parentId = parentId;
    this.name = name;
    this.description = description;
    this.value = value;
    this.colour = colour;
  }
}
