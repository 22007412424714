import { ApiErrorViolation, ApiValidationError } from '@api/interfaces/api-validation-error.interface';
import { HttpStatusCode } from '@angular/common/http';

export class ApiValidationErrorsReader {

  private error: ApiValidationError;

  public constructor(error: ApiValidationError) {
    this.error = error;
  }

  public getCode(): number | undefined {
    return this.error.code;
  }

  public getStatus(): HttpStatusCode | undefined {
    return this.error.status;
  }

  public getDetails(): string | undefined {
    return this.error.detail;
  }

  public getAttributeViolationMessage(propertyPath: string): string | undefined {
    return this.getAttributeViolation(propertyPath)?.title;
  }

  public hasAttributeViolation(propertyPath: string): boolean {
    return !!this.getAttributeViolation(propertyPath);
  }

  public getAttributeViolation(propertyPath: string): ApiErrorViolation | undefined {
    if (!this.error.violations) {
      return undefined;
    }

    return this.error.violations.find((violation: ApiErrorViolation) => {
      return violation.propertyPath === propertyPath;
    });
  }

  public getViolationAttributes(): string[] {
    if (!this.error.violations) {
      return [];
    }

    return this.error.violations.map((violation: ApiErrorViolation) => {
      return violation.propertyPath;
    });
  }

  public hasViolations(): boolean {
    return !!this.error.violations && this.error.violations.length > 0;
  }

  public firstErrorMessage(): string | undefined {
    const violation = this.error.violations && this.error.violations.length > 0 ? this.error.violations[0] : null;
    if (!violation) {
      return undefined;
    }

    return violation.title;
  }
}
