import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioVideoCallDialogComponent } from '@common/components/audio-video-call-dialog/audio-video-call-dialog.component';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { AssetsModule } from '@core/assets/assets.module';
import { TranslateModule } from '@ngx-translate/core';
import { ContactAvatarGroupModule } from '@modules/panel/contacts/components/contact-avatar-group/contact-avatar-group.module';
import { ContactAvatarModule } from '@modules/panel/contacts/components/contact-avatar/contact-avatar.module';

@NgModule({
  imports: [
    CommonModule,
    RippleModule,
    ButtonModule,
    AssetsModule,
    TranslateModule,
    ContactAvatarGroupModule,
    ContactAvatarModule,
  ],
  declarations: [
    AudioVideoCallDialogComponent,
  ],
  exports: [
    AudioVideoCallDialogComponent,
  ],
})
export class AudioVideoCallDialogModule {
}
