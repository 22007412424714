import { ResourceFilterModel } from '@api/models/collection/requests/resource-filter.model';
import { jsonArrayMember, jsonObject } from 'typedjson';
import { AttributeFilterModel } from '@api/models/collection/requests/attribute-filter.model';

@jsonObject
export class DashboardFilter extends ResourceFilterModel {
  @jsonArrayMember(AttributeFilterModel) public id?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public creatorId?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public devExpressDashboardId?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public name?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public createdAt?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public owned?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel, { name: 'permissionId' }) public permissions?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel, { name: 'query' }) public global?: AttributeFilterModel[];
}
