<div class="lang-picker p-input-filled">
  <p-dropdown
    styleClass="p-inputtext-sm"
    [options]="languages$ | async"
    (onChange)="handleLanguageChange($event)"
    [(ngModel)]="currentLang">
    <ng-template let-item pTemplate="item">
      <span [title]="item.title">{{item.label}}</span>
    </ng-template>
  </p-dropdown>
</div>
