import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';

@jsonObject
export class CollectionEntriesDownloadResponse {
  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly name!: string;
  @jsonMember public readonly size!: number;
  @jsonMember public readonly mimeType!: string;
  @jsonMember public readonly extension!: string;
  @jsonMember public readonly downloadUrl!: string;
}
