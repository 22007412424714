import { Pipe, PipeTransform } from '@angular/core';
import { AlertSeverity } from '@common/components/alert/enums/alert-severity.enum';

@Pipe({
  name: 'alertDialogSeverityCssClass',
})
export class AlertDialogSeverityCssClassPipe implements PipeTransform {

  private cssClasses: { [key in AlertSeverity]: string } = {
    [AlertSeverity.INFO]: 'alert-dialog--info',
    [AlertSeverity.SUCCESS]: 'alert-dialog--success',
    [AlertSeverity.WARN]: 'alert-dialog--warn',
    [AlertSeverity.DANGER]: 'alert-dialog--danger',
    [AlertSeverity.ERROR]: 'alert-dialog--error',
  };

  public transform(severity?: AlertSeverity): string {
    if (!severity) {
      return '';
    }

    return this.cssClasses[severity];
  }

}
