import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPayloadProcessorService } from '@app/api/helpers/api-payload-processor.service';
import { IdResponse } from '@app/api/interfaces/id.response';
import { CollectionRequest } from '@app/api/models/collection/requests/collection.request';
import { CollectionResponse } from '@app/api/models/collection/responses/collection.response';
import { Uuid } from '@app/api/types/uuid.type';
import { apiParams } from '@environments/const/environment.const';
import { apiCMUiAAdmin } from '@environments/environment';
import { Observable, Subject } from 'rxjs';
import { UserPermissionGroupFilter } from '../models/filters/user-permission-group.filter';
import { CreatePermissionGroupRequest } from '../models/requests/create-permission-group.request';
import { UpdatePermissionGroupRequest } from '../models/requests/update-permission-group.request';
import { UserPermissionGroupDetailsResponse } from '../models/responses/user-permission-group-details.response';
import { UserPermissionGroupResponse } from '../models/responses/user-permission-group.response';
import { AdminUserPermissionGroupsApi } from './interfaces/admin-user-permission-groups.api';
import { tap } from 'rxjs/operators';

const { ID } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class AdminUserPermissionGroupsApiService implements AdminUserPermissionGroupsApi {

  private permissionGroupsChanged$ = new Subject<void>();

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(request: CollectionRequest<UserPermissionGroupFilter>): Observable<CollectionResponse<UserPermissionGroupResponse>> {
    const { PERMISSION_GROUPS: { LIST } } = apiCMUiAAdmin;
    const requestQueryString: string = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, UserPermissionGroupFilter);
    const url: string = `${this.grabEndpointUrl(LIST)}?${requestQueryString}`;

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToCollection(UserPermissionGroupResponse),
      );
  }

  public details(id: Uuid): Observable<UserPermissionGroupDetailsResponse> {
    const { PERMISSION_GROUPS: { DETAILS } } = apiCMUiAAdmin;
    const url: string = this
      .grabEndpointUrl(DETAILS)
      .replace(ID, id.toString());

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToModel(UserPermissionGroupDetailsResponse),
      );
  }

  public create(request: CreatePermissionGroupRequest): Observable<IdResponse> {
    const { PERMISSION_GROUPS: { LIST } } = apiCMUiAAdmin;
    const url: string = this.grabEndpointUrl(LIST);
    const payload: Object = this.apiPayloadProcessor.toObject(request, CreatePermissionGroupRequest);

    return this.http
      .post(url, payload)
      .pipe(
        this.apiPayloadProcessor.mapToModel(IdResponse),
        tap(() => this.notifyPermissionsGroupChanged()),
      );
  }

  public update(request: UpdatePermissionGroupRequest): Observable<Object> {
    const { PERMISSION_GROUPS: { UPDATE } } = apiCMUiAAdmin;
    const url: string = this
      .grabEndpointUrl(UPDATE)
      .replace(ID, request.id.toString());
    const payload: Object = this.apiPayloadProcessor.toObject(request, UpdatePermissionGroupRequest);

    return this.http.put(url, payload).pipe(
      tap(() => this.notifyPermissionsGroupChanged()),
    );
  }

  public delete(id: Uuid): Observable<Object> {
    const { PERMISSION_GROUPS: { DELETE } } = apiCMUiAAdmin;
    const url: string = this
      .grabEndpointUrl(DELETE)
      .replace(ID, id.toString());

    return this.http.delete(url).pipe(
      tap(() => this.notifyPermissionsGroupChanged()),
    );
  }

  public permissionGroupsChanged(): Observable<void> {
    return this.permissionGroupsChanged$.asObservable();
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiCMUiAAdmin.API_HOST_URL, endpoint].join('');
  }

  private notifyPermissionsGroupChanged(): void {
    this.permissionGroupsChanged$.next();
  }
}
