import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { ProcedureFieldPropertyType } from '@api/procedures-api/models/enums/procedure-field-property-type.enum';
import { ProcedureFieldPropertyValueResponse } from '@api/procedures-api/models/responses/procedure-field-property-value.response';

@jsonObject
export class ProcedureFieldPropertyResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly key!: ProcedureFieldPropertyType;
  @jsonArrayMember(ProcedureFieldPropertyValueResponse, { name: 'value' }) public readonly values!: ProcedureFieldPropertyValueResponse[];

}
