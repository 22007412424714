import { Injectable } from '@angular/core';
import { RouteName } from '@core/routing/route-name.enum';
import { Permission } from '@core/permissions/models/permission.enum';
import { PermissionsList } from '@core/permissions/models/permissions-list.model';
import { LogicalOperator } from '@core/permissions/models/logical-operator.enum';

@Injectable({
  providedIn: 'root',
})
export class RoutePermissionsProvider {
  private readonly contactsPanelPermissions = [Permission.HAS_CONTACTS_ACCESS];
  private readonly chatPanelPermissions = [Permission.HAS_CHAT_AND_VIDEO_CONVERSATIONS_ACCESS];
  private readonly videoPanelPermissions = [Permission.HAS_CHAT_AND_VIDEO_CONVERSATIONS_ACCESS];
  private readonly usersPanelPermissions = [Permission.HAS_USERS_ACCESS];
  private readonly createUsersPermissions = [Permission.CAN_CREATE_USERS];
  private readonly permissionGroupsPanelPermissions = [Permission.HAS_PERMISSION_GROUPS_ACCESS];
  private readonly usersAndPermissionGroupsTabbedPanelPermissions = new PermissionsList([Permission.HAS_USERS_ACCESS, Permission.HAS_PERMISSION_GROUPS_ACCESS], LogicalOperator.OR);
  private readonly dictionariesPanelPermissions = [Permission.HAS_DICTIONARIES_ACCESS];
  private readonly attributesPanelPermissions = [Permission.HAS_ATTRIBUTES_ACCESS];
  private readonly dashboardPanelPermissions = [Permission.HAS_DASHBOARD_ACCESS];
  private readonly dashboardManagementPermissions = [Permission.CAN_CREATE_DASHBOARDS];
  private readonly proceduresPanelPermissions = [Permission.HAS_PROCEDURES_ACCESS];
  private readonly tasksPanelPermissions = [Permission.HAS_TASKS_ACCESS];
  private readonly documentsPanelPermissions = [Permission.HAS_DOCUMENTS_ACCESS];
  private readonly settingsPanelPermissions = [Permission.HAS_SETTINGS_ACCESS];

  private routes: { [key in RouteName]: Permission | Permission[] | PermissionsList } = {
    [RouteName.ROOT]: [],
    [RouteName.SIGN_IN]: [Permission.IS_NOT_LOGGED],
    [RouteName.SIGN_OUT]: [Permission.IS_LOGGED],
    [RouteName.RESET_PASSWORD_INFO]: [Permission.IS_NOT_LOGGED],
    [RouteName.UI_GUIDE]: [],
    [RouteName.DASHBOARD]: this.dashboardPanelPermissions,
    [RouteName.DASHBOARD_CREATE]: this.dashboardManagementPermissions,
    [RouteName.DASHBOARD_UPDATE]: this.dashboardManagementPermissions,
    [RouteName.DASHBOARDS_MANAGEMENT]: this.dashboardManagementPermissions,
    [RouteName.MY_PROFILE]: [Permission.IS_LOGGED],
    [RouteName.PANEL_TASKS]: this.tasksPanelPermissions,
    [RouteName.PANEL_TASKS_ALERTS]: this.tasksPanelPermissions,
    [RouteName.PANEL_TASKS_DETAILS]: this.tasksPanelPermissions,
    [RouteName.PANEL_PROCEDURES]: this.proceduresPanelPermissions,
    [RouteName.PANEL_PROCEDURE_ALERTS]: this.proceduresPanelPermissions,
    [RouteName.PANEL_PROCEDURES_DETAILS]: this.proceduresPanelPermissions,
    [RouteName.PANEL_PROCEDURE_CREATOR]: this.proceduresPanelPermissions,
    [RouteName.PANEL_PROCEDURE_CREATOR_STAGES]: this.proceduresPanelPermissions,
    [RouteName.PANEL_PROCEDURE_CREATOR_STAGE_DEFINITION]: this.proceduresPanelPermissions,
    [RouteName.PANEL_PROCEDURE_CREATOR_STAGE_FIELD_ADD]: this.proceduresPanelPermissions,
    [RouteName.PANEL_PROCEDURE_CREATOR_STAGE_FIELD_UPDATE]: this.proceduresPanelPermissions,
    [RouteName.PANEL_CONTACTS]: this.contactsPanelPermissions,
    [RouteName.PANEL_DOCUMENT_CREATE]: this.documentsPanelPermissions,
    [RouteName.PANEL_DOCUMENT_DIRECTORY_DETAILS]: this.documentsPanelPermissions,
    [RouteName.PANEL_DOCUMENT_FILE_DETAILS]: this.documentsPanelPermissions,
    [RouteName.PANEL_DOCUMENT_FILE_UPDATE]: this.documentsPanelPermissions,
    [RouteName.PANEL_CONTACT_DETAILS]: this.contactsPanelPermissions,
    [RouteName.PANEL_DOCUMENTS]: this.documentsPanelPermissions,
    [RouteName.PANEL_CAMERA]: [],
    [RouteName.MANAGE_USERS_OR_PERMISSION_GROUPS]: this.usersAndPermissionGroupsTabbedPanelPermissions,
    [RouteName.MANAGE_USERS]: this.usersPanelPermissions,
    [RouteName.MANAGE_USERS_PERMISSIONS]: this.permissionGroupsPanelPermissions,
    [RouteName.MANAGE_USERS_PERMISSIONS_UPDATE]: this.permissionGroupsPanelPermissions,
    [RouteName.MANAGE_USERS_PERMISSIONS_CREATE]: this.permissionGroupsPanelPermissions,
    [RouteName.CREATE_USER]: this.createUsersPermissions,
    [RouteName.UPDATE_USER]: this.usersPanelPermissions,
    [RouteName.MANAGE_DICTIONARIES]: this.dictionariesPanelPermissions,
    [RouteName.MANAGE_DICTIONARY_ITEMS]: this.dictionariesPanelPermissions,
    [RouteName.MANAGE_ATTRIBUTES]: this.attributesPanelPermissions,
    [RouteName.MANAGE_ATTRIBUTES_PROCEDURES]: this.attributesPanelPermissions,
    [RouteName.MANAGE_ATTRIBUTES_DOCUMENTS]: this.attributesPanelPermissions,
    [RouteName.MANAGE_SETTINGS]: this.settingsPanelPermissions,
    [RouteName.MANAGE_SETTINGS_PROCEDURES]: this.settingsPanelPermissions,
    [RouteName.MANAGE_SETTINGS_DOCUMENTS]: this.settingsPanelPermissions,
    [RouteName.CHAT]: this.chatPanelPermissions,
    [RouteName.CHAT_CONVERSATION]: this.chatPanelPermissions,
    [RouteName.CHAT_CONVERSATION_CREATE]: this.chatPanelPermissions,
    [RouteName.VIDEO_CALL_ROOM]: this.videoPanelPermissions,
    [RouteName.INCOMING_CALL_ACCEPT]: this.videoPanelPermissions,
    [RouteName.INCOMING_CALL_REJECT]: this.videoPanelPermissions,
    [RouteName.NOT_FOUND]: [],
  };

  public constructor() {
  }

  public getPermissions(route: RouteName): Permission | Permission[] | PermissionsList {
    return this.routes[route];
  }
}
