import { AnyT, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { ProcedureFieldValidatorType } from '@api/procedures-api/models/enums/procedure-field-validator.type';
import { ProcedureFieldValidatorDateRangeParamResponse } from './procedure-field-validator-date-range-param.response';
import { ProcedureFieldValidatorLengthParamResponse } from '@api/procedures-api/models/responses/procedure-field-validator-length-param.response';
import { ProcedureFieldValidatorDateRelativeParamResponse } from '@api/procedures-api/models/responses/procedure-field-validator-date-relative-param.response';
import { JsonSerializer } from '@core/serializer/json.serializer';
import { ProcedureFieldValidatorRegexParamResponse } from './procedure-field-validator-regex-param.response';
import { Optional } from '@app/api/types/optional.type';

@jsonObject
export class ProcedureFieldValidatorResponse<T = unknown> {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly type!: ProcedureFieldValidatorType;
  @jsonMember public readonly errorMessage?: string;

  @jsonMember(AnyT, { name: 'params' }) private _params!: T;

  public get regex(): Optional<ProcedureFieldValidatorRegexParamResponse> {
    if (this.type !== ProcedureFieldValidatorType.REGEX) {
      return;
    }

    return JsonSerializer.parse(this._params, ProcedureFieldValidatorRegexParamResponse);
  }

  public get dateRange(): Optional<ProcedureFieldValidatorDateRangeParamResponse> {
    if (this.type !== ProcedureFieldValidatorType.DATE_RANGE) {
      return;
    }

    return JsonSerializer.parse(this._params, ProcedureFieldValidatorDateRangeParamResponse);
  }

  public get length(): Optional<ProcedureFieldValidatorLengthParamResponse> {
    if (this.type !== ProcedureFieldValidatorType.LENGTH) {
      return;
    }

    return JsonSerializer.parse(this._params, ProcedureFieldValidatorLengthParamResponse);
  }

  public get dateRelative(): Optional<ProcedureFieldValidatorDateRelativeParamResponse> {
    if (this.type !== ProcedureFieldValidatorType.DATE_RELATIVE) {
      return;
    }

    return JsonSerializer.parse(this._params, ProcedureFieldValidatorDateRelativeParamResponse);
  }

}
