export enum AssetsMusic {

  INCOMING_CALL_MUSIC = 'incoming_call_music.wav',

  OUTGOING_CALL_MUSIC = 'outgoing_call_music.wav',

  UNTAKEN_CALL_MUSIC = 'untaken_call_music.wav',

  END_CALL_MUSIC = 'end_call_music.wav',

}
