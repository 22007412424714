import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NOTIFIER_API } from '@api/notifier-api/services/tokens/notifier-api.token';
import { NotifierApiService } from '@api/notifier-api/services/notifier-api.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: NOTIFIER_API,
      useClass: NotifierApiService,
    },
  ],
})
export class NotifierApiModule {
}
