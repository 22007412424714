import { Uuid } from '@app/api/types/uuid.type';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class ProcedureStageExecutionOrderCreateRequest {

  @jsonMember(String) public nextProcedureStageId!: Uuid;
  @jsonMember public expression!: string;

  public constructor(
    nextProcedureStageId: Uuid,
    expression: string,
  ) {
    this.nextProcedureStageId = nextProcedureStageId;
    this.expression = expression;
  }

}
