import { ContactResponse } from '@api/contacts-api/models/responses/contact.response';
import { ConversationType } from '@api/conversations-api/models/enums/conversation-type.enum';
import { ConversationInfoResponse } from '@api/conversations-api/models/responses/conversation-info.response';
import { MessageResponse } from '@api/conversations-api/models/responses/message.response';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { ConversationContextOperationsResponse } from '@api/conversations-api/models/responses/conversation-context-operations.response';

@jsonObject
export class ConversationResponse extends ConversationInfoResponse {

  @jsonMember public readonly admin!: ContactResponse;
  @jsonMember public readonly creator!: ContactResponse;
  @jsonArrayMember(ContactResponse) public readonly participants!: ContactResponse[];
  @jsonMember public readonly lastMessage!: MessageResponse;
  @jsonMember public readonly contextOperations!: ConversationContextOperationsResponse;

  public getOtherParticipant(currentUserId: Uuid): ContactResponse | undefined {
    if (this.type === ConversationType.GROUP) {
      return undefined;
    }

    return this.participants.find(participant => participant.userId !== currentUserId);
  }

  public contextualFriendlyName(currentUserId: Uuid): string {
    const otherParticipant = this.getOtherParticipant(currentUserId);
    return otherParticipant?.fullName || this.name;
  }

  public get lastMessageBody(): string | undefined {
    return this.lastMessage?.body;
  }
}
