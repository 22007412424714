import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { CollectionRequest } from '@api/models/collection/requests/collection.request';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';
import { Observable } from 'rxjs';
import { TaskFilter } from '../models/filters/task.filter';
import { TaskDetailsResponse } from '../models/responses/task-details.response';
import { TasksManagementApi } from './interfaces/tasks-management.api';
import { CreateTaskRequest } from '@api/procedures-api/models/requests/create-task.request';
import { IdResponse } from '@api/interfaces/id.response';
import { apiProceduresManagement } from '@environments/environment';
import { Uuid } from '@api/types/uuid.type';
import { apiParams } from '@environments/const/environment.const';
import { UpdateTaskRequest } from '@api/procedures-api/models/requests/update-task.request';
import { TaskResponse } from '../models/responses/task-response';
import { TasksStatsResponse } from '@api/procedures-api/models/responses/tasks-stats.response';

const { TASK_ID } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class TasksManagementApiService implements TasksManagementApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(request: CollectionRequest<TaskFilter>): Observable<CollectionResponse<TaskResponse>> {
    const { LIST } = apiProceduresManagement.TASKS;

    const requestQueryString: string = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, TaskFilter);
    const url: string = `${this.grabEndpointUrl(LIST)}?${requestQueryString}`;

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToCollection(TaskResponse),
      );
  }

  public details(id: Uuid): Observable<TaskDetailsResponse> {
    const { DETAILS } = apiProceduresManagement.TASKS;
    const url: string = this
      .grabEndpointUrl(DETAILS)
      .replace(TASK_ID, id.toString());

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToModel(TaskDetailsResponse),
      );
  }

  public create(request: CreateTaskRequest): Observable<IdResponse> {
    const { CREATE } = apiProceduresManagement.TASKS;
    const url: string = this.grabEndpointUrl(CREATE);
    const payload: Object = this.apiPayloadProcessor.toObject(request, CreateTaskRequest);

    return this.http
      .post(url, payload)
      .pipe(
        this.apiPayloadProcessor.mapToModel(IdResponse),
      );
  }

  public update(id: Uuid, request: UpdateTaskRequest): Observable<Object> {
    const { UPDATE } = apiProceduresManagement.TASKS;
    const url: string = this
      .grabEndpointUrl(UPDATE)
      .replace(TASK_ID, id.toString());
    const payload: Object = this.apiPayloadProcessor.toObject(request, UpdateTaskRequest);

    return this.http.patch(url, payload);
  }

  public delete(id: Uuid): Observable<void> {
    const { DELETE } = apiProceduresManagement.TASKS;
    const url: string = this
      .grabEndpointUrl(DELETE)
      .replace(TASK_ID, id.toString());

    return this.http.delete<void>(url);
  }

  public cancel(id: Uuid): Observable<void> {
    const { CANCEL } = apiProceduresManagement.TASKS;
    const url: string = this
      .grabEndpointUrl(CANCEL)
      .replace(TASK_ID, id.toString());

    return this.http.post<void>(url, id);
  }

  public cancelMultiple(includesIds: Uuid[]): Observable<void> {
    const { TASKS: { CANCEL_MULTIPLE } } = apiProceduresManagement;
    const url: string = this.grabEndpointUrl(CANCEL_MULTIPLE);

    return this.http.post<void>(url, { includedIds: includesIds });
  }

  public archive(id: Uuid): Observable<void> {
    const { ARCHIVE } = apiProceduresManagement.TASKS;
    const url: string = this
      .grabEndpointUrl(ARCHIVE)
      .replace(TASK_ID, id.toString());

    return this.http.post<void>(url, id);
  }

  public stats(filter?: TaskFilter): Observable<TasksStatsResponse> {
    const { STATS } = apiProceduresManagement.TASKS;
    const requestQueryString: string = this.apiPayloadProcessor.mapObjectToQueryString({
      filter: this.apiPayloadProcessor.toObject(filter, TaskFilter),
    });
    const url: string = `${this.grabEndpointUrl(STATS)}?${requestQueryString}`;

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToModel(TasksStatsResponse),
      );
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiProceduresManagement.API_HOST_URL, endpoint].join('');
  }

}
