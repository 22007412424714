import { Uuid } from '@app/api/types/uuid.type';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { PermissionGroupType } from '@api/cmuia-api/models/enums/permission-group-type';

@jsonObject
export class CreatePermissionGroupRequest {

  @jsonMember(String) public readonly type: PermissionGroupType;
  @jsonMember public readonly name: string;
  @jsonMember public readonly description: string;
  @jsonArrayMember(String) public readonly permissionsIds: Uuid[];
  @jsonMember public readonly color?: string;

  public constructor(type: PermissionGroupType, name: string, description: string, permissionsIds: Uuid[], color?: string) {
    this.type = type;
    this.name = name;
    this.description = description;
    this.permissionsIds = permissionsIds;
    this.color = color;
  }

}
