import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { REUSE_ROUTE } from '@core/routing/routing-data.const';
import { ViewportScroller } from '@angular/common';

@Injectable()
export class RouteCustomReuseStrategyProvider extends RouteReuseStrategy {

  public constructor(
    private readonly viewportScroller: ViewportScroller,
  ) {
    super();
  }

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data[REUSE_ROUTE] ?? false;
  }

  public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  public shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    this.resetScrollPosition(future, current);

    return current.data[REUSE_ROUTE] ?? future.routeConfig === current.routeConfig;
  }

  private resetScrollPosition(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): void {
    if (future.routeConfig !== current.routeConfig) {
      this.viewportScroller.scrollToPosition([0, 0]);
    }
  }

}
