import { Injectable } from '@angular/core';
import { VideoProviderApi } from '@api/video-provider-api/services/interfaces/video-provider.api';
import { from, fromEvent, Observable } from 'rxjs';
import {
  connect,
  ConnectOptions,
  createLocalAudioTrack,
  createLocalTracks,
  CreateLocalTracksOptions,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalParticipant,
  LocalTrack,
  LocalTrackPublication,
  LocalTrackPublishOptions,
  LocalVideoTrack,
  Participant,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
} from 'twilio-video';
import { RoomEvent } from '@api/video-provider-api/services/models/enums/room-event';
import { TrackEvent } from '@api/video-provider-api/services/models/enums/track-event';
import { first } from 'rxjs/operators';
import { ParticipantEvent } from '@api/video-provider-api/services/models/enums/participant-event';

@Injectable({
  providedIn: 'root',
})
export class VideoProviderApiService implements VideoProviderApi {

  public readonly videoTrackSettings: MediaTrackConstraintSet = {
    width: 1280, height: 720, frameRate: 24,
  };

  public connectToRoom(token: string, name: string, options?: ConnectOptions): Observable<Room> {
    return from(
      connect(token, {
        ...(options || {}),
        name,
      }),
    );
  }

  public participantConnected(room: Room): Observable<RemoteParticipant> {
    return fromEvent(room, RoomEvent.PARTICIPANT_CONNECTED) as Observable<RemoteParticipant>;
  }

  public participantDisconnected(room: Room): Observable<RemoteParticipant> {
    return fromEvent(room, RoomEvent.PARTICIPANT_DISCONNECTED) as Observable<RemoteParticipant>;
  }

  public trackSubscribed(room: Room): Observable<[RemoteTrack, RemoteTrackPublication, RemoteParticipant]> {
    return fromEvent(room, RoomEvent.TRACK_SUBSCRIBED) as Observable<[RemoteTrack, RemoteTrackPublication, RemoteParticipant]>;
  }

  public publishTrack(participant: LocalParticipant, track: LocalTrack, options?: LocalTrackPublishOptions): Observable<LocalTrackPublication> {
    return from(participant.publishTrack(track, options));
  }

  public publishTracks(participant: LocalParticipant, tracks: LocalTrack[]): Observable<LocalTrackPublication[]> {
    return from(participant.publishTracks(tracks));
  }

  public createLocalTracks(options?: CreateLocalTracksOptions): Observable<LocalTrack[]> {
    return from(createLocalTracks(options));
  }

  public createLocalVideoTrack(): Observable<LocalVideoTrack> {
    return from(createLocalVideoTrack(this.videoTrackSettings));
  }

  public createLocalAudioTrack(): Observable<LocalAudioTrack> {
    return from(createLocalAudioTrack());
  }

  public trackEnded(track: MediaStreamTrack): Observable<void> {
    return fromEvent<void>(track, TrackEvent.ENDED).pipe(first());
  }

  public trackPublished(participant: Participant): Observable<RemoteTrackPublication | LocalTrackPublication> {
    return fromEvent<RemoteTrackPublication | LocalTrackPublication>(participant, ParticipantEvent.TRACK_PUBLISHED);
  }

  public shareScreen(): Observable<MediaStream> {
    // @ts-ignore
    return from(navigator.mediaDevices.getDisplayMedia());
  }
}
