import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FcmMessagingService } from '@core/fcm/services/fcm-messaging.service';

@Injectable({
  providedIn: 'root',
})
export class RegisterFcmGuard implements CanActivate {

  public constructor(private fcmService: FcmMessagingService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.registerForPushNotifications();

    return of(true);
  }

  private registerForPushNotifications(): void {
    this.fcmService.registerDevice();
  }
}
