export enum Role {

  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_EXPERT = 'ROLE_EXPERT',
  ROLE_EMPLOYEE = 'ROLE_EMPLOYEE',
  ROLE_PERMISSION_GROUP_ADMIN = 'ROLE_PERMISSION_GROUP_ADMIN',
  ROLE_CONTACTS_LIST = 'ROLE_CONTACTS_LIST',
  ROLE_CONTACTS_CHAT_AND_VIDEO_CONVERSATIONS = 'ROLE_CONTACTS_CHAT_AND_VIDEO_CONVERSATIONS',
  ROLE_CMUIA_USERS_LIST = 'ROLE_CMUIA_USERS_LIST',
  ROLE_CMUIA_USERS_MANAGEMENT = 'ROLE_CMUIA_USERS_MANAGEMENT',
  ROLE_CMUIA_PERMISSION_GROUPS_LIST = 'ROLE_CMUIA_PERMISSION_GROUPS_LIST',
  ROLE_CMUIA_PERMISSION_GROUPS_MANAGEMENT = 'ROLE_CMUIA_PERMISSION_GROUPS_MANAGEMENT',
  ROLE_CMUIA_MY_PROFILE_DATA_MANAGEMEN = 'ROLE_CMUIA_MY_PROFILE_DATA_MANAGEMENT',
  ROLE_CMUIA_QR_CODE_AUTH_ALLOWED = 'ROLE_CMUIA_QR_CODE_AUTH_ALLOWED',
  ROLE_DICTIONARIES_LIST = 'ROLE_DICTIONARIES_LIST',
  ROLE_DICTIONARIES_MANAGEMENT = 'ROLE_DICTIONARIES_MANAGEMENT',
  ROLE_ATTRIBUTES_LIST = 'ROLE_ATTRIBUTES_LIST',
  ROLE_ATTRIBUTES_MANAGEMENT = 'ROLE_ATTRIBUTES_MANAGEMENT',
  ROLE_DASHBOARD_LIST = 'ROLE_DASHBOARD_LIST',
  ROLE_DASHBOARD_MANAGEMENT = 'ROLE_DASHBOARD_MANAGEMENT',
  ROLE_DOCUMENTS_MANAGEMENT = 'ROLE_DOCUMENTS_MANAGEMENT',
  ROLE_DOCUMENTS_ALL_USERS_PRIVATE_DIRECTORIES_LIST = 'ROLE_DOCUMENTS_ALL_USERS_PRIVATE_DIRECTORIES_LIST',
  ROLE_DOCUMENTS_ALL_USERS_PRIVATE_DIRECTORIES_MANAGEMENT = 'ROLE_DOCUMENTS_ALL_USERS_PRIVATE_DIRECTORIES_MANAGEMENT',
  ROLE_TASKS_MANAGEMENT = 'ROLE_TASKS_MANAGEMENT',
  ROLE_TASKS_LIST = 'ROLE_TASKS_LIST',
  ROLE_TASKS_MY_TASK_LIST = 'ROLE_TASKS_MY_TASK_LIST',

}
