import { FileSystemRepositoryApi } from '@api/documents-api/services/interfaces/file-system-repository.api';
import { Uuid } from '@api/types/uuid.type';
import { RepositoryFileDetailsResponse } from '@api/documents-api/models/responses/repository-file-details.response';
import { Observable } from 'rxjs';
import { apiDirectoriesCommon } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { apiParams } from '@environments/const/environment.const';
import { IdResponse } from '@api/interfaces/id.response';
import { Injectable } from '@angular/core';

const { ID } = apiParams;

@Injectable()
export class FileSystemRepositoryApiService implements FileSystemRepositoryApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public details(id: Uuid): Observable<RepositoryFileDetailsResponse> {
    const { API_HOST_URL, REPOSITORY: { DETAILS } } = apiDirectoriesCommon;
    const url = `${API_HOST_URL}/${DETAILS}`.replace(ID, id.toString());

    return this
      .http
      .get(url)
      .pipe(this.apiPayloadProcessor.mapToModel(RepositoryFileDetailsResponse));
  }

  public preview(id: Uuid): string {
    const { API_HOST_URL, REPOSITORY: { PREVIEW } } = apiDirectoriesCommon;

    return `${API_HOST_URL}/${PREVIEW}`.replace(ID, id.toString());
  }

  public upload(file: File): Observable<IdResponse> {
    const { API_HOST_URL, REPOSITORY: { UPLOAD } } = apiDirectoriesCommon;
    const url: string = `${API_HOST_URL}/${UPLOAD}`;
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);

    return this
      .http
      .post(url, formData)
      .pipe(this.apiPayloadProcessor.mapToModel(IdResponse));
  }

}
