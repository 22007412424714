import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Language } from '@core/translations/enums/language.enum';

@Injectable()
export class ApplicationTranslationProvider extends TranslateLoader {

  public constructor(
    private readonly http: HttpClient,
  ) {
    super();
  }

  public getTranslation(language: Language): Observable<object> {
    return this.http.get(`./assets/i18n/${language}.json`);
  }

}
