import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { FileMimeType } from '@core/files/enums/file-mime-type';
import { FileExtension } from '@core/files/enums/file-extension.enum';
import { RepositoryFileThumbResponse } from '@api/documents-api/models/responses/repository-file-thumb.response';

@jsonObject
export class RepositoryFileDetailsResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly name!: string;
  @jsonMember public readonly size!: number;
  @jsonMember(String) public readonly mimeType!: FileMimeType;
  @jsonMember(String) public readonly extension!: FileExtension;
  @jsonMember public readonly previewUrl!: string;
  @jsonMember public readonly downloadUrl!: string;
  @jsonMember public readonly createdAt!: Date;
  @jsonMember public readonly updatedAt!: Date;
  @jsonMember public readonly thumb!: RepositoryFileThumbResponse;

}
