<div class="header__inner">
  <div class="menu-icon" (click)="toggleMenu.next($event)">
    <i class="{{ ICON.HAMBURGER | iconCssClass }}"></i>
  </div>

  <div class="brand-icon">
    <a [routerLink]="homeUrl">
      <img class="d-none d-md-block" src="{{ brandLogoSrc }}" alt="ARSolutions"/>
      <img class="d-block d-md-none" src="{{ brandSmallLogoSrc }}" alt="ARSolutions"/>
    </a>
  </div>

  <div class="space-fill"></div>

  <app-dashboard-layout-header-notifications></app-dashboard-layout-header-notifications>

  <div class="separator"></div>

  <app-dashboard-layout-header-user-info [userContext]="userContext$ | async"></app-dashboard-layout-header-user-info>

  <app-layout-header-lang-picker></app-layout-header-lang-picker>
</div>

