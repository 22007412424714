import { Uuid } from '@app/api/types/uuid.type';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class DictionaryItemValueResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly name!: string;
  @jsonMember public readonly value!: string;
  @jsonMember public readonly colour!: string;

}
