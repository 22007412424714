import { jsonArrayMember, jsonObject } from 'typedjson';
import { AttributeFilterModel } from '@api/models/collection/requests/attribute-filter.model';
import { ResourceFilterModel } from '@api/models/collection/requests/resource-filter.model';

@jsonObject
export class ContactFilter extends ResourceFilterModel {
  @jsonArrayMember(AttributeFilterModel) public fullName?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public jobName?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public department?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public roles?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public havingPrivateConversation?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public lastContactAt?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel, { name: 'query' }) public global?: AttributeFilterModel[];
}
