import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { LayoutName } from '../enums/layout-name.enum';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LayoutSwitcherService {

  private readonly ATTR_LAYOUT = 'layout';
  private renderer: Renderer2;
  private readonly htmlElement: HTMLHtmlElement | null;

  public constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.htmlElement = this.document.querySelector('html');
  }

  public setLayout(layout: LayoutName): void {
    this.switchActiveLayout(layout);
  }

  public unsetLayout(): void {
    this.renderer.removeAttribute(this.document.body, this.ATTR_LAYOUT);
    this.renderer.removeAttribute(this.htmlElement, this.ATTR_LAYOUT);
  }

  private switchActiveLayout(layout: LayoutName): void {
    this.renderer.setAttribute(this.document.body, this.ATTR_LAYOUT, layout);
    this.renderer.setAttribute(this.htmlElement, this.ATTR_LAYOUT, layout);
  }
}
