import { AttributeFilterModel } from '@app/api/models/collection/requests/attribute-filter.model';
import { ResourceFilterModel } from '@app/api/models/collection/requests/resource-filter.model';
import { jsonArrayMember, jsonObject } from 'typedjson';

@jsonObject
export class ProcedureFilter extends ResourceFilterModel {

  @jsonArrayMember(AttributeFilterModel) public name?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public number?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public description?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public categoryId?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public version?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public status?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public authorDignity?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public expertDignity?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public verifierDignity?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public createdAt?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public activeTaskCount?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public finishedTaskCount?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public alertTaskCount?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel, { name: 'query' }) public global?: AttributeFilterModel[];

}
