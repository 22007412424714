import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from './toast.component';
import { ToastModule as PrimeNgToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ToastMessageService } from '@common/components/toast/services/toast-message.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    PrimeNgToastModule,
    TranslateModule,
  ],
  declarations: [
    ToastComponent,
  ],
  exports: [
    ToastComponent,
  ],
  providers: [
    MessageService,
    ToastMessageService,
  ],
})
export class ToastModule {
}
