import { FileSystemObjectType } from '@api/documents-api/models/enums/file-system-object-type.enum';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { FileExtension } from '@core/files/enums/file-extension.enum';
import { CollectionEntryContextOperationsResponse } from './collection-entry-context-operations.response';

@jsonObject
export class CollectionEntryResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember(String) public readonly parentId!: Uuid;
  @jsonMember(String) public readonly type!: FileSystemObjectType;
  @jsonMember public readonly isPinned!: boolean;
  @jsonMember public readonly name!: string;
  @jsonMember public readonly contextOperations!: CollectionEntryContextOperationsResponse;
  @jsonMember(String) public readonly extension?: FileExtension;
  @jsonMember({ name: 'readablePath' }) public readonly path?: string;
  @jsonArrayMember(String) public readonly permissionGroupsIds?: Uuid[];

  @jsonArrayMember(CollectionEntryResponse) public readonly children?: CollectionEntryResponse[];

  public get isDirectory(): boolean {
    return this.type === FileSystemObjectType.DIRECTORY;
  }

  public get isFile(): boolean {
    return this.type === FileSystemObjectType.FILE;
  }

}
