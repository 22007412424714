export enum FileMimeType {

  IMAGE_WILDCARD = 'image/*',
  AUDIO_WILDCARD = 'audio/*',
  VIDEO_WILDCARD = 'video/*',

  IMAGE_JPG = 'image/jpg',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PNG = 'image/png',
  IMAGE_GIF = 'image/gif',

  VIDEO_MP4 = 'video/mp4',

  AUDIO_MPEG = 'audio/mpeg',

  APPLICATION_PDF = 'application/pdf',
  APPLICATION_ZIP = 'application/zip',

  DOCUMENT_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

}
