import { jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export class ProcedureFieldOptionValueRequest {

  @jsonMember public label!: string;
  @jsonMember public value!: string;

  public constructor(
    label: string,
    value: string,
  ) {
    this.label = label;
    this.value = value;
  }

}
