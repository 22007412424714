import { Injectable } from '@angular/core';
import { ClientEvent } from '@api/conversations-provider-api/models/enums/client-event';
import { ConversationEvent } from '@api/conversations-provider-api/models/enums/conversation-event';
import { Conversation } from '@api/conversations-provider-api/models/interfaces/conversation';
import { Message } from '@api/conversations-provider-api/models/interfaces/message';
import { Paginator } from '@api/conversations-provider-api/models/interfaces/paginator';
import { ConversationsProviderApi } from '@api/conversations-provider-api/services/interfaces/conversations-provider.api';
import { Uuid } from '@api/types/uuid.type';
import { Client, User } from '@twilio/conversations';
import { BehaviorSubject, defer, EMPTY, from, fromEvent, Observable, of, Subject } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { ConversationUpdate } from '@api/conversations-provider-api/models/interfaces/conversation-update';
import { ConnectionState } from '@api/conversations-provider-api/models/enums/connection-state';
import { MessageAttributes } from '@api/conversations-provider-api/models/interfaces/message-attributes';
import { MessageUpdate } from '@api/conversations-provider-api/models/interfaces/message-update';

@Injectable({
  providedIn: 'root',
})
export class ConversationsProviderApiService implements ConversationsProviderApi {
  private client!: Client;
  private _user!: User;
  private _clientShutdown$ = new Subject<void>();
  private _connectionStateChanged$ = new BehaviorSubject<ConnectionState>(ConnectionState.NOT_INITIALIZED);

  public clientShutdown = this._clientShutdown$.asObservable();
  public connectionStateChanged = this._connectionStateChanged$.asObservable();

  public get user(): User {
    return this._user;
  }

  public connectClient(identity: Uuid): Observable<unknown> {
    return defer(() => of(new Client(identity as string)))
      .pipe(
        tap(client => {
          this.client = client;
          this._user = this.client.user;

          this.bindConnectionStateChangedEvent();
        }),
      );
  }

  public shutdownClient(): Observable<void> {
    if (!this.client) {
      return EMPTY;
    }

    this._clientShutdown$.next();
    return from(this.client.shutdown());
  }

  public getUser(identity: Uuid): Observable<User> {
    return from(this.client.getUser(identity as string));
  }

  public getConversation(sid: Uuid): Observable<Conversation> {
    return from(this.client.getConversationBySid(sid as string) as unknown as Promise<Conversation>);
  }

  public messageAdded(conversation: Conversation): Observable<Message> {
    return fromEvent<Message>(conversation, ConversationEvent.MESSAGE_ADDED);
  }

  public messageUpdated(conversation: Conversation): Observable<MessageUpdate> {
    if (!conversation.messageUpdated$) {
      const source = new Subject<MessageUpdate>();
      (fromEvent(conversation, ConversationEvent.MESSAGE_UPDATED) as Observable<MessageUpdate>)
        .subscribe((msg) => source.next(msg));

      conversation.messageUpdated$ = source;
    }

    return conversation.messageUpdated$;
  }

  public messageAddedGlobal(): Observable<Message> {
    return fromEvent(this.client, ConversationEvent.MESSAGE_ADDED) as Observable<Message>;
  }

  public conversationJoined(): Observable<Conversation> {
    return fromEvent(this.client, ClientEvent.CONVERSATION_JOINED) as Observable<Conversation>;
  }

  public conversationLeft(): Observable<Conversation> {
    return fromEvent(this.client, ClientEvent.CONVERSATION_LEFT) as Observable<Conversation>;
  }

  public tokenAboutToExpire(): Observable<Client> {
    return fromEvent(this.client, ClientEvent.TOKEN_ABOUT_TO_EXPIRE) as Observable<Client>;
  }

  public conversationJoinedBySidOnce(conversationSid: Uuid): Observable<Conversation> {
    return this.conversationJoined().pipe(
      filter((conversation: Conversation) => conversation.sid === conversationSid),
      first(),
    );
  }

  public conversationJoinedByIdOnce(conversationId: Uuid): Observable<Conversation> {
    return this.conversationJoined().pipe(
      filter((conversation: Conversation) => conversation.attributes.id === conversationId),
      first(),
    );
  }

  public conversationUpdated(conversation: Conversation): Observable<ConversationUpdate> {
    return fromEvent(conversation, ConversationEvent.UPDATED) as Observable<ConversationUpdate>;
  }

  public conversationRemoved(conversation: Conversation): Observable<ConversationUpdate> {
    return fromEvent(conversation, ConversationEvent.REMOVED) as Observable<ConversationUpdate>;
  }

  public getMessages(conversation: Conversation): Observable<Paginator<Message>> {
    return from(conversation.getMessages());
  }

  public sendMessage(conversation: Conversation, message: string | FormData, attributes?: MessageAttributes): Observable<number> {
    return from(conversation.sendMessage(message, attributes));
  }

  public updateLastReadMessageIndex(conversation: Conversation, messageIndex: number): Observable<number> {
    return from(conversation.updateLastReadMessageIndex(messageIndex));
  }

  public updateToken(token: string): Observable<Client> {
    return from(this.client.updateToken(token))
      .pipe(
        tap(client => this.client = client),
      );
  }

  private bindConnectionStateChangedEvent(): void {
    this.client.on(
      ClientEvent.CONNECTION_STATE_CHANGED,
      (state) => this._connectionStateChanged$.next(state as ConnectionState),
    );
  }
}
