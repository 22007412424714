import { jsonMember, jsonObject } from 'typedjson';
import { CallStatus } from '@api/conversations-api/models/enums/call-status.enum';
import { CallSettingsResponse } from '@api/conversations-api/models/responses/call-settings.response';
import { Uuid } from '@api/types/uuid.type';

@jsonObject
export abstract class BaseCallResponse {
  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember(String) public readonly status!: CallStatus;
  @jsonMember public readonly settings!: CallSettingsResponse;
  @jsonMember public readonly createdAt!: Date;
  @jsonMember public readonly acceptedAt?: Date;
  @jsonMember public readonly rejectedAt?: Date;
  @jsonMember public readonly missedAt?: Date;

  public isRingingStatus(): boolean {
    return this.status === CallStatus.RINGING;
  }

  public isAcceptedStatus(): boolean {
    return this.status === CallStatus.ACCEPTED;
  }

  public isRejectedStatus(): boolean {
    return this.status === CallStatus.REJECTED;
  }

  public isMissedStatus(): boolean {
    return this.status === CallStatus.MISSED;
  }

  public isCanceledStatus(): boolean {
    return this.status === CallStatus.RINGING_CANCELED;
  }
}
