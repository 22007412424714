import { ResourceCollectionRequestBuilder } from '@api/helpers/resource-collection-request.builder';
import { ContactFilter } from '@api/contacts-api/models/filters/contact.filter';
import { SortModel } from '@api/models/collection/requests/sort.model';
import { SortOrder } from '@api/models/collection/enum/sort-order.enum';
import { AttributeFilterModel } from '@api/models/collection/requests/attribute-filter.model';
import { Injectable } from '@angular/core';
import { ContactFilterField } from '@api/contacts-api/models/filters/contact-filter-field.enum';

@Injectable({
  providedIn: 'root',
})
export class ContactsCollectionRequestBuilder extends ResourceCollectionRequestBuilder<ContactFilter> {

  public addFilterHavingPrivateConversationOnly(): this {
    const filter = new AttributeFilterModel();

    filter.value = 'true';

    return this.addFilter(ContactFilterField.HAVING_PRIVATE_CONVERSATION, filter);
  }

  public addFilterNotHavingPrivateConversationOnly(): this {
    const filter = new AttributeFilterModel();

    filter.value = 'false';

    return this.addFilter(ContactFilterField.HAVING_PRIVATE_CONVERSATION, filter);
  }

  public addFilterGlobal(query: string): this {
    const filter = new AttributeFilterModel();

    filter.value = query;

    return this.addFilter('global', filter);
  }

  public addFilterByFullName(query: string): this {
    const filter = new AttributeFilterModel();

    filter.value = query;

    return this.addFilter('fullName', filter);
  }

  public addOrderHavingPrivateConversationFirst(): this {
    const sort = new SortModel();

    sort.orderBy = ContactFilterField.HAVING_PRIVATE_CONVERSATION;
    sort.order = SortOrder.DESC;

    this.addSort(sort);

    return this;
  }

  protected getFilterInstance(): ContactFilter {
    return new ContactFilter();
  }

}
