import { Injectable, OnDestroy } from '@angular/core';
import { ConfirmationMessage } from '@common/components/confirmation/interface/confirmation-message.interface';
import { ConfirmationService } from 'primeng/api';
import { ConfirmationTypeKey } from '@common/components/confirmation/enums/confirmation-type-key.enum';
import { ConfirmationMessageBuilder } from '@common/components/confirmation/service/confirmation-message.builder';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ConfirmationMessageService implements OnDestroy {

  private readonly DEFAULT_ACCEPT_LABEL = 'LABEL.YES';
  private readonly DEFAULT_REJECT_LABEL = 'LABEL.NO';

  private dialogMessagesQueue: ConfirmationMessage[] = [];
  private destroy$ = new Subject<void>();

  public constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly confirmationMessageBuilder: ConfirmationMessageBuilder,
    private readonly router: Router,
    private readonly translateService: TranslateService,
  ) {
    this.showQueuedMessagesOnNavigationEnd();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public showConfirmDialog(message: ConfirmationMessage): void {
    const { key } = message;

    if (!key) {
      message.key = ConfirmationTypeKey.DIALOG;
    }

    this.confirmationService.confirm(this.translateMessage(message));
  }

  public showConfirmPopup(target: EventTarget, message: ConfirmationMessage): void {
    const { key } = message;

    if (!key) {
      message.key = ConfirmationTypeKey.POPUP;
    }

    message.target = target;

    this.confirmationService.confirm(this.translateMessage(message));
  }

  public messageBuilder(): ConfirmationMessageBuilder {
    return this.confirmationMessageBuilder;
  }

  public enqueueConfirmDialog(message: ConfirmationMessage): void {
    this.dialogMessagesQueue.push(message);
  }

  public closeConfirmation(): void {
    this.confirmationService.close();
  }

  private showQueuedMessagesOnNavigationEnd(): void {
    this.router
      .events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe(() => setTimeout(() => this.showQueuedMessages()));
  }

  private showQueuedMessages(): void {
    let message;

    while (message = this.dialogMessagesQueue.pop()) {
      this.showConfirmDialog(message);
    }
  }

  private translateMessage(message: ConfirmationMessage): ConfirmationMessage {
    const { message: body, header, acceptLabel: accept, rejectLabel: reject } = message;
    const acceptLabel: string = accept ?? this.DEFAULT_ACCEPT_LABEL;
    const rejectLabel: string = reject ?? this.DEFAULT_REJECT_LABEL;

    return {
      ...message,
      header: this.translate(header),
      message: this.translate(body),
      acceptLabel: this.translate(acceptLabel),
      rejectLabel: this.translate(rejectLabel),
    };
  }

  private translate(value?: string): string | undefined {
    return value && this.translateService.instant(value);
  }

}
