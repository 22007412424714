import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoverLayoutFooterComponent } from './cover-layout-footer.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [CoverLayoutFooterComponent],
  exports: [CoverLayoutFooterComponent],
})
export class CoverLayoutFooterModule {
}
