export const TITLE = 'title';
export const BREADCRUMB = 'breadcrumb';
export const PROCEDURE = 'procedure';
export const PROCEDURE_STAGES = 'procedureStages';
export const PROCEDURE_STAGE = 'procedureStage';
export const PROCEDURE_STAGE_FIELDS = 'procedureStageFields';
export const PROCEDURE_STAGE_FIELD = 'procedureStageField';
export const ACTION_TOKEN = 'actionToken';
export const PASSWORD_COMPLEXITY_SETTINGS = 'passwordComplexitySettings';
export const PERMISSIONS = 'permissions';
export const ROUTE_PERMISSIONS = 'routePermissions';
export const ROUTE_NO_PERMISSION_REDIRECT_PATH = 'noPermissionRedirectPath';
export const USER = 'user';
export const DICTIONARY = 'dictionary';
export const CONTACT = 'contact';
export const DIRECTORY = 'directory';
export const DIRECTORIES_TREE = 'directoriesTree';
export const DIRECTORY_CHILDREN = 'directoryChildren';
export const DOCUMENT_DETAILS = 'documentDetails';
export const DOCUMENT_FILE_DETAILS = 'documentFileDetails';
export const PROPERTIES = 'properties';
export const ATTRIBUTES = 'attributes';
export const CONVERSATION = 'conversation';
export const CALL_SETTINGS = 'callSettings';
export const VIDEO = 'video';
export const DASHBOARD = 'dashboard';
export const REUSE_ROUTE = 'reuseRoute';
