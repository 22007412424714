import { ActionTokenApi } from '@api/cmuia-api/services/interfaces/action-token.api';
import { Uuid } from '@api/types/uuid.type';
import { Observable } from 'rxjs';
import { ActionTokenPayload, ActionTokenResponse } from '@api/cmuia-api/models/responses/action-token.response';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { apiCMUiACommon } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ActionTokenApiService implements ActionTokenApi {

  public constructor(
    private http: HttpClient,
    private apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public details(id: Uuid): Observable<ActionTokenResponse<ActionTokenPayload>> {
    const url = this.grabEndpointUrl(apiCMUiACommon.ACTION_TOKENS.DETAILS)
      .replace('{id}', id.toString());

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToModel(ActionTokenResponse),
    );
  }

  public confirm(id: Uuid): Observable<Object> {
    const url = this.grabEndpointUrl(apiCMUiACommon.ACTION_TOKENS.CONFIRM)
      .replace('{id}', id.toString());

    return this.http.post(url, {});
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiCMUiACommon.API_HOST_URL, endpoint].join('');
  }

}
