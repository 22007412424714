import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { TokenRetrieverService } from '@common/services/token-retriever.service';

@Injectable({
  providedIn: 'root',
})
export class ChatTokenRetrieverGuard implements CanActivate {

  public constructor(private tokenRetrieverService: TokenRetrieverService) {
  }

  public canActivate(): boolean {
    this.tokenRetrieverService.retrieveChatToken();

    return true;
  }

}
