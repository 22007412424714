import { Injectable } from '@angular/core';
import { RouteName } from '@core/routing/route-name.enum';
import { PermissionsService } from '@core/permissions/services/permissions.service';
import { RoutePermissionsProvider } from '@core/routing/provider/route-permissions.provider';

@Injectable({
  providedIn: 'root',
})
export class LoggedUserLandingPageRouteProvider {
  private orderedLandingRoutes: RouteName[] = [
    RouteName.DASHBOARD,
    RouteName.PANEL_TASKS,
    RouteName.PANEL_CONTACTS,
    RouteName.PANEL_DOCUMENTS,
    RouteName.PANEL_PROCEDURES,
    RouteName.MANAGE_USERS,
    RouteName.MANAGE_DICTIONARIES,
    RouteName.MANAGE_ATTRIBUTES,
    RouteName.MANAGE_SETTINGS,
    RouteName.MY_PROFILE,
  ];

  public constructor(private permissionsService: PermissionsService,
                     private routePermissionsProvider: RoutePermissionsProvider,
  ) {
  }

  public resolveLandingPageRoute(): RouteName | undefined {
    return this.getFirstAccessibleLandingRoute();
  }

  private getFirstAccessibleLandingRoute(): RouteName | undefined {
    return this.orderedLandingRoutes.find((possibleRoute) => this.hasPermissionToAccessRoute(possibleRoute));
  }

  private hasPermissionToAccessRoute(possibleRoute: RouteName): boolean {
    return this.permissionsService.checkPermissions(
      this.routePermissionsProvider.getPermissions(possibleRoute),
    );
  }
}
