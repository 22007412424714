import { Injectable } from '@angular/core';
import { SettingsApi } from '@api/settings-api/services/interfaces/settings.api';
import { HttpClient } from '@angular/common/http';
import { apiSettingsCommon } from '@environments/environment';
import { Observable } from 'rxjs';
import { SettingsCollectionItemResponse } from '@api/settings-api/models/responses/settings-collection-item.response';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { SettingsParameterCategory } from '@api/settings-api/models/enums/settings-parameter-category';
import { apiParams } from '@environments/const/environment.const';
import { UpdateSettingsRequest } from '@api/settings-api/models/requests/update-settings.request';

const { CATEGORY } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class SettingsApiService implements SettingsApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(category: SettingsParameterCategory): Observable<SettingsCollectionItemResponse[]> {
    const { LIST } = apiSettingsCommon;
    const url: string = this.grabEndpointUrl(LIST).replace(CATEGORY, category);

    return this
      .http
      .get<SettingsCollectionItemResponse[]>(url);
  }

  public update(request: UpdateSettingsRequest, category: SettingsParameterCategory): Observable<void> {
    const { UPDATE } = apiSettingsCommon;
    const url: string = this.grabEndpointUrl(UPDATE).replace(CATEGORY, category);
    const payload: Object = this.apiPayloadProcessor.toObject(request, UpdateSettingsRequest);

    return this
      .http
      .put<void>(url, payload);
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiSettingsCommon.API_HOST_URL, endpoint].join('');
  }

}
