import { Pipe, PipeTransform } from '@angular/core';
import { Icon } from '../enums/icon.enum';
import { IconCssClassProvider } from '../providers/icon-css-class.provider';


@Pipe({
  name: 'iconCssClass',
})
export class IconCssClassPipe implements PipeTransform {

  public constructor(private iconCssClassProvider: IconCssClassProvider) {
  }

  public transform(icon: Icon): string {
    return this.iconCssClassProvider.getCssClass(icon);
  }

}
