import { Injectable } from '@angular/core';
import { Permission } from '../models/permission.enum';
import { UserPermissions } from '../models/user-permissions.model';
import { UserContextModel } from '@core/auth/models/user-context.model';
import { Role } from '@core/auth/enums/role.enum';

@Injectable({
  providedIn: 'root',
})
export class PermissionsBuilder {
  private user?: UserContextModel;
  private userPermissions!: UserPermissions;

  public build(user?: UserContextModel): UserPermissions {
    this.prepare(user);
    this.resolveAllPermissions();

    return this.userPermissions;
  }

  private prepare(user?: UserContextModel): void {
    this.user = user;
    this.userPermissions = new UserPermissions([]);
  }

  private resolveAllPermissions(): void {
    let permissions: Map<Permission, boolean>;
    if (!this.user) {
      permissions = this.resolveAllPermissionsForNotLoggedUser();
    } else {
      permissions = this.resolveAllPermissionsForLoggedUser();
    }

    const currentPermissions: Permission[] = [];
    permissions.forEach((hasPermission: boolean, permission: Permission) => {
      if (hasPermission) {
        currentPermissions.push(permission);
      }
    });

    this.userPermissions.permissions = currentPermissions;
  }

  private resolveAllPermissionsForLoggedUser(): Map<Permission, boolean> {
    const permissions = new Map<Permission, boolean>();

    permissions.set(Permission.IS_LOGGED, true);
    permissions.set(Permission.IS_NOT_LOGGED, false);

    permissions.set(Permission.IS_ADMIN, this.hasRole(Role.ROLE_ADMIN, this.user));
    permissions.set(Permission.IS_NOT_ADMIN, !this.hasRole(Role.ROLE_ADMIN, this.user));

    permissions.set(Permission.IS_MANAGER, this.hasRole(Role.ROLE_MANAGER, this.user));
    permissions.set(Permission.IS_NOT_MANAGER, !this.hasRole(Role.ROLE_MANAGER, this.user));

    permissions.set(Permission.IS_EXPERT, this.hasRole(Role.ROLE_EXPERT, this.user));
    permissions.set(Permission.IS_NOT_EXPERT, !this.hasRole(Role.ROLE_EXPERT, this.user));

    permissions.set(Permission.IS_EMPLOYEE, this.hasRole(Role.ROLE_EMPLOYEE, this.user));
    permissions.set(Permission.IS_NOT_EMPLOYEE, !this.hasRole(Role.ROLE_EMPLOYEE, this.user));

    permissions.set(Permission.HAS_CONTACTS_ACCESS, this.user?.globalPermits.hasContactsAccess ?? false);
    permissions.set(Permission.HAS_CHAT_AND_VIDEO_CONVERSATIONS_ACCESS, this.user?.globalPermits.hasChatAndVideoConversationsAccess ?? false);
    permissions.set(Permission.HAS_USERS_ACCESS, this.user?.globalPermits.hasUsersAccess ?? false);
    permissions.set(Permission.HAS_PERMISSION_GROUPS_ACCESS, this.user?.globalPermits.hasPermissionGroupsAccess ?? false);
    permissions.set(Permission.HAS_DICTIONARIES_ACCESS, this.user?.globalPermits.hasDictionariesAccess ?? false);
    permissions.set(Permission.HAS_ATTRIBUTES_ACCESS, this.user?.globalPermits.hasAttributesAccess ?? false);
    permissions.set(Permission.HAS_DASHBOARD_ACCESS, this.user?.globalPermits.hasDashboardAccess ?? false);
    permissions.set(Permission.HAS_PROCEDURES_ACCESS, this.user?.globalPermits.hasProceduresAccess ?? false);
    permissions.set(Permission.HAS_TASKS_ACCESS, this.user?.globalPermits.hasTasksAccess ?? false);
    permissions.set(Permission.HAS_DOCUMENTS_ACCESS, this.user?.globalPermits.hasDocumentsAccess ?? false);
    permissions.set(Permission.HAS_ALL_TASKS_ACCESS, this.user?.globalPermits.hasAllTasksAccess ?? false);
    permissions.set(Permission.HAS_MY_TASKS_ACCESS, this.user?.globalPermits.hasMyTasksAccess ?? false);
    permissions.set(Permission.HAS_SETTINGS_ACCESS, this.user?.globalPermits.hasSettingsAccess ?? false);

    permissions.set(Permission.CAN_CREATE_USERS, this.user?.globalPermits.canCreateUsers ?? false);
    permissions.set(Permission.CAN_CREATE_PERMISSION_GROUPS, this.user?.globalPermits.canCreatePermissionGroups ?? false);
    permissions.set(Permission.CAN_CREATE_ATTRIBUTES, this.user?.globalPermits.canCreateAttributes ?? false);
    permissions.set(Permission.CAN_CREATE_PROCEDURES, this.user?.globalPermits.canCreateProcedures ?? false);
    permissions.set(Permission.CAN_CREATE_TASKS, this.user?.globalPermits.canCreateTasks ?? false);
    permissions.set(Permission.CAN_UPDATE_OWN_PROFILE_DATA, this.user?.userOwnContextOperations.canUpdateProfile ?? false);
    permissions.set(Permission.CAN_CHANGE_OWN_PASSWORD, this.user?.userOwnContextOperations.canChangePassword ?? false);
    permissions.set(Permission.CAN_AUTH_BY_QR_CODE, this.user?.userOwnContextOperations.canAuthByQrCode ?? false);
    permissions.set(Permission.CAN_CREATE_DASHBOARDS, this.user?.globalPermits.canCreateDashboards ?? false);

    return permissions;
  }

  private resolveAllPermissionsForNotLoggedUser(): Map<Permission, boolean> {
    const permissions = new Map<Permission, boolean>();

    permissions.set(Permission.IS_LOGGED, false);
    permissions.set(Permission.IS_NOT_LOGGED, true);

    return permissions;
  }

  private hasRole(role: Role, user?: UserContextModel): boolean {
    if (!user || !user.roles) {
      return false;
    }

    return user.roles.includes(role);
  }

}
