import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';

@jsonObject
export class TreeExpandedNodeQueryParams {

  @jsonMember public readonly depth!: number;
  @jsonMember(String) public readonly id!: Uuid;

  public constructor(
    id: Uuid,
    depth: number,
  ) {
    this.id = id;
    this.depth = depth;
  }

}
