import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { CollectionRequest } from '@api/models/collection/requests/collection.request';
import { TaskActivitiesFilter } from '@api/procedures-api/models/filters/task-activities.filter';
import { Uuid } from '@api/types/uuid.type';
import { Observable } from 'rxjs';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';
import { apiProceduresCommon } from '@environments/environment';
import { apiParams } from '@environments/const/environment.const';
import { TaskActivitiesApi } from '@api/procedures-api/services/interfaces/task-activities.api';
import { TaskActivityResponse } from '../models/responses/task-activity.response';

const { TASK_ID, ID } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class TaskActivitiesApiService implements TaskActivitiesApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(request: CollectionRequest<TaskActivitiesFilter>, id: Uuid): Observable<CollectionResponse<TaskActivityResponse>> {
    const { ACTIVITIES: { LIST } } = apiProceduresCommon.TASKS;
    const requestQueryString: string = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, TaskActivitiesFilter);
    const url: string = `${this.grabEndpointUrl(LIST)
      .replace(TASK_ID, id.toString())}?${requestQueryString}`;

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToCollection(TaskActivityResponse),
      );
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiProceduresCommon.API_HOST_URL, endpoint].join('');
  }

}
