import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire/compat';
import { firebase } from '@environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';

@NgModule({
  imports: [
    CommonModule,

    AngularFireModule.initializeApp(firebase),
    AngularFireMessagingModule,
  ],
})
export class FcmModule {
}
