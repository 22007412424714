<div class="d-flex flex-column align-items-center p-5">
  <span class="font-weight-bold audio-video-call-dialog__call-status">
    <i class="mr-1 audio-video-call-dialog__call-status__icon"
       [ngClass]="ICON.FILLED_PHONE | iconCssClass">
    </i>
    {{callInformation | translate}}
  </span>
  <ng-container *ngIf="isGroupMeeting">
    <div class="audio-video-call-dialog__participants mt-5">
      <app-contact-avatar-group
        [desiredParticipants]="desiredParticipants">
      </app-contact-avatar-group>
    </div>
    <span
      class="mt-3 font-weight-bold audio-video-call-dialog__call-title mw-100 text-truncate">
      {{meetingName}}
    </span>
    <span
      class="mt-1 font-weight-bold audio-video-call-dialog__call-subtitle">
      {{METING_SUB_TITLE | translate}}
    </span>
  </ng-container>
  <ng-container *ngIf="!isGroupMeeting">
    <app-contact-avatar
      class="mt-5"
      [contact]="privateCallWithContact"
      size="xlarge">
    </app-contact-avatar>
    <span
      class="mt-3 font-weight-bold audio-video-call-dialog__call-title mw-100 text-truncate">
      {{privateCallWithContact.fullName}}
    </span>
    <span
      class="mt-1 font-weight-bold audio-video-call-dialog__call-subtitle">
      {{privateCallWithContact.jobName}}
    </span>
  </ng-container>
  <div class="d-flex mt-5 audio-video-call-dialog__actions-container">
    <button
      *ngIf="callMode === CALL_MODE.INCOMING"
      pRipple
      pButton
      class="p-button-rounded p-button-lg audio-video-call-dialog__accept-call"
      [icon]="ICON.FILLED_PHONE | iconCssClass"
      (click)="onAcceptCallClick()">
    </button>
    <button
      pRipple
      pButton
      class="p-button-rounded p-button-lg audio-video-call-dialog__decline-call"
      [icon]="ICON.FILLED_PHONE | iconCssClass"
      (click)="onDeclineCallClick()">
    </button>
  </div>
</div>
