import { apiParams } from '@environments/const/environment.const';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { apiProceduresManagement } from '@environments/environment';
import { Uuid } from '@app/api/types/uuid.type';
import { Observable } from 'rxjs';
import { IdResponse } from '@api/interfaces/id.response';
import { ProcedureFieldsApi } from '@api/procedures-api/services/interfaces/procedure-fields.api';
import { ProcedureFieldUpdateRequest } from '@api/procedures-api/models/requests/procedure-field-update.request';
import { ProcedureFieldCreateRequest } from '@api/procedures-api/models/requests/procedure-field-create.request';
import { ProcedureFieldResponse } from '@api/procedures-api/models/responses/procedure-field.response';
import { ProcedureFieldDetailsResponse } from '@api/procedures-api/models/responses/procedure-field-details.response';

const { STAGE_ID, FIELD_ID } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class ProcedureFieldsApiService implements ProcedureFieldsApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public create(stageId: Uuid, request: ProcedureFieldCreateRequest): Observable<IdResponse> {
    const { FIELDS: { CREATE } } = apiProceduresManagement.PROCEDURES;
    const url = this
      .grabEndpointUrl(CREATE)
      .replace(STAGE_ID, stageId.toString());
    const body = this.apiPayloadProcessor.toObject(request, ProcedureFieldCreateRequest);

    return this
      .http
      .post(url, body)
      .pipe(this.apiPayloadProcessor.mapToModel(IdResponse));
  }

  public update(stageId: Uuid, fieldId: Uuid, request: ProcedureFieldUpdateRequest): Observable<Object> {
    const { FIELDS: { UPDATE } } = apiProceduresManagement.PROCEDURES;
    const url = this
      .grabEndpointUrl(UPDATE)
      .replace(STAGE_ID, stageId.toString())
      .replace(FIELD_ID, fieldId.toString());
    const body = this.apiPayloadProcessor.toObject(request, ProcedureFieldUpdateRequest);

    return this
      .http
      .put(url, body);
  }

  public partialUpdate(stageId: Uuid, fieldId: Uuid, partialRequest: Partial<ProcedureFieldUpdateRequest>): Observable<Object> {
    const { FIELDS: { UPDATE } } = apiProceduresManagement.PROCEDURES;
    const url = this
      .grabEndpointUrl(UPDATE)
      .replace(STAGE_ID, stageId.toString())
      .replace(FIELD_ID, fieldId.toString());
    const request: ProcedureFieldUpdateRequest = ProcedureFieldUpdateRequest.fromPartial(partialRequest);
    const body = this.apiPayloadProcessor.toObject(request, ProcedureFieldUpdateRequest);

    return this
      .http
      .patch(url, body);
  }

  public delete(stageId: Uuid, fieldId: Uuid): Observable<Object> {
    const { FIELDS: { DELETE } } = apiProceduresManagement.PROCEDURES;
    const url = this
      .grabEndpointUrl(DELETE)
      .replace(STAGE_ID, stageId.toString())
      .replace(FIELD_ID, fieldId.toString());

    return this
      .http
      .delete(url);
  }

  public details(stageId: Uuid, fieldId: Uuid): Observable<ProcedureFieldDetailsResponse> {
    const { FIELDS: { DETAILS } } = apiProceduresManagement.PROCEDURES;
    const url = this
      .grabEndpointUrl(DETAILS)
      .replace(STAGE_ID, stageId.toString())
      .replace(FIELD_ID, fieldId.toString());

    return this
      .http
      .get(url)
      .pipe(this.apiPayloadProcessor.mapToModel(ProcedureFieldDetailsResponse));
  }

  public list(stageId: Uuid): Observable<ProcedureFieldResponse[]> {
    const { FIELDS: { LIST } } = apiProceduresManagement.PROCEDURES;
    const url = this
      .grabEndpointUrl(LIST)
      .replace(STAGE_ID, stageId.toString());

    return this
      .http
      .get(url)
      .pipe(this.apiPayloadProcessor.mapToModelArray(ProcedureFieldResponse));
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiProceduresManagement.API_HOST_URL, endpoint].join('');
  }

}
