import { DashboardsApi } from '@api/dashboards-api/services/interfaces/dashboards.api';
import { CollectionRequest } from '@app/api/models/collection/requests/collection.request';
import { DashboardFilter } from '@api/dashboards-api/models/filters/dashboard.filter';
import { Observable } from 'rxjs';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { apiReportsCommon } from '@environments/environment';
import { DashboardResponse } from '@api/dashboards-api/models/responses/dashboard.response';
import { Uuid } from '@api/types/uuid.type';
import { apiParams } from '@environments/const/environment.const';
import { DashboardPermissionResponse } from '@api/dashboards-api/models/responses/dashboard-permission.response';
import { LOADING_INDICATOR_ENABLED } from '@core/layouts/components/loading-indicator/interceptors/loading-interceptor-context-params';

const { ID } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class DashboardsApiService implements DashboardsApi {

  public constructor(
    private http: HttpClient,
    private apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(request?: CollectionRequest<DashboardFilter>): Observable<CollectionResponse<DashboardResponse>> {
    let url = this.grabEndpointUrl(apiReportsCommon.DASHBOARDS.LIST);
    if (request) {
      const requestQueryString = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, DashboardFilter);
      url = url + '?' + requestQueryString;
    }

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToCollection(DashboardResponse),
    );
  }

  public details(id: Uuid): Observable<DashboardResponse> {
    const url = this.grabEndpointUrl(apiReportsCommon.DASHBOARDS.DETAILS).replace(ID, id.toString());

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToModel(DashboardResponse),
    );
  }

  public getDefault(): Observable<DashboardResponse> {
    const url = this.grabEndpointUrl(apiReportsCommon.DASHBOARDS.GET_DEFAULT);

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToModel(DashboardResponse),
    );
  }

  public setDefault(id: Uuid): Observable<void> {
    const url = this.grabEndpointUrl(apiReportsCommon.DASHBOARDS.SET_DEFAULT);

    return this.http.post<void>(url, {
      dashboardId: id,
    }, {
      context: new HttpContext().set(LOADING_INDICATOR_ENABLED, false),
    });
  }

  public delete(id: Uuid): Observable<void> {
    const url = this.grabEndpointUrl(apiReportsCommon.DASHBOARDS.DELETE).replace(ID, id.toString());

    return this.http.delete<void>(url);
  }

  public dashboardPermissions(id: Uuid): Observable<DashboardPermissionResponse[]> {
    const url = this.grabEndpointUrl(apiReportsCommon.DASHBOARDS.DASHBOARD_PERMISSIONS).replace(ID, id.toString());

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToModelArray(DashboardPermissionResponse),
    );
  }

  public permissions(): Observable<DashboardPermissionResponse[]> {
    const url = this.grabEndpointUrl(apiReportsCommon.DASHBOARDS.PERMISSIONS_LIST);

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToModelArray(DashboardPermissionResponse),
    );
  }

  public setPermissions(id: Uuid, permissionsIds: Uuid[]): Observable<void> {
    const url = this.grabEndpointUrl(apiReportsCommon.DASHBOARDS.SET_DASHBOARD_PERMISSIONS).replace(ID, id.toString());

    return this.http.put<void>(url, {
      permissionsIds: permissionsIds,
    });
  }

  public devExpressApiGate(): string {
    return this.grabEndpointUrl(apiReportsCommon.DEV_EXPRESS_API_GATE);
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiReportsCommon.API_HOST_URL, endpoint].join('');
  }

}
