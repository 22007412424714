import { jsonMember, jsonObject } from 'typedjson';
import { RepositoryFileThumbStatus } from '@api/documents-api/models/enums/repository-file-thumb-status.enum';

@jsonObject
export class RepositoryFileThumbResponse {

  @jsonMember public readonly url?: string;
  @jsonMember(String) public readonly status!: RepositoryFileThumbStatus;

}
