import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { CollectionRequest } from '@api/models/collection/requests/collection.request';
import { TaskFilter } from '@api/procedures-api/models/filters/task.filter';
import { Observable } from 'rxjs';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';
import { apiProceduresManagement } from '@environments/environment';
import { TaskProceduresApi } from '@api/procedures-api/services/interfaces/task-procedures.api';
import { ProcedureFilter } from '@api/procedures-api/models/filters/procedure.filter';
import { ProcedureResponse } from '@api/procedures-api/models/responses/procedure.response';

@Injectable({
  providedIn: 'root',
})
export class TaskProceduresApiService implements TaskProceduresApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(request: CollectionRequest<ProcedureFilter>): Observable<CollectionResponse<ProcedureResponse>> {
    const { PROCEDURES } = apiProceduresManagement.TASKS;
    const requestQueryString: string = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, TaskFilter);
    const url: string = `${this.grabEndpointUrl(PROCEDURES)}?${requestQueryString}`;

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToCollection(ProcedureResponse),
      );
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiProceduresManagement.API_HOST_URL, endpoint].join('');
  }

}
