export enum CollectionEntryFilterField {

  ID = 'id',
  PARENT = 'parent',
  TYPE = 'type',
  SIZE = 'size',
  NAME = 'name',
  CREATED_AT = 'createdAt',
  ATTRIBUTE = 'attribute',

}
