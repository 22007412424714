import { Permission } from './permission.enum';

export class UserPermissions {

  public permissions: Permission[];

  public constructor(permissions: Permission[]) {
    this.permissions = permissions;
  }
}
