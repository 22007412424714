import { _commonEnvironment } from './_common-environment';

export const environment = {
  production: true,
};

export const apiCMUiACommon = {
  ..._commonEnvironment.apiCMUiACommon,
  ...{
    // Endpoints overrides
    API_HOST_URL: 'https://cmuia.arsolutions.test.lemisoft.pl/api',
  },
};

export const apiCMUiAAdmin = {
  ..._commonEnvironment.apiCMUiAAdmin,
  ...{
    // Endpoints overrides
    API_HOST_URL: 'https://cmuia.arsolutions.test.lemisoft.pl/api/admin',
  },
};

export const apiDictionariesCommon = {
  ..._commonEnvironment.apiDictionariesCommon,
  ...{
    // Endpoints overrides
    API_HOST_URL: 'https://dictionaries.arsolutions.test.lemisoft.pl/api',
  },
};

export const apiDirectoriesCommon = {
  ..._commonEnvironment.apiDirectoriesCommon,
  ...{
    // Endpoints overrides
    API_HOST_URL: 'https://documents.arsolutions.test.lemisoft.pl/api',
  },
};

export const apiConversationsCommon = {
  ..._commonEnvironment.apiConversationsCommon,
  ...{
    // Endpoints overrides
    API_HOST_URL: 'https://conversations.arsolutions.test.lemisoft.pl/api',
  },
};

export const apiProceduresManagement = {
  ..._commonEnvironment.apiProceduresManagement,
  ...{
    // Endpoints overrides
    API_HOST_URL: 'https://procedures.arsolutions.test.lemisoft.pl/api/management',
  },
};

export const apiProceduresCommon = {
  ..._commonEnvironment.apiProceduresCommon,
  ...{
    // Endpoints overrides
    API_HOST_URL: 'https://procedures.arsolutions.test.lemisoft.pl/api',
  },
};

export const apiNotifierCommon = {
  ..._commonEnvironment.apiNotifierCommon,
  ...{
    // Endpoints overrides
    API_HOST_URL: 'https://notifier.arsolutions.test.lemisoft.pl/api',
  },
};

export const apiReportsCommon = {
  ..._commonEnvironment.apiReportsCommon,
  ...{
    // Endpoints overrides
    API_HOST_URL: 'https://reports.arsolutions.test.lemisoft.pl/api',
  },
};

export const apiSettingsCommon = {
  ..._commonEnvironment.apiSettingsCommon,
  ...{
    // Endpoints overrides
    API_HOST_URL: 'https://settings.arsolutions.test.lemisoft.pl/api',
  },
};


export const firebase = {
  ..._commonEnvironment.firebase,
  ...{
    // Firebase overrides
  },
};

