import { ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Icon } from '@core/assets/enums/icon.enum';
import { ContactAvailability } from '@api/contacts-api/models/enums/contact-availability.enum';
import { CONVERSATIONS_LIVE_DATA_API } from '@api/conversations-api/services/tokens/conversations-live-data-api.token';
import { ConversationsLiveDataApi } from '@api/conversations-api/services/interfaces/conversations-live-data.api';
import { ContactResponse } from '@api/contacts-api/models/responses/contact.response';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-contact-avatar',
  templateUrl: './contact-avatar.component.html',
  styleUrls: ['./contact-avatar.component.scss'],
})
export class ContactAvatarComponent implements OnChanges, OnDestroy {

  private readonly AVATAR_PLACEHOLDER_ICON = Icon.USER;

  @Input() public contact!: ContactResponse;
  @Input() public size: 'large' | 'xlarge' | 'medium' = 'large';
  @Input() public showStatus = true;
  @Input() public label?: string;

  public avatarIcon?: Icon;
  public avatarUrl?: string;
  public badgeSeverity?: string;
  public mediumSize?: boolean;

  private availabilityStatusBadgeSeverities = {
    [ContactAvailability.ONLINE]: 'success',
    [ContactAvailability.OFFLINE]: 'secondary',
    [ContactAvailability.BUSY]: 'danger',
  };
  private destroyed$ = new Subject<void>();

  public constructor(
    @Inject(CONVERSATIONS_LIVE_DATA_API) private readonly conversationsLiveDataService: ConversationsLiveDataApi,
    private readonly cd: ChangeDetectorRef,
  ) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.initView();

    if (this.showStatus) {
      this.subscribeForAvailabilityStatusUpdates();
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private initView(): void {
    if (this.contact?.avatar) {
      this.avatarUrl = this.contact.avatar;
      this.avatarIcon = undefined;
    } else {
      this.avatarUrl = undefined;
      this.avatarIcon = this.AVATAR_PLACEHOLDER_ICON;
    }

    if (this.showStatus) {
      this.badgeSeverity = this.availabilityStatusBadgeSeverities[this.contact?.availabilityStatus];
    }
    this.mediumSize = this.size === 'medium';
  }

  private subscribeForAvailabilityStatusUpdates(): void {
    this
      .conversationsLiveDataService
      .contactUpdated()
      .pipe(
        filter(contact => contact.id === this.contact?.id),
        takeUntil(this.destroyed$),
      )
      .subscribe((contact: ContactResponse) => {
        this.badgeSeverity = this.availabilityStatusBadgeSeverities[contact.availabilityStatus];
        this.cd.detectChanges();
      });
  }

}
