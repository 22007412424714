import { Component } from '@angular/core';

@Component({
  selector: 'app-cover-layout-header',
  templateUrl: './cover-layout-header.component.html',
  styleUrls: ['./cover-layout-header.component.scss'],
})
export class CoverLayoutHeaderComponent {

  public constructor() {
  }

}
