import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoverLayoutModule } from './cover-layout/cover-layout.module';
import { DashboardLayoutModule } from './dashboard-layout/dashboard-layout.module';
import { LoadingIndicatorModule } from './components/loading-indicator/loading-indicator.module';


@NgModule({
  imports: [
    CommonModule,
    CoverLayoutModule,
    DashboardLayoutModule,
    LoadingIndicatorModule,
  ],
  declarations: [],
  exports: [
    CoverLayoutModule,
    DashboardLayoutModule,
    LoadingIndicatorModule,
  ],
})
export class LayoutsModule {
}
