import { Inject, Injectable } from '@angular/core';
import { AUTH_API } from '@api/cmuia-api/services/tokens/auth-api.token';
import { AuthApi } from '@api/cmuia-api/services/interfaces/auth.api';
import { Observable, of } from 'rxjs';
import { SignInResponse } from '@api/cmuia-api/models/responses/sign-in.response';
import { SignInRequest } from '@api/cmuia-api/models/requests/sign-in.request';
import { finalize, tap } from 'rxjs/operators';
import { AppLoggedUserResponse } from '@api/cmuia-api/models/responses/app-logged-user.response';
import { TokenStorageService } from './token-storage.service';
import { RefreshResponse } from '@api/cmuia-api/models/responses/refresh.response';
import { RefreshTokenRequest } from '@api/cmuia-api/models/requests/refresh-token.request';
import { UserContextModel } from '../models/user-context.model';
import { UserModel } from '../models/user.model';
import { UserContextService } from './user-context.service';
import { USER_PROFILE_API } from '@api/cmuia-api/services/tokens/user-profile-api.token';
import { UserProfileApi } from '@api/cmuia-api/services/interfaces/user-profile.api';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public constructor(@Inject(AUTH_API) private authApiService: AuthApi,
                     @Inject(USER_PROFILE_API) private userProfileApiService: UserProfileApi,
                     private tokenStorageService: TokenStorageService,
                     private userContextService: UserContextService) {
  }

  public signIn(username: string, password: string): Observable<SignInResponse> {
    const request = new SignInRequest(username, password);

    return this.authApiService.signIn(request).pipe(
      tap((response: SignInResponse) => {
        const { token, refreshToken } = response;

        this.storeTokens(token, refreshToken);
      }),
    );
  }

  public signOut(): Observable<Object> {
    return of(true).pipe(
      finalize(() => {
        this.destroyUserSession();
      }),
    );
  }

  public me(): Observable<AppLoggedUserResponse> {
    return this.userProfileApiService.me().pipe(
      tap((response: AppLoggedUserResponse) => {
        this.handleUserInfoResponse(response);
      }),
    );
  }

  public refresh(): Observable<RefreshResponse> {
    const request = new RefreshTokenRequest(this.tokenStorageService.getRefreshToken() || '');

    return this.authApiService.refresh(request).pipe(
      tap((response: RefreshResponse) => {
        const { token, refreshToken } = response;

        this.storeTokens(token, refreshToken);
      }));
  }

  private destroyUserSession(): void {
    this.clearTokens();
    this.clearUserContext();
  }

  private storeTokens(token: string, refreshToken: string): void {
    this.tokenStorageService.storeToken(token);
    this.tokenStorageService.storeRefreshToken(refreshToken);
  }

  private clearTokens(): void {
    this.tokenStorageService.clearToken();
    this.tokenStorageService.clearRefreshToken();
  }

  public hasRefreshToken(): boolean {
    return !!this.tokenStorageService.getRefreshToken();
  }

  private handleUserInfoResponse(response: AppLoggedUserResponse): void {
    const userContext = this.prepareUserContext(response);

    this.storeUserContext(userContext);
  }

  private prepareUserContext(response: AppLoggedUserResponse): UserContextModel {
    const user = new UserModel(
      response.id,
      response.email,
      response.firstName,
      response.lastName,
      response.jobName,
      response.department,
      response.avatar,
    );

    return new UserContextModel(user, response.roles, response.globalPermits, response.contextOperations);
  }

  private storeUserContext(model: UserContextModel): void {
    this.userContextService.set(model);
  }

  private clearUserContext(): void {
    this.userContextService.clear();
  }
}
