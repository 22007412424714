import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CONTACTS_API } from '@api/contacts-api/services/tokens/contacts-api.token';
import { ContactsApiService } from '@api/contacts-api/services/contacts-api.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  declarations: [],
  providers: [
    {
      provide: CONTACTS_API,
      useClass: ContactsApiService,
    },
  ],
})
export class ContactsApiModule {
}
