import { Injectable } from '@angular/core';
import { VideoApi } from '@api/conversations-api/services/interfaces/video.api';
import { Uuid } from '@api/types/uuid.type';
import { Observable } from 'rxjs';
import { VideoRoomResponse } from '@api/conversations-api/models/responses/video-room.response';
import { CreateVideoRoomRequest } from '@api/conversations-api/models/requests/create-video-room.request';
import { VideoTokenResponse } from '@api/conversations-api/models/responses/video-token.response';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { apiConversationsCommon } from '@environments/environment';
import { apiParams } from '@environments/const/environment.const';
import { IdResponse } from '@api/interfaces/id.response';
import { InviteParticipantsRequest } from '@api/conversations-api/models/requests/invite-participants.request';
import { CallDetailsResponse } from '@api/conversations-api/models/responses/call-details.response';
import { CreateVideoRoomResponse } from '@api/conversations-api/models/responses/create-video-room.response';
import { CancelOutgoingCallsRequest } from '@api/conversations-api/models/requests/cancel-outgoing-calls.request';
import { CanceledCallsResponse } from '@api/conversations-api/models/responses/canceled-calls.response';

@Injectable({
  providedIn: 'root',
})
export class VideoApiService implements VideoApi {

  public constructor(
    private http: HttpClient,
    private apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public getToken(): Observable<VideoTokenResponse> {
    const url = this.grabEndpointUrl(apiConversationsCommon.VIDEO.TOKEN);

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToModel(VideoTokenResponse),
    );
  }

  public createRoomAndCall(request: CreateVideoRoomRequest): Observable<CreateVideoRoomResponse> {
    const url = this.grabEndpointUrl(apiConversationsCommon.VIDEO.ROOM.CALL);
    const payload = this.apiPayloadProcessor.toObject(request, CreateVideoRoomRequest);

    return this.http.post(url, payload).pipe(
      this.apiPayloadProcessor.mapToModel(CreateVideoRoomResponse),
    );
  }

  public acceptIncomingCall(callId: Uuid): Observable<VideoRoomResponse> {
    const url = this.grabEndpointUrl(apiConversationsCommon.VIDEO.ROOM.ACCEPT_CALL).replace(apiParams.ID, callId.toString());

    return this.http.post(url, {}).pipe(
      this.apiPayloadProcessor.mapToModel(VideoRoomResponse),
    );
  }

  public rejectIncomingCall(callId: Uuid): Observable<void> {
    const url = this.grabEndpointUrl(apiConversationsCommon.VIDEO.ROOM.REJECT_CALL).replace(apiParams.ID, callId.toString());

    return this.http.post<void>(url, {});
  }

  public cancelOutgoingCall(callId: Uuid): Observable<void> {
    const url = this.grabEndpointUrl(apiConversationsCommon.VIDEO.ROOM.CANCEL_CALL).replace(apiParams.ID, callId.toString());

    return this.http.post<void>(url, {});
  }

  public cancelMultipleOutgoingCalls(request: CancelOutgoingCallsRequest): Observable<CanceledCallsResponse> {
    const url = this.grabEndpointUrl(apiConversationsCommon.VIDEO.ROOM.CANCEL_MULTIPLE_CALLS);
    const payload = this.apiPayloadProcessor.toObject(request, CancelOutgoingCallsRequest);

    return this.http.post<void>(url, payload).pipe(
      this.apiPayloadProcessor.mapToModel(CanceledCallsResponse),
    );
  }

  public startRecording(roomId: Uuid): Observable<IdResponse> {
    const url = this.grabEndpointUrl(apiConversationsCommon.VIDEO.ROOM.START_RECORDING).replace(apiParams.ID, roomId.toString());

    return this.http.post(url, {}).pipe(
      this.apiPayloadProcessor.mapToModel(IdResponse),
    );
  }

  public endRecording(roomId: Uuid): Observable<void> {
    const url = this.grabEndpointUrl(apiConversationsCommon.VIDEO.ROOM.END_RECORDING).replace(apiParams.ID, roomId.toString());

    return this.http.post<void>(url, {});
  }

  public roomDetails(roomId: Uuid): Observable<VideoRoomResponse> {
    const url = this.grabEndpointUrl(apiConversationsCommon.VIDEO.ROOM.DETAILS).replace(apiParams.ID, roomId.toString());

    return this.http.get<VideoRoomResponse>(url);
  }

  public callDetails(callId: Uuid): Observable<CallDetailsResponse> {
    const url = this.grabEndpointUrl(apiConversationsCommon.VIDEO.ROOM.CALL_DETAILS).replace(apiParams.ID, callId.toString());

    return this.http.get<CallDetailsResponse>(url);
  }

  public inviteParticipants(roomId: Uuid, request: InviteParticipantsRequest): Observable<void> {
    const url = this.grabEndpointUrl(apiConversationsCommon.VIDEO.ROOM.INVITE_PARTICIPANTS).replace(apiParams.ID, roomId.toString());
    const payload = this.apiPayloadProcessor.toObject(request, InviteParticipantsRequest);

    return this.http.post<void>(url, payload);
  }

  public saveScreenShot(roomId: Uuid, picture: Blob): Observable<IdResponse> {
    const url = this.grabEndpointUrl(apiConversationsCommon.VIDEO.ROOM.UPLOAD_SCREENSHOT).replace(apiParams.ID, roomId.toString());

    const formData = new FormData();
    formData.append('file', picture);

    return this.http.post(url, formData).pipe(
      this.apiPayloadProcessor.mapToModel(IdResponse),
    );
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiConversationsCommon.API_HOST_URL, endpoint].join('');
  }
}
