import { Injectable } from '@angular/core';
import { Icon } from '../enums/icon.enum';

@Injectable({
  providedIn: 'root',
})
export class IconCssClassProvider {

  private icons: { [key in Icon]: string } = {
    [Icon.MENU_DASHBOARD]: 'app-icon-home',
    [Icon.MENU_TASKS]: 'app-icon-clipboard',
    [Icon.MENU_PROCEDURES]: 'app-icon-calendar-week',
    [Icon.MENU_CONTACTS]: 'app-icon-users',
    [Icon.MENU_DOCUMENTS]: 'app-icon-folder',
    [Icon.MENU_CAMERA]: 'app-icon-camera',
    [Icon.MENU_USERS]: 'app-icon-persona',
    [Icon.MENU_DICTIONARIES]: 'app-icon-slider-circle-h',
    [Icon.MENU_ATTRIBUTES]: 'app-icon-tray',
    [Icon.MENU_CONFIGURATION_PARAMETERS]: 'app-icon-cog',

    [Icon.HEADER_NOTIFICATION_BELL]: 'app-icon-bell',
    [Icon.HEADER_NOTIFICATION_CHAT]: 'app-icon-comment',

    [Icon.HAMBURGER]: 'app-icon-hamburger-menu',
    [Icon.SETTINGS]: 'app-icon-cog',
    [Icon.PENCIL]: 'app-icon-pencil',
    [Icon.EYE]: 'app-icon-eye',
    [Icon.MORE]: 'app-icon-more-h',
    [Icon.TRASH]: 'app-icon-trash',
    [Icon.REFRESH_DOUBLE]: 'app-icon-refresh-double',
    [Icon.CLOSE]: 'app-icon-close',
    [Icon.CHECK_MARK]: 'app-icon-checkmark',
    [Icon.USER]: 'app-icon-user',
    [Icon.USERS]: 'app-icon-users',
    [Icon.PHONE]: 'app-icon-phone',
    [Icon.MICROPHONE]: 'app-icon-microphone',
    [Icon.MICROPHONE_MUTE]: 'app-icon-microphone-mute',
    [Icon.COMMENT]: 'app-icon-comment',
    [Icon.VIDEO]: 'app-icon-video',
    [Icon.COPY]: 'app-icon-copy',
    [Icon.COPY_ALT]: 'app-icon-copy-alt',
    [Icon.SEARCH]: 'app-icon-search',
    [Icon.CHECK_MARK_CIRCLE]: 'app-icon-checkmark-circle',
    [Icon.USER_CIRCLE]: 'app-icon-user-circle',
    [Icon.CLOSE_CIRCLE]: 'app-icon-close-circle',
    [Icon.CLEAR_FILTER]: 'pi pi-filter-slash',
    [Icon.AT]: 'app-icon-at-2',
    [Icon.SMILE]: 'app-icon-smile-2',
    [Icon.PAPERCLIP]: 'app-icon-paperclip-2',
    [Icon.TILTED_PAPERCLIP]: 'app-icon-paperclip',
    [Icon.SHARE]: 'app-icon-share',
    [Icon.DRAG]: 'app-icon-drag',

    [Icon.MESSAGE_SUCCESS]: 'pi pi-check',
    [Icon.MESSAGE_INFO]: 'pi pi-info-circle',
    [Icon.MESSAGE_WARN]: 'pi pi-exclamation-triangle',
    [Icon.MESSAGE_ERROR]: 'pi pi-times-circle',

    [Icon.ARROW_LEFT]: 'app-icon-arrow-left',
    [Icon.ARROW_RIGHT]: 'app-icon-arrow-right',
    [Icon.ARROW_TO_TOP]: 'app-icon-arrow-to-top',
    [Icon.ARROW_COLLAPSE_LEFT]: 'app-icon-collapse-left',

    [Icon.ALERT_INFO]: 'app-icon-info-circle',
    [Icon.ALERT_SUCCESS]: 'app-icon-checkmark-circle',
    [Icon.ALERT_WARNING]: 'app-icon-exclamation-mark',
    [Icon.ALERT_DANGER]: 'app-icon-exclamation-mark',
    [Icon.ALERT_ERROR]: 'app-icon-close-circle',

    [Icon.COLLAPSE_ALT]: 'app-icon-collapse-alt',
    [Icon.EXPAND_ALT]: 'app-icon-expand-alt',
    [Icon.EXPAND_ALT_2]: 'app-icon-expand-alt2',

    [Icon.FILLED_VIDEO]: 'app-icon-filled-camera',
    [Icon.FILLED_PHONE]: 'app-icon-filled-phone',
    [Icon.FILLED_CHAT]: 'app-icon-filled-chat',
    [Icon.FILLED_CLOSE]: 'app-icon-filled-close',
    [Icon.FILLED_PHOTO_CAMERA]: 'app-icon-filled-camera-photo',
    [Icon.FILLED_ADD_USER]: 'app-icon-filled-add-user',
    [Icon.FILLED_COG]: 'app-icon-filled-cog',
    [Icon.FILLED_FOLDER]: 'app-icon-filled-folder',
    [Icon.FILLED_GRID]: 'app-icon-filled-grid',
    [Icon.FILLED_LIST]: 'app-icon-filled-list',
    [Icon.FILLED_PENCIL]: 'app-icon-filled-pencil',
    [Icon.FILLED_ARROW_TOP_RIGHT]: 'app-icon-filled-arrow-corner-top-right',
    [Icon.FILLED_SAVE]: 'app-icon-filled-save',
    [Icon.FILLED_RECORD]: 'app-icon-filled-record',
    [Icon.FILLED_SHARED_RIGHT]: 'app-icon-filled-share-square-right',
    [Icon.FILLED_ARROW_UNDO]: 'app-icon-filled-arrow-undo',
    [Icon.FILLED_MICROPHONE_2]: 'app-icon-filled-microphone-2',
    [Icon.FILLED_MICROPHONE_2_DISABLED]: 'app-icon-filled-microphone-2-disabled',
    [Icon.FILLED_VIDEO_DISABLED]: 'app-icon-filled-camera-disabled',
    [Icon.FILLED_PAINT_BUCKET]: 'app-icon-filled-paint-bucket',
    [Icon.FILLED_PENCIL_EDIT_SMALL]: 'app-icon-filled-pencil-edit-small',
    [Icon.FILLED_PENCIL_EDIT_MEDIUM]: 'app-icon-filled-pencil-edit-medium',
    [Icon.FILLED_PENCIL_EDIT_LARGE]: 'app-icon-filled-pencil-edit-large',
    [Icon.FILLED_QUESTION_MARK]: 'app-icon-filled-question-mark',
    [Icon.FLAG]: 'app-icon-flag-2',

    [Icon.FOLDER]: 'app-icon-folder',
    [Icon.FOLDER_MINUS]: 'app-icon-folder-minus',
    [Icon.FOLDER_PLUS]: 'app-icon-folder-plus',

    [Icon.FILLED_GRID]: 'app-icon-filled-grid',
    [Icon.FILLED_LIST]: 'app-icon-filled-list',

    [Icon.EXPAND_ALT]: 'app-icon-expand-alt',
    [Icon.EXPAND_ALT_2]: 'app-icon-expand-alt2',

    [Icon.CLOUD_DOWNLOAD]: 'app-icon-cloud-download',

    [Icon.PLUS]: 'app-icon-plus',
    [Icon.MINUS]: 'app-icon-minus',

    [Icon.CHAT]: 'app-icon-chat',
    [Icon.DOWNLOAD]: 'app-icon-download',
    [Icon.TEXT]: 'app-icon-text',
    [Icon.ERASER]: 'app-icon-eraser',
    [Icon.INTERVIEW]: 'app-icon-interview',
    [Icon.PAUSE]: 'app-icon-pause',
    [Icon.PLAY]: 'app-icon-play',

    [Icon.NOTE]: 'app-icon-note',
    [Icon.NOTIFICATION_BELL]: 'app-icon-notification-bell',
    [Icon.DOCUMENT_IN_ACCEPT]: 'app-icon-document-in-accept',
    [Icon.DOCUMENT_IN_PROGRESS]: 'app-icon-document-in-progress',

    [Icon.LINE_BASIC]: 'app-icon-line-basic',
    [Icon.LINE_ARROW]: 'app-icon-line-arrow',
    [Icon.LINE_WIDTH_SMALL]: 'app-icon-line-width-small',
    [Icon.LINE_WIDTH_MEDIUM]: 'app-icon-line-width-medium',
    [Icon.LINE_WIDTH_LARGE]: 'app-icon-line-width-large',

    [Icon.FONT_SIZE_2]: 'app-icon-font-size-2',
    [Icon.FONT_SIZE_SMALL]: 'app-icon-font-size-small',
    [Icon.FONT_SIZE_MEDIUM]: 'app-icon-font-size-medium',
    [Icon.FONT_SIZE_LARGE]: 'app-icon-font-size-large',

    [Icon.WARNING]: 'app-icon-warning',
    [Icon.SEND]: 'app-icon-send-2',

    [Icon.CHEVRON_LEFT]: 'app-icon-chevron-left',
    [Icon.CHEVRON_RIGHT]: 'app-icon-chevron-right',
    [Icon.CHEVRON_DOWN]: 'app-icon-chevron-down',

    [Icon.ZOOM_IN]: 'pi pi-search-plus',
    [Icon.ZOOM_OUT]: 'pi pi-search-minus',
    [Icon.DOWNLOAD_2]: 'pi pi-download',

    [Icon.ROTATE_LEFT]: 'pi pi-undo',
    [Icon.ROTATE_RIGHT]: 'pi pi-refresh',
  };

  public getCssClass(icon: Icon): string {
    return this.icons[icon];
  }

}
