import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class ChangeMyPasswordRequest {

  @jsonMember public oldPassword!: string;
  @jsonMember public newPassword!: string;
  @jsonMember public newPasswordRepeat!: string;

  public constructor(oldPassword: string, newPassword: string, newPasswordRepeat: string) {
    this.oldPassword = oldPassword;
    this.newPassword = newPassword;
    this.newPasswordRepeat = newPasswordRepeat;
  }
}
