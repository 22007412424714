import { apiParams } from '@environments/const/environment.const';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { apiProceduresManagement } from '@environments/environment';
import { ProcedureStagesApi } from '@api/procedures-api/services/interfaces/procedure-stages.api';
import { Uuid } from '@app/api/types/uuid.type';
import { Observable } from 'rxjs';
import { IdResponse } from '@api/interfaces/id.response';
import { ProcedureStageResponse } from '@api/procedures-api/models/responses/procedure-stage.response';
import { ProcedureStageCreateRequest } from '@api/procedures-api/models/requests/procedure-stage-create.request';
import { ProcedureStageUpdateRequest } from '@api/procedures-api/models/requests/procedure-stage-update.request';
import { ProcedureStageReorderRequest } from '@api/procedures-api/models/requests/procedure-stage-reorder.request';

const { STAGE_ID, PROCEDURE_ID } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class ProcedureStagesApiService implements ProcedureStagesApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public create(procedureId: Uuid, request: ProcedureStageCreateRequest): Observable<IdResponse> {
    const { STAGES: { CREATE } } = apiProceduresManagement.PROCEDURES;
    const url = this.grabEndpointUrl(CREATE).replace(PROCEDURE_ID, procedureId.toString());
    const body = this.apiPayloadProcessor.toObject(request, ProcedureStageCreateRequest);

    return this
      .http
      .post(url, body)
      .pipe(this.apiPayloadProcessor.mapToModel(IdResponse));
  }

  public update(procedureId: Uuid, stageId: Uuid, request: ProcedureStageUpdateRequest): Observable<Object> {
    const { STAGES: { UPDATE } } = apiProceduresManagement.PROCEDURES;
    const url = this
      .grabEndpointUrl(UPDATE)
      .replace(PROCEDURE_ID, procedureId.toString())
      .replace(STAGE_ID, stageId.toString());
    const body = this.apiPayloadProcessor.toObject(request, ProcedureStageUpdateRequest);

    return this
      .http
      .patch(url, body);
  }

  public partialUpdate(procedureId: Uuid, stageId: Uuid, partialRequest: Partial<ProcedureStageUpdateRequest>): Observable<Object> {
    const { STAGES: { UPDATE } } = apiProceduresManagement.PROCEDURES;
    const url = this
      .grabEndpointUrl(UPDATE)
      .replace(PROCEDURE_ID, procedureId.toString())
      .replace(STAGE_ID, stageId.toString());
    const request = ProcedureStageUpdateRequest.fromPartial(partialRequest);

    return this
      .http
      .patch(url, request);
  }

  public delete(procedureId: Uuid, stageId: Uuid): Observable<Object> {
    const { STAGES: { DELETE } } = apiProceduresManagement.PROCEDURES;
    const url = this
      .grabEndpointUrl(DELETE)
      .replace(PROCEDURE_ID, procedureId.toString())
      .replace(STAGE_ID, stageId.toString());

    return this
      .http
      .delete(url);
  }

  public details(procedureId: Uuid, stageId: Uuid): Observable<ProcedureStageResponse> {
    const { STAGES: { DETAILS } } = apiProceduresManagement.PROCEDURES;
    const url = this
      .grabEndpointUrl(DETAILS)
      .replace(PROCEDURE_ID, procedureId.toString())
      .replace(STAGE_ID, stageId.toString());

    return this
      .http
      .get(url)
      .pipe(this.apiPayloadProcessor.mapToModel(ProcedureStageResponse));
  }

  public list(procedureId: Uuid): Observable<ProcedureStageResponse[]> {
    const { STAGES: { LIST } } = apiProceduresManagement.PROCEDURES;
    const url = this.grabEndpointUrl(LIST).replace(PROCEDURE_ID, procedureId.toString());

    return this
      .http
      .get(url)
      .pipe(this.apiPayloadProcessor.mapToModelArray(ProcedureStageResponse));
  }

  public reorder(procedureId: Uuid, stageId: Uuid, request: ProcedureStageReorderRequest): Observable<Object> {
    const { STAGES: { REORDER } } = apiProceduresManagement.PROCEDURES;
    const url = this.grabEndpointUrl(REORDER)
      .replace(PROCEDURE_ID, procedureId.toString())
      .replace(STAGE_ID, stageId.toString());
    const body = this.apiPayloadProcessor.toObject(request, ProcedureStageReorderRequest);

    return this
      .http
      .post(url, body);
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiProceduresManagement.API_HOST_URL, endpoint].join('');
  }

}
