import { jsonArrayMember, jsonObject } from 'typedjson';
import { AttributeFilterModel } from '@api/models/collection/requests/attribute-filter.model';
import { ResourceFilterModel } from '@api/models/collection/requests/resource-filter.model';

@jsonObject
export class UserFilter extends ResourceFilterModel {
  @jsonArrayMember(AttributeFilterModel) public status?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public permissionGroupsName?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public permissionGroupsType?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public email?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public firstName?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public lastName?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public jobName?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public department?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public lastLoginAt?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel, { name: 'query' }) public global?: AttributeFilterModel[];
}
