import {
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { RouteProvider } from '@core/routing/provider/route.provider';
import { Router } from '@angular/router';
import { RouteName } from '@core/routing/route-name.enum';
import { UserContextModel } from '@core/auth/models/user-context.model';
import { Icon } from '@core/assets/enums/icon.enum';
import { Menu } from 'primeng/menu';
import { map, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-layout-header-user-info',
  templateUrl: './dashboard-layout-header-user-info.component.html',
  styleUrls: ['./dashboard-layout-header-user-info.component.scss'],
})
export class DashboardLayoutHeaderUserInfoComponent implements OnInit, OnChanges {

  private readonly AVATAR_PLACEHOLDER_ICON = Icon.USER;
  private readonly MENU_ITEMS_LABELS = {
    MY_PROFILE: 'HEADER.USER_DROPDOWN.MY_PROFILE',
    LOGOUT: 'HEADER.USER_DROPDOWN.LOGOUT',
  };

  @Input() public userContext?: UserContextModel;
  @ViewChild(Menu) public menu!: Menu;

  public avatarUrl?: string;
  public avatarIcon?: Icon;
  public dignity?: string;
  public jobName?: string;
  public items$!: Observable<MenuItem[]>;

  public constructor(
    private readonly routeProvider: RouteProvider,
    private readonly router: Router,
    private readonly cd: ChangeDetectorRef,
    private readonly zone: NgZone,
    private readonly translateService: TranslateService,
  ) {
  }

  public ngOnInit(): void {
    this.items$ = this.buildMenuItems();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.initParams();
  }

  private initParams(): void {
    const user = this.userContext?.user;
    if (!user) {
      return this.clearViewParams();
    }

    this.dignity = user.fullName;
    this.jobName = user.jobName;
    this.avatarUrl = user.avatar;
    this.avatarIcon = this.avatarUrl ? undefined : this.AVATAR_PLACEHOLDER_ICON;
  }

  private clearViewParams(): void {
    this.dignity = undefined;
    this.jobName = undefined;
    this.avatarUrl = undefined;
    this.avatarIcon = this.AVATAR_PLACEHOLDER_ICON;
  }

  private buildMenuItems(): Observable<MenuItem[]> {
    return this
      .translateService
      .stream(Object.values(this.MENU_ITEMS_LABELS))
      .pipe(
        map((labels) =>
          [
            {
              label: labels[this.MENU_ITEMS_LABELS.MY_PROFILE],
              command: () => {
                this.zone.run(() => {
                  this.goToMyProfile();
                });
              },
            },
            {
              label: labels[this.MENU_ITEMS_LABELS.LOGOUT],
              command: () => {
                this.zone.run(() => {
                  this.doLogout();
                });
              },
            },
          ],
        ),
      );
  }

  private doLogout(): void {
    this.router.navigate(this.routeProvider.getPath(RouteName.SIGN_OUT));
  }

  private goToMyProfile(): void {
    this.router.navigate(this.routeProvider.getPath(RouteName.MY_PROFILE));
  }

  public toggleUserMenu($event: MouseEvent): void {
    this.menu.toggle($event);
    this.cd.detectChanges();
  }
}
