import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';

@jsonObject
export class ProcedureUserDignityResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember(String) public readonly firstName!: string;
  @jsonMember(String) public readonly lastName!: string;

  public get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

}
