import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';

@jsonObject
export class CreateVideoRoomRequest {
  @jsonMember public name?: string;
  @jsonArrayMember(String) public participantsIds!: Uuid[];
  @jsonMember public audioEnabled!: boolean;
  @jsonMember public videoEnabled!: boolean;

  public constructor(participantsIds: Uuid[], audioEnabled: boolean, videoEnabled: boolean, name?: string) {
    this.participantsIds = participantsIds;
    this.audioEnabled = audioEnabled;
    this.videoEnabled = videoEnabled;
    this.name = name;
  }
}
