import { AnyT, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';

export interface ActionTokenPayload {
  readonly _type: string;
}

export interface ChangeEmailActionTokenPayload extends ActionTokenPayload {
  readonly fromEmail: string,
  readonly toEmail: string,
}

@jsonObject
export class ActionTokenResponse<PayloadType extends ActionTokenPayload> {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly type!: string;
  @jsonMember public readonly used!: boolean;
  @jsonMember public readonly validTo!: Date;
  @jsonMember public readonly createdAt!: Date;
  @jsonMember public readonly usedAt!: Date;
  @jsonMember(AnyT) public readonly payload!: PayloadType;

}
