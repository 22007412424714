import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AUTH_API } from './services/tokens/auth-api.token';
import { AuthApiService } from './services/auth-api.service';
import { USER_PROFILE_API } from '@api/cmuia-api/services/tokens/user-profile-api.token';
import { UserProfileApiService } from '@api/cmuia-api/services/user-profile-api.service';
import { ACTION_TOKEN_API } from '@api/cmuia-api/services/tokens/action-token-api.token';
import { ActionTokenApiService } from '@api/cmuia-api/services/action-token-api.service';
import { ADMIN_USERS_API } from '@api/cmuia-api/services/tokens/admin-users-api.token';
import { AdminUsersApiService } from '@api/cmuia-api/services/admin-users-api.service';
import { ADMIN_USER_PERMISSION_GROUPS_API } from './services/tokens/admin-user-permission-groups-api.token';
import { AdminUserPermissionGroupsApiService } from './services/admin-user-permission-groups-api.service';
import { ADMIN_USER_PERMISSIONS_API } from './services/tokens/admin-user-permissions-api.token';
import { AdminUserPermissionsApiService } from './services/admin-user-permissions-api.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: AUTH_API,
      useClass: AuthApiService,
    },
    {
      provide: ACTION_TOKEN_API,
      useClass: ActionTokenApiService,
    },
    {
      provide: USER_PROFILE_API,
      useClass: UserProfileApiService,
    },
    {
      provide: ADMIN_USERS_API,
      useClass: AdminUsersApiService,
    },
    {
      provide: ADMIN_USER_PERMISSION_GROUPS_API,
      useClass: AdminUserPermissionGroupsApiService,
    },
    {
      provide: ADMIN_USER_PERMISSIONS_API,
      useClass: AdminUserPermissionsApiService,
    },
  ],
})
export class CMUiAApiModule {
}
