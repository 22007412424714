import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { BreadcrumbsSeparatorDirective } from './directives/breadcrumbs-separator.directive';


@NgModule({
  imports: [
    CommonModule,
    BreadcrumbModule,
  ],
  declarations: [BreadcrumbsComponent, BreadcrumbsSeparatorDirective],
  exports: [BreadcrumbsComponent],
})
export class BreadcrumbsModule {
}
