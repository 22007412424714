import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContactsApiModule } from '@api/contacts-api/contacts-api.module';
import { ConversationsApiModule } from '@api/conversations-api/conversations-api.module';
import { ConversationsProviderApiModule } from '@api/conversations-provider-api/conversations-provider-api.module';
import { DictionariesApiModule } from '@api/dictionaries-api/dictionaries-api.module';
import { DocumentsApiModule } from '@api/documents-api/documents-api.module';
import { CMUiAApiModule } from './cmuia-api/cmuia-api.module';
import { VideoProviderApiModule } from '@api/video-provider-api/video-provider-api.module';
import { ProceduresApiModule } from './procedures-api/procedures-api.module';
import { NotifierApiModule } from '@api/notifier-api/notifier-api.module';
import { DashboardsApiModule } from '@api/dashboards-api/dashboards-api.module';
import { SettingsApiModule } from '@api/settings-api/settings-api.module';

@NgModule({
  imports: [
    CommonModule,
    CMUiAApiModule,
    DictionariesApiModule,
    ContactsApiModule,
    DocumentsApiModule,
    ProceduresApiModule,
    NotifierApiModule,
    DashboardsApiModule,
    SettingsApiModule,
  ],
  exports: [
    CMUiAApiModule,
    DictionariesApiModule,
    ContactsApiModule,
    ConversationsApiModule,
    DocumentsApiModule,
    ConversationsProviderApiModule,
    VideoProviderApiModule,
    ProceduresApiModule,
    NotifierApiModule,
    SettingsApiModule,
  ],
})
export class ApiModule {
}
