import { Observable, pipe, throwError, timer } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';

// https://stackoverflow.com/a/68547493/9907293
export function backoff<T>(maxRetries = 5, baseDelay = 1000, retryCallback = () => {}):
  (_: Observable<T>) => Observable<T> {
  return pipe(
    retryWhen(errors => errors.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        if (retryAttempt > maxRetries) {
          return throwError(error);
        } else {
          const waitMs = Math.pow(2, retryAttempt) * baseDelay;
          retryCallback();
          return timer(waitMs);
        }
      }),
    )),
  );
}
