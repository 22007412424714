import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';

@jsonObject
export class CreateUserRequest {

  @jsonArrayMember(String) public permissionGroupsIds!: Uuid[];
  @jsonMember public email?: string;
  @jsonMember public firstName?: string;
  @jsonMember public lastName?: string;
  @jsonMember public jobName?: string;
  @jsonMember public department?: string;

  public constructor(
    permissionGroupsIds?: Uuid[],
    email?: string,
    firstName?: string,
    lastName?: string,
    jobName?: string,
    department?: string,
  ) {
    this.permissionGroupsIds = permissionGroupsIds ?? [];
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.jobName = jobName;
    this.department = department;
  }
}
