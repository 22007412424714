import { AnyT, jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class SettingPropertyRequest<ValueType = unknown> {

  @jsonMember public readonly key!: string;
  @jsonMember(AnyT) public readonly value!: ValueType;

  public constructor(
    key: string,
    value: ValueType,
  ) {
    this.key = key;
    this.value = value;
  }

}
