import { apiParams } from '@environments/const/environment.const';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { apiProceduresManagement } from '@environments/environment';
import { Observable } from 'rxjs';
import { Uuid } from '@api/types/uuid.type';
import { IdResponse } from '@api/interfaces/id.response';
import { ProcedureFieldPropertiesApi } from '@api/procedures-api/services/interfaces/procedure-field-properties-api';
import { ProcedureFieldPropertyResponse } from '@api/procedures-api/models/responses/procedure-field-property.response';
import { ProcedureFieldPropertyUpdateRequest } from '@api/procedures-api/models/requests/procedure-field-property-update.request';
import { ProcedureFieldPropertyCreateRequest } from '@api/procedures-api/models/requests/procedure-field-property-create.request';

const { VALIDATOR_ID, FIELD_ID, PROPERTY_ID } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class ProcedureFieldPropertiesApiService implements ProcedureFieldPropertiesApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public add(fieldId: Uuid, request: ProcedureFieldPropertyCreateRequest): Observable<IdResponse> {
    const { PROCEDURES: { FIELDS: { PROPERTIES: { CREATE } } } } = apiProceduresManagement;
    const body: Object = this.apiPayloadProcessor.toObject(request, ProcedureFieldPropertyCreateRequest);
    const url: string = this
      .grabEndpointUrl(CREATE)
      .replace(FIELD_ID, fieldId.toString());

    return this
      .http
      .post(url, body)
      .pipe(this.apiPayloadProcessor.mapToModel(IdResponse));
  }

  public delete(fieldId: Uuid, propertyId: Uuid): Observable<Object> {
    const { PROCEDURES: { FIELDS: { PROPERTIES: { DELETE } } } } = apiProceduresManagement;
    const url: string = this
      .grabEndpointUrl(DELETE)
      .replace(FIELD_ID, fieldId.toString())
      .replace(VALIDATOR_ID, propertyId.toString());

    return this
      .http
      .delete(url);
  }

  public list(fieldId: Uuid): Observable<ProcedureFieldPropertyResponse[]> {
    const { PROCEDURES: { FIELDS: { PROPERTIES: { LIST } } } } = apiProceduresManagement;
    const url: string = this
      .grabEndpointUrl(LIST)
      .replace(FIELD_ID, fieldId.toString());

    return this
      .http
      .get(url)
      .pipe(this.apiPayloadProcessor.mapToModelArray(ProcedureFieldPropertyResponse));
  }

  public update(fieldId: Uuid, propertyId: Uuid, request: ProcedureFieldPropertyUpdateRequest): Observable<Object> {
    const { PROCEDURES: { FIELDS: { PROPERTIES: { UPDATE } } } } = apiProceduresManagement;
    const body: Object = this.apiPayloadProcessor.toObject(request, ProcedureFieldPropertyUpdateRequest);
    const url: string = this
      .grabEndpointUrl(UPDATE)
      .replace(FIELD_ID, fieldId.toString())
      .replace(PROPERTY_ID, propertyId.toString());

    return this
      .http
      .put(url, body);
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiProceduresManagement.API_HOST_URL, endpoint].join('');
  }

}
