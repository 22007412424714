import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LogicalOperator } from '../models/logical-operator.enum';
import { Permission } from '../models/permission.enum';
import { PermissionsList } from '../models/permissions-list.model';
import { UserPermissions } from '../models/user-permissions.model';
import { PermissionsBuilder } from './permissions.builder';
import { UserContextModel } from '@core/auth/models/user-context.model';
import { UserContextService } from '@core/auth/services/user-context.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {

  private user?: UserContextModel;
  private currentPermissions!: UserPermissions;

  public permissionsReady$ = new BehaviorSubject<boolean>(false);

  public constructor(private userService: UserContextService,
                     private userPermissionsBuilder: PermissionsBuilder) {
    this.subscribeForUserAndAccountChange();
  }

  private subscribeForUserAndAccountChange(): void {
    this.userService.userContext$.subscribe((userContext: UserContextModel | null) => {
      this.onUserContextChanged(userContext);
    });
  }

  private onUserContextChanged(user: UserContextModel | null): void {
    this.user = user ? user : undefined;
    this.currentPermissions = this.userPermissionsBuilder.build(this.user);
    this.permissionsReady$.next(true);
  }

  public checkPermissions(permissions: Permission | Permission[] | PermissionsList): boolean {
    if (!this.currentPermissions) {
      return false;
    }

    if (typeof permissions === 'string') {
      return this.hasPermission(permissions);

    } else if (permissions instanceof Array) {
      return this.checkPermissionsList(new PermissionsList(permissions as Permission[]));

    } else if (permissions instanceof PermissionsList) {
      return this.checkPermissionsList(permissions);

    } else {
      throw new Error('Nieprawidłowa definicja uprawnień do sprawdzenia: ' + JSON.stringify(permissions));
    }
  }

  public doesNotHavePermission(permission: Permission): boolean {
    if (!this.currentPermissions) {
      return false;
    }

    return this.currentPermissions.permissions.indexOf(permission) < 0;
  }

  private hasPermission(permission: Permission): boolean {
    return this.currentPermissions.permissions.includes(permission);
  }

  private checkPermissionsList(permissions: PermissionsList): boolean {
    if (permissions.operator === LogicalOperator.AND) {
      return this.hasAllPermissions(permissions.permissions);
    } else if (permissions.operator === LogicalOperator.OR) {
      return this.hasOneOfPermissions(permissions.permissions);
    } else {
      throw new Error('Nieprawidłowy operator "' + permissions.operator + '"');
    }
  }

  private hasAllPermissions(permissions: Permission[]): boolean {
    return permissions.every((onePermission: Permission) => this.hasPermission(onePermission));
  }

  private hasOneOfPermissions(permissions: Permission[]): boolean {
    return permissions.some((onePermission: Permission) => this.hasPermission(onePermission));
  }

  public getUser(): UserContextModel | undefined {
    return this.user;
  }

  public isLogged(): boolean {
    return this.checkPermissions(Permission.IS_LOGGED);
  }

  public isNotLogged(): boolean {
    return this.checkPermissions(Permission.IS_NOT_LOGGED);
  }

  public isAdmin(): boolean {
    return this.checkPermissions(Permission.IS_ADMIN);
  }

  public isNotAdmin(): boolean {
    return this.checkPermissions(Permission.IS_NOT_ADMIN);
  }

  public isManager(): boolean {
    return this.checkPermissions(Permission.IS_MANAGER);
  }

  public isNotManager(): boolean {
    return this.checkPermissions(Permission.IS_NOT_MANAGER);
  }

  public isExpert(): boolean {
    return this.checkPermissions(Permission.IS_EXPERT);
  }

  public isNotExpert(): boolean {
    return this.checkPermissions(Permission.IS_NOT_EXPERT);
  }

  public isEmployee(): boolean {
    return this.checkPermissions(Permission.IS_EMPLOYEE);
  }

  public isNotEmployee(): boolean {
    return this.checkPermissions(Permission.IS_NOT_EMPLOYEE);
  }
}
