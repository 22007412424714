import { Uuid } from '@api/types/uuid.type';
import { jsonMember, jsonObject } from 'typedjson';
import { AttributeConfigRequest } from './attribute-config.request';

@jsonObject
export class UpdateAttributeRequest {

  @jsonMember public readonly name?: string;
  @jsonMember public readonly isRequired?: boolean;
  @jsonMember({ name: 'payload' }) public readonly config?: AttributeConfigRequest;

  public constructor(name?: string, isRequired?: boolean, config?: AttributeConfigRequest) {
    this.name = name;
    this.isRequired = isRequired;
    this.config = config;
  }

}
