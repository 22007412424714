import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PROCEDURES_API } from './services/tokens/procedures-api.token';
import { ProceduresApiService } from './services/procedures-api.service';
import { PROCEDURE_STAGES_API } from '@api/procedures-api/services/tokens/procedure-stages-api.token';
import { ProcedureStagesApiService } from '@api/procedures-api/services/procedure-stages-api.service';
import { PROCEDURE_FIELDS_API } from '@api/procedures-api/services/tokens/procedure-fields-api.token';
import { ProcedureFieldsApiService } from '@api/procedures-api/services/procedure-fields-api.service';
import { PROCEDURE_FIELD_VALIDATORS_API } from '@api/procedures-api/services/tokens/procedure-field-validators-api.token';
import { ProcedureFieldValidatorsApiService } from '@api/procedures-api/services/procedure-field-validators-api.service';
import { PROCEDURES_EXPERTS_API } from '@api/procedures-api/services/tokens/procedures-experts-api.token';
import { ProceduresExpertsApiService } from '@api/procedures-api/services/procedures-experts-api.service';
import { PROCEDURES_DICTIONARIES_API } from '@api/procedures-api/services/tokens/procedures-dictionaries-api.token';
import { ProceduresDictionariesApiService } from '@api/procedures-api/services/procedures-dictionaries-api.service';
import { PROCEDURE_VERIFICATIONS_API } from '@api/procedures-api/services/tokens/procedure-verifications-api.token';
import { ProcedureVerificationsApiService } from '@api/procedures-api/services/procedure-verifications-api.service';
import { PROCEDURE_FIELD_PROPERTIES_API } from '@api/procedures-api/services/tokens/procedure-field-properties-api.token';
import { ProcedureFieldPropertiesApiService } from '@api/procedures-api/services/procedure-field-properties-api.service';
import { PROCEDURES_FIELD_WORKERS_API } from '@api/procedures-api/services/tokens/procedures-field-workers-api.token';
import { ProceduresFieldWorkersApiService } from '@api/procedures-api/services/procedures-field-workers-api.service';
import { TASKS_MANAGEMENT_API } from '@api/procedures-api/services/tokens/tasks-management-api.token';
import { TasksManagementApiService } from '@api/procedures-api/services/tasks-management-api.service';
import { TASK_PROCEDURES_API } from '@api/procedures-api/services/tokens/task-procedures-api.token';
import { TaskProceduresApiService } from '@api/procedures-api/services/task-procedures-api.service';
import { ATTRIBUTES_OF_PROCEDURES_API } from '@api/procedures-api/services/tokens/attributes-of-procedures-api.token';
import { AttributesOfProceduresService } from '@api/procedures-api/services/attributes-of-procedures.service';
import { PROCEDURES_NOTIFICATIONS_API } from '@api/procedures-api/services/tokens/notifications-api.token';
import { ProceduresNotificationsApiService } from '@api/procedures-api/services/procedures-notifications-api.service';
import { PROCEDURES_LIVE_DATA_API } from '@api/procedures-api/services/tokens/procedures-live-data-api.token';
import { ProceduresLiveDataApiService } from '@api/procedures-api/services/procedures-live-data-api.service';
import { TASKS_API } from '@api/procedures-api/services/tokens/tasks-api.token';
import { TasksApiService } from '@api/procedures-api/services/tasks-api.service';
import { TASK_STAGES_MANAGEMENT_API } from '@api/procedures-api/services/tokens/task-stages-management-api.token';
import { TaskStagesManagementApiService } from '@api/procedures-api/services/task-stages-management-api.service';
import { TASK_STAGES_API } from './services/tokens/task-stages-api.token';
import { TaskStagesApiService } from './services/task-stages-api.service';
import { TASK_ACTIVITIES_MANAGEMENT_API } from './services/tokens/task-activities-management-api.token';
import { TaskActivitiesManagementApiService } from '@api/procedures-api/services/task-activities-management-api.service';
import { TaskActivitiesApiService } from '@api/procedures-api/services/task-activities-api.service';
import { TASK_ACTIVITIES_API } from './services/tokens/task-activities-api.token';
import { PROCEDURES_DICTIONARIES_MANAGEMENT_API } from '@api/procedures-api/services/tokens/procedures-dictionaries-management-api.token';
import { ProceduresDictionariesManagementApiService } from '@api/procedures-api/services/procedures-dictionaries-management-api.service';
import { PROCEDURE_STAGE_EXECUTION_ORDER_API } from '@api/procedures-api/services/tokens/procedure-stage-execution-order-api.token';
import { ProcedureStageExecutionOrderApiService } from '@api/procedures-api/services/procedure-stage-execution-order-api.service';
import { PROCEDURE_EXPRESSIONS_API } from '@api/procedures-api/services/tokens/procedure-expression-api.token';
import { ProcedureExpressionsApiService } from '@api/procedures-api/services/procedure-expressions-api.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    {
      provide: ATTRIBUTES_OF_PROCEDURES_API,
      useClass: AttributesOfProceduresService,
    },
    {
      provide: PROCEDURES_API,
      useClass: ProceduresApiService,
    },
    {
      provide: PROCEDURE_STAGES_API,
      useClass: ProcedureStagesApiService,
    },
    {
      provide: PROCEDURE_STAGE_EXECUTION_ORDER_API,
      useClass: ProcedureStageExecutionOrderApiService,
    },
    {
      provide: PROCEDURE_EXPRESSIONS_API,
      useClass: ProcedureExpressionsApiService,
    },
    {
      provide: PROCEDURE_FIELDS_API,
      useClass: ProcedureFieldsApiService,
    },
    {
      provide: PROCEDURE_FIELD_VALIDATORS_API,
      useClass: ProcedureFieldValidatorsApiService,
    },
    {
      provide: PROCEDURE_FIELD_PROPERTIES_API,
      useClass: ProcedureFieldPropertiesApiService,
    },
    {
      provide: PROCEDURES_DICTIONARIES_API,
      useClass: ProceduresDictionariesApiService,
    },
    {
      provide: PROCEDURES_DICTIONARIES_MANAGEMENT_API,
      useClass: ProceduresDictionariesManagementApiService,
    },
    {
      provide: PROCEDURES_EXPERTS_API,
      useClass: ProceduresExpertsApiService,
    },
    {
      provide: PROCEDURE_VERIFICATIONS_API,
      useClass: ProcedureVerificationsApiService,
    },
    {
      provide: PROCEDURES_FIELD_WORKERS_API,
      useClass: ProceduresFieldWorkersApiService,
    },
    {
      provide: PROCEDURES_NOTIFICATIONS_API,
      useClass: ProceduresNotificationsApiService,
    },
    {
      provide: PROCEDURES_LIVE_DATA_API,
      useClass: ProceduresLiveDataApiService,
    },
    {
      provide: TASKS_MANAGEMENT_API,
      useClass: TasksManagementApiService,
    },
    {
      provide: TASKS_API,
      useClass: TasksApiService,
    },
    {
      provide: TASK_PROCEDURES_API,
      useClass: TaskProceduresApiService,
    },
    {
      provide: TASK_ACTIVITIES_MANAGEMENT_API,
      useClass: TaskActivitiesManagementApiService,
    },
    {
      provide: TASK_ACTIVITIES_API,
      useClass: TaskActivitiesApiService,
    },
    {
      provide: TASK_STAGES_MANAGEMENT_API,
      useClass: TaskStagesManagementApiService,
    },
    {
      provide: TASK_STAGES_API,
      useClass: TaskStagesApiService,
    },
  ],
})
export class ProceduresApiModule {
}
