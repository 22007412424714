import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingIndicatorComponent } from './loading-indicator.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingIndicatorInterceptor } from './interceptors/loading-indicator.interceptor';
import { ProgressSpinnerModule } from 'primeng/progressspinner';


@NgModule({
  imports: [
    CommonModule,

    // PrimeNg
    ProgressSpinnerModule,
  ],
  declarations: [LoadingIndicatorComponent],
  exports: [LoadingIndicatorComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingIndicatorInterceptor,
      multi: true,
    },
  ],
})
export class LoadingIndicatorModule {
}
