import { UserModel } from './user.model';
import { Role } from '../enums/role.enum';
import { AppLoggedUserGlobalPermitsResponse } from '@api/cmuia-api/models/responses/app-logged-user-global-permits.response';
import { AppLoggedUserOwnContextOperationsResponse } from '@api/cmuia-api/models/responses/app-logged-user-own-context-operations.response';

export class UserContextModel {
  public readonly user!: UserModel;
  public readonly roles!: Role[];
  public readonly userOwnContextOperations!: AppLoggedUserOwnContextOperationsResponse;
  public readonly globalPermits!: AppLoggedUserGlobalPermitsResponse;

  public constructor(
    user: UserModel,
    roles: Role[],
    globalPermits: AppLoggedUserGlobalPermitsResponse,
    userOwnContextOperations: AppLoggedUserOwnContextOperationsResponse,
  ) {
    this.user = user;
    this.roles = roles;
    this.globalPermits = globalPermits;
    this.userOwnContextOperations = userOwnContextOperations;
  }
}
