import { AnyT, jsonMember, jsonObject } from 'typedjson';
import { ProcedureFieldValidatorType } from '@api/procedures-api/models/enums/procedure-field-validator.type';
import { ProcedureFieldValidatorParamsRequestType } from '../types/procedure-field-validator-params-request.type';

@jsonObject
export class ProcedureFieldValidatorCreateRequest<ParamsType = ProcedureFieldValidatorParamsRequestType> {

  @jsonMember public type!: ProcedureFieldValidatorType;
  @jsonMember(AnyT) public params!: ParamsType;
  @jsonMember public errorMessage?: string;

}
