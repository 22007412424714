import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardLayoutHeaderNotificationsComponent } from './dashboard-layout-header-notifications.component';
import { HeaderNotificationsModule } from '@core/layouts/dashboard-layout/components/dashboard-layout-header-notifications/header-notifications/header-notifications.module';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { PermissionsModule } from '@core/permissions/permissions.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    HeaderNotificationsModule,
    ButtonModule,
    RippleModule,
    PermissionsModule,
    TranslateModule,
  ],
  declarations: [DashboardLayoutHeaderNotificationsComponent],
  exports: [DashboardLayoutHeaderNotificationsComponent],
})
export class DashboardLayoutHeaderNotificationsModule {
}
