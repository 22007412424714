import { Injectable } from '@angular/core';
import { NotifierApi } from '@api/notifier-api/services/interfaces/notifier.api';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { apiNotifierCommon } from '@environments/environment';
import { PushDeviceType } from '@api/notifier-api/models/enums/push-device-type';

@Injectable({
  providedIn: 'root',
})
export class NotifierApiService implements NotifierApi {

  public constructor(private http: HttpClient) {
  }

  public registerPushDevice(fcmRegistrationId: string): Observable<void> {
    const url = this.grabEndpointUrl(apiNotifierCommon.PUSH.REGISTER_DEVICE);
    const payload = {
      registrationToken: fcmRegistrationId,
      deviceType: PushDeviceType.BROWSER,
    };

    return this.http.post<void>(url, payload);
  }

  public unregisterPushDevice(fcmRegistrationId: string): Observable<void> {
    const url = this.grabEndpointUrl(apiNotifierCommon.PUSH.UNREGISTER_DEVICE);
    const payload = {
      registrationToken: fcmRegistrationId,
    };

    return this.http.post<void>(url, payload);
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiNotifierCommon.API_HOST_URL, endpoint].join('');
  }
}
