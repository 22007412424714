import { jsonArrayMember, jsonObject } from 'typedjson';
import { FilePropertyRequest } from '@api/documents-api/models/requests/file-property.request';

@jsonObject
export class UpdateDirectoryRequest {

  @jsonArrayMember(FilePropertyRequest) public readonly properties!: FilePropertyRequest[];

  public constructor(
    properties: FilePropertyRequest[],
  ) {
    this.properties = properties;
  }

}
