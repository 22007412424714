export enum FileSystemObjectPropertyName {

  EXTENSION = 'INTERNAL_EXTENSION',
  CATEGORY = 'INTERNAL_CATEGORY',
  NAME = 'INTERNAL_NAME',
  PROPERTIES = 'INTERNAL_PROPERTIES',
  PERMISSION_GROUPS = 'INTERNAL_PERMISSION_GROUPS',
  DESCRIPTION = 'INTERNAL_DESCRIPTION',
  FILE = 'INTERNAL_FILE',
  SIZE = 'INTERNAL_SIZE',

}
