<i
  class="header-notification-item align-middle p-text-secondary p-overlay-badge {{triggerButtonStyleCss}} {{ triggerButtonIcon | iconCssClass }}"
  pRipple
  (click)="togglePanel($event)">
  <p-badge
    *ngIf="notifications && notifications.length > 0"
    styleClass="p-badge-dot">
  </p-badge>
</i>

<p-overlayPanel
  appOverlayPanelPositionCorrectorDirective
  appendTo="body"
  styleClass="header-notifications__popover"
  [showCloseIcon]="false"
  [style]="{width: '340px'}">
  <ng-template pTemplate>
    <ng-container
      *ngIf="notifications && notifications.length > 0; else empty">
      <div
        class="notifications-list">
        <ng-container
          *ngFor="let notification of notifications">
          <app-header-notifications-item
            [model]="notification"
            (clicked)="onNotificationClicked(notification)">
          </app-header-notifications-item>
        </ng-container>
      </div>

      <div
        class="mt-3 p-fluid">
        <button
          pButton
          pRipple
          class="p-button-text"
          [label]="markAllReadButtonText | translate"
          (click)="onMarkAllReadClicked()">
        </button>
      </div>

      <ng-template
        *ngIf="additionalButtonsTemplate"
        [ngTemplateOutlet]="additionalButtonsTemplate">
      </ng-template>
    </ng-container>

    <ng-template #empty>
      <div class="text-center mb-2">
        {{ emptyText | translate }}
      </div>

      <ng-template
        *ngIf="additionalButtonsTemplate"
        [ngTemplateOutlet]="additionalButtonsTemplate">
      </ng-template>
    </ng-template>
  </ng-template>
</p-overlayPanel>
