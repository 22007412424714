import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoadingIndicatorService } from './services/loading-indicator.service';
import { LazyModuleLoadingService } from '@core/routing/services/lazy-module-loading.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent implements OnInit, OnDestroy {

  public visibility$!: Observable<boolean>;
  private destroyed$ = new Subject<void>();

  public constructor(private loadingIndicatorService: LoadingIndicatorService,
                     private lazyModuleLoadingService: LazyModuleLoadingService) {
  }

  public ngOnInit(): void {
    this.visibility$ = this.loadingIndicatorService.visibility$;

    this.configureLoadingIndicatorForLazyModules();
  }

  private configureLoadingIndicatorForLazyModules(): void {
    this.lazyModuleLoadingService.loading$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe((isLoading: boolean) => {
      if (isLoading) {
        this.loadingIndicatorService.show();
      } else {
        this.loadingIndicatorService.hide();
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
