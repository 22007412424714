import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class ProceduresStatsResponse {

  @jsonMember public readonly createdCount!: number;
  @jsonMember public readonly draftsCount!: number;
  @jsonMember public readonly inVerificationCount!: number;
  @jsonMember public readonly alertsCount!: number;

}
