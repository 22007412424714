export enum ProcedureFieldValidatorType {

  REQUIRED = 'REQUIRED',
  LENGTH = 'LENGTH',
  REGEX = 'REGEX',
  DATE_RELATIVE = 'DATE_RELATIVE',
  DATE_RANGE = 'DATE_RANGE',
  EXCLUDE_ALPHA_CHARS = 'EXCLUDE_ALPHA_CHARS',
  EXCLUDE_DIGIT_CHARS = 'EXCLUDE_DIGIT_CHARS',
  EXCLUDE_SPECIAL_CHARS = 'EXCLUDE_SPECIAL_CHARS',

}
