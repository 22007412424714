import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Permission } from '../models/permission.enum';
import { PermissionsList } from '../models/permissions-list.model';
import { PermissionsService } from '@core/permissions/services/permissions.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appShowIfHasPermission]',
})
export class ShowIfHasPermissionDirective implements OnDestroy {

  protected hasPermission!: boolean;
  protected subscription = new Subscription();
  protected permissionToCheck!: Permission | Permission[] | PermissionsList;

  @Input('appShowIfHasPermission')
  public set permission(permission: Permission | Permission[] | PermissionsList) {
    this.permissionToCheck = permission;
    this.evaluateVisibility();
  }

  public constructor(protected permissionsService: PermissionsService,
                     protected templateRef: TemplateRef<Object>,
                     protected viewContainer: ViewContainerRef) {

    this.subscribeForPermissionChange();
  }

  protected evaluateVisibility(): void {
    this.hasPermission = this.checkPermission(this.permissionToCheck);
    this.render();
  }

  protected render(): void {
    if (this.hasPermission) {
      this.addView();
    } else {
      this.removeView();
    }
  }

  protected addView(): void {
    if (this.viewContainer.length === 0) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  protected removeView(): void {
    this.viewContainer.clear();
  }

  private checkPermission(permission: Permission | Permission[] | PermissionsList): boolean {
    if (!permission) {
      return true;
    }

    return this.permissionsService.checkPermissions(permission);
  }

  private subscribeForPermissionChange(): void {
    this.subscription.add(this.permissionsService.permissionsReady$.subscribe(() => {
      this.evaluateVisibility();
    }));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
