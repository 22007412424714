import { Injectable } from '@angular/core';
import { Params, Router, UrlCreationOptions, UrlTree } from '@angular/router';
import { FIELD_ID, ID, PROCEDURE_ID, STAGE_ID, TASK_ID, TYPE } from '@core/routing/routing-param.const';
import { RouteName } from '../route-name.enum';
import {
  ACCEPT,
  ADD,
  ALERTS,
  ATTRIBUTES,
  CALL,
  CAMERA,
  CHAT,
  CONTACTS,
  CREATE,
  CREATOR,
  DASHBOARD,
  DESIGNER,
  DETAILS,
  DICTIONARIES,
  DIRECTORY,
  DOCUMENTS,
  FIELDS,
  FILE,
  MANAGE,
  NOT_FOUND,
  PANEL,
  PERMISSIONS,
  PROCEDURES,
  PROFILE,
  REJECT,
  RESET_PASSWORD,
  ROOT,
  SETTINGS,
  SIGN_IN,
  SIGN_OUT,
  STAGES,
  TASKS,
  UI_GUIDE,
  UPDATE,
  USERS,
  VIDEO,
} from '../routing-path.const';

@Injectable({
  providedIn: 'root',
})
export class RouteProvider {

  private routes: { [key in RouteName]: string[] } = {
    [RouteName.ROOT]: [ROOT],
    [RouteName.SIGN_IN]: [ROOT, SIGN_IN],
    [RouteName.SIGN_OUT]: [ROOT, SIGN_OUT],
    [RouteName.RESET_PASSWORD_INFO]: [ROOT, RESET_PASSWORD],
    [RouteName.UI_GUIDE]: [ROOT, UI_GUIDE],
    [RouteName.DASHBOARD]: [ROOT, DASHBOARD],
    [RouteName.DASHBOARD_CREATE]: [ROOT, DASHBOARD, MANAGE, CREATE],
    [RouteName.DASHBOARD_UPDATE]: [ROOT, DASHBOARD, MANAGE, `{${ID}}`, DESIGNER],
    [RouteName.DASHBOARDS_MANAGEMENT]: [ROOT, DASHBOARD, MANAGE],
    [RouteName.MY_PROFILE]: [ROOT, PROFILE],
    [RouteName.PANEL_TASKS]: [ROOT, PANEL, TASKS],
    [RouteName.PANEL_TASKS_ALERTS]: [ROOT, PANEL, TASKS, ALERTS],
    [RouteName.PANEL_TASKS_DETAILS]: [ROOT, PANEL, TASKS, `{${TASK_ID}}`, DETAILS],
    [RouteName.PANEL_PROCEDURES]: [ROOT, PANEL, PROCEDURES],
    [RouteName.PANEL_PROCEDURE_ALERTS]: [ROOT, PANEL, PROCEDURES, ALERTS],
    [RouteName.PANEL_PROCEDURES_DETAILS]: [ROOT, PANEL, PROCEDURES, `{${PROCEDURE_ID}}`, DETAILS],
    [RouteName.PANEL_PROCEDURE_CREATOR]: [ROOT, PANEL, PROCEDURES, CREATOR, `{${PROCEDURE_ID}}`],
    [RouteName.PANEL_PROCEDURE_CREATOR_STAGES]: [ROOT, PANEL, PROCEDURES, CREATOR, `{${PROCEDURE_ID}}`, STAGES],
    [RouteName.PANEL_PROCEDURE_CREATOR_STAGE_DEFINITION]: [ROOT, PANEL, PROCEDURES, CREATOR, `{${PROCEDURE_ID}}`, STAGES, `{${STAGE_ID}}`],
    [RouteName.PANEL_PROCEDURE_CREATOR_STAGE_FIELD_ADD]: [ROOT, PANEL, PROCEDURES, CREATOR, `{${PROCEDURE_ID}}`, STAGES, `{${STAGE_ID}}`, FIELDS, ADD],
    [RouteName.PANEL_PROCEDURE_CREATOR_STAGE_FIELD_UPDATE]: [ROOT, PANEL, PROCEDURES, CREATOR, `{${PROCEDURE_ID}}`, STAGES, `{${STAGE_ID}}`, FIELDS, `{${FIELD_ID}}`],
    [RouteName.PANEL_CONTACTS]: [ROOT, PANEL, CONTACTS],
    [RouteName.PANEL_CONTACT_DETAILS]: [ROOT, PANEL, CONTACTS, `{${ID}}`, DETAILS],
    [RouteName.PANEL_DOCUMENTS]: [ROOT, PANEL, DOCUMENTS],
    [RouteName.PANEL_DOCUMENT_CREATE]: [ROOT, PANEL, DOCUMENTS, DIRECTORY, `{${ID}}`, CREATE],
    [RouteName.PANEL_DOCUMENT_DIRECTORY_DETAILS]: [ROOT, PANEL, DOCUMENTS, DIRECTORY, `{${ID}}`],
    [RouteName.PANEL_DOCUMENT_FILE_DETAILS]: [ROOT, PANEL, DOCUMENTS, FILE, `{${ID}}`],
    [RouteName.PANEL_DOCUMENT_FILE_UPDATE]: [ROOT, PANEL, DOCUMENTS, FILE, `{${ID}}`, UPDATE],
    [RouteName.PANEL_CAMERA]: [ROOT, PANEL, CAMERA],
    [RouteName.MANAGE_USERS_OR_PERMISSION_GROUPS]: [ROOT, MANAGE, USERS],
    [RouteName.MANAGE_USERS]: [ROOT, MANAGE, USERS, USERS],
    [RouteName.MANAGE_USERS_PERMISSIONS]: [ROOT, MANAGE, USERS, PERMISSIONS],
    [RouteName.MANAGE_USERS_PERMISSIONS_UPDATE]: [ROOT, MANAGE, USERS, PERMISSIONS, UPDATE],
    [RouteName.MANAGE_USERS_PERMISSIONS_CREATE]: [ROOT, MANAGE, USERS, PERMISSIONS, CREATE],
    [RouteName.CREATE_USER]: [ROOT, MANAGE, USERS, CREATE],
    [RouteName.UPDATE_USER]: [ROOT, MANAGE, USERS, `{${ID}}`, UPDATE],
    [RouteName.MANAGE_DICTIONARIES]: [ROOT, MANAGE, DICTIONARIES],
    [RouteName.MANAGE_DICTIONARY_ITEMS]: [ROOT, MANAGE, DICTIONARIES, `{${TYPE}}`, DETAILS],
    [RouteName.MANAGE_ATTRIBUTES]: [ROOT, MANAGE, ATTRIBUTES],
    [RouteName.MANAGE_ATTRIBUTES_PROCEDURES]: [ROOT, MANAGE, ATTRIBUTES, PROCEDURES],
    [RouteName.MANAGE_ATTRIBUTES_DOCUMENTS]: [ROOT, MANAGE, ATTRIBUTES, DOCUMENTS],
    [RouteName.MANAGE_SETTINGS]: [ROOT, MANAGE, SETTINGS],
    [RouteName.MANAGE_SETTINGS_PROCEDURES]: [ROOT, MANAGE, SETTINGS, PROCEDURES],
    [RouteName.MANAGE_SETTINGS_DOCUMENTS]: [ROOT, MANAGE, SETTINGS, DOCUMENTS],
    [RouteName.CHAT]: [ROOT, PANEL, CONTACTS, CHAT],
    [RouteName.CHAT_CONVERSATION]: [ROOT, PANEL, CONTACTS, CHAT, `{${ID}}`],
    [RouteName.CHAT_CONVERSATION_CREATE]: [ROOT, PANEL, CONTACTS, CHAT, CREATE, `{${ID}}`],
    [RouteName.VIDEO_CALL_ROOM]: [ROOT, PANEL, CONTACTS, VIDEO, `{${ID}}`],
    [RouteName.INCOMING_CALL_ACCEPT]: [ROOT, PANEL, CONTACTS, VIDEO, CALL, `{${ID}}`, ACCEPT],
    [RouteName.INCOMING_CALL_REJECT]: [ROOT, PANEL, CONTACTS, VIDEO, CALL, `{${ID}}`, REJECT],
    [RouteName.NOT_FOUND]: [ROOT, NOT_FOUND],
  };

  public constructor(private router: Router) {
  }

  public getPath(name: RouteName, params: Params = {}): string[] {
    const route: string[] = this.routes[name];

    return this.replaceRouteParams(route, params);
  }

  public getUrlTree(name: RouteName, params: Params = {}, options?: UrlCreationOptions): UrlTree {
    return this.router.createUrlTree(this.getPath(name, params), options);
  }

  private replaceRouteParams(route: string[], params: Params): string[] {
    const keys: string[] = Object.keys(params);

    return [
      ...route
        .map((element: string) => {
          const matchedKey: string | undefined = keys.find((key: string) => `{${key}}` === element);

          return !matchedKey
            ? element
            : params[matchedKey];
        }),
    ];
  }
}
