import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DASHBOARDS_API } from '@api/dashboards-api/services/tokens/dashboards-api.token';
import { DashboardsApiService } from '@api/dashboards-api/services/dashboards-api.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  declarations: [],
  providers: [
    {
      provide: DASHBOARDS_API,
      useClass: DashboardsApiService,
    },
  ],
})
export class DashboardsApiModule {
}
