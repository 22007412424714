import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayPanelPositionCorrectorDirective } from '@common/directives/overlay-panel/overlay-panel-position-corrector.directive';

@NgModule({
  declarations: [
    OverlayPanelPositionCorrectorDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    OverlayPanelPositionCorrectorDirective,
  ],
})
export class OverlayPanelPositionCorrectorModule {
}
