import { Injectable } from '@angular/core';
import { UserProfileApi } from '@api/cmuia-api/services/interfaces/user-profile.api';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { apiCMUiACommon } from '@environments/environment';
import { UpdateUserProfileRequest } from '@api/cmuia-api/models/requests/update-user-profile.request';
import { ChangeMyPasswordRequest } from '@api/cmuia-api/models/requests/change-my-password.request';
import { AppLoggedUserResponse } from '@api/cmuia-api/models/responses/app-logged-user.response';
import { UploadAvatarRequest } from '@api/cmuia-api/models/requests/upload-avatar.request';

@Injectable({
  providedIn: 'root',
})
export class UserProfileApiService implements UserProfileApi {

  public constructor(
    private http: HttpClient,
    private apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public update(request: UpdateUserProfileRequest): Observable<Object> {
    const url = this.grabEndpointUrl(apiCMUiACommon.ME.PROFILE);
    const payload = this.apiPayloadProcessor.toObject(request, UpdateUserProfileRequest);

    return this.http.patch(url, payload);
  }

  public me(): Observable<AppLoggedUserResponse> {
    const url = this.grabEndpointUrl(apiCMUiACommon.ME.PROFILE);

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToModel(AppLoggedUserResponse),
    );
  }

  public changePassword(request: ChangeMyPasswordRequest): Observable<Object> {
    const url = this.grabEndpointUrl(apiCMUiACommon.ME.CHANGE_PASSWORD);
    const payload = this.apiPayloadProcessor.toObject(request, ChangeMyPasswordRequest);

    return this.http.post(url, payload);
  }

  public uploadAvatar(request: UploadAvatarRequest): Observable<Object> {
    const url = this.grabEndpointUrl(apiCMUiACommon.ME.UPLOAD_AVATAR);

    const formData = new FormData();
    formData.append('file', request.file);

    return this.http.post(url, formData);
  }

  public deleteAvatar(): Observable<Object> {
    const url = this.grabEndpointUrl(apiCMUiACommon.ME.DELETE_AVATAR);

    return this.http.delete(url);
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiCMUiACommon.API_HOST_URL, endpoint].join('');
  }

}
