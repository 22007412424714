import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastMessage } from '@app/common/components/toast/interface/toast-message.interface';
import { ToastMessageSeverity } from '@common/components/toast/enums/toast-message-severity.enum';
import { ToastMessageBuilder } from '@common/components/toast/services/toast-message.builder';
import { ApiValidationErrorsReader } from '@api/helpers/api-validation-errors.reader';

@Injectable({
  providedIn: 'root',
})
export class ApiResponseMessageBuilderService {

  private severity: ToastMessageSeverity = ToastMessageSeverity.SUCCESS;
  private title: string = this.defaultTitle(ToastMessageSeverity.SUCCESS);
  private message?: string;
  private defaultMessage?: string;
  private sticky: boolean = false;

  public constructor(
    private readonly toastMessageBuilder: ToastMessageBuilder,
  ) {
  }

  public reset(): this {
    this.title = this.defaultTitle(ToastMessageSeverity.SUCCESS);
    this.message = undefined;
    this.defaultMessage = undefined;
    this.severity = ToastMessageSeverity.SUCCESS;
    this.sticky = false;

    return this;
  }

  public initSuccessMessage(title: string, message: string): this {
    return this
      .setSeverity(ToastMessageSeverity.SUCCESS)
      .setTitle(title)
      .setMessage(message);
  }

  public initInfoMessage(title: string, message: string): this {
    return this
      .setSeverity(ToastMessageSeverity.INFO)
      .setTitle(title)
      .setMessage(message);
  }

  public initErrorMessage(title: string, message: string): this {
    return this
      .setSeverity(ToastMessageSeverity.ERROR)
      .setTitle(title)
      .setMessage(message);
  }

  public initHttpErrorMessage(err: HttpErrorResponse): this {
    const errorsReader: ApiValidationErrorsReader = new ApiValidationErrorsReader(err.error);
    const message: string | undefined = errorsReader.firstErrorMessage();

    if (message) {
      this.setMessage(message);
    }

    return this
      .setSeverity(ToastMessageSeverity.ERROR)
      .setTitle(this.defaultTitle(ToastMessageSeverity.ERROR));
  }

  public initHttpErrorDetailsMessage(err: HttpErrorResponse): this {
    const errorsReader: ApiValidationErrorsReader = new ApiValidationErrorsReader(err.error);
    const details: string | undefined = errorsReader.getDetails();

    if (details) {
      this.setMessage(details);
    }

    return this
      .setSeverity(ToastMessageSeverity.ERROR);
  }

  public setTitle(title: string): this {
    this.title = title;

    return this;
  }

  public setMessage(body: string): this {
    this.message = body;

    return this;
  }

  public setDefaultMessage(message: string): this {
    this.defaultMessage = message;

    return this;
  }

  public setSeverity(severity: ToastMessageSeverity): this {
    this.severity = severity;

    return this;
  }

  public setSticky(sticky: boolean): this {
    this.sticky = sticky;

    return this;
  }

  public build(): ToastMessage {
    if (!this.message && !this.defaultMessage) {
      throw new Error('Message or default message must be set.');
    }

    const message: string = this.message || this.defaultMessage as string;
    const toast: ToastMessage = this
      .toastMessageBuilder
      .setBody(message)
      .setTitle(this.title)
      .setSeverity(this.severity)
      .setSticky(this.sticky)
      .build();

    this.reset();

    return toast;
  }

  private defaultTitle(severity: ToastMessageSeverity): string {
    switch (severity) {
      case ToastMessageSeverity.SUCCESS:
        return 'MESSAGE.GENERAL.SUCCESS.TITLE';

      case ToastMessageSeverity.WARN:
        return 'MESSAGE.GENERAL.WARN.TITLE';

      case ToastMessageSeverity.ERROR:
        return 'MESSAGE.GENERAL.ERROR.TITLE';

      case ToastMessageSeverity.INFO:
        return 'MESSAGE.GENERAL.INFO.TITLE';
    }
  }

}
