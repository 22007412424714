import { Injectable } from '@angular/core';
import { AssetsImage } from '../enums/assets-image.enum';

@Injectable({
  providedIn: 'root',
})
export class AssetsImageProvider {

  private readonly ASSETS_ROOT_PATH = '/assets/images/';

  public getUrl(name: AssetsImage): string {
    return [this.ASSETS_ROOT_PATH, name].join('');
  }

}
