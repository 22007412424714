import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DOCUMENTS_MANAGER_API } from '@api/documents-api/services/tokens/documents-manager-api.token';
import { DocumentsManagerApiService } from '@api/documents-api/services/documents-manager-api.service';
import { FILE_SYSTEM_REPOSITORY_API } from '@api/documents-api/services/tokens/file-system-repository-api.token';
import { FileSystemRepositoryApiService } from '@api/documents-api/services/file-system-repository-api.service';
import { ATTRIBUTES_OF_DOCUMENTS_API } from '@api/documents-api/services/tokens/attributes-of-documents-api.token';
import { AttributesOfDocumentsService } from '@api/documents-api/services/attributes-of-documents.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
  providers: [
    {
      provide: DOCUMENTS_MANAGER_API,
      useClass: DocumentsManagerApiService,
    },
    {
      provide: FILE_SYSTEM_REPOSITORY_API,
      useClass: FileSystemRepositoryApiService,
    },
    {
      provide: ATTRIBUTES_OF_DOCUMENTS_API,
      useClass: AttributesOfDocumentsService,
    },
  ],
})
export class DocumentsApiModule {
}
