import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { RefreshResponse } from '@api/cmuia-api/models/responses/refresh.response';
import { apiCMUiACommon } from '@environments/environment';
import { TokenProvider } from '../services/token.provider';
import { AuthService } from '../services/auth.service';
import { NavigateService } from '../../routing/services/navigate.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { RouteName } from '../../routing/route-name.enum';
import { HttpStatusCode } from '../../http/consts/http-status-code.const';
import { HttpRequestHeader } from '../../http/consts/http-request-header.const';
import { ToastMessageService } from '@common/components/toast/services/toast-message.service';

/**
 * Implementacja w oparciu o artykuł na Medium.
 * https://itnext.io/angular-tutorial-implement-refresh-token-with-httpinterceptor-bfa27b966f57
 */
@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  private readonly AUTHORIZATION_HEADER_EXCLUDED_ENDPOINTS: string[] = [
    apiCMUiACommon.AUTHENTICATION.SIGN_IN,
    apiCMUiACommon.AUTHENTICATION.REFRESH,
  ];
  private readonly ERROR_HANDLER_EXCLUDED_ENDPOINTS: string[] = [
    apiCMUiACommon.AUTHENTICATION.SIGN_IN,
    apiCMUiACommon.AUTHENTICATION.REFRESH,
  ];

  private refreshTokenInProgress: boolean = false;
  private refreshTokenSubject: BehaviorSubject<RefreshResponse | null> = new BehaviorSubject<RefreshResponse | null>(null);

  public constructor(
    private readonly tokenProvider: TokenProvider,
    private readonly authService: AuthService,
    private readonly navigateService: NavigateService,
    private readonly toastMessagesService: ToastMessageService,
  ) {
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { url } = request;

    return next
      .handle(this.addAuthenticationToken(request))
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (this.isExcludedByErrorHandler(url)) {
            if (url.includes(apiCMUiACommon.AUTHENTICATION.REFRESH)) {
              this.navigateToLogoutPage();
            }

            return throwError(error);
          }

          switch (error.status) {
            case HttpStatusCode.FORBIDDEN: {
              //this.handleForbidden();

              return throwError(error);
            }
            case HttpStatusCode.NOT_FOUND: {
              this.navigateService.navigate(RouteName.NOT_FOUND);

              return throwError(error);
            }
            default: {
              return throwError(error);
            }
          }

          if (!this.authService.hasRefreshToken()) {
            return throwError(error);
          }

          if (this.refreshTokenInProgress) {
            return this.refreshTokenSubject.pipe(
              filter(Boolean),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(request))),
            );
          }

          this.refreshTokenInProgress = true;
          this.refreshTokenSubject.next(null);

          return this.authService.refresh().pipe(
            switchMap((response: RefreshResponse) => {
              this.refreshTokenInProgress = false;
              this.refreshTokenSubject.next(response);

              return next.handle(this.addAuthenticationToken(request));
            }),
            catchError(() => {
              this.refreshTokenInProgress = false;
              this.navigateToLogoutPage();

              return throwError(error);
            }),
          );
        }));
  }

  private isExcludedByAuthorizationHeader(url: string): boolean {
    return this.AUTHORIZATION_HEADER_EXCLUDED_ENDPOINTS
      .reduce((excluded: boolean, currentUrl: string) => url.includes(currentUrl) || excluded, false);
  }

  private isExcludedByErrorHandler(url: string): boolean {
    return this.ERROR_HANDLER_EXCLUDED_ENDPOINTS
      .reduce((excluded: boolean, currentUrl: string) => url.includes(currentUrl) || excluded, false);
  }

  private navigateToLogoutPage(): void {
    this.navigateService.navigate(RouteName.SIGN_OUT);
  }

  private addAuthenticationToken(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const token: string | null = this.tokenProvider.token;

    if (!token) {
      return request;
    }

    const { url } = request;

    if (this.isExcludedByAuthorizationHeader(url)) {
      return request;
    }

    return request.clone({
      setHeaders: {
        [HttpRequestHeader.AUTHORIZATION]: `Bearer ${token}`,
      },
    });
  }

  private handleForbidden(): void {
    this.navigateService.navigate(RouteName.DASHBOARD);
    this.showForbiddenMessage();
  }

  private showForbiddenMessage(): void {
    this
      .toastMessagesService
      .enqueue(
        this
          .toastMessagesService
          .messageBuilder()
          .setErrorSeverity()
          .setTitle('MESSAGE.GENERAL.FORBIDDEN.TITLE.FAILURE')
          .setBody('MESSAGE.GENERAL.FORBIDDEN.BODY.FAILURE')
          .build(),
      );
  }

}
