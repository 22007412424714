import { Injectable, OnDestroy } from '@angular/core';
import { Translation } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { primengTranslations } from '@core/translations/data/primeng-translations';
import { mapValuesDeep } from '@common/helpers/map-values-deep.helper';
import { isArray } from 'lodash-es';
import { Language } from '@core/translations/enums/language.enum';

@Injectable({
  providedIn: 'root',
})
export class PrimengTranslationProvider implements OnDestroy {

  private readonly primengTranslations = primengTranslations;
  private readonly destroy$ = new Subject<void>();

  public constructor(
    private readonly translateService: TranslateService,
  ) {
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public getCurrentTranslation(): Observable<Translation> {
    return this
      .listenForLanguageChange()
      .pipe(
        map(() => {
          const translation: Translation = mapValuesDeep(this.primengTranslations, (key: string | string[]) => {
            return this.prepareTranslations(key);
          });

          return translation;
        }),
        takeUntil(this.destroy$),
      );
  }

  private listenForLanguageChange(): Observable<string> {
    return this
      .translateService
      .onLangChange
      .pipe(
        map(({ lang }) => lang),
        startWith(Language.POLISH),
      );
  }

  private prepareTranslations(key: string | string[]): string | string[] {
    return isArray(key) ? key.map(item => this.getLabel(item)) : this.getLabel(key);
  }

  private getLabel(key: string): string {
    return this.translateService.instant(key);
  }

}
