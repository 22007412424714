export enum FileExtension {

  // Image
  TTF = 'ttf',
  JPG = 'jpg',
  JPEG = 'jpeg',
  BMP = 'bmp',
  GIF = 'gif',
  PNG = 'png',
  TIF = 'tif',
  RAW = 'raw',

  // Graphic
  SVG = 'svg',
  EPS = 'eps',
  AI = 'ai',
  CDR = 'cdr',
  PS = 'ps',
  CAD = 'cad',
  FLA = 'fla',
  PSD = 'psd',

  // Video
  MP4 = 'mp4',
  MOV = 'mov',
  AVI = 'avi',
  FLV = 'flv',
  MPG = 'mpg',
  WMV = 'wmv',

  // Audio
  MP3 = 'mp3',
  AAC = 'aac',
  MIDI = 'midi',

  // Archive
  ZIP = 'zip',
  ISO = 'iso',
  DMG = 'dmg',

  // Language / script
  DS = 'ds',
  CSS = 'css',
  JS = 'js',
  PHP = 'php',
  SQL = 'sql',
  XML = 'xml',
  HTML = 'html',

  // Text
  TXT = 'txt',
  DOC = 'doc',
  PDF = 'pdf',

  // Library
  DLL = 'dll',

  // Data
  DAT = 'dat',

  // Spreadsheet
  XLS = 'xls',
  XLSX = 'xlsx',

  // Presentation
  PPT = 'ppt',

  // Other
  INDD = 'indd',

}
