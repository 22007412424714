import { Injectable } from '@angular/core';
import { LoadingIndicatorService } from '../services/loading-indicator.service';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LOADING_INDICATOR_ENABLED } from '@core/layouts/components/loading-indicator/interceptors/loading-interceptor-context-params';

@Injectable()
export class LoadingIndicatorInterceptor {

  public constructor(private loadingIndicatorService: LoadingIndicatorService) {
  }

  public intercept(request: HttpRequest<void>, next: HttpHandler): Observable<HttpEvent<void>> {
    const isLoaderIndicatorEnabled = request.context.get(LOADING_INDICATOR_ENABLED);

    if (isLoaderIndicatorEnabled) {
      this.show();
    }

    return next
      .handle(request)
      .pipe(
        finalize(() => {
          if (isLoaderIndicatorEnabled) {
            this.hide();
          }
        }),
      );
  }

  private show(): void {
    this.loadingIndicatorService.show();
  }

  private hide(): void {
    this.loadingIndicatorService.hide();
  }
}
