import { Uuid } from '@app/api/types/uuid.type';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { UserPermissionResponse } from './user-permission.response';
import { PermissionGroupContextOperationsResponse } from '@api/cmuia-api/models/responses/permission-group-context-operations.response';

@jsonObject
export class UserPermissionGroupDetailsResponse {
  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly name!: string;
  @jsonArrayMember(UserPermissionResponse) public readonly permissions!: Array<UserPermissionResponse>;
  @jsonMember public readonly contextOperations!: PermissionGroupContextOperationsResponse;
}
