import { jsonArrayMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';

@jsonObject
export class CancelOutgoingCallsRequest {
  @jsonArrayMember(String) public callsIds!: Uuid[];

  public constructor(callsIds: Uuid[]) {
    this.callsIds = callsIds;
  }
}
