import { ResourceFilterModel } from '@api/models/collection/requests/resource-filter.model';
import { jsonArrayMember, jsonObject } from 'typedjson';
import { AttributeFilterModel } from '@api/models/collection/requests/attribute-filter.model';

@jsonObject
export class ProcedureVerificationFilter extends ResourceFilterModel {
  @jsonArrayMember(AttributeFilterModel) public id?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public procedureId?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public verifierId?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public verifierDignity?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public status?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public comment?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public createdAt?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public acceptedAt?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel) public rejectedAt?: AttributeFilterModel[];
  @jsonArrayMember(AttributeFilterModel, { name: 'query' }) public global?: AttributeFilterModel[];
}
