export enum ProcedureFieldType {

  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  INTEGER = 'INTEGER',
  BOOLEAN = 'BOOLEAN',
  SELECT = 'SELECT',
  MULTIPLE_SELECT = 'MULTIPLE_SELECT',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',

}
