import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { Uuid } from '@api/types/uuid.type';
import { Observable } from 'rxjs';
import { TaskStageCollectionResponse } from '@api/procedures-api/models/responses/task-stage-collection.response';
import { apiProceduresCommon, apiProceduresManagement } from '@environments/environment';
import { TaskStageResponse } from '@api/procedures-api/models/responses/task-stage.response';
import { apiParams } from '@environments/const/environment.const';

const { TASK_ID, STAGE_ID } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class TaskStagesApiService {

  public constructor(
    private readonly http: HttpClient,
    private readonly payloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public stages(taskId: Uuid): Observable<TaskStageCollectionResponse[]> {
    const { TASKS: { STAGES: { LIST } } } = apiProceduresManagement;
    const url = this.grabEndpointUrl(LIST)
      .replace(TASK_ID, taskId.toString());

    return this
      .http
      .get(url)
      .pipe(this.payloadProcessor.mapToModelArray(TaskStageCollectionResponse));
  }

  public details(taskId: Uuid, stageId: Uuid): Observable<TaskStageResponse> {
    const { TASKS: { STAGES: { DETAILS } } } = apiProceduresManagement;
    const url = this.grabEndpointUrl(DETAILS)
      .replace(TASK_ID, taskId.toString())
      .replace(STAGE_ID, stageId.toString());

    return this
      .http
      .get(url)
      .pipe(this.payloadProcessor.mapToModel(TaskStageResponse));
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiProceduresCommon.API_HOST_URL, endpoint].join('');
  }
}
