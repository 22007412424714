import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConversationFilter } from '@api/conversations-api/models/filters/conversation.filter';
import { CreateConversationRequest } from '@api/conversations-api/models/requests/create-conversation.request';
import { ChatTokenResponse } from '@api/conversations-api/models/responses/chat-token.response';
import { ConversationResponse } from '@api/conversations-api/models/responses/conversation.response';
import { ConversationsCollectionRequestBuilder } from '@api/conversations-api/services/conversations-collection-request.builder';
import { ChatApi } from '@api/conversations-api/services/interfaces/chat.api';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { CollectionRequest } from '@api/models/collection/requests/collection.request';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';
import { Uuid } from '@api/types/uuid.type';
import { apiConversationsCommon } from '@environments/environment';
import { Observable } from 'rxjs';
import { UnreadConversationResponse } from '@api/conversations-api/models/responses/unread-conversation.response';
import { apiParams } from '@environments/const/environment.const';
import { DeleteParticipantsRequest } from '@api/conversations-api/models/requests/delete-participants.request';
import { ContactResponse } from '@api/contacts-api/models/responses/contact.response';

const { ID } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class ChatApiService implements ChatApi {

  public constructor(
    private http: HttpClient,
    private apiPayloadProcessor: ApiPayloadProcessorService,
    private conversationsCollectionRequestBuilder: ConversationsCollectionRequestBuilder,
  ) {
  }

  public getToken(): Observable<ChatTokenResponse> {
    const url = this.grabEndpointUrl(apiConversationsCommon.CHAT.TOKEN);

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToModel(ChatTokenResponse),
    );
  }

  public createConversation(request: CreateConversationRequest): Observable<ConversationResponse> {
    const url = this.grabEndpointUrl(apiConversationsCommon.CHAT.CONVERSATIONS.CREATE);
    const payload = this.apiPayloadProcessor.toObject(request, CreateConversationRequest);

    return this.http.post(url, payload).pipe(
      this.apiPayloadProcessor.mapToModel(ConversationResponse),
    );
  }

  public deleteConversation(id: Uuid): Observable<Object> {
    const { CONVERSATIONS: { DELETE } } = apiConversationsCommon.CHAT;
    const url = this
      .grabEndpointUrl(DELETE)
      .replace(ID, id.toString());

    return this.http.delete(url);
  }

  public conversationDetails(id: Uuid): Observable<ConversationResponse> {
    const url = this.grabEndpointUrl(apiConversationsCommon.CHAT.CONVERSATIONS.DETAILS).replace(ID, id.toString());

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToModel(ConversationResponse),
    );
  }

  public conversationsList(request: CollectionRequest<ConversationFilter>): Observable<CollectionResponse<ConversationResponse>> {
    const requestQueryString = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, ConversationFilter);
    const url = this.grabEndpointUrl(apiConversationsCommon.CHAT.CONVERSATIONS.LIST) + '?' + requestQueryString;

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToCollection(ConversationResponse),
    );
  }

  public getConversationsCollectionRequestBuilder(): ConversationsCollectionRequestBuilder {
    return this.conversationsCollectionRequestBuilder;
  }

  public unreadConversationList(): Observable<UnreadConversationResponse[]> {
    const url = this.grabEndpointUrl(apiConversationsCommon.CHAT.CONVERSATIONS.UNREAD_LIST);

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToModelArray(UnreadConversationResponse),
    );
  }

  public markAllConversationsAsRead(): Observable<void> {
    const url = this.grabEndpointUrl(apiConversationsCommon.CHAT.CONVERSATIONS.MARK_ALL_READ);

    return this.http.post<void>(url, {});
  }

  public leaveConversation(id: Uuid, newAdminContactId?: Uuid): Observable<void> {
    const url = this.grabEndpointUrl(apiConversationsCommon.CHAT.CONVERSATIONS.LEAVE).replace(ID, id.toString());

    return this.http.post<void>(url, { newAdminContactId });
  }

  public updateConversation(id: Uuid, name: string): Observable<void> {
    const url = this.grabEndpointUrl(apiConversationsCommon.CHAT.CONVERSATIONS.DETAILS).replace(ID, id.toString());

    return this.http.patch<void>(url, { name });
  }

  public forwardMessage(messageIdOrSid: Uuid | string, targetContactsIds: Uuid[]): Observable<void> {
    const url = this.grabEndpointUrl(apiConversationsCommon.CHAT.CONVERSATIONS.FORWARD_MESSAGE).replace(ID, messageIdOrSid.toString());

    return this.http.post<void>(url, {
      contactsIds: targetContactsIds,
    });
  }

  public deleteMessage(messageIdOrSid: string | Uuid): Observable<void> {
    const url = this.grabEndpointUrl(apiConversationsCommon.CHAT.CONVERSATIONS.DELETE_MESSAGE).replace(ID, messageIdOrSid.toString());

    return this.http.delete<void>(url);
  }

  public addParticipants(id: Uuid, contactsIds: Uuid[]): Observable<void> {
    const url = this.grabEndpointUrl(apiConversationsCommon.CHAT.CONVERSATIONS.ADD_PARTICIPANTS).replace(ID, id.toString());

    return this.http.post<void>(url, {
      participantsIds: contactsIds,
    });
  }

  public participants(id: Uuid): Observable<ContactResponse[]> {
    const { CONVERSATIONS: { PARTICIPANTS_LIST } } = apiConversationsCommon.CHAT;
    const url = this
      .grabEndpointUrl(PARTICIPANTS_LIST)
      .replace(ID, id.toString());

    return this
      .http
      .get(url).pipe(this.apiPayloadProcessor.mapToModelArray(ContactResponse));
  }

  public deleteParticipants(id: Uuid, request: DeleteParticipantsRequest): Observable<void> {
    const { CONVERSATIONS: { DELETE_PARTICIPANTS } } = apiConversationsCommon.CHAT;
    const url = this
      .grabEndpointUrl(DELETE_PARTICIPANTS)
      .replace(ID, id.toString());
    const body = this
      .apiPayloadProcessor
      .toObject(request, DeleteParticipantsRequest);

    return this
      .http
      .delete<void>(url, { body });
  }

  public conversationFileDownloadUrl(mediaSid: string): string {
    return this.grabEndpointUrl(apiConversationsCommon.CHAT.FILES.DOWNLOAD).replace(ID, mediaSid);
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiConversationsCommon.API_HOST_URL, endpoint].join('');
  }
}
