import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';

@jsonObject
export class CollectionEntryDetailsAttributeResponse {

  @jsonMember(String) public readonly attributeId!: Uuid;
  @jsonMember public readonly value!: string;

}
