import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoverLayoutHeaderComponent } from './cover-layout-header.component';
import { LayoutHeaderLangPickerModule } from '@core/layouts/components/layout-header-lang-picker/layout-header-lang-picker.module';

@NgModule({
  imports: [
    CommonModule,
    LayoutHeaderLangPickerModule,
  ],
  declarations: [CoverLayoutHeaderComponent],
  exports: [CoverLayoutHeaderComponent],
})
export class CoverLayoutHeaderModule {
}
