import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { AdminUsersApi } from '@api/cmuia-api/services/interfaces/admin-users.api';
import { Observable } from 'rxjs';
import { UserResponse } from '@api/cmuia-api/models/responses/user.response';
import { apiCMUiAAdmin } from '@environments/environment';
import { Uuid } from '@api/types/uuid.type';
import { UpdateUserRequest } from '@api/cmuia-api/models/requests/update-user.request';
import { CreateUserRequest } from '@api/cmuia-api/models/requests/create-user.request';
import { IdResponse } from '@api/interfaces/id.response';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';
import { CollectionRequest } from '@api/models/collection/requests/collection.request';
import { UserFilter } from '@api/cmuia-api/models/filters/user.filter';
import { UsersCollectionRequestBuilder } from '@api/cmuia-api/services/users-collection-request.builder';

@Injectable({
  providedIn: 'root',
})
export class AdminUsersApiService implements AdminUsersApi {

  public constructor(
    private http: HttpClient,
    private apiPayloadProcessor: ApiPayloadProcessorService,
    private collectionRequestBuilder: UsersCollectionRequestBuilder,
  ) {
  }

  public list(request: CollectionRequest<UserFilter>): Observable<CollectionResponse<UserResponse>> {
    const requestQueryString = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, UserFilter);
    const url = this.grabEndpointUrl(apiCMUiAAdmin.USERS.LIST) + '?' + requestQueryString;

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToCollection(UserResponse),
    );
  }

  public details(id: Uuid): Observable<UserResponse> {
    const url = this.grabEndpointUrl(apiCMUiAAdmin.USERS.DETAILS).replace('{id}', id.toString());

    return this.http.get(url).pipe(
      this.apiPayloadProcessor.mapToModel(UserResponse),
    );
  }

  public create(request: CreateUserRequest): Observable<IdResponse> {
    const url = this.grabEndpointUrl(apiCMUiAAdmin.USERS.CREATE);
    const payload = this.apiPayloadProcessor.toObject(request, CreateUserRequest);

    return this.http.post(url, payload).pipe(
      this.apiPayloadProcessor.mapToModel(IdResponse),
    );
  }

  public update(request: UpdateUserRequest): Observable<Object> {
    const url = this.grabEndpointUrl(apiCMUiAAdmin.USERS.UPDATE).replace('{id}', request.id.toString());
    const payload = this.apiPayloadProcessor.toObject(request, UpdateUserRequest);

    return this.http.patch(url, payload);
  }

  public activate(id: Uuid): Observable<Object> {
    const url = this.grabEndpointUrl(apiCMUiAAdmin.USERS.ACTIVATE).replace('{id}', id.toString());

    return this.http.post(url, {});
  }

  public deactivate(id: Uuid): Observable<Object> {
    const url = this.grabEndpointUrl(apiCMUiAAdmin.USERS.DEACTIVATE).replace('{id}', id.toString());

    return this.http.post(url, {});
  }

  public getCollectionRequestBuilder(): UsersCollectionRequestBuilder {
    return this.collectionRequestBuilder;
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiCMUiAAdmin.API_HOST_URL, endpoint].join('');
  }
}
