import { Uuid } from '@app/api/types/uuid.type';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class UserPermissionResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly name!: string;
  @jsonMember public readonly category!: string;
  @jsonMember public readonly code!: string;

}
