import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { CollectionMetadata } from '@api/models/collection/responses/collection-metadata';

@jsonObject
export class CollectionResponse<Item> {
  @jsonArrayMember(Object) public readonly collection!: Item[];
  @jsonMember public readonly metadata!: CollectionMetadata;

  public static from<Item>(collection: Item[], metadata: CollectionMetadata): CollectionResponse<Item> {
    return Object.assign(new CollectionResponse<Item>(), {
      collection: collection,
      metadata: metadata,
    });
  }
}
