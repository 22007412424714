import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationMessageService } from '@common/components/confirmation/service/confirmation-message.service';


@NgModule({
  imports: [
    CommonModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
  ],
  declarations: [
    ConfirmationDialogComponent,
    ConfirmationPopupComponent,
  ],
  exports: [
    ConfirmationDialogComponent,
    ConfirmationPopupComponent,
  ],
  providers: [
    ConfirmationService,
    ConfirmationMessageService,
  ],
})
export class ConfirmationModule {
}
