import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { ProcedureUserDignityResponse } from '@api/procedures-api/models/responses/procedure-user-dignity.response';
import { ProcedureVerificationStatus } from '@api/procedures-api/models/enums/procedure-verification-status';
import { ProcedureVerificationContextOperationsResponse } from '@api/procedures-api/models/responses/procedure-verification-context-operations.response';

@jsonObject()
export class ProcedureVerificationResponse {
  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember(String) public readonly procedureId!: Uuid;
  @jsonMember public readonly verifier!: ProcedureUserDignityResponse;
  @jsonMember(String) public readonly status!: ProcedureVerificationStatus;
  @jsonMember public readonly comment?: string;
  @jsonMember public readonly createdAt!: Date;
  @jsonMember public readonly acceptedAt?: Date;
  @jsonMember public readonly rejectedAt?: Date;
  @jsonMember public readonly contextOperations!: ProcedureVerificationContextOperationsResponse;
}
