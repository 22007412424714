import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserContextModel } from '../models/user-context.model';

@Injectable({
  providedIn: 'root',
})
export class UserContextService {
  private userContextSource$: BehaviorSubject<UserContextModel | null> = new BehaviorSubject<UserContextModel | null>(null);

  public get userContext$(): Observable<UserContextModel | null> {
    return this.userContextSource$.asObservable();
  }

  public set(model: UserContextModel): void {
    this.userContextSource$.next(model);
  }

  public clear(): void {
    this.userContextSource$.next(null);
  }

  public hasContext(): boolean {
    return !!this.userContextSource$.getValue();
  }

  public get context(): UserContextModel | null {
    return this.userContextSource$.getValue();
  }
}
