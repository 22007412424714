import { Uuid } from '@api/types/uuid.type';
import { DictionaryType } from '@api/dictionaries-api/models/enums/dictionary-type';
import { jsonMember, jsonObject } from 'typedjson';
import { DictionaryItemOptionsResponse } from '@api/dictionaries-api/models/responses/dictionary-item-options.response';
import { DictionaryItemContextOperationsResponse } from '@api/dictionaries-api/models/responses/dictionary-item-context-operations.response';

@jsonObject
export class DictionaryItemResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember(String) public readonly parentId!: Uuid;
  @jsonMember(String) public readonly dictionaryType!: DictionaryType;
  @jsonMember public readonly name!: string;
  @jsonMember public readonly description?: string;
  @jsonMember public readonly value!: string;
  @jsonMember public readonly colour?: string;
  @jsonMember public readonly isActive!: boolean;
  @jsonMember public readonly contextOperations!: DictionaryItemContextOperationsResponse;
  @jsonMember public readonly options?: DictionaryItemOptionsResponse;

}
