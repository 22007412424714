import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardLayoutSidebarComponent } from './dashboard-layout-sidebar.component';
import { RippleModule } from 'primeng/ripple';
import { RouterModule } from '@angular/router';
import { AssetsModule } from '@core/assets/assets.module';
import { RoutingPipesModule } from '@core/routing/pipes/routing-pipes.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    RippleModule,
    AssetsModule,
    RoutingPipesModule,
  ],
  declarations: [DashboardLayoutSidebarComponent],
  exports: [DashboardLayoutSidebarComponent],
})
export class DashboardLayoutSidebarModule {
}
