import { ChangeDetectorRef, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { Breadcrumb } from 'primeng/breadcrumb';
import { MenuItem } from 'primeng/api';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'p-breadcrumb',
})
export class BreadcrumbsSeparatorDirective implements OnChanges {

  private readonly CSS_CLASSES_TO_REMOVE = [
    'pi',
    'pi-chevron-right',
  ];

  @Input() public model?: MenuItem[];

  public constructor(private breadcrumbsCmp: Breadcrumb,
                     private breadcrumbsCmpElement: ElementRef,
                     private cd: ChangeDetectorRef,
                     private renderer: Renderer2) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.overrideSeparators();
  }

  private overrideSeparators(): void {
    this.cd.detectChanges();

    const htmlElement: HTMLElement = this.breadcrumbsCmpElement.nativeElement;
    const chevrons = htmlElement.querySelectorAll('.p-breadcrumb-chevron');

    chevrons.forEach((oneChevron) => {
      this.overrideChevron(oneChevron);
    });
  }

  private overrideChevron(oneChevron: Element): void {
    this.CSS_CLASSES_TO_REMOVE.forEach((cssClass: string) => {
      this.renderer.removeClass(oneChevron, cssClass);
    });
    this.renderer.appendChild(oneChevron, this.renderer.createText('\\'));
  }
}
