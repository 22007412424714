import { AdminDictionariesApi } from '@api/dictionaries-api/services/interfaces/admin-dictionaries.api';
import { Observable } from 'rxjs';
import { DictionaryResponse } from '@api/dictionaries-api/models/responses/dictionary.response';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { apiDictionariesCommon } from '@environments/environment';
import { DictionaryType } from '@api/dictionaries-api/models/enums/dictionary-type';
import { CollectionRequest } from '@app/api/models/collection/requests/collection.request';
import { DictionaryFilter } from '../models/filters/dictionary.filter';
import { CollectionResponse } from '@app/api/models/collection/responses/collection.response';
import { apiParams } from '@environments/const/environment.const';

const { TYPE } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class AdminDictionariesApiService implements AdminDictionariesApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(request: CollectionRequest<DictionaryFilter>): Observable<CollectionResponse<DictionaryResponse>> {
    const { DICTIONARIES: { LIST } } = apiDictionariesCommon;
    const requestQueryString: string = this.apiPayloadProcessor.mapToCollectionRequestQueryString(request, DictionaryFilter);
    const url: string = `${this.grabEndpointUrl(LIST)}?${requestQueryString}`;

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToCollection(DictionaryResponse),
      );
  }

  public details(type: DictionaryType): Observable<DictionaryResponse> {
    const url: string = this.grabEndpointUrl(apiDictionariesCommon.DICTIONARIES.DETAILS).replace(TYPE, type);

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToModel(DictionaryResponse),
      );
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiDictionariesCommon.API_HOST_URL, endpoint].join('');
  }

}
