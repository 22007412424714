import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { NOTIFIER_API } from '@api/notifier-api/services/tokens/notifier-api.token';
import { NotifierApi } from '@api/notifier-api/services/interfaces/notifier.api';

@Injectable({
  providedIn: 'root',
})
export class FcmMessagingService {

  private messages$ = new Subject<unknown>();

  public constructor(private angularFireMessaging: AngularFireMessaging,
                     @Inject(NOTIFIER_API) private notifierApiService: NotifierApi,
  ) {
    this.angularFireMessaging.messages.subscribe(
      // @ts-ignore
      (_messaging) => {
        // @ts-ignore
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        // @ts-ignore
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      },
    );
  }

  public registerDevice(): void {
    // https://www.twilio.com/docs/chat/javascript/push-notifications-web
    this.requestPermission()
      .subscribe(
        (registrationId) => {
          if (registrationId) {
            this.registerBackendDevice(registrationId);
          }
        },
        (err) => {
          console.error('Nie udalo sie zarejestrowac w FCM', err);
        },
      );

    this.receiveMessage()
      .subscribe((payload) => {
        this.messages$.next(payload);
      });
  }

  public unregisterDevice(): void {
    this.angularFireMessaging
      .getToken
      .subscribe((registrationId) => {
        if (registrationId) {
          this.unregisterFcmDevice(registrationId);
          this.unregisterBackendDevice(registrationId);
        }
      });
  }

  private unregisterFcmDevice(registrationId: string): void {
    this.angularFireMessaging
      .deleteToken(registrationId)
      .subscribe();
  }

  private registerBackendDevice(registrationId: string): void {
    this.notifierApiService
      .registerPushDevice(registrationId)
      .subscribe();
  }

  private unregisterBackendDevice(registrationId: string): void {
    this.notifierApiService
      .unregisterPushDevice(registrationId)
      .subscribe();
  }

  private requestPermission(): Observable<string | null> {
    return this.angularFireMessaging.requestToken;
  }

  private receiveMessage(): Observable<unknown> {
    return this.angularFireMessaging.messages;
  }
}
