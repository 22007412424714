import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@api/types/uuid.type';
import { TaskStageStatus } from '@api/procedures-api/models/enums/task-stage-status.enum';
import { ProcedureStageResponse } from '@api/procedures-api/models/responses/procedure-stage.response';
import { TaskStageFieldResponse } from '@api/procedures-api/models/responses/task-stage-field.response';

@jsonObject
export class TaskStageResponse {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember public readonly status!: TaskStageStatus;
  @jsonMember public readonly startedAt!: Date;
  @jsonMember public readonly endedAt?: Date;
  @jsonMember public readonly stage!: ProcedureStageResponse;
  @jsonArrayMember(TaskStageFieldResponse) public readonly fields?: TaskStageFieldResponse[];

}
