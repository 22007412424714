import { jsonMember, jsonObject } from 'typedjson';
import { ContactResponse } from '@api/contacts-api/models/responses/contact.response';
import { BaseCallResponse } from '@api/conversations-api/models/responses/base-call.response';
import { CallPendingVideoRoomResponse } from '@api/conversations-api/models/responses/call-pending-video-room.response';

@jsonObject
export class IncomingCallResponse extends BaseCallResponse {
  @jsonMember public readonly caller!: ContactResponse;
  @jsonMember public readonly pendingRoom!: CallPendingVideoRoomResponse;
}
