import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NotificationItemViewModel } from '@core/layouts/dashboard-layout/components/dashboard-layout-header-notifications/header-notifications/models/notification-item.view-model';
import { CONVERSATIONS_NOTIFICATIONS_API } from '@api/conversations-api/services/tokens/notifications-api.token';
import { ConversationsNotificationsApi } from '@api/conversations-api/services/interfaces/conversations-notifications.api';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { CollectionResponse } from '@api/models/collection/responses/collection.response';
import { NotificationResponse } from '@api/conversations-api/models/responses/notification.response';
import { CONVERSATIONS_LIVE_DATA_API } from '@api/conversations-api/services/tokens/conversations-live-data-api.token';
import { ConversationsLiveDataApi } from '@api/conversations-api/services/interfaces/conversations-live-data.api';

@Injectable()
export class ConversationsNotificationsProvider implements OnDestroy {

  private readonly RELOAD_NOTIFICATIONS_DEBOUNCE_TIME_MS = 500;
  private source$ = new BehaviorSubject<NotificationItemViewModel[]>([]);
  private destroyed$ = new Subject<void>();

  public constructor(@Inject(CONVERSATIONS_NOTIFICATIONS_API) private conversationsNotificationsApi: ConversationsNotificationsApi,
                     @Inject(CONVERSATIONS_LIVE_DATA_API) private conversationsLiveDataApi: ConversationsLiveDataApi,
  ) {
    this.loadNotifications();
    this.subscribeForNotificationsLiveData();
  }

  public getSource(): Observable<NotificationItemViewModel[]> {
    return this.source$.asObservable();
  }

  private subscribeForNotificationsLiveData(): void {
    this.conversationsLiveDataApi
      .notificationsUpdated()
      .pipe(
        debounceTime(this.RELOAD_NOTIFICATIONS_DEBOUNCE_TIME_MS),
        takeUntil(this.destroyed$),
      ).subscribe(() => this.loadNotifications());
  }

  private loadNotifications(): void {
    const request = this.conversationsNotificationsApi
      .getCollectionRequestBuilder()
      .disablePagination()
      .build();

    this.conversationsNotificationsApi
      .unreadNotificationsList(request)
      .pipe(
        map((response) => this.mapToViewModels(response)),
      )
      .subscribe(
        (notifications) => this.source$.next(notifications),
        () => this.source$.next([]),
      );
  }

  private mapToViewModels(response: CollectionResponse<NotificationResponse>): NotificationItemViewModel[] {
    return response.collection.map((notification) => {
      return {
        title: notification.title,
        description: notification.message,
        time: notification.bumpedAt,
        data: notification,
        icon: undefined,
      };
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
