import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardLayoutHeaderUserInfoComponent } from './dashboard-layout-header-user-info.component';
import { AvatarModule } from 'primeng/avatar';
import { RippleModule } from 'primeng/ripple';
import { MenuModule } from 'primeng/menu';
import { AssetsModule } from '@core/assets/assets.module';


@NgModule({
  imports: [
    CommonModule,

    // Moduly wlasne
    AssetsModule,

    // PrimeNg
    AvatarModule,
    RippleModule,
    MenuModule,
  ],
  declarations: [DashboardLayoutHeaderUserInfoComponent],
  exports: [DashboardLayoutHeaderUserInfoComponent],
})
export class DashboardLayoutHeaderUserInfoModule {
}
