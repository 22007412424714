import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoverLayoutComponent } from './cover-layout.component';
import { RouterModule } from '@angular/router';
import { CoverLayoutHeaderModule } from './components/cover-layout-header/cover-layout-header.module';
import { CoverLayoutFooterModule } from './components/cover-layout-footer/cover-layout-footer.module';
import { ToastModule } from '@common/components/toast/toast.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    // Moduly wlasne
    CoverLayoutHeaderModule,
    CoverLayoutFooterModule,
    ToastModule,
  ],
  declarations: [
    CoverLayoutComponent,
  ],
})
export class CoverLayoutModule {
}
