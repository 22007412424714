import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class DictionaryItemOptionsRequest {

  @jsonMember public categoryPrefix?: string;

  public constructor(
    categoryPrefix?: string,
  ) {
    this.categoryPrefix = categoryPrefix;
  }

}
