import { Uuid } from '@app/api/types/uuid.type';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { PermissionGroupType } from '@api/cmuia-api/models/enums/permission-group-type';

@jsonObject
export class UpdatePermissionGroupRequest {

  @jsonMember(String) public readonly id!: Uuid;
  @jsonMember(String) public readonly type!: PermissionGroupType;
  @jsonMember public readonly description!: string;
  @jsonMember public readonly name!: string;
  @jsonArrayMember(String) public readonly permissionsIds?: Uuid[];
  @jsonMember public readonly color?: string;

  public constructor(id: Uuid, type: PermissionGroupType, name: string, description: string, permissionsIds?: Uuid[], color?: string) {
    this.id = id;
    this.type = type;
    this.name = name;
    this.description = description;
    this.permissionsIds = permissionsIds;
    this.color = color;
  }

}
