import { Injectable } from '@angular/core';
import { ProcedureStageExecutionOrderApi } from '@api/procedures-api/services/interfaces/procedure-stage-execution-order.api';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { Uuid } from '@app/api/types/uuid.type';
import { apiProceduresManagement } from '@environments/environment';
import { ProcedureStageExecutionOrderResponse } from '@api/procedures-api/models/responses/procedure-stage-execution-order.response';
import { Observable } from 'rxjs';
import { apiParams } from '@environments/const/environment.const';
import { IdResponse } from '@api/interfaces/id.response';
import { ProcedureStageExecutionOrderUpdateRequest } from '@api/procedures-api/models/requests/procedure-stage-execution-order-update.request';
import { ProcedureStageExecutionOrderCreateRequest } from '@api/procedures-api/models/requests/procedure-stage-execution-order-create.request';

const { STAGE_ID, ID } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class ProcedureStageExecutionOrderApiService implements ProcedureStageExecutionOrderApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(stageId: Uuid): Observable<ProcedureStageExecutionOrderResponse[]> {
    const { STAGES_EXECUTION_ORDER: { LIST } } = apiProceduresManagement.PROCEDURES;
    const url = this.grabEndpointUrl(LIST)
      .replace(STAGE_ID, stageId.toString());

    return this
      .http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToModelArray(ProcedureStageExecutionOrderResponse),
      );
  }

  public details(stageId: Uuid, id: Uuid): Observable<ProcedureStageExecutionOrderResponse> {
    const { STAGES_EXECUTION_ORDER: { DETAILS } } = apiProceduresManagement.PROCEDURES;
    const url = this.grabEndpointUrl(DETAILS)
      .replace(STAGE_ID, stageId.toString())
      .replace(ID, id.toString());

    return this
      .http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToModel(ProcedureStageExecutionOrderResponse),
      );
  }

  public create(stageId: Uuid, request: ProcedureStageExecutionOrderCreateRequest): Observable<IdResponse> {
    const { STAGES_EXECUTION_ORDER: { CREATE } } = apiProceduresManagement.PROCEDURES;
    const url = this.grabEndpointUrl(CREATE).replace(STAGE_ID, stageId.toString());
    const body = this.apiPayloadProcessor.toObject(request, ProcedureStageExecutionOrderCreateRequest);

    return this
      .http
      .post(url, body)
      .pipe(this.apiPayloadProcessor.mapToModel(IdResponse));
  }

  public update(stageId: Uuid, id: Uuid, request: ProcedureStageExecutionOrderUpdateRequest): Observable<void> {
    const { STAGES_EXECUTION_ORDER: { UPDATE } } = apiProceduresManagement.PROCEDURES;
    const url = this.grabEndpointUrl(UPDATE)
      .replace(STAGE_ID, stageId.toString())
      .replace(ID, id.toString());
    const body = this.apiPayloadProcessor.toObject(request, ProcedureStageExecutionOrderUpdateRequest);

    return this
      .http
      .put<void>(url, body);
  }

  public delete(stageId: Uuid, id: Uuid): Observable<void> {
    const { STAGES_EXECUTION_ORDER: { DELETE } } = apiProceduresManagement.PROCEDURES;
    const url = this.grabEndpointUrl(DELETE)
      .replace(STAGE_ID, stageId.toString())
      .replace(ID, id.toString());

    return this
      .http
      .delete<void>(url);
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiProceduresManagement.API_HOST_URL, endpoint].join('');
  }

}
