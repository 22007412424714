import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPayloadProcessorService } from '@api/helpers/api-payload-processor.service';
import { Observable } from 'rxjs';
import { apiProceduresCommon } from '@environments/environment';
import { ProceduresDictionariesApi } from '@api/procedures-api/services/interfaces/procedures-dictionaries.api';
import { DictionaryType } from '@api/dictionaries-api/models/enums/dictionary-type';
import { DictionaryItemValueResponse } from '@api/dictionaries-api/models/responses/dictionary-item-value.repsonse';
import { apiParams } from '@environments/const/environment.const';

const { TYPE } = apiParams;

@Injectable({
  providedIn: 'root',
})
export class ProceduresDictionariesApiService implements ProceduresDictionariesApi {

  public constructor(
    private readonly http: HttpClient,
    private readonly apiPayloadProcessor: ApiPayloadProcessorService,
  ) {
  }

  public list(type: DictionaryType): Observable<DictionaryItemValueResponse[]> {
    const { LIST } = apiProceduresCommon.DICTIONARIES;
    const url: string = this.grabEndpointUrl(LIST)
      .replace(TYPE, type);

    return this.http
      .get(url)
      .pipe(
        this.apiPayloadProcessor.mapToModelArray(DictionaryItemValueResponse),
      );
  }

  private grabEndpointUrl(endpoint: string): string {
    return [apiProceduresCommon.API_HOST_URL, endpoint].join('');
  }

}
