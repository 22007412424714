import { Directive, ElementRef, HostListener, Inject, Input, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appDropDashboardHeaderShadow]',
})
export class DropDashboardHeaderShadowDirective {

  private readonly HEADER_DROP_SHADOW_CSS_CLASS = 'grid-container--drop-header-shadow';

  @Input() public dropShadowOffsetY?: number;

  @HostListener('window:scroll')
  public onScroll(): void {
    this.manageHeaderDropShadow();
  }

  public constructor(@Inject(DOCUMENT) private document: Document,
                     private gridContainer: ElementRef,
                     private renderer: Renderer2) {
  }

  private manageHeaderDropShadow(): void {

    if (!this.dropShadowOffsetY || !this.document || !this.document.scrollingElement) {
      return;
    }

    const scrollTop = this.document.scrollingElement.scrollTop;

    if (scrollTop > this.dropShadowOffsetY) {
      this.dropHeaderShadow();
    } else {
      this.removeHeaderShadow();
    }
  }

  private dropHeaderShadow(): void {
    this.renderer.addClass(this.gridContainer.nativeElement, this.HEADER_DROP_SHADOW_CSS_CLASS);
  }

  private removeHeaderShadow(): void {
    this.renderer.removeClass(this.gridContainer.nativeElement, this.HEADER_DROP_SHADOW_CSS_CLASS);
  }
}
